<template>
  <div>
    <!-- Begin - Dashboard Side Navbar -->

    <!-- Component Start -->

    <SideBarInstaller />
    <!-- Component End  -->

    <!-- End - Dashboard Side Navbar  -->

    <!-- Begin -- Nav Top Bar -->

    <TopBarInstaller :user_info="user_info" />

    <!-- End - Nav Top Bar -->

    <!-- Begin - Dashboard Content -->

    <!-- Change the left padding to match the side nav bar -->
    <main class="pl-48 pt-24 p-4 w-full h-full bg-gray-100">
      <div class="flex flex-col mb-4">
        <h1 class="text-2xl font-bold text-gray-800">Accueil</h1>
      </div>

      <!-- Begin - Section 1 -->
      <div class="flex flex-row lg:flex-row gap-6">
        <div
          class="w-full lg:w-2/12 min-h-[200px] p-4 bg-white rounded-md shadow-md">
          <!-- <div class="flex flex-wrap lg:flex-nowrap py-2 h-full"> -->
          <div class="flex flex-wrap lg:flex-nowrap py-2">
            <!-- Replace div with img tag and relevant image -->
            <div
              class="flex flex-col items-center justify-center w-full lg:w-28 min-h-[120px] rounded-md border-2 object-cover">
              <img
                class="rounded-full border-1 border object-contain"
                :src="user_info.photo.small"
                alt="Workflow"
                v-if="user_info.photo" />
              <img
                class="rounded-full border-1 border object-contain w-full h-full"
                :src="installer_img"
                alt="Workflow"
                v-else />
            </div>

            <div class="px-4 self-center text-center lg:text-left lg:self-end">
              <ul class="pl-2">
                <li class="font-bold text-fuchsia-700 uppercase">Nom</li>
                <li class="text-gray-500 text-md">{{ name }}</li>
              </ul>
            </div>
          </div>
        </div>
        <div
          class="w-full lg:w-7/12 min-h-[200px] p-4 bg-white rounded-md shadow-md">
          <!-- <div class="flex flex-row py-2 h-full"> -->
          <div class="flex flex-row py-2">
            <div
              class="relative w-full min-w-[120px] lg:max-w-[120px] min-h-[120px] bg-purple-200 px-2 rounded-md">
              <span
                class="absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] text-4xl"
                >{{ my_poses.length }}</span
              >
            </div>
            <div class="px-4 self-end">
              <p v-if="my_poses.length > 0">
                <!-- Poses are assigned from -->
                Les poses sont attribuées à partir du module planning de pose.
                <span
                  class="uppercase text-gray-400 text-xs self-end"
                  v-for="pose in my_poses"
                  :key="pose">
                  <!-- {{ getClientDetails(pose.fk_soc).name }} --> </span
                >.
                <br />
                <!-- Please check below list for more details regarding date, location, materials etc. -->
                Veuillez consulter la liste ci-dessous pour obtenir plus de
                détails concernant la date, le lieu et les matériaux nécessaires
                pour les installations.
              </p>
              <p v-else>Vous n'avez pas encore attribué de poses.</p>
            </div>
          </div>
        </div>

        <div class="w-full lg:w-3/12 p-4 bg-red-700 rounded-md shadow-md">
          <h3 class="text-md font-bold uppercase text-white text-center">
            IMPORTANT
          </h3>
          <div
            class="flex flex-col justify-end gap-2 h-full pb-6"
            v-if="my_poses.length > 0">
            <div
              v-for="pose in my_poses.sort()"
              :key="pose">
         
              <div v-if="getDateFormat(pose.start_date) == present">
                <p
                class="uppercase text-xs text-white font-semibold">
                DOCUMENT(S) A FAIRE SIGNER POUR LA POSE {{ pose.id }}
              </p>
                <div
                  class="flex items-center text-xs text-gray-200"
                  v-if="faire_signer_files.length > 0"
                  v-for="files in faire_signer_files"
                  >
          
                  <svg
                    class="w-6 h-6 dark:text-white"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M15 13l-3 3m0 0l-3-3m3 3V8m0 13a9 9 0 110-18 9 9 0 010 18z"></path>
                  </svg>

                  <a
                    :href="files.url"
                    download
                    target="blank">
                    {{ files.name }}</a
                  >
                </div>
                <div
                  class="flex items-center cursor-pointer bg-white rounded-md px-2 hover:bg-red-300">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    aria-hidden="true"
                    role="img"
                    class="iconify iconify--fa6-solid w-6 h-8"
                    preserveAspectRatio="xMidYMid meet"
                    viewBox="0 0 576 512">
                    <path
                      class="fill-red-700"
                      d="M292.7 342.3c-3 3-4.7 7.1-4.7 11.4V416h62.34c4.264 0 8.35-1.703 11.35-4.727l156.9-158l-67.88-67.88L292.7 342.3zm275.8-174.9l-31.9-31.9c-9.875-10-26-10-36 0l-27.25 27.25l67.88 67.88l27.25-27.25c10.02-9.98 10.02-26.08.02-35.98zM256 0v128h128L256 0zm0 448c-16.07-.285-30.62-9.359-37.88-23.88c-2.875-5.875-8-6.5-10.12-6.5s-7.25.625-10 6.125l-7.749 15.38C187.6 444.6 181.1 448 176 448h-1.1c-6.5-.5-12-4.75-14-11L144 386.6l-10.6 31.9C127.5 436.1 111 448 92.45 448H80c-8.87 0-16-7.1-16-16s7.13-16 16-16h12.4c4.875 0 9.102-3.125 10.6-7.625l18.25-54.63C124.5 343.9 133.6 337.3 144 337.3s19.5 6.625 22.75 16.5l13.88 41.63c19.75-16.25 54.13-9.75 66 14.12c1.87 3.65 5.57 6.05 9.37 6.35V347a32.02 32.02 0 0 1 9.451-22.71L384 206.5V160H256c-17.67 0-32-14.33-32-32V0H48C21.49 0 0 21.49 0 48v416c0 26.5 21.49 48 48 48h288c26.51 0 48-21.49 48-48v-16H256z"></path>
                  </svg>
                  <input
                    type="file"
                    class="text-red-700 uppercase text-xs font-bold ml-2"
                    @change="
                      filesChange(
                        $event.target.name,
                        $event.target.files,
                        $event,
                        'faire_signer',
                        pose.id
                      )
                    " />
                </div>
              </div>
            </div>

            <div
              v-for="pose in my_poses.sort()"
              :key="pose">
              <p
                v-if="getDateFormat(pose.start_date) == present"
                class="uppercase text-xs text-white font-semibold">
                CHEQUE A RECUPERER POUR LA POSE {{ pose.id }}
              </p>
              <div v-if="getDateFormat(pose.start_date) == present">
                <div
                  class="flex items-center text-xs text-gray-200"
                  v-if="cheque_recuperer_files.length > 0"
                  v-for="files in cheque_recuperer_files"
                  >
                  <svg
                    class="w-6 h-6 dark:text-white"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M15 13l-3 3m0 0l-3-3m3 3V8m0 13a9 9 0 110-18 9 9 0 010 18z"></path>
                  </svg>
                  <a
                    :href="files.url"
                    download
                    target="blank">
                    {{ files.name }}</a
                  >
                </div>
                <div
                  class="flex items-center cursor-pointer bg-white rounded-md px-2 hover:bg-red-300">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    aria-hidden="true"
                    role="img"
                    class="iconify iconify--fa6-solid w-6 h-8"
                    preserveAspectRatio="xMidYMid meet"
                    viewBox="0 0 576 512">
                    <path
                      class="fill-red-700"
                      d="M292.7 342.3c-3 3-4.7 7.1-4.7 11.4V416h62.34c4.264 0 8.35-1.703 11.35-4.727l156.9-158l-67.88-67.88L292.7 342.3zm275.8-174.9l-31.9-31.9c-9.875-10-26-10-36 0l-27.25 27.25l67.88 67.88l27.25-27.25c10.02-9.98 10.02-26.08.02-35.98zM256 0v128h128L256 0zm0 448c-16.07-.285-30.62-9.359-37.88-23.88c-2.875-5.875-8-6.5-10.12-6.5s-7.25.625-10 6.125l-7.749 15.38C187.6 444.6 181.1 448 176 448h-1.1c-6.5-.5-12-4.75-14-11L144 386.6l-10.6 31.9C127.5 436.1 111 448 92.45 448H80c-8.87 0-16-7.1-16-16s7.13-16 16-16h12.4c4.875 0 9.102-3.125 10.6-7.625l18.25-54.63C124.5 343.9 133.6 337.3 144 337.3s19.5 6.625 22.75 16.5l13.88 41.63c19.75-16.25 54.13-9.75 66 14.12c1.87 3.65 5.57 6.05 9.37 6.35V347a32.02 32.02 0 0 1 9.451-22.71L384 206.5V160H256c-17.67 0-32-14.33-32-32V0H48C21.49 0 0 21.49 0 48v416c0 26.5 21.49 48 48 48h288c26.51 0 48-21.49 48-48v-16H256z"></path>
                  </svg>
                  <input
                    type="file"
                    class="text-red-700 uppercase text-xs font-bold ml-2"
                    @change="
                      filesChange(
                        $event.target.name,
                        $event.target.files,
                        $event,
                        'cheque_recuperer_files',
                        pose.id
                      )
                    " />
                </div>
              </div>
            </div>

            <!-- <div  v-for="pose in my_poses" :key="pose" >
              <div v-if="getDateFormat(pose.start_date) == present" >
              <p class="uppercase text-xs text-white font-semibold mt-2">CHEQUE A RECUPERER POUR LA POSE {{pose.id}}</p>
              <div class="flex items-center text-xs text-gray-200" v-if="cheque_recuperer_files[pose.id]">
                <svg class="w-6 h-6 dark:text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 13l-3 3m0 0l-3-3m3 3V8m0 13a9 9 0 110-18 9 9 0 010 18z">                    
                  </path>
                </svg>
                <a :href="cheque_recuperer_files[pose.id].url" download target="blank"> {{cheque_recuperer_files[pose.id].name}}</a>
              </div>
              <div
                class="flex items-center cursor-pointer bg-white rounded-md px-2 hover:bg-red-300"
                v-if="pose.collect_cheque == '1'"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  aria-hidden="true"
                  role="img"
                  class="iconify iconify--fa6-solid w-6 h-8"
                  preserveAspectRatio="xMidYMid meet"
                  viewBox="0 0 576 512"
                >
                  <path
                    class="fill-red-700"
                    d="M292.7 342.3c-3 3-4.7 7.1-4.7 11.4V416h62.34c4.264 0 8.35-1.703 11.35-4.727l156.9-158l-67.88-67.88L292.7 342.3zm275.8-174.9l-31.9-31.9c-9.875-10-26-10-36 0l-27.25 27.25l67.88 67.88l27.25-27.25c10.02-9.98 10.02-26.08.02-35.98zM256 0v128h128L256 0zm0 448c-16.07-.285-30.62-9.359-37.88-23.88c-2.875-5.875-8-6.5-10.12-6.5s-7.25.625-10 6.125l-7.749 15.38C187.6 444.6 181.1 448 176 448h-1.1c-6.5-.5-12-4.75-14-11L144 386.6l-10.6 31.9C127.5 436.1 111 448 92.45 448H80c-8.87 0-16-7.1-16-16s7.13-16 16-16h12.4c4.875 0 9.102-3.125 10.6-7.625l18.25-54.63C124.5 343.9 133.6 337.3 144 337.3s19.5 6.625 22.75 16.5l13.88 41.63c19.75-16.25 54.13-9.75 66 14.12c1.87 3.65 5.57 6.05 9.37 6.35V347a32.02 32.02 0 0 1 9.451-22.71L384 206.5V160H256c-17.67 0-32-14.33-32-32V0H48C21.49 0 0 21.49 0 48v416c0 26.5 21.49 48 48 48h288c26.51 0 48-21.49 48-48v-16H256z"
                  ></path>
                </svg>
                <input
                  type="file"
                  class="text-red-700 uppercase text-xs font-bold ml-2"
                  placeholder="Cheque a recuperer"
                  @change="
                        filesChange($event.target.name, $event.target.files, $event, 'cheque_recuperer', pose.id);"
                />
              </div>
              </div>
            </div> -->
          </div>
          <p
            v-else
            class="text-white text-center py-2">
            Vous n'avez pas encore attribué de poses.
          </p>
        </div>
      </div>
      <!-- End - Section 1 -->

      <!-- Begin - Section 2 -->

      <div class="flex flex-row lg:flex-row gap-6 mt-4">
        <!-- Begin - 1st Column (Coordnnes Client / Informatins Sur La Pose / Dssier Metre) -->
        <div class="w-full lg:w-4/12 min-h-[200px]">
          <div class="w-full min-h-[200px] p-4 bg-white rounded-md shadow-md">
            <h3
              class="text-md font-bold uppercase border-b-2 border-fuchsia-700 text-fuchsia-700">
              Informations Sur La Pose
            </h3>

            <div class="h-[550px] mt-2 overflow-y-scroll">
              <div
                class="flex items-center justify-between my-4 rounded-md"
                v-if="my_poses.length > 0">
                <div
                  v-for="(pose, index) in my_poses"
                  :key="index">
                  <div v-if="getDateFormat(pose.start_date) == present">
                    <p class="text-sm overflow-y-scroll">
                      <strong>Pose Id :&nbsp; </strong>{{ pose.id }}
                    </p>
                    <br />
                    <p class="text-sm overflow-y-scroll">
                      <strong>Remarques clients :&nbsp; </strong
                      >
                      
                      <p v-html="pose.note_client"></p>
                    </p>
                    <br />
                    <p class="text-sm overflow-y-scroll">
                      <strong>Remarques métrés: &nbsp;</strong>
                      {{ pose.note_measurement }}
                    </p>
                    <br />
                    <p class="text-sm overflow-y-scroll">
                      <strong>Remarques hôtels:&nbsp; </strong>
                      <p v-html="pose.note_sale"></p>
                    </p>
                    <br />
                    <p class="text-sm overflow-y-scroll">
                      <strong>Notes:&nbsp; </strong>
                      <p v-html="pose.note_public"></p>
  
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Begin - 3rd Column (Hotel / Nte Sur La Pose) -->
        <div
          class="w-full lg:w-8/12 min-h-[200px] p-4 bg-white rounded-md shadow-md">
          <h3
            class="text-md font-bold border-b-2 border-fuchsia-700 text-fuchsia-700">
            LISTE DES POSES PLANIFIÉES
          </h3>

          <div
            class="flex items-center justify-between my-4 bg-fuchsia-700 p-3 rounded-md">
            <div class="rounded-full cursor-pointer hover:bg-white/25">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5"
                viewBox="0 0 20 20"
                fill="white">
                <path
                  fill-rule="evenodd"
                  d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                  clip-rule="evenodd" />
              </svg>
            </div>
            <div class="text-xs text-white font-bold uppercase">
              Toutes les poses assignées
            </div>
            <div class="rounded-full cursor-pointer hover:bg-white/25">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5"
                viewBox="0 0 20 20"
                fill="white">
                <path
                  fill-rule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clip-rule="evenodd" />
              </svg>
            </div>
          </div>

          <div
            class="px-4 max-h-[450px] overflow-y-scroll"
            v-if="my_poses.length > 0">
            <!-- INSTALLER POSES -->
            <div
              class="w-full p-4 rounded-md my-4"
              v-for="(pose, index) in my_poses"
              :key="index"
              :class="{
                'bg-[#ffe5e5]': index == 0,
                'bg-[#ffe5e5]': index % 2,
                'bg-[#e6f9ee]': !(index % 2)
              }">
              <div
                class="flex items-center py-2 gap-4 border-b-2 border-dotted">
                <p class="font-bold uppercase text-sm">
                  {{ getClientDetails(pose.fk_soc).name }}
                </p>
                <span class="uppercase text-gray-400 text-xs self-end"
                  >CODE POSTAL: {{ getClientDetails(pose.fk_soc).zip }}
                </span>
              </div>

              <div
                class="flex flex-col md:flex-row items-start md:items-center justify-between mt-4">
                <div class="">
                  <span
                    class="text-sm text-gray-400 px-2 uppercase"
                    v-for="category in JSON.parse(pose.categories)"
                    :key="category"
                    :class="[
                      'text-[#' + getProductGroupDetails(category).color + ']'
                    ]"
                    >{{ getProductGroupDetails(category).name }}</span
                  >
                  <span
                    class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full bg-white border-2 last:mr-0 mr-1"
                    :class="{
                      'text-green-500': pose.status == 2,
                      'text-red-500': pose.status == 1,
                      'text-gray-500': pose.status == 0
                    }">
                    <strong v-if="pose.status == 0"
                      >Planification en attente</strong
                    >
                    <strong v-if="pose.status == 1"
                      >Planifié, Non attribué</strong
                    >
                    <strong v-if="pose.status == 2">Planifié, attribué</strong>
                  </span>
                  <span
                    class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full bg-white border-2 last:mr-0 mr-1"
                    :class="{
                      'text-green-500': pose.validate == '1',
                      'text-red-500': pose.validate == '0'
                    }">
                    <strong v-if="pose.validate == '1'">Validé</strong>
                    <strong v-if="pose.validate == '0'"
                      >En attente de validation</strong
                    >
                  </span>
                </div>
                <div class="flex gap-4 items-center p-1">
                  <span class="font-bold"
                    >{{ getDateFormat(pose.start_date) }} au
                    {{ getDateFormat(pose.end_date) }}</span
                  >
                  <router-link :to="{ path: '/installer' + pose.id }">
                    <button
                      class="p-2 rounded-md bg-fuchsia-700 text-white uppercase text-xs hover:bg-fuchsia-400 transition-all">
                      Voir la fiche
                    </button>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
          <div
            class="px-4 max-h-[450px] overflow-y-scroll"
            v-else>
            <div class="flex items-center justify-center">
              <p class="text-sm text-gray-400">Aucune pose planifiée</p>
              <button
                disabled
                type="button"
                class="text-white hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 inline-flex items-center"
                :class="'bg-[#9f137e]'"
                v-if="my_posesStatus">
                <svg
                  role="status"
                  class="inline w-4 h-4 mr-3 text-white animate-spin"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="#E5E7EB" />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentColor" />
                </svg>
                <!-- Processing... Please wait. -->
                Traitement... Veuillez patienter.
              </button>
            </div>
          </div>
        </div>
        <!-- End - 3rd Column (Hotel / Note Sur La Pose) -->
      </div>

      <!-- End - Section 2 -->
    </main>

    <!-- End - Dashboard Content -->
  </div>
</template>
<script>
import logo from '../assets/logo.png'
import installer_img from '../assets/installer.png'
import store from '@/store'
import TopBarInstaller from '@/components/TopBarInstaller.vue'
import SideBarInstaller from '@/components/SideBarInstaller.vue'
import { mapState } from 'vuex'
import axios from '../http-common' // <-- HTTP common
import moment from 'moment'

export default {
  title: 'InstallerHomeView',
  components: {
    TopBarInstaller,
    SideBarInstaller
  },
  data() {
    return {
      present: '',
      logo,
      installer_img,
      name: '',
      selected_agency: '',
      user_info: {},
      my_poses: [],
      my_posesStatus: false,
      faire_signer_files: [],
      cheque_recuperer_files:[]
    }
  },

  methods: {
    uploadFile(payload) {
      axios.post('installationapi/upload', payload, {
        headers: {
          'Content-Type': 'multipart/form-data',
          DOLAPIKEY: JSON.parse(window.localStorage.getItem('user-info')).data
            .success.token
        }
      })
    },
    // File Upload
    filesChange(fieldName, fileList, $event, name, pose_id) {
      let previewImage = ''
      if (!fileList.length) return
      const file = $event.target.files[0]
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = event => {
        previewImage = event.target.result.split(';base64,')[1]
        Array.from(Array(fileList.length).keys()).map(x => {
          this.uploadFile({
            filename: name + '__' + fileList[x].name,
            id: pose_id,
            fileencoding: 'base64',
            filecontent: previewImage,
            overwriteifexists: 1
          })
        })
      }
    },
    handleLogout() {
      this.$store.commit('setAuthentication', false)
      localStorage.removeItem('user-info')
      this.$router.push('/login')
    },
    getUserInfo() {
      let header = {
        DOLAPIKEY: JSON.parse(window.localStorage.getItem('user-info')).data
          .success.token
      }
      axios
        .get(
          'installationapi/user?includePermissions=1&includeGroups=1&includeLeaves=1',
          {
            headers: header
          }
        )
        .then(response => {
          this.user_info = response.data
        })
        .catch(error => {
          this.user_info = {}
        })
    },

    getClientDetails(id) {
      let name = ''
      let zip = ''
      try {
        this.$store.state.clients.forEach(client => {
          if (client.id == id) {
            name = client.name
            zip = client.zip
          }
        })
        return { name: name, zip: zip }
      } catch (error) {
        return 'Not found'
      }
    },

    getDateFormat(date) {
      return moment.unix(date).format('DD/MM/YYYY')
    },

    presentDay() {
      var today = new Date()
      var dd = String(today.getDate()).padStart(2, '0')
      var mm = String(today.getMonth() + 1).padStart(2, '0') //January is 0!
      var yyyy = today.getFullYear()
      today = dd + '/' + mm + '/' + yyyy
      this.present = today
      // console.log(this.present)
      // console.log('new',today)
      // let posse = "";
      // console.log(this.my_poses)
      // this.my_poses.forEach((posse) => {
      //   console.log(posse)
      // if (this.getDateFormat(posse.start_date) == moment().format('l')) {
      // console.log(this.getDateFormat(posse.start_date))
      // if (this.getDateFormat(posse.start_date) == today) {
      // // console.log (posse.date_creation);
      // return posse.date_creation
      // }

      // });
    },

    getProductGroupDetails(id) {
      let name = ''
      let color = ''
      this.product_groups.forEach(product_group => {
        if (product_group.id == id) {
          name = product_group.name
          color = product_group.color
        }
      })
      return { name: name, color: color }
    },

    loadPoses(id) {
      return new Promise(resolve => {
        let api_url =
          `/installationapi/installations?agency_id=` +
          this.$store.state.selectedAgencyID
        let data = []
        this.installer_poses = []
        axios
          .get(api_url, {
            headers: {
              DOLAPIKEY: JSON.parse(window.localStorage.getItem('user-info'))
                .data.success.token
            }
          })
          .then(response => response.data)
          .then(poses => {
            poses.reverse().forEach(pose => {
              const keys = Object.keys(pose.planning)
              keys.forEach((key, index) => {
                const day_keys = Object.keys(pose.planning[key].days)
                day_keys.forEach((k, i) => {
                  const team_keys = Object.keys(
                    pose.planning[key].days[k].teams
                  )
                  team_keys.forEach((l, j) => {
                    if (pose.planning[key].days[k].teams[l].members) {
                      const memb_keys = Object.keys(
                        pose.planning[key].days[k].teams[l].members
                      )
                      memb_keys.forEach((m, o) => {
                        // if(id == pose.planning[key].days[k].teams[l].members[m]){
                        if (pose.planning[key].days[k].teams[l].members[m]) {
                          if (!data.includes(pose.id)) {
                            if (this.my_poses.indexOf(pose) == -1) {
                              this.my_poses.push(pose)
                              // console.log(pose,"jack");
                              this.fetchPoseData(pose.id)
                            }
                          }
                        }
                      })
                    }
                  })
                })
              })
            })
          })
        resolve('resolved')
      })
    },

    fetchPoseData: function (id) {
      let api_url = `/installationapi/installations/` + id
      axios
        .get(api_url, {
          headers: {
            DOLAPIKEY: JSON.parse(window.localStorage.getItem('user-info')).data
              .success.token
          }
        })
        .then(response => response.data)
        .then(pose => {
          if (pose.files.length > 0) {
            // console.log("kay",pose)
            // if (this.getDateFormat(pose.date_creation) == this.present ){
            pose.files.filter(file => {
              if (file.name.startsWith('faire_signer')) {
                this.faire_signer_files.push(file);
              }
              if (file.name.startsWith('cheque_recuperer')) {
                this.cheque_recuperer_files.push(file);
              }
            })
            // }
          }
        })
    }
  },
  async mounted() {
    this.presentDay()
    this.getDateFormat()
    this.my_posesStatus = true
    this.my_poses = []
    const authUser = JSON.parse(window.localStorage.getItem('user-info'))
    this.name = authUser.firstname + ' ' + authUser.lasttname
    this.getUserInfo()
    await this.loadPoses(authUser.id)
    this.my_posesStatus = false
    this.getClientDetails()
  },
  computed: {
    ...mapState({
      agencies: state => state.agencies,
      installer_poses: state => state.installer_poses,
      product_groups: state => state.product_groups,
      poses: state => state.poses
    })
  }
}
</script>
<style scoper>
.logo {
  width: 6rem;
  height: 4rem;
}
.box {
  background-color: #9f137e;
  color: #fff;
}
.pink {
  color: #fff;
}
</style>
