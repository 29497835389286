<template>
  <!-- Begin -- Nav Top Bar -->

  <div
    class="
      fixed
      left-0
      px-16
      top-0
      h-14
      w-screen
      text-gray-700
      bg-gray-100
      rounded
      top-nav
    "
  >
    <div class="flex items-center h-14">
      <h1 class="top-nav__item--header">Deal Eco</h1>

      <!-- <div class="flex mx-6">
        <select
          class="p-2 rounded-md"
          style="background: #fff"
          v-model="selected_agency"
        >
          <option disabled value="">Please select agency</option>
          <option
            v-for="agency in agencies"
            :key="agency.id"
            :value="agency.id"
          >
            {{ agency.label }}
          </option>
        </select>
      </div> -->

      <div class="relative flex items-center ml-auto">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="absolute right-4 h-6 w-6 cursor-pointer"   
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          @click="querySearch"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M8 16l2.879-2.879m0 0a3 3 0 104.243-4.242 3 3 0 00-4.243 4.242zM21 12a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
        <input
          type="text"
          name="search"
          id=""
          placeholder="Recherche pose..."
          class="py-2 pl-4 pr-12 rounded-md"
          v-model="query"
          :class="{ 'bg-red-50 border border-red-500': queryError == true }"
        />
      </div>
      <div class="flex items-center gap-2 ml-6">
        <div v-if="user_info.photo">
          <img class="h-10 w-10 rounded-full" :src="user_info.photo.mini" alt="Workflow" v-if="user_info.photo"/>
        </div>
        <div v-else>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-10 w-10"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fill-rule="evenodd"
              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
              clip-rule="evenodd"
            />
          </svg>
        </div>
        <div class="flex flex-col">
          <span class="text-xs uppercase" v-if="name">{{ name }}</span>
          <span
            class="text-xs capitalize text-gray-400"
            v-for="group in groups"
            :key="group"
            >{{ group }}</span
          >
        </div>
      </div>

      <div class="flex ml-6">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-5 w-5"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
          />
        </svg>
      </div>

      <div
        class="w-5 h-5 cursor-pointer ml-2"
        title="Logout"
        @click.prevent="handleLogout"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          aria-hidden="true"
          role="img"
          class="iconify iconify--icons8 w-5 h-5"
          preserveAspectRatio="xMidYMid meet"
          viewBox="0 0 32 32"
        >
          <path
            class="fill-red-700"
            d="M15 4v12h2V4h-2zm-3 .688C7.346 6.338 4 10.788 4 16c0 6.617 5.383 12 12 12s12-5.383 12-12c0-5.213-3.346-9.662-8-11.313v2.157C23.526 8.39 26 11.91 26 16c0 5.514-4.486 10-10 10S6 21.514 6 16c0-4.09 2.474-7.61 6-9.156V4.687z"
          ></path>
        </svg>
      </div>
    </div>
  </div>

  <!-- End - Nav Top Bar -->
</template>

<script>
import { computed } from "@vue/runtime-core";
import { mapState } from "vuex";

export default {
  name: "TopBar",
  props: {
    msg: String,
    agency: String,
    user_info: Object,
  },
  data() {
    return {
      name: "",
      groups: [],
      selected_agency: "",
      query: "",
      queryError: false,
    };
  },
  created() {
    this.$store.dispatch("loadAgencies");
  },
  methods: {
    handleLogout() {
      this.$store.commit("setAuthentication", false);
      localStorage.removeItem("user-info");
      this.$store.commit("setUserInfo", {});
      this.$router.push("/login");
      this.$store.commit("clearData");  
    },
    querySearch() {
      if(this.query) {
        this.queryError = false;
        this.$router.push({
          name: "sales_search",
          params: {
            query: this.query,
          },
        });
      }else{
        this.queryError = true;
      }
    },
  },
  watch: {
    selected_agency: function () {
      this.$store.dispatch("updateAgencySelection", this.selected_agency);
      this.$emit("agency-changed");   
    },
    query: function () {
      this.queryError = false;
    },
  },
  mounted() {
    this.query = this.query_string;
    const authUser = JSON.parse(window.localStorage.getItem("user-info"));

    this.name = authUser.firstname + " " + authUser.lasttname;
    authUser.user_groups.forEach((group) => {
      this.groups.push(group.name);
    });
    if (this.$store.state.selectedAgencyID) {
      this.selected_agency = this.$store.state.selectedAgencyID;
    } else {
      if (parseInt(authUser.agency_id) > 0) {
        this.selected_agency = parseInt(authUser.agency_id);
      }
      location.reload();
    }
  },
  computed: {
    ...mapState({
      agencies: (state) => state.agencies,
    }),
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
