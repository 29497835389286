import { createApp } from 'vue' // import the createApp function
import App from './App.vue' // <-- App.vue
import "./assets/css/style.css"; // main CSS file

import router from './router' // <-- import the router
import store from './store'  // <-- store

import 'v-calendar/dist/style.css';  // CSS for Calender
import titleMixin from './mixins/titleMixin' // <-- mixin
import CKEditor from '@ckeditor/ckeditor5-vue';

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

const options = {
    confirmButtonColor: '#9f137e',
    cancelButtonColor: '#ff7674',
};

createApp(App)
    .use(store)
        .use(router)
        .use(CKEditor)
            .use(Datepicker)
                .use(VueSweetalert2, options)
                    .mixin(titleMixin)
                        .mount('#app')