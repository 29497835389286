<template>
  <div class="bg-white rounded-md">
    <SideBar />
    <TopBar />

    <!-- Begin - Dashboard Content -->

    <!-- Begin - Dashboard Content -->
      <main class="pl-24 pt-24 p-4 w-full h-full bg-gray-200">
          <div
          class="
            relative
            z-10
            flex
            items-baseline
            justify-between
            pt-6
            pb-6
            border-b border-gray-200
          "
          v-if="searchedPoses.length > 0"
        >
          <h1 class="text-4xl font-extrabold tracking-tight text-gray-900 uppercase">
            poses assorties - "{{query}}"
          </h1>
        </div>

        <section aria-labelledby="products-heading" class="pt-6 pb-24" v-if="searchedPoses.length > 0">
          <h2 id="products-heading" class="sr-only">Products</h2>

          <div class="flex flex-wrap space-y-4 lg:space-y-0">
            <!-- Filters -->
            <!-- Product grid -->
            <div class="w-full lg:w-12/12">
              <!-- Replace with your content -->

              <div
                class="
                  border-4 border-dashed border-gray-200
                  rounded-lg
                  mx-2
                  h-96
                  overflow-scroll
                  lg:h-full
                "
              >
                <table class="w-full text-base text-left text-gray-500" 
                  v-if="searchedPoses.length > 0">
                  <thead class="text-xs text-gray-700 uppercase bg-gray-50">
                    <tr>
                      <th scope="col" class="px-6 py-3">ID</th>
                      <th scope="col" class="px-6 py-3">Client</th>
                      <th scope="col" class="px-6 py-3">Financement</th>
                      <th scope="col" class="px-6 py-3">logement</th>
                      <th scope="col" class="px-6 py-3">Date D'Installation</th>
                      <th scope="col" class="px-6 py-3">Montant</th>
                      <th scope="col" class="px-6 py-3">Statut</th>
                      <th scope="col" class="px-6 py-3">
                        <span class="sr-only">Modifier</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      class="bg-white border-b"
                      v-for="pose in searchedPoses"
                      :key="pose"
                      >
                      <th
                        scope="row"
                        class="
                          px-6
                          py-4
                          font-medium
                          text-gray-900
                          whitespace-nowrap
                        "
                      >
                        {{ pose.id }}
                      </th>
                      <td class="px-6 py-4">
                        <router-link
                          :to="{ path: '/clients/' + pose.client  }"
                          >
                          <span class="title">
                            <strong> {{ getClientDetails(pose.client) }} </strong>
                          </span>
                        </router-link
                        >                        
                      </td>
                      <td class="px-6 py-4">
                        {{ getPaymentDetails(pose.financement) }}
                      </td>
                      <td class="px-6 py-4">
                        {{ pose.logement }}
                      </td>
                      <td class="px-6 py-4" v-if="pose.start_date">
                        {{ pose.start_date }}
                      </td>
                      <td class="px-6 py-4" v-else>
                        <span
                          class="text-red-500"
                        >
                          <strong>En Attente</strong>
                        </span>
                      </td>
                      <td class="px-6 py-4">
                        <span
                          :class="{
                            'text-green-500': pose.estimated_cost >= 1000,
                            'text-red-500': pose.estimated_cost < 0,
                          }"
                        >
                          <strong>{{ pose.estimated_cost }} </strong>
                        </span>
                      </td>
                      <td class="px-6 py-4">
                        <span
                        class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full bg-white border-2 uppercase last:mr-0 mr-1"
                          :class="{
                            'text-blue-500': pose.status == 3,
                            'text-green-500': pose.status == 2,
                            'text-red-500': pose.status == 1,
                            'text-gray-500': pose.status == 0,
                          }"
                        >
                          <strong v-if="pose.status == 0">Plannification En Attente</strong>
                          <strong v-if="pose.status == 1">Planned, Not assigned</strong>
                          <strong v-if="pose.status == 2">Planned, Assigned</strong>
                          <strong v-if="pose.status == 3">FINITION</strong>
                        </span>
                      </td>

                      <td class="px-6 py-4 text-right">
                        <router-link
                          :to="{ path: '/pose/edit/' + pose.id }"
                          append
                          class="
                            dashboard-bot__form--formbtn
                            submit
                            save_continue
                            edit_link
                          "
                          >Modifier</router-link
                        >
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div v-else 
                    class="
                      text-center
                      overflow-scroll
                      lg:h-auto
                      py-20
                      ">   
                      <label class="text-center text-gray-500 py-2">
                      Aucune pose trouvée                      
                    </label><br/>
                    <router-link
                        :to="{ path: '/pose' }"
                        append
                        class="
                          dashboard-bot__form--formbtn
                          save_continue
                          edit_link mt-2
                        "
                        >Ajouter une pose</router-link>
                  </div>
              </div>
              <!-- /End replace -->
            </div>
          </div>

        </section>

        <div
          class="
            relative
            z-10
            flex
            items-baseline
            justify-between
            pt-6
            pb-6
            border-b border-gray-200
          " v-if="searchedClients.length > 0"
        >
          <h1 class="text-4xl font-extrabold tracking-tight text-gray-900 uppercase">
            Clients assorties  - "{{query}}""
          </h1>
        </div>

        <section aria-labelledby="products-heading" class="pt-6 pb-24" v-if="searchedClients.length > 0">

          <div class="flex flex-wrap space-y-4 lg:space-y-0">
            

            <!-- Product grid -->
            <div class="w-full lg:w-12/12">
              <!-- Replace with your content -->
              <div
                class="
                  border-4 border-dashed border-gray-200
                  rounded-lg
                  h-96
                  lg:h-full
                "
              >
                <table class="w-full text-base text-left text-gray-500" v-if="searchedClients.length > 0">
                  <thead class="text-xs text-gray-700 uppercase bg-gray-50">
                    <tr>
                      <th scope="col" class="px-6 py-3">ID</th>
                      <th scope="col" class="px-6 py-3">Client</th>
                      <th scope="col" class="px-6 py-3">ADRESSE</th>
                      <th scope="col" class="px-6 py-3">CODE POSTAL</th>
                      <th scope="col" class="px-6 py-3">TELEPHONE</th>
                      <th scope="col" class="px-6 py-3">Portable</th>
                      <th scope="col" class="px-6 py-3">Email</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      class="bg-white border-b"
                      v-for="client in searchedClients"
                      :key="client"
                    >
                      <th
                        scope="row"
                        class="
                          px-6
                          py-4
                          font-medium
                          text-gray-900
                          whitespace-nowrap
                        "
                      >
                        <router-link
                          :to="{ path: '/clients/' + client.id  }"
                          >
                          {{ client.id }}
                        </router-link>
                      </th>
                      <td class="px-6 py-4">                                                
                          <router-link
                          :to="{ path: '/clients/' + client.id  }"
                          >
                            <span class="title">
                              <strong> {{ client.name }} </strong>
                            </span>
                        </router-link>
                      </td>
                      <td class="px-6 py-4">
                        {{ client.address1 }}, {{ client.address2 }}
                      </td>
                      <td class="px-6 py-4">
                        {{ client.zip }}
                      </td>
                      <td class="px-6 py-4">
                        {{ client.phone }}
                      </td>
                      <td class="px-6 py-4">
                        {{ client.portable }}
                      </td>
                      <td class="px-6 py-4">
                        {{ client.email }}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div v-else 
                    class="
                      text-center
                      overflow-scroll
                      lg:h-auto
                      py-20
                      ">   
                      <label class="text-center text-gray-500 py-2">
                      Aucun client          
                    </label>
                  </div>
              </div>
              <!-- /End replace -->
            </div>
          </div>

          <div class="flex flex-col items-center mt-3">
            
          </div>
        </section>

         <div
          class="
            relative
            z-10
            flex
            items-baseline
            justify-between
            pt-6
            pb-6
            border-b border-gray-200
          " v-if="searchedClients.length == 0 && searchedPoses.length == 0"
        >
          <h1 class="text-4xl font-extrabold tracking-tight text-gray-900 uppercase">
            No results found  - "{{query}}""
          <router-link
                        :to="{ path: '/' }"
                        append
                        class="
                          dashboard-bot__form--formbtn
                          save_continue
                          edit_link mt-2
                        "
                        >De retour à la maison</router-link>
          </h1>
        </div>
      </main>
      <!-- End - Dashboard Content -->

    <!-- End - Dashboard Content -->
  </div>
    
</template>

<script>
// @ is an alias to /src
import SideBar from '@/components/SideBar.vue'
import TopBar from '@/components/TopBar.vue'
import { mapGetters, mapState } from "vuex";
import moment from "moment"; // <-- Moment


export default {
  title: 'SearchView',
  name: 'SearchView',
  components: {
    SideBar,
    TopBar,
  }, 
  data() {
    return {
        query: '',
        searchedPoses: [],
        searchedClients: [],
        payment_methods: {
            '0': "Chèque",
            '1': "Virement bancaire",
            '2': "Espèce",
            '3': "Carte bancaire",
            '4': "Ordre de prélèvement",
            '5': "TIP",
            '6': "Cetelem",
            '7': "Financo",
            '8': "Domofinance",
            '9': "Franfinance",
            '10': "Projexio",
        }

        // payment_methods: {
        //     '0': "Bancontact",
        //     '1': "Credit card",
        //     '2': "Cheque",
        //     '3': "Factor",
        //     '4': "Giropay",
        //     '5': "iDeal",
        //     '6': "Klarna",
        //     '7': "LCR",
        //     '8': "Cash",
        //     '9': "Debit order",
        //     '10': "Sofort", 
        //     '11': "TIP", 
        //     '12': "Traite",
        //     '13': "Online Payment",
        //     '14': "Transfer",
        // }
        
    }
  },
  mounted() {
    this.$store.dispatch("loadPoses", { limit: "", page: "" });
    this.query = this.$route.params.query;
    this.$store.dispatch("loadClients", { limit: "", page: "" });
    this.searchedPoses = [];
    this.searchedClients = [];
    this.searchedPoses = this.poses.filter(pose => pose.client == this.query);
    this.poses.forEach(pose => {
      let client_name = this.getClientDetails(pose.client);
      if (client_name.toLowerCase().includes(this.query.toLowerCase())) {
        this.searchedPoses.push(pose);
      }
    });
    this.clients.forEach(client => {
      if (client.name.toLowerCase().includes(this.query.toLowerCase())) {
        this.searchedClients.push(client);
      }
    });

  },
  computed: {
     ...mapState({
      clients: (state) => state.clients,
      poses: (state) => state.poses,
    }),
  },
  beforeRouteUpdate (to, from) {
    this.searchedPoses = [];
    this.searchedClients = [];

    this.query = to.params.query;
    this.searchedPoses = this.poses.filter(pose => pose.client == this.query);
    this.poses.forEach(pose => {
      let client_name = this.getClientDetails(pose.client);
      if (client_name.toLowerCase().includes(this.query.toLowerCase())) {
        this.searchedPoses.push(pose);
      }
    });
    this.clients.forEach(client => {
      if (client.name.toLowerCase().includes(this.query.toLowerCase())) {
        this.searchedClients.push(client);
      }
    });
  },
  methods: {
    getPaymentDetails(id) {
      return this.payment_methods[id];
    },
    getClientDetails(id) {
      let name = "";
      try {        
        this.$store.state.clients.forEach((client) => {
          if (client.id == id) {
            name =  client.name
          }
        });
        return name;
      } catch (error) {
        return "Not found";
      }
    },
  }
}
</script>
<style scoped>
h6.title{
  color: #9f137e;
}
span.title {
  color: #9f137e;
}
a.edit_link {
  background-color: #9f137e;
  color: #fff;
  border: #9f137e solid 1px;
}
a.edit_link:hover {
  color: #9f137e;
  background-color: #fff;
  border: #9f137e solid 1px;
}

</style>
