<template>
    <div class="filter-box">
        <slot name="categories" />
    </div>
</template>
<script>
export default {
    name: 'FilterBox',
    props: {
        category: {
            type: String,
            default: 'All'
        }
    },
};
</script>