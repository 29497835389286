<template>
  <div class="bg-white rounded-md">
    <div>
      <div class="relative z-40 lg:hidden" role="dialog" aria-modal="true">
        <div class="fixed inset-0 bg-black bg-opacity-25"></div>
      </div>

      <main class="max-w107xl mx-2 px14 sm:px-6">
        <div
          class="
            relative
            z-10
            flex
            items-baseline
            justify-between
            pt-6
            pb-6
            border-b border-gray-200
          "
        >
          <h1 class="text-4xl font-extrabold tracking-tight text-gray-900">
            CLIENTS
          </h1>
          <input type="hidden" name="customfield" class="form-control" v-model="agency_changed">
          <div class="relative flex items-center ml-auto">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="absolute right-4 h-6 w-6 cursor-pointer"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  @click="querySearch"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M8 16l2.879-2.879m0 0a3 3 0 104.243-4.242 3 3 0 00-4.243 4.242zM21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
                <input
                  type="text"
                  name="search"
                  id="id_recherche"
                  placeholder="Recherche client..."
                  class="py-2 pl-4 pr-12 rounded-md"
                  v-model="query"
                  :class="{ 'bg-red-50 border border-red-500': queryError == true }"
                />
              </div>
        </div>

        <section aria-labelledby="products-heading" class="pt-6 pb-24">
          <h2 id="products-heading" class="sr-only">Products</h2>

          <div class="flex flex-wrap space-y-4 lg:space-y-0">
            <!-- Filters -->
            <div class="w-full lg:w-2/12">
              <AddNewClient @client-submited="clientSubmitted" class="p-2"/>
              <form class="shadow-md border border-gray-100 rounded-md mx-2">
                <h3 class="pt-4 px-4 uppercase font-bold">FILTRES</h3>
                <FilterBox>
                  <template v-slot:categories>
                    <ul class="flex flex-col pose-list space-y-2 items-end p-4">
                      <li class="w-full rounded-lg uppercase">
                        <a
                          href="#"
                          class="
                            filters-item
                            flex
                            items-center
                            rounded-lg
                            px-4
                            py-0
                          "
                          :class="{ active: !category }"
                          @click.prevent="getFilteredData()">
                          Tous
                        </a>
                      </li>
                      <li
                        class="w-full rounded-lg uppercase"
                        v-for="filter in filters"
                        :key="filter"
                      >
                        <a
                          href="#"
                          class="
                            filters-item
                            flex
                            items-center
                            rounded-lg
                            px-4
                            py-0
                            uppercase
                          "
                          :class="{ active: category == filter.value }"
                          @click.prevent="getFilteredData(filter.value)"
                        >
                          <div v-html="filter.icon"></div>

                          {{ filter.name }}
                        </a>
                      </li>
                    </ul>
                  </template>
                </FilterBox>
              </form>
              
            </div>

            <!-- Product grid -->
            <div class="w-full lg:w-10/12">
              <!-- Replace with your content -->
              <div
                class="
                  border-4 border-dashed border-gray-200
                  rounded-lg
                  h-96
                  lg:h-full
                "
              >
                <table class="w-full text-base text-left text-gray-500">
                  <thead class="text-xs text-gray-700 uppercase bg-gray-50">
                    <tr>
                      <th scope="col" class="px-6 py-3">ID</th>
                      <th scope="col" class="px-6 py-3">Client</th>
                      <th scope="col" class="px-6 py-3">ADRESSE</th>
                      <th scope="col" class="px-6 py-3">CODE POSTAL</th>
                      <th scope="col" class="px-6 py-3">TELEPHONE</th>
                      <th scope="col" class="px-6 py-3">Portable</th>
                      <th scope="col" class="px-6 py-3">Email</th>
                    </tr>
                  </thead>
                  <tbody v-if="filtered_clients.length > 0">
                    <tr
                      class="bg-white border-b"
                      v-for="client in filtered_clients"
                      :key="client"
                    >
                      <th
                        scope="row"
                        class="
                          px-6
                          py-4
                          font-medium
                          text-gray-900
                          whitespace-nowrap
                        "
                      >
                        <router-link
                          :to="{ path: '/clients/' + client.id  }"
                          >
                          {{ client.id }}
                        </router-link>
                      </th>
                      <td class="px-6 py-4">                                                
                          <router-link
                          :to="{ path: '/clients/' + client.id  }"
                          >
                            <span class="title">
                              <strong> {{ client.name }} </strong>
                            </span>
                        </router-link>
                      </td>
                      <td class="px-6 py-4">
                        {{ client.address }},
                      </td>
                      <td class="px-6 py-4">
                        {{ client.zip }}
                      </td>
                      <td class="px-6 py-4">
                        {{ client.phone }}
                      </td>
                      <td class="px-6 py-4">
                        {{ client.portable }}
                      </td>
                      <td class="px-6 py-4">
                        {{ client.email }}
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td colspan="7" class="px-6 py-4">
                        <div class="text-center">
                          <p class="text-gray-500">
                            Aucun client trouvé
                          </p>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- /End replace -->
            </div>
          </div>

          <div class="flex flex-col items-center mt-3">
            
          </div>
        </section>
      </main>
    </div>
  </div>
</template>
<script>
import axios from "../http-common"; // <-- HTTP common
import FilterBox from "./FilterBox.vue";
import { mapState } from "vuex";
import AddNewClient from "./AddNewClient";

export default {
  name: "ClientList",
  props: ['agency_changed'],
  components: {
    FilterBox,
    AddNewClient
  },
  data() {
    return {
      category: "",
      filters: [],
      limit: 2000,
      page: 0,
      query: "",
      queryError: false,
      filtered_clients: [],
    };
  },
  mounted() {
    this.$store.dispatch("loadClients", {
      type: "loadClients",
      limit: "",
      page: "",
    });
    this.getFilteredData(this.category);
    this.fetchFilters();
  },
  methods: {
    fetchFilters(client_id) {
      this.logements = [];
      this.filters = [];
      let header = {
        DOLAPIKEY: JSON.parse(window.localStorage.getItem("user-info")).data.success.token,
      };
      axios
        .get("categories?type=customer", {
          headers: header,
        })
        .then((response) => {
          response.data.map((item) => {
            this.filters.push({
              id: parseInt(item.id),
              value: item.id,
              name: item.label,
              color: item.color ? item.color : "",
            });
          });
        })
        .catch((error) => {
          this.logements = [];
        });
    },
    querySearch() {
      // if(this.query) {
      //   this.filtered_clients = this.clients.filter(client => {
      //     return client.name.toLowerCase().includes(this.query.toLowerCase());
      //   });
      //   this.queryError = false;
        
      // }else{
      //   this.queryError = true;
      // }
      this.getFilteredData(this.category);
      
    },
    clientSubmitted(id) {
      this.$store.dispatch("loadClients", {type: "loadClients", limit: "", page: ""});
      this.filtered_clients = this.clients;
      this.$router.push("/clients/");
    },
    nextPage() {
      this.page++;
      this.$store.dispatch("loadClients", {
        type: "loadClients",
        limit: this.limit,
        page: this.page,
      });
    },
    getFilteredData(category) {
      this.category = category;  
      var filternomphone = '';
      if(this.query) {
        filternomphone = ` AND ((t.nom:like:'%${this.query}%') OR (t.phone:like:'%${this.query}%')) `;
      }

      var filteragencyid = '';
      if(this.$store.state.selectedAgencyID != "0"){
        filteragencyid = ` AND (efu.agency:=:${this.$store.state.selectedAgencyID}) `;
      }

      // let api_url = `/thirdparties?sqlfilters=(client:=:1) AND (fournisseur:=:0) AND (t.fk_typent:!=:`+process.env.VUE_APP_HOTEL_TYPE_INT_ID+`) `+filternomphone;
      let api_url = `/installationapi/thirdparties?sqlfilters=(client:=:1) AND (fournisseur:=:0) AND (t.fk_typent:!=:`+process.env.VUE_APP_HOTEL_TYPE_INT_ID+`) `+filternomphone+filteragencyid;
      if(this.limit){
        api_url += `&limit=`+this.limit+`&page=`+this.page;
      }
      if(this.category){
        api_url += `&category=`+this.category;
      }
      axios.get(api_url, {
                headers: {
                    'DOLAPIKEY': JSON.parse(window.localStorage.getItem('user-info')).data.success.token,
                }
            })
            .then(response => response.data)
            .then(clients => {
            // if(this.$store.state.selectedAgencyID != "0"){
            //   clients = clients.filter(client => client.array_options.options_agency == this.$store.state.selectedAgencyID);
            // }
            this.filtered_clients = clients;
        })
        .catch(error => {
          this.filtered_clients = [];
        });
    },
  },
  computed: {
    ...mapState(["clients"]),
  },
  watch: {
    query: function () {
      this.getFilteredData(this.category);
      this.queryError = false;
    },
    agency_changed: function (val) {
      this.$store.dispatch("loadClients", {
        type: "loadClients",
        limit: "",
        page: "",
      });
      this.getFilteredData(this.category);
    },
  },
};
</script>
<style scoped>
.edit_link,
span.title {
  color: #9f137e;
}
a.edit_link {
  background-color: #9f137e;
  color: #fff;
  border: #9f137e solid 1px;
}
a.edit_link:hover {
  color: #9f137e;
  background-color: #fff;
  border: #9f137e solid 1px;
}
.pose-list .active {
  background-color: #9f137e;
  color: #fff;
  border: #9f137e solid 1px;
}
</style>