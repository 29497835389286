<template>
   <div class="container mx-auto">
      <div class="flex justify-center">
         <button
            @click="isOpen = true"
            class="px-6 py-2 text-white bg-[#9f137e] rounded-md shadow"
            type="button"
            >
        Ajouter un nouveau client
         </button>
         <div
            v-show="isOpen"
            class="
            absolute
            inset-0
            flex
            items-center
            justify-center
            bg-gray-700 bg-opacity-50
            z-9999"
            style="z-index: 9999 !important"
            >
            <div class="relative p-4 w-full max-w-md h-full md:h-auto">
               <!-- Modal content -->
               <div class="relative bg-white rounded-lg shadow ">
                  <button type="button" class="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center  " @click="isOpen = false">
                     <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path>
                     </svg>
                  </button>
                  <div class="py-6 px-6 lg:px-8">
                     <h3 class="mb-4 text-xl font-medium text-gray-900 ">Veuillez entrer les détails</h3>
                     <form class="space-y-6" action="#">
                        <div>
                           <label for="vendor_name" class="block mb-2 text-sm font-medium text-gray-900">Nom du client</label>
                           <input type="text" name="vendor_name" id="id_vendor_name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " 
                           placeholder="Nom du client " required v-model="name" :class="{ 'bg-red-50 border border-red-500': nameError == true }">
                           <span v-if="nameErrorValue" class="text-red-500 text-sm">{{ nameErrorValue }}</span>
                        </div>
                        <div>
                           <label for="address" class="block mb-2 text-sm font-medium text-gray-900 ">Adresse</label>
                           <input type="text" name="address" id="address" required placeholder="Adresse" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " 
                           v-model="address" :class="{ 'bg-red-50 border border-red-500': addressError == true }">
                        </div>
                        <div>
                           <label for="zip" class="block mb-2 text-sm font-medium text-gray-900 ">Code postal </label>
                           <input type="number" name="zip" id="zip" required placeholder="Code postal" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " 
                           v-model="zip" :class="{ 'bg-red-50 border border-red-500': zipError == true }">
                        </div>
                        <div>
                           <label for="town" class="block mb-2 text-sm font-medium text-gray-900 ">Ville </label>
                           <input type="text" name="town" id="town" required placeholder="Ville" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " 
                           v-model="town" >
                        </div>
                        <div>
                           <label for="phone" class="block mb-2 text-sm font-medium text-gray-900 ">Numéro de téléphone</label>
                           <input type="text" name="phone" id="phone" required placeholder="Numéro de téléphone" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " 
                           v-model="phone" :class="{ 'bg-red-50 border border-red-500': phoneError == true }" >
                           <span v-if="phoneErrorValue" class="text-red-500 text-sm">{{ phoneErrorValue }}</span>
                        </div>
                         <div>
                           <label for="email" class="block mb-2 text-sm font-medium text-gray-900 ">Email</label>
                           <input type="email" name="email" id="email" required placeholder="Email" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " 
                           v-model="email"  >
                        </div>
                        <div>
                           <label for="origin" class="block mb-2 text-sm font-medium text-gray-900 ">Provenance du client</label>
                           <select @change="updateOrigin"
                              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                              v-model="origin"
                              required
                            :class="{ 'bg-red-50 border border-red-500': agencyError == true }">
                              <option default value="">Sélectionner</option>
                              <option v-for="item in origins" :value="item" :key="item">
                                 {{item}}
                              </option>
                           </select>
                           <!-- <input type="text" name="origin" id="origin" required placeholder="Origin" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " 
                           v-model="origin"> -->
                        </div>
                        
                        <div>
                           <label for="email" class="block mb-2 text-sm font-medium text-gray-900 ">Affecter une agence</label>
                           <select @change="updateVendor"
                              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                              v-model="agency"
                              required
                            :class="{ 'bg-red-50 border border-red-500': agencyError == true }">
                              <option default value="">Sélectionner</option>
                              <option v-for="item in agencies" :value="item.id" :key="item.id">
                                 {{ item.label }}
                              </option>
                           </select>
                        </div>
                        <div>
                           <label for="email" class="block mb-2 text-sm font-medium text-gray-900 ">Affecter un vendeur </label>
                           <select
                              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                              v-model="salesperson"
                              required
                            :class="{ 'bg-red-50 border border-red-500': salespersonError == true }">
                              <option default value="">Sélectionner</option>
                              <option v-for="item in salespersonsData" :value="item.value" :key="item.id">
                                 {{ item.label }}
                              </option>
                           </select>
                        </div>
                        <button type="button" class="w-full text-white bg-[#9f137e] rounded-md hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium text-sm px-5 py-2.5 text-center "
                        @click.prevent="addNewClient">Ajouter un nouveau client</button>
                        
                       
                     </form>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>
<script>
import axios from "../http-common"; // <-- HTTP common
import { mapGetters, mapState } from "vuex";
   export default {
     name: "AddNewClient",
      emits: ["client-submited"],
      data() {
         return {
         isOpen: false,
         name: "",
         address: "",
         zip: "",
         town: "",
         phone: "",
         email: "",
         salesperson: "",
         salespersonsData: "",
         agency: "",
         nameError: false,
         nameErrorValue: "",
         addressError: false,
         zipError: false,
         salespersonError: false,   
         agencyError: false,  
         phoneError: false, 
         phoneErrorValue: "",
         origin: "",
         origins: [
         "Campagne d'e-mailing",
         "Campagne de télécopie",
         "Campagne de publipostage",
         "Campagne téléphonique",
         "Contact commercial ",
         "Contact entrant d'un client",
         "Employé",
         "l'Internet",
         "Partenaire",
         "Coordonnées de la boutique",
         "Partenaire",
         "Bouche à oreille",
      ], 
         };
      },
     methods: {
       
      updateVendor(value) {

         //  console.log("sale peesiono",this.salespersons);
         this.salespersonsData = this.salespersons.filter(item=>item.agency_id==value.target.value)
  
      },
      updateOrigin(value) {

      // console.log("sale peesiono",value.srcElement.value);
      this.origin = value.srcElement.value

      },
       async addNewClient() {
         let header = {
            DOLAPIKEY: JSON.parse(window.localStorage.getItem("user-info")).data.success.token,
         };
         if(this.name){
            await this.checkNameExists();
         }
         var regExp = /^[0][0-9]/;
         if(this.name && this.address && this.zip && this.phone.length == 10 && regExp.test(this.phone) == true &&
         this.nameError == false && this.addressError == false && this.zipError == false && this.phoneError == false &&
         this.salespersonError == false && this.agencyError == false){
            axios.post("/thirdparties", {
               name: this.name,
               address: this.address,
               zip: this.zip,
               prospect: 0,
               phone: this.phone,
               client:'1',
               email: this.email,
               town: this.town,
               commercial_id : this.salesperson?parseInt(this.salesperson):null, 
               name_alias: this.origin

            }, {
              headers: header,
            }).then(response => {
               this.name = "";
               this.address = "";
               this.zip = "";
               this.phone = "";
               this.isOpen = false;
               this.nameError = false;
               this.phoneError = false;
               this.agencyError = false;
               this.nameErrorValue = "";
               this.origin="";
               this.email = "";
               this.salesperson = "";
               
               this.$emit("client-submited", response.data);
               this.updateAgency(response.data);

               this.$swal.fire({
                  title: "Êtes-vous sûr?",
                  text: "Client ajouté avec succès",
                  icon: "success",
                  showCancelButton: false,
                  confirmButtonColor: "#3085d6",
                  confirmButtonText: "Ok",
                  }).then((result) => {
                     if (result.value) {
                        location.reload();
                     }
                  });
               
            }).catch(error => {
               this.$swal("Something went wrong, please try again", "", "error");
            });
         }else{
            if(!this.name) {
               this.nameError = true;
            }
            if(!this.address) {
               this.addressError = true;
            }
            if(!this.zip) {
               this.zipError = true;
            }
            if(!this.agency) {
               this.agencyError = true;
            }
            if(JSON.stringify(this.phone).length < 10) {
               this.phoneError = true;
            }
            var regExp = /^[0][0-9]/;
            if(!regExp.test(this.phone)) {
               this.phoneError = true;
            }
         }
       },
       async checkNameExists(){
          let header = {
            DOLAPIKEY: JSON.parse(window.localStorage.getItem("user-info")).data.success.token,
         };
         // axios.get(`/thirdparties?sqlfilters=t.nom like '${this.name}' AND t.client=1`, {
         axios.get(`/thirdparties?sqlfilters=(t.nom:like:'${this.name}') AND (t.client:=:1)`, {
            headers: header,
         }).then(response => {
            if(response.data.length > 0) {
               this.nameError = true;
               this.nameErrorValue = "Ce nom existe déjà";
            }else{
               this.nameError = false;
               this.nameErrorValue = "";
            }
         }).catch(error => {
            console.log(error);
            this.nameError = false;
            this.nameErrorValue = "";
         });
       },
       async updateAgency(agency) {
         let header = {
            DOLAPIKEY: JSON.parse(window.localStorage.getItem("user-info")).data.success.token,
         };
         axios.put("/thirdparties/"+agency, {
               "array_options": {
                "options_agency": this.agency ? parseInt(this.agency) : this.agency
                },
            }, {
              headers: header,
            }).then(response => {
               this.agency = "";
               // console.log(response.data);
            }).catch(error => {
               console.log(error);
            });
       },
     },
     watch: {

         name () {
            this.nameError = false;
         },
         address () {
            this.addressError = false;
         },
         zip () {
            this.zipError = false;
         },
         agency () {
            this.agencyError = false;
         },
         salespersons(val){
            this.salespersonsData =val;
         },
         
         phone:{
            handler:function (newValue,oldValue) {
               let firstChar = newValue.charAt(0);
               if(newValue.length != 10 || firstChar != 0 || isNaN(newValue)) {
                  this.phoneError = true;
                  this.phoneErrorValue = "Le numéro de téléphone doit être composé de 10 chiffres et commencer par 0";
               }
               else{
                  this.phoneError = false;
                  this.phoneErrorValue = "";
               }
            },
            deep:false,
            immediate:false
         },
     },
     computed: {
         ...mapState({
            salespersons: (state) => state.allSalesPersons,
            agencies: (state) => state.agencies,
         }),
      },
      mounted() {
         this.$store.dispatch("loadAllUsers");
         this.$store.dispatch("loadAgencies");
         
         
      }
   };

</script>