import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LoginView from '../views/LoginView.vue'
import InstallerHomeView from '../views/InstallerHomeView.vue'
import PoseCreate from '../views/PoseCreate.vue'
import ListClients from '../views/ListClients.vue'
import TeamManagement from '../views/TeamManagement.vue'
import PosePlanifier from '../views/PosePlanifier.vue'
import ClientDetails from '../views/ClientDetails.vue'
import InstallerHomeDetails from '../views/InstallerHomeDetails.vue'
import InstallerPlanningDetails from '../views/InstallerPlanningDetails.vue'
import SalesPersonHomeView from '../views/SalesPersonHomeView.vue'
import SalesPersonHomeDetails from '../views/SalesPersonHomeDetails.vue'
import SalesPlanningDetails from '../views/SalesPlanningDetails.vue'
import SearchView from '../views/SearchView.vue'
import SearchInstallerView from '../views/SearchInstallerView.vue'
import SearchSalesView from '../views/SearchSalesView.vue'
import store from '@/store'


const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: { requiresAuth: true, requiresAdmin: true, requiresInstaller: false }
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView,
    meta: { requiresAuth: false, requiresAdmin: false, requiresInstaller: false }
  },
  {
    path: '/installer',
    name: 'installer',
    component: InstallerHomeView,
    meta: { requiresAuth: true, requiresAdmin: false, requiresInstaller: true }
  },
  {
    path: '/salesperson',
    name: 'salesperson',
    component: SalesPersonHomeView,
    meta: { requiresAuth: true, requiresAdmin: false, requiresInstaller: false, requiresSalesPerson: true }
  },
  {
    path: '/salesperson:id',
    name: 'salesperson_details',
    component: SalesPersonHomeDetails,
    meta: { requiresAuth: true, requiresAdmin: false, requiresInstaller: false,  requiresSalesPerson: true  }
  },
  {
    path: '/salesperson/planning',
    name: 'salesperson_planning',
    component: SalesPlanningDetails,
    meta: { requiresAuth: true, requiresAdmin: false, requiresInstaller: false,  requiresSalesPerson: true  }
  },
  {
    path: '/installer:id',
    name: 'installer_details',
    component: InstallerHomeDetails,
    meta: { requiresAuth: true, requiresAdmin: false, requiresInstaller: true }
  },
  {
    path: '/installer/planning',
    name: 'installer_planning',
    component: InstallerPlanningDetails,
    meta: { requiresAuth: true, requiresAdmin: false, requiresInstaller: true }
  },
  {
    path: '/pose',
    name: 'pose',
    component: PoseCreate,
    meta: { requiresAuth: true, requiresAdmin: true, requiresInstaller: false }
  },
  {
    path: '/pose/edit/:id',
    name: 'posecreate',
    component: PoseCreate,
    meta: { requiresAuth: true, requiresAdmin: true, requiresInstaller: false }
  },
  {
    path: '/pose/planifier',
    name: 'planifier',
    component: PosePlanifier,
    meta: { requiresAuth: true, requiresAdmin: true, requiresInstaller: false }
  },
  {
    path: '/clients',
    name: 'clients',
    component: ListClients,
    meta: { requiresAuth: true, requiresAdmin: true, requiresInstaller: false }
  },
  {
    path: '/clients/:id',
    name: 'clientdetails',
    component: ClientDetails,
    meta: { requiresAuth: true, requiresAdmin: true, requiresInstaller: false }
  },
  {
    path: '/teams',
    name: 'teams',
    component: TeamManagement,
    meta: { requiresAuth: true, requiresAdmin: true, requiresInstaller: false }
  },
  {
    path: '/search/:query',
    name: 'search',
    component: SearchView,
    meta: { requiresAuth: true, requiresAdmin: true, requiresInstaller: false}
  },
  {
    path: '/installer/search/:query',
    name: 'installer_search',
    component: SearchInstallerView,
    meta: { requiresAuth: true, requiresAdmin: false, requiresInstaller: true, requiresSalesPerson: false }
  },
  {
    path: '/sales/search/:query',
    name: 'sales_search',
    component: SearchSalesView,
    meta: { requiresAuth: true, requiresAdmin: false, requiresInstaller: false, requiresSalesPerson: true }
  }
  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if(to.meta.requiresAuth){
    const authUser = JSON.parse(window.localStorage.getItem('user-info'));
      if(!authUser || !authUser.token) {
        next({ name: 'login' })
      }
      else if(to.meta.requiresAdmin){
        const authUser = JSON.parse(window.localStorage.getItem('user-info'));
        if(authUser.user === 'admin') {
          next()
        }else if(authUser.user === 'manager') {
          next()
        }else if(authUser.user === 'salesperson'){
          next('/salesperson')
        }else{
          next('/installer')
        }
      }
      else if(to.meta.requiresInstaller){
        const authUser = JSON.parse(window.localStorage.getItem('user-info'));
        if(authUser.user === 'installer') {
          next()
        }else if(authUser.user === 'salesperson'){
          next('/salesperson')
        }
        else{
          next('/')
        }
      }else if(to.meta.requiresSalesPerson){
        const authUser = JSON.parse(window.localStorage.getItem('user-info'));
        if(authUser.user === 'salesperson') {
          next()
        }else if(authUser.user === 'installer'){
          next('/installer')
        }else{
          next('/')
        }
      }else{
        next('/login')
      }
  }else{
    if (to.name === 'login') {
      const authUser = JSON.parse(window.localStorage.getItem('user-info'));
      if(authUser){
        if(authUser.user === 'manager') {
          next('/')
        }else if(authUser.user === 'installer'){
          next('/installer')
        }else{
         next() 
        }
      }else{
        next() 
      }
      
    } else {
      next('login')
    }
  }
  
})

export default router
