<template>
  <div>
    <SideBar />
    <TopBar @agency-changed="agencyChanged" />
    <main class="pl-24 pt-24 p-4 w-full h-full bg-gray-200">
      <!-- Titl Part -->
      <div class="flex flex-col">
        <h1 class="text-2xl font-bold text-gray-800">POSES A PLANNIFIER</h1>
      </div>
      <!-- End - Titl Part -->
      <input type="hidden" name="customfield" class="form-control" v-model="agencychange" />
      <!-- Start - Team Size -->
      <div>
        <!-- Start - Product Filter -->
        <div class="flex justify-end gap-2">
          <button
            class="text-xs font-bold text-blue-800 bg-white active:bg-blue-200 focus:bg-blue-200 rounded-md px-2 py-1 rounded-b-none uppercase"
            :class="{ 'bg-blue-200': !category_pose_selected }"
            @click.prevent="getFilteredData()"
          >
            Toutes
          </button>
          <button
            class="text-xs font-bold bg-white active:bg-blue-200 focus:bg-blue-200 rounded-md px-2 py-1 rounded-b-none uppercase"
            v-for="(catergory, index) in filters"
            :key="index"
            :class="['text-[#' + catergory.color + ']']"
            @click.prevent="getFilteredData(catergory.id)"
          >
            {{ catergory.label }}
          </button>
        </div>
        <!-- End - Product Filter -->
        <!-- <div
          class="flex flex-wrap lg:flex-nowrap items-end content-center gap-10 p-6 w-full bg-white rounded-lg border border-gray-200 shadow-md rounded-tr-none"
            v-if="unplannedPosesStatus != 'resolved'" >
            <div class="border border-gray-200 shadow rounded-md p-4 max-w-sm w-full mx-auto">
              <div class="animate-pulse flex space-x-4">
                <div class="rounded-full bg-slate-200 h-10 w-10"></div>
                <div class="flex-1 space-y-6 py-1">
                  <div class="h-2 bg-slate-200 rounded"></div>
                  <div class="space-y-3">
                    <div class="grid grid-cols-3 gap-4">
                      <div class="h-2 bg-slate-200 rounded col-span-2"></div>
                      <div class="h-2 bg-slate-200 rounded col-span-1"></div>
                    </div>
                    <div class="h-2 bg-slate-200 rounded"></div>
                  </div>
                </div>
              </div>
            </div>
        </div> -->

        <div
          class="flex flex-wrap lg:flex-nowrap items-end content-center gap-10 p-6 w-full bg-white rounded-lg border border-gray-200 shadow-md rounded-tr-none overflow-x-auto"
          id="planned-tasks"
          v-if="filtered_poses.length > 0"
        >
          <!-- Begin - Section 1 -->
          <div
            class="fc-event  relative w-full lg:w-3/12 h-[150px] p-4 rounded-m shadow-sm header-box-outer cursor-move overflow-y-auto"
            v-for="(pose, index) in filtered_poses"
            :key="index"
            :class="'bg-[#e6f9ee]'"
            draggable="true"
            @dragstart="startDrag($event, pose, index, (type = 'normal_poses'))"
          >
            <!-- Begin - Section Subtitle -->
            <div class="flex items-center border-b">
              <h4 class="text-xs font-bold uppercase mb-1">
              {{ getClientDetails(pose.client) }}<br>
              {{ posse1(pose.agency) }}
              <!-- {{ pose.categories&&pose.planning[pose.categories.match(/(\d+)/)[0]]?.name }} -->
                <!-- <span class="uppercase text-xs font-bold text-[#9f137e]">
                  {{ pose.start_date }} - {{ pose.end_date }}</span
                > -->
              </h4>
              <!-- <h4 class="text-xs font-bold bg-fuchsia-700 text-white rounded-md px-2 py-1 mb-1 ml-auto">
                {{ pose.estimated_daysgit  }}
              </h4> -->
              <div class="text-xs font-bold bg-fuchsia-700 text-white rounded-md px-2 py-1 mb-1 ml-auto">
              <!-- <button  @click="getClientSale(pose.client)">c
                </button><br> -->
                <button   @click="posse(pose.client)">ⓘ </button>
              </div>
              <!-- new data -->
            </div>
            
            <!-- End - Section Subtitle -->
            <!-- Begin - Small Toggle Shape Container -->
            <div class="flex flex-wrap gap-2 h-[100px] overflow-y-scroll">
              <div
                class="border-0 shadow rounded-md p-4 max-w-sm w-full mx-auto"
                v-if="makeInitialTeamStatus != 'resolved'"
              >
                <div class="animate-pulse flex space-x-4">
                  <div class="rounded-full bg-slate-200 h-10 w-10"></div>
                  <div class="flex-1 space-y-6 py-1">
                    <div class="h-2 bg-slate-200 rounded"></div>
                    <div class="space-y-3">
                      <div class="grid grid-cols-3 gap-4">
                        <div class="h-2 bg-slate-200 rounded col-span-2"></div>
                        <div class="h-2 bg-slate-200 rounded col-span-1"></div>
                      </div>
                      <div class="h-2 bg-slate-200 rounded"></div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Replace div tags with img tag - Change border-pink-400 to desired color-->

              <!-- Team of 1 -->
              <div
                class="relative h-11 py-0 mt-4 ml-0 px-1 rounded-md dashboard__img-toggle-shape orange bg-white header-box"
                v-for="(team, i) in initial_teams[pose.id]"
                :key="i"
                :class="['border-[#' + team.color + ']']"
              >
                <!-- Change the bg-fuchsia-700 to the desired color, match with the border of the parent  -->
                <!--  Full DAY if "J" -->
                <div
                  class="flex gap-1 items-center justify-center w-9 h-4 rounded-md absolute top-[-16px] left-[-2px] rounded-bl-none rounded-br-none"
                  title="Full Day"
                  v-if="team.day_type == 'J'"
                  :class="['bg-[#' + team.color + ']']"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    aria-hidden="true"
                    role="img"
                    class="iconify iconify--ep w-4 h-4"
                    preserveAspectRatio="xMidYMid meet"
                    viewBox="0 0 1024 1024"
                  >
                    <path
                      fill="white"
                      d="M512 704a192 192 0 1 0 0-384a192 192 0 0 0 0 384zm0 64a256 256 0 1 1 0-512a256 256 0 0 1 0 512zm0-704a32 32 0 0 1 32 32v64a32 32 0 0 1-64 0V96a32 32 0 0 1 32-32zm0 768a32 32 0 0 1 32 32v64a32 32 0 1 1-64 0v-64a32 32 0 0 1 32-32zM195.2 195.2a32 32 0 0 1 45.248 0l45.248 45.248a32 32 0 1 1-45.248 45.248L195.2 240.448a32 32 0 0 1 0-45.248zm543.104 543.104a32 32 0 0 1 45.248 0l45.248 45.248a32 32 0 0 1-45.248 45.248l-45.248-45.248a32 32 0 0 1 0-45.248zM64 512a32 32 0 0 1 32-32h64a32 32 0 0 1 0 64H96a32 32 0 0 1-32-32zm768 0a32 32 0 0 1 32-32h64a32 32 0 1 1 0 64h-64a32 32 0 0 1-32-32zM195.2 828.8a32 32 0 0 1 0-45.248l45.248-45.248a32 32 0 0 1 45.248 45.248L240.448 828.8a32 32 0 0 1-45.248 0zm543.104-543.104a32 32 0 0 1 0-45.248l45.248-45.248a32 32 0 0 1 45.248 45.248l-45.248 45.248a32 32 0 0 1-45.248 0z"
                    ></path>
                  </svg>
                </div>
                <!--  Morning DAY if "M" -->
                <div
                  class="flex gap-1 items-center justify-center w-9 h-4 rounded-md absolute top-[-16px] left-[-2px] rounded-bl-none rounded-br-none"
                  title="Morning Day"
                  v-if="team.day_type == 'M'"
                  :class="['bg-[#' + team.color + ']']"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    aria-hidden="true"
                    role="img"
                    class="iconify iconify--ep w-4 h-4"
                    preserveAspectRatio="xMidYMid meet"
                    viewBox="0 0 1024 1024"
                  >
                    <path
                      fill="white"
                      d="M32 768h960a32 32 0 1 1 0 64H32a32 32 0 1 1 0-64zm129.408-96a352 352 0 0 1 701.184 0h-64.32a288 288 0 0 0-572.544 0h-64.32zM512 128a32 32 0 0 1 32 32v96a32 32 0 0 1-64 0v-96a32 32 0 0 1 32-32zm407.296 168.704a32 32 0 0 1 0 45.248l-67.84 67.84a32 32 0 1 1-45.248-45.248l67.84-67.84a32 32 0 0 1 45.248 0zm-814.592 0a32 32 0 0 1 45.248 0l67.84 67.84a32 32 0 1 1-45.248 45.248l-67.84-67.84a32 32 0 0 1 0-45.248z"
                    ></path>
                  </svg>
                </div>
                <!-- Afternoon if "A" -->
                <div
                  class="flex gap-1 items-center justify-center w-9 h-4 rounded-md absolute top-[-16px] left-[-2px] rounded-bl-none rounded-br-none"
                  title="Half Day"
                  v-if="team.day_type == 'A'"
                  :class="['bg-[#' + team.color + ']']"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    aria-hidden="true"
                    role="img"
                    class="iconify iconify--ep w-4 h-4 rotate-180"
                    preserveAspectRatio="xMidYMid meet"
                    viewBox="0 0 1024 1024"
                  >
                    <path
                      fill="white"
                      d="M32 768h960a32 32 0 1 1 0 64H32a32 32 0 1 1 0-64zm129.408-96a352 352 0 0 1 701.184 0h-64.32a288 288 0 0 0-572.544 0h-64.32zM512 128a32 32 0 0 1 32 32v96a32 32 0 0 1-64 0v-96a32 32 0 0 1 32-32zm407.296 168.704a32 32 0 0 1 0 45.248l-67.84 67.84a32 32 0 1 1-45.248-45.248l67.84-67.84a32 32 0 0 1 45.248 0zm-814.592 0a32 32 0 0 1 45.248 0l67.84 67.84a32 32 0 1 1-45.248 45.248l-67.84-67.84a32 32 0 0 1 0-45.248z"
                    ></path>
                  </svg>
                </div>
                <div class="flex items-center justify-center gap-2" v-if="team.members">
                  <div
                    class="flex items-center justify-center rounded-full w-10 h-10 object-contain bg-[#dbbba6]"
                    v-for="(member, j) in team.members"
                    :key="j"
                  >
                    <div
                      class="flex items-center justify-center rounded-full w-10 h-10 object-contain bg-[#dbbba6]"
                      v-if="getUserImg(member)"
                    >
                      <span
                        class="flex items-center justify-center text-xs font-semibold w-8 h-8 uppercase rounded-full text-pink-800 last:mr-0 mr-1"
                        :class="['bg-[#' + getMemberColor(member) + ']']"
                      >
                        <img :src="getUserImg(member)" class="rounded-full" />
                      </span>
                    </div>
                    <div
                      class="flex items-center justify-center rounded-full w-10 h-10 object-contain bg-[#dbbba6]"
                      v-else
                    >
                      <span
                        class="flex items-center justify-center text-xs font-semibold w-8 h-8 uppercase rounded-full text-pink-800 last:mr-0 mr-1"
                        :class="['bg-[#' + getMemberColor(member) + ']']"
                      >
                        {{ getUserName(member).first_name.slice(0, 1) }}{{ getUserName(member).last_name.slice(0, 1) }}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="flex items-center justify-center gap-2" v-else>
                  <div
                    class="flex items-center justify-center rounded-full w-10 h-10 object-contain bg-[#dbbba6]"
                    v-for="(size, j) in parseInt(team.team_size)"
                    :key="j"
                  >
                    <span
                      class="flex items-center justify-center text-xs font-semibold w-8 h-8 uppercase rounded-full text-pink-800 last:mr-0 mr-1"
                      :class="[team.color ? 'bg-[#' + team.color + ']' : 'bg-fuchsia-100']"
                    >
                    </span>
                  </div>
                </div>
              </div>
              <!-- End - Team of 1 -->
            </div>

            <!-- End - Small Toggle Shape Container -->
            <div class="absolute right-3 bottom-[20%] translate-y-[20%] flex flex-col">
              <!-- If active, change the color from bg-gray-200 to desired color -->
              <div
                class="w-2 h-2 rounded-full my-1"
                :class="pose.need_material == '1' ? 'bg-fuchsia-700' : 'bg-gray-200'"
              ></div>
              <div
                class="w-2 h-2 rounded-full my-1"
                :class="pose.need_equipment == '1' ? 'bg-fuchsia-700' : 'bg-gray-200'"
              ></div>
              <div
                class="w-2 h-2 rounded-full my-1"
                :class="pose.file_status == '1' ? 'bg-fuchsia-700' : 'bg-gray-200'"
              ></div>
              <div
                class="w-2 h-2 rounded-full my-1"
                :class="pose.validate == '1' ? 'bg-fuchsia-700' : 'bg-gray-200'"
              ></div>
              <div
                class="w-2 h-2 rounded-full my-1"
                :class="pose.payment_status == '1' ? 'bg-fuchsia-700' : 'bg-gray-200'"
              ></div>
            </div>
          </div>
          <!-- End - Section 1 -->
        </div>
        <div
          class="flex flex-wrap lg:flex-nowrap items-end content-center gap-10 p-6 w-full bg-white rounded-lg border border-gray-200 shadow-md rounded-tr-none  "
          id="planned-tasks"
          v-else
        >
          <p class="text-center text-sm text-gray-600">Aucune pose disponible</p>
        </div>
      </div>

      <!-- End - Team Size -->

      <!-- Start - Select Team -->

      <div
        class="relative flex flex-col mt-2 p-1 w-full bg-white rounded-lg border border-gray-200 shadow-md"
        id="planned-tasks"
      >
        <!-- Start - Team Product Filter -->
        <div class="flex justify-end gap-2">
          <button
            class="text-xs font-bold text-blue-800 bg-white active:bg-blue-200 focus:bg-blue-200 rounded-md px-2 py-1 rounded-b-none uppercase"
            :class="{ 'bg-blue-200': !category }"
            @click.prevent="getFilteredTeamData()"
          >
            Toutes
          </button>
          <button
            class="text-xs font-bold bg-white active:bg-blue-200 focus:bg-blue-200 rounded-md px-2 py-1 rounded-b-none uppercase"
            v-for="(catergory, index) in filters"
            :key="index"
            :class="['text-[#' + catergory.color + ']']"
            @click.prevent="getFilteredTeamData(catergory.id)"
          >
            {{ catergory.label }}
          </button>
        </div>
        <!-- End - Product Filter -->

        <div class="rounded-md shadow-md border p-2 py-4 my-2">
          <h3 class="uppercase text-sm font-bold border-b mb-2 mx-2">
            Equipes Disponibles <span class="uppercase text-xs font-bold text-[#9f137e]"> - {{ selected_date }}</span>
          </h3>

          <!-- Start - Teams -->
          <div class="flex flex-wrap gap-2" v-if="filtered_teams.length > 0">
            <!-- Team of n -->
            <div
              class="relative h-11 py-0 ml-2 px-2 mt-2 rounded-md dashboard__img-toggle-shape orange bg-white header-box cursor-move"
              v-for="(team, index) in filtered_teams"
              :key="index"
              draggable="true"
              @dragstart="availableTeamDrag($event, team)"
            >
              <!--  Full DAY if "J" -->
              <div
                class="flex gap-1 items-center justify-center w-9 h-4 rounded-md absolute top-[-16px] left-[-2px] rounded-bl-none rounded-br-none"
                title="Full Day"
                v-if="team.availability == 'J'"
                :class="[team.category ? 'bg-[#' + getCategoryColor(team.category) + ']' : 'bg-[#dbbba6]']"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  aria-hidden="true"
                  role="img"
                  class="iconify iconify--ep w-4 h-4"
                  preserveAspectRatio="xMidYMid meet"
                  viewBox="0 0 1024 1024"
                >
                  <path
                    fill="white"
                    d="M512 704a192 192 0 1 0 0-384a192 192 0 0 0 0 384zm0 64a256 256 0 1 1 0-512a256 256 0 0 1 0 512zm0-704a32 32 0 0 1 32 32v64a32 32 0 0 1-64 0V96a32 32 0 0 1 32-32zm0 768a32 32 0 0 1 32 32v64a32 32 0 1 1-64 0v-64a32 32 0 0 1 32-32zM195.2 195.2a32 32 0 0 1 45.248 0l45.248 45.248a32 32 0 1 1-45.248 45.248L195.2 240.448a32 32 0 0 1 0-45.248zm543.104 543.104a32 32 0 0 1 45.248 0l45.248 45.248a32 32 0 0 1-45.248 45.248l-45.248-45.248a32 32 0 0 1 0-45.248zM64 512a32 32 0 0 1 32-32h64a32 32 0 0 1 0 64H96a32 32 0 0 1-32-32zm768 0a32 32 0 0 1 32-32h64a32 32 0 1 1 0 64h-64a32 32 0 0 1-32-32zM195.2 828.8a32 32 0 0 1 0-45.248l45.248-45.248a32 32 0 0 1 45.248 45.248L240.448 828.8a32 32 0 0 1-45.248 0zm543.104-543.104a32 32 0 0 1 0-45.248l45.248-45.248a32 32 0 0 1 45.248 45.248l-45.248 45.248a32 32 0 0 1-45.248 0z"
                  ></path>
                </svg>
              </div>
              <!--  Morning DAY if "M" -->
              <div
                class="flex gap-1 items-center justify-center w-9 h-4 rounded-md absolute top-[-16px] left-[-2px] rounded-bl-none rounded-br-none"
                title="Morning Day"
                v-if="team.availability == 'M'"
                :class="[team.category ? 'bg-[#' + getCategoryColor(team.category) + ']' : 'bg-[#dbbba6]']"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  aria-hidden="true"
                  role="img"
                  class="iconify iconify--ep w-4 h-4"
                  preserveAspectRatio="xMidYMid meet"
                  viewBox="0 0 1024 1024"
                >
                  <path
                    fill="white"
                    d="M32 768h960a32 32 0 1 1 0 64H32a32 32 0 1 1 0-64zm129.408-96a352 352 0 0 1 701.184 0h-64.32a288 288 0 0 0-572.544 0h-64.32zM512 128a32 32 0 0 1 32 32v96a32 32 0 0 1-64 0v-96a32 32 0 0 1 32-32zm407.296 168.704a32 32 0 0 1 0 45.248l-67.84 67.84a32 32 0 1 1-45.248-45.248l67.84-67.84a32 32 0 0 1 45.248 0zm-814.592 0a32 32 0 0 1 45.248 0l67.84 67.84a32 32 0 1 1-45.248 45.248l-67.84-67.84a32 32 0 0 1 0-45.248z"
                  ></path>
                </svg>
              </div>
              <!-- Afternoon if "A" -->
              <div
                class="flex gap-1 items-center justify-center w-9 h-4 rounded-md absolute top-[-16px] left-[-2px] rounded-bl-none rounded-br-none"
                title="Half Day"
                v-if="team.availability == 'A'"
                :class="[team.category ? 'bg-[#' + getCategoryColor(team.category) + ']' : 'bg-[#dbbba6]']"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  aria-hidden="true"
                  role="img"
                  class="iconify iconify--ep w-4 h-4 rotate-180"
                  preserveAspectRatio="xMidYMid meet"
                  viewBox="0 0 1024 1024"
                >
                  <path
                    fill="white"
                    d="M32 768h960a32 32 0 1 1 0 64H32a32 32 0 1 1 0-64zm129.408-96a352 352 0 0 1 701.184 0h-64.32a288 288 0 0 0-572.544 0h-64.32zM512 128a32 32 0 0 1 32 32v96a32 32 0 0 1-64 0v-96a32 32 0 0 1 32-32zm407.296 168.704a32 32 0 0 1 0 45.248l-67.84 67.84a32 32 0 1 1-45.248-45.248l67.84-67.84a32 32 0 0 1 45.248 0zm-814.592 0a32 32 0 0 1 45.248 0l67.84 67.84a32 32 0 1 1-45.248 45.248l-67.84-67.84a32 32 0 0 1 0-45.248z"
                  ></path>
                </svg>
              </div>

              <div class="flex items-center justify-center gap-2">
                <div
                  class="flex items-center justify-center rounded-full w-10 h-10 object-contain bg-[#dbbba6]"
                  v-for="(slot, i) in team.size"
                  :key="i"
                >
                  <div v-if="getUserImg(team.members[i])">
                    <span
                      class="flex items-center justify-center text-xs font-semibold w-8 h-8 uppercase rounded-full text-pink-800 last:mr-0 mr-1"
                      :class="[team.category ? 'bg-[#' + getCategoryColor(team.category) + ']' : 'bg-fuchsia-100']"
                    >
                      <img :src="getUserImg(team.members[i])" class="rounded-full" draggable="false" />
                    </span>
                  </div>
                  <div v-else>
                    <span
                      class="flex items-center justify-center text-xs font-semibold w-8 h-8 uppercase rounded-full text-pink-800 last:mr-0 mr-1"
                      :class="[team.category ? 'bg-[#' + getCategoryColor(team.category) + ']' : 'bg-fuchsia-100']"
                    >
                      {{ getUserName(team.members[i]).first_name.slice(0, 1)
                      }}{{ getUserName(team.members[i]).last_name.slice(0, 1) }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <!-- End - Team of n -->
          </div>
          <p class="text-center text-sm text-gray-600" v-else>Pas d’équipes disponibles </p>
          <!-- End - Teams -->
        </div>
      </div>

      <!-- End - Select Team -->

      <div
        class="flex flex-wrap lg:flex-nowrap items-start content-center gap-10 mt-2 p-6 w-full bg-white rounded-lg border border-gray-200 shadow-md"
      >
        <!-- Begin - Section 1 - Equipes -->
        <div class="w-full lg:w-4/5 p-2 bg-white rounded-md border shadow-sm overflow-y-scroll">
          <!-- Begin - Section Subtitle -->

          <!-- End - Section Subtitle -->
          <!-- Begin - Small Toggle Shape Container -->
          <div class="flex flex-wrap gap-2 min-h-[800px] h-full overflow-y-auto">
            <!-- Loader -->
            <div class="border border-gray-200 shadow rounded-md p-4 max-w-sm w-full mx-auto" v-if="startDragStatus">
              <div class="animate-pulse flex space-x-4">
                <div class="rounded-full bg-slate-200 h-10 w-10"></div>
                <div class="flex-1 space-y-6 py-1">
                  <div class="h-2 bg-slate-200 rounded"></div>
                  <div class="space-y-3">
                    <div class="grid grid-cols-3 gap-4">
                      <div class="h-2 bg-slate-200 rounded col-span-2"></div>
                      <div class="h-2 bg-slate-200 rounded col-span-1"></div>
                    </div>
                    <div class="h-2 bg-slate-200 rounded"></div>
                  </div>
                </div>
              </div>
            </div>
            <button
              disabled
              type="button"
              class="text-white hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 inline-flex items-center"
              :class="'bg-[#9f137e]'"
              v-if="startEventDropStatus"
            >
              <svg
                role="status"
                class="inline w-4 h-4 mr-3 text-white animate-spin"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="#E5E7EB"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentColor"
                />
              </svg>
              Processing... Please wait.
            </button>
            <!-- end of loader -->
            <FullCalendar class="" :options="calendarOptions" defaultDate='2018-06-01'>
              <template v-slot:eventContent="arg">
                <!-- Begin - Section 2 -  RESPONSABLES D'EQUIPES -->
                <div
                  class="absolute top-[-5px] left-[-5px] bg-white rounded-full cursor-pointer pose-hover"
                  @click="mouseOver(arg.event.id)"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                    <path
                      fill-rule="evenodd"
                      d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </div>
                <div class="flex flex-direction-column">
                  <!-- Remove Pose Icon -->
                  <div
                    class="absolute top-[-5px] right-[-5px] bg-white rounded-full cursor-pointer remove-pose-icon"
                    @click="removePose(arg.event.id, arg.event.extendedProps.status)"
                    v-if="arg.event.extendedProps.after_sales == 0"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-4 w-4"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="red"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1"
                        d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                      ></path>
                    </svg>
                  </div>
                  <!-- End - Remove Pose Icon -->
                  <div
                    class="fc-event relative w-full lg:w-4/4   rounded-md border border-gray-200 shadow-sm header-box-outer cursor-move overflow-y-auto"
                    draggable="true ? arg.event.extendedProps.after_sales == 0 : false"
                    @dragstart="startDrag2($event)"
                    v-for="(dayData, index) in arg.event.extendedProps.data"
                    :key="index"
                    :class="
                      arg.event.extendedProps.status == '4' || arg.event.extendedProps.status == '5'
                        ? 'bg-[#ffe5e5]'
                        : 'bg-[#e6f9ee]' +` h-[${100*arg.event.extendedProps.data.length}px]`
                    "
                  >
                    <!-- Begin - Section Subtitle -->

                    <h4 class="text-xs font-bold uppercase border-b">
                      {{ arg.event.title }} - {{ arg.event.extendedProps.days }}
                      <span
                        class="float-right inline-flex items-center justify-center px-2 py-1 text-[0.6rem] font-bold leading-none text-red-100 bg-fuchsia-700 rounded-full"
                        >{{ arg.event.extendedProps.validated_count }}</span
                      >
                    </h4>
                    <!-- End - Section Subtitle -->
                    <!-- Begin - Small Toggle Shape Container -->
                    <div class="flex flex-wrap gap-2 overflow-y-scroll" :class="arg.event.id">
                      <!-- Replace div tags with img tag - Change border-pink-400 to desired color-->
                      <!-- Team of 1 -->
                      <div
                        class="relative h-11 py-0 ml-0 px-1 mt-4 rounded-md dashboard__img-toggle-shape orange bg-white header-box"
                        v-for="(team, i) in dayData"
                        :key="i"
                        :class="['border-[#' + team.color + ']']"
                        @droppable="true ? arg.event.extendedProps.after_sales == 0 : false"
                        @drop="
                          onDropTeamtoPose(
                            $event,
                            i,
                            team,
                            arg.event.extendedProps.planning,
                            arg.event.start,
                            arg,
                            index,
                            arg.event.extendedProps.status
                          )
                        "
                        @dragover.prevent
                        @dragenter.prevent
                      >
                        <!-- Remove Team Icon -->
                        <div
                          class="absolute top-[-5px] right-[-5px] bg-white rounded-full cursor-pointer remove-pose-icon"
                          @click="removeTeam(team)"
                          v-if="arg.event.extendedProps.after_sales == 0"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="h-4 w-4"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="red"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="1"
                              d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                            ></path>
                          </svg>
                        </div>
                        <!-- End - Remove Team Icon -->

                        <!-- Change the bg-fuchsia-700 to the desired color, match with the border of the parent  -->
                        <!--  Full DAY if "J" -->
                        <div
                          class="flex gap-1 items-center justify-center w-9 h-4 rounded-md absolute top-[-16px] left-[-2px] rounded-bl-none rounded-br-none"
                          title="Full Day"
                          v-if="team.day_type == 'J'"
                          :class="['bg-[#' + team.color + ']']"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            aria-hidden="true"
                            role="img"
                            class="iconify iconify--ep w-4 h-4"
                            preserveAspectRatio="xMidYMid meet"
                            viewBox="0 0 1024 1024"
                          >
                            <path
                              fill="white"
                              d="M512 704a192 192 0 1 0 0-384a192 192 0 0 0 0 384zm0 64a256 256 0 1 1 0-512a256 256 0 0 1 0 512zm0-704a32 32 0 0 1 32 32v64a32 32 0 0 1-64 0V96a32 32 0 0 1 32-32zm0 768a32 32 0 0 1 32 32v64a32 32 0 1 1-64 0v-64a32 32 0 0 1 32-32zM195.2 195.2a32 32 0 0 1 45.248 0l45.248 45.248a32 32 0 1 1-45.248 45.248L195.2 240.448a32 32 0 0 1 0-45.248zm543.104 543.104a32 32 0 0 1 45.248 0l45.248 45.248a32 32 0 0 1-45.248 45.248l-45.248-45.248a32 32 0 0 1 0-45.248zM64 512a32 32 0 0 1 32-32h64a32 32 0 0 1 0 64H96a32 32 0 0 1-32-32zm768 0a32 32 0 0 1 32-32h64a32 32 0 1 1 0 64h-64a32 32 0 0 1-32-32zM195.2 828.8a32 32 0 0 1 0-45.248l45.248-45.248a32 32 0 0 1 45.248 45.248L240.448 828.8a32 32 0 0 1-45.248 0zm543.104-543.104a32 32 0 0 1 0-45.248l45.248-45.248a32 32 0 0 1 45.248 45.248l-45.248 45.248a32 32 0 0 1-45.248 0z"
                            ></path>
                          </svg>
                        </div>
                        <!--  Morning DAY if "M" -->
                        <div
                          class="flex gap-1 items-center justify-center w-9 h-4 rounded-md absolute top-[-16px] left-[-2px] rounded-bl-none rounded-br-none"
                          title="Morning Day"
                          v-if="team.day_type == 'M'"
                          :class="['bg-[#' + team.color + ']']"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            aria-hidden="true"
                            role="img"
                            class="iconify iconify--ep w-4 h-4"
                            preserveAspectRatio="xMidYMid meet"
                            viewBox="0 0 1024 1024"
                          >
                            <path
                              fill="white"
                              d="M32 768h960a32 32 0 1 1 0 64H32a32 32 0 1 1 0-64zm129.408-96a352 352 0 0 1 701.184 0h-64.32a288 288 0 0 0-572.544 0h-64.32zM512 128a32 32 0 0 1 32 32v96a32 32 0 0 1-64 0v-96a32 32 0 0 1 32-32zm407.296 168.704a32 32 0 0 1 0 45.248l-67.84 67.84a32 32 0 1 1-45.248-45.248l67.84-67.84a32 32 0 0 1 45.248 0zm-814.592 0a32 32 0 0 1 45.248 0l67.84 67.84a32 32 0 1 1-45.248 45.248l-67.84-67.84a32 32 0 0 1 0-45.248z"
                            ></path>
                          </svg>
                        </div>
                        <!-- Afternoon if "A" -->
                        <div
                          class="flex gap-1 items-center justify-center w-9 h-4 rounded-md absolute top-[-16px] left-[-2px] rounded-bl-none rounded-br-none"
                          title="Half Day"
                          v-if="team.day_type == 'A'"
                          :class="['bg-[#' + team.color + ']']"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            aria-hidden="true"
                            role="img"
                            class="iconify iconify--ep w-4 h-4 rotate-180"
                            preserveAspectRatio="xMidYMid meet"
                            viewBox="0 0 1024 1024"
                          >
                            <path
                              fill="white"
                              d="M32 768h960a32 32 0 1 1 0 64H32a32 32 0 1 1 0-64zm129.408-96a352 352 0 0 1 701.184 0h-64.32a288 288 0 0 0-572.544 0h-64.32zM512 128a32 32 0 0 1 32 32v96a32 32 0 0 1-64 0v-96a32 32 0 0 1 32-32zm407.296 168.704a32 32 0 0 1 0 45.248l-67.84 67.84a32 32 0 1 1-45.248-45.248l67.84-67.84a32 32 0 0 1 45.248 0zm-814.592 0a32 32 0 0 1 45.248 0l67.84 67.84a32 32 0 1 1-45.248 45.248l-67.84-67.84a32 32 0 0 1 0-45.248z"
                            ></path>
                          </svg>
                        </div>

                        <div class="flex items-center justify-center gap-2" v-if="team.members.length > 0">
                          <div
                            class="flex items-center justify-center rounded-full w-10 h-10 object-contain bg-[#dbbba6]"
                            v-for="(member, j) in team.members"
                            :key="j"
                          >
                            <div
                              class="flex items-center justify-center rounded-full w-10 h-10 object-contain bg-[#dbbba6]"
                              v-if="getUserImg(member)"
                            >
                              <span
                                class="flex items-center justify-center text-xs font-semibold w-8 h-8 uppercase rounded-full text-pink-800 last:mr-0 mr-1"
                                :class="['bg-[#' + getMemberColor(member) + ']']"
                              >
                                <img :src="getUserImg(member)" class="rounded-full" />
                              </span>
                            </div>
                            <div
                              class="flex items-center justify-center rounded-full w-10 h-10 object-contain bg-[#dbbba6]"
                              v-else
                            >
                              <span
                                class="flex items-center justify-center text-xs font-semibold w-8 h-8 uppercase rounded-full text-pink-800 last:mr-0 mr-1"
                                :class="['bg-[#' + getMemberColor(member) + ']']"
                              >
                                {{ getUserName(member).first_name.slice(0, 1)
                                }}{{ getUserName(member).last_name.slice(0, 1) }}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div class="flex items-center justify-center gap-2" v-else>
                          <div
                            class="flex items-center justify-center rounded-full w-10 h-10 object-contain bg-[#dbbba6]"
                            v-for="(size, j) in parseInt(team.team_size)"
                            :key="j"
                          >
                            <span
                              class="flex items-center justify-center text-xs font-semibold w-8 h-8 uppercase rounded-full text-pink-800 last:mr-0 mr-1"
                              :class="[team.color ? 'bg-[#' + team.color + ']' : 'bg-fuchsia-100']"
                            >
                            </span>
                          </div>
                        </div>
                      </div>
                      <!-- End - Team of 1 -->
                    </div>
                  </div>
                </div>
              </template>
            </FullCalendar>
          </div>
          <!-- End - Small Toggle Shape Container -->
        </div>
        <!-- End - Section 1 - Equipes -->
        <!-- Begin - Section 1 - Equipes -->
        <div class="w-full lg:w-1/5 p-2 bg-white rounded-md border shadow-sm">
          <!-- Begin - Section Subtitle -->

          <!-- End - Section Subtitle -->
          <!-- Begin - Small Toggle Shape Container -->
          <div class="flex flex-col gap-2 h-[100px] overflow-y-auto">
            <h4 class="text-xs font-bold uppercase border-b">CHIFFRE D'AFFAIRE DU MOIS</h4>
            <div class="flex justify-between items-center mb-2 mt-2">
              <h5 class="text-l font-bold leading-none text-red-500 dark:text-red-200">
                {{ formatPrice(revenue_of_month) }}
              </h5>
            </div>
          </div>
          <div class="flex flex-col gap-2 h-[100px] overflow-y-auto">
            <h4 class="text-xs font-bold uppercase border-b">Revenu par semaine</h4>
            <div class="flex justify-between items-center mb-2 mt-2">
              <h5 class="text-l font-bold leading-none text-red-500 dark:text-red-200">
                {{ formatPrice(revenue_of_week) }}
              </h5>
            </div>
          </div>
          <!-- End - Small Toggle Shape Container -->
          <div class="flex flex-col gap-2 h-[600px] overflow-y-auto">
            <h4 class="text-xs font-bold uppercase border-b">FINITION POSES</h4>

            <div
              class="flex flex-col lg:flex-nowrap items-end content-center gap-10 p-6 w-full bg-white rounded-lg border border-gray-200 shadow-md rounded-tr-none"
              id="planned-tasks"
              v-if="fin_poses.length > 0"
            >
              <!-- Begin - Section 1 -->
              <div
                class="relative w-full lg:w-12/12 h-[170px] p-4 rounded-md border border-gray-200 shadow-sm header-box-outer cursor-move"
                v-for="(pose, index) in fin_poses"
                :key="index"
                :class="'bg-[#ffe5e5]'"
               
                @dragstart="startDrag($event, pose, index, (type = 'fin_poses'))"
              >
                <!-- Begin - Section Subtitle -->
                <div class="flex items-center border-b">
                  <h4 class="text-xs font-bold uppercase mb-1">
                    {{ getClientDetails(pose.client) }}
                    <span class="uppercase text-xs font-bold text-[#9f137e]">
                      {{ pose.start_date }} - {{ pose.end_date }}</span
                    >
                  </h4>
                  <h4 class="text-xs font-bold bg-fuchsia-700 text-white rounded-md px-2 py-1 mb-1 ml-auto">
                    {{ pose.estimated_days }}
                  </h4>
                </div>

                <!-- End - Section Subtitle -->
                <!-- Begin - Small Toggle Shape Container -->
                <div class="flex flex-wrap gap-2 h-[100px] overflow-y-scroll">
                  <!-- Team of 1 -->
                  <div
                    class="relative h-11 py-0 mt-4 ml-0 px-1 rounded-md dashboard__img-toggle-shape orange bg-white header-box"
                    v-for="(team, i) in initial_teams[pose.id]"
                    :key="i"
                    :class="['border-[#' + team.color + ']']"
                  >
                    <!-- Change the bg-fuchsia-700 to the desired color, match with the border of the parent  -->
                    <!--  Full DAY if "J" -->
                    <div
                      class="flex gap-1 items-center justify-center w-9 h-4 rounded-md absolute top-[-16px] left-[-2px] rounded-bl-none rounded-br-none"
                      title="Full Day"
                      v-if="team.day_type == 'J'"
                      :class="['bg-[#' + team.color + ']']"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="iconify iconify--ep w-4 h-4"
                        preserveAspectRatio="xMidYMid meet"
                        viewBox="0 0 1024 1024"
                      >
                        <path
                          fill="white"
                          d="M512 704a192 192 0 1 0 0-384a192 192 0 0 0 0 384zm0 64a256 256 0 1 1 0-512a256 256 0 0 1 0 512zm0-704a32 32 0 0 1 32 32v64a32 32 0 0 1-64 0V96a32 32 0 0 1 32-32zm0 768a32 32 0 0 1 32 32v64a32 32 0 1 1-64 0v-64a32 32 0 0 1 32-32zM195.2 195.2a32 32 0 0 1 45.248 0l45.248 45.248a32 32 0 1 1-45.248 45.248L195.2 240.448a32 32 0 0 1 0-45.248zm543.104 543.104a32 32 0 0 1 45.248 0l45.248 45.248a32 32 0 0 1-45.248 45.248l-45.248-45.248a32 32 0 0 1 0-45.248zM64 512a32 32 0 0 1 32-32h64a32 32 0 0 1 0 64H96a32 32 0 0 1-32-32zm768 0a32 32 0 0 1 32-32h64a32 32 0 1 1 0 64h-64a32 32 0 0 1-32-32zM195.2 828.8a32 32 0 0 1 0-45.248l45.248-45.248a32 32 0 0 1 45.248 45.248L240.448 828.8a32 32 0 0 1-45.248 0zm543.104-543.104a32 32 0 0 1 0-45.248l45.248-45.248a32 32 0 0 1 45.248 45.248l-45.248 45.248a32 32 0 0 1-45.248 0z"
                        ></path>
                      </svg>
                    </div>
                    <!--  Morning DAY if "M" -->
                    <div
                      class="flex gap-1 items-center justify-center w-9 h-4 rounded-md absolute top-[-16px] left-[-2px] rounded-bl-none rounded-br-none"
                      title="Morning Day"
                      v-if="team.day_type == 'M'"
                      :class="['bg-[#' + team.color + ']']"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="iconify iconify--ep w-4 h-4"
                        preserveAspectRatio="xMidYMid meet"
                        viewBox="0 0 1024 1024"
                      >
                        <path
                          fill="white"
                          d="M32 768h960a32 32 0 1 1 0 64H32a32 32 0 1 1 0-64zm129.408-96a352 352 0 0 1 701.184 0h-64.32a288 288 0 0 0-572.544 0h-64.32zM512 128a32 32 0 0 1 32 32v96a32 32 0 0 1-64 0v-96a32 32 0 0 1 32-32zm407.296 168.704a32 32 0 0 1 0 45.248l-67.84 67.84a32 32 0 1 1-45.248-45.248l67.84-67.84a32 32 0 0 1 45.248 0zm-814.592 0a32 32 0 0 1 45.248 0l67.84 67.84a32 32 0 1 1-45.248 45.248l-67.84-67.84a32 32 0 0 1 0-45.248z"
                        ></path>
                      </svg>
                    </div>
                    <!-- Afternoon if "A" -->
                    <div
                      class="flex gap-1 items-center justify-center w-9 h-4 rounded-md absolute top-[-16px] left-[-2px] rounded-bl-none rounded-br-none"
                      title="Half Day"
                      v-if="team.day_type == 'A'"
                      :class="['bg-[#' + team.color + ']']"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="iconify iconify--ep w-4 h-4 rotate-180"
                        preserveAspectRatio="xMidYMid meet"
                        viewBox="0 0 1024 1024"
                      >
                        <path
                          fill="white"
                          d="M32 768h960a32 32 0 1 1 0 64H32a32 32 0 1 1 0-64zm129.408-96a352 352 0 0 1 701.184 0h-64.32a288 288 0 0 0-572.544 0h-64.32zM512 128a32 32 0 0 1 32 32v96a32 32 0 0 1-64 0v-96a32 32 0 0 1 32-32zm407.296 168.704a32 32 0 0 1 0 45.248l-67.84 67.84a32 32 0 1 1-45.248-45.248l67.84-67.84a32 32 0 0 1 45.248 0zm-814.592 0a32 32 0 0 1 45.248 0l67.84 67.84a32 32 0 1 1-45.248 45.248l-67.84-67.84a32 32 0 0 1 0-45.248z"
                        ></path>
                      </svg>
                    </div>

                    <div class="flex items-center justify-center gap-2" v-if="team.members">
                      <div
                        class="flex items-center justify-center rounded-full w-10 h-10 object-contain bg-[#dbbba6]"
                        v-for="(member, j) in team.members"
                        :key="j"
                      >
                        <div
                          class="flex items-center justify-center rounded-full w-10 h-10 object-contain bg-[#dbbba6]"
                          v-if="getUserImg(member)"
                        >
                          <span
                            class="flex items-center justify-center text-xs font-semibold w-8 h-8 uppercase rounded-full text-pink-800 last:mr-0 mr-1"
                            :class="['bg-[#' + getMemberColor(member) + ']']"
                          >
                            <img :src="getUserImg(member)" class="rounded-full" />
                          </span>
                        </div>
                        <div
                          class="flex items-center justify-center rounded-full w-10 h-10 object-contain bg-[#dbbba6]"
                          v-else
                        >
                          <span
                            class="flex items-center justify-center text-xs font-semibold w-8 h-8 uppercase rounded-full text-pink-800 last:mr-0 mr-1"
                            :class="['bg-[#' + getMemberColor(member) + ']']"
                          >
                            {{ getUserName(member).first_name.slice(0, 1)
                            }}{{ getUserName(member).last_name.slice(0, 1) }}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="flex items-center justify-center gap-2" v-else>
                      <div
                        class="flex items-center justify-center rounded-full w-10 h-10 object-contain bg-[#dbbba6]"
                        v-for="(size, j) in parseInt(team.team_size)"
                        :key="j"
                      >
                        <span
                          class="flex items-center justify-center text-xs font-semibold w-8 h-8 uppercase rounded-full text-pink-800 last:mr-0 mr-1"
                          :class="[team.color ? 'bg-[#' + team.color + ']' : 'bg-fuchsia-100']"
                        >
                        </span>
                      </div>
                    </div>
                  </div>
                  <!-- End - Team of 1 -->
                </div>

                <!-- End - Small Toggle Shape Container -->
                <div class="absolute right-3 bottom-[20%] translate-y-[20%] flex flex-col">
                  <!-- If active, change the color from bg-gray-200 to desired color -->
                  <div
                    class="w-2 h-2 rounded-full my-1"
                    :class="pose.need_material == '1' ? 'bg-fuchsia-700' : 'bg-gray-200'"
                  ></div>
                  <div
                    class="w-2 h-2 rounded-full my-1"
                    :class="pose.need_equipment == '1' ? 'bg-fuchsia-700' : 'bg-gray-200'"
                  ></div>
                  <div
                    class="w-2 h-2 rounded-full my-1"
                    :class="pose.file_status == '1' ? 'bg-fuchsia-700' : 'bg-gray-200'"
                  ></div>
                  <div
                    class="w-2 h-2 rounded-full my-1"
                    :class="pose.validate == '1' ? 'bg-fuchsia-700' : 'bg-gray-200'"
                  ></div>
                  <div
                    class="w-2 h-2 rounded-full my-1"
                    :class="pose.payment_status == '1' ? 'bg-fuchsia-700' : 'bg-gray-200'"
                  ></div>
                </div>
              </div>
              <!-- End - Section 1 -->
            </div>
          </div>
        </div>
        <!-- End - Section 1 - Equipes -->
      </div>
    </main>
  </div>
</template>
<script>
import SideBar from "@/components/SideBar.vue";
import TopBar from "@/components/TopBar.vue";
import { SetupCalendar, Calendar, DatePicker } from "v-calendar";
import "@fullcalendar/core/vdom"; // FullCalendar Vue Component
import FullCalendar, { CalendarOptions, EventApi, DateSelectArg, EventClickArg } from "@fullcalendar/vue3";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import { INITIAL_EVENTS, createEventId } from "./event-utils";
import frLocale from "@fullcalendar/core/locales/fr";
import { mapGetters, mapState } from "vuex";
import axios from "../http-common"; // <-- HTTP common
import ApiCallService from "@/services/apiCall";
import moment from "moment";
import { minutesToSeconds } from 'date-fns';
export default {
  name: "PosePlanifier",
  title: "Pose Planifier | DealEco",
  components: {
    SideBar,
    TopBar,
    Calendar,
    FullCalendar,
  },
  data() {
    const month = new Date().getMonth();
    const year = new Date().getFullYear();
    let selected_date = new Date().toISOString().replace(/T.*$/, "");
    if(localStorage.getItem("selected_date")){
      selected_date =  moment(localStorage.getItem("selected_date")).format("YYYY-MM-DD");
    }
    return {
      successMessage: "",
      selected_date: selected_date,
      teams: [],
      revenue_of_month: 0,
      revenue_of_week: 0,
      masks: {
        weekdays: "WWW",
      },
      attributes: [],
      calendarEventDrag: false,
      calendarOptions: {
        initialDate: selected_date,
        locales: [frLocale],
        locale: "fr",
        plugins: [
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin, // needed for dateClick
        ],
        droppable: true,
        headerToolbar: {
          left: "prev,next today",
          center: "title",
          right: "dayGridMonth,dayGridWeek,dayGridDay",
        },
        initialView: "dayGridWeek",
        initialEvents: INITIAL_EVENTS, // alternatively, use the `events` setting to fetch from a feed
        editable: true,
        selectable: true,
        selectMirror: true,
        dayMaxEvents: true,
        eventDurationEditable: false,
        weekends: true,
        displayEventTime: false,
        hiddenDays: [0, 6],
        dateClick: this.onDayClickHandler,
        events: [],
        eventDrop: this.eventDrop,
        eventClick: this.eventClick,
        // eventOverlap: false,
      },
      agency_name: "",
      filtered_poses: [],
      filtered_teams: [],
      initial_teams: {},
      category: "",
      category_pose_selected: "",
      teams_of_day: {},
      pose_teams_of_day: {},
      not_available_members: {},
      filters: [],
      activeWeekNumber: 0,
      start: "",
      end: "",
      weekDays: [],
      leave_data: {},
      overriding: [],
      answer: "",
      team_leaves: [],
      unplannedPosesStatus: "",
      makeInitialTeamStatus: "",
      startDragStatus: false,
      startEventDropStatus: false,
      leave_dates: {},
      pose_status_availability: "",
      fin_poses: [],
      all_poses: [],
      agencychange: "",
    };
  },
  unmounted() {
    this.calendarOptions.events = [];
    this.filtered_poses = [];
    this.filtered_teams = [];
    this.initial_teams = {};
    this.category = "";
    this.teams_of_day = {};
    this.teams = [];
  },
  async mounted() {    
    this.calculateRevenue()
    if(localStorage.getItem("selected_date")){
      this.selected_date = moment(localStorage.getItem("selected_date")).format("YYYY-MM-DD");
    }
    this.calendarOptions.initialDate = this.selected_date;
    var weeknumber = moment(moment().toDate(), "MM-DD-YYYY").isoWeek();
    this.weekDays = this.getDateRangeOfWeek(weeknumber + 1);
    await this.getCreatedTeams();
    this.filters = this.product_groups;
    // this.unplannedPosesStatus = await this.fetchUnplannedPoses();
    this.makeInitialTeamStatus = await this.makeInitialTeams();
    await this.getLeavesofSelectedWeek();
    this.$store.dispatch("loadInstallers");
    this.$store.dispatch("loadProductGroups");
    this.$store.dispatch("loadAgencies");
    this.$store.dispatch("loadPoses", { limit: "", page: "" });
    // let initialeventsStatus = await this.makeInitialEvents();
    this.posse1();
    this.getClientDetails();
    this.getClientMetre();
  },
  methods: {
    getDateFormat(date){
      return moment.unix(date).format('DD/MM/YYYY');
    },
    posse1(id){
      let agent = "";
      this.$store.state.agencies.forEach((agency)=>{
        if(agency.id == id){
          agent = agency.label
        }
  });
  return agent;
},
    posse(id){
      try {
      this.filtered_poses.forEach((pose)=>{
        if(pose.client == id){
        // console.log(pose)
        this.$swal({
          title: "Détails de la pose",
          html: 
           `<b>Pose Id </b> : ${pose.id} <br>
            <b>Coût :</b> ${pose.cost} € <br>
            <b>Catégorie :</b> ${pose.categories&&pose.planning[pose.categories.match(/(\d+)/)[0]]?.name } <br>
            <b>Jours_estimés :</b> ${ pose.estimated_days} <br>
            <b>Adresse :</b> ${this.getClientAdd(pose.client)} <br>
            <b>Code postal :</b> ${this.getClientZip(pose.client)} <br>
            <b>Commerciaux :</b> ${this.getClientSale(pose.sales)}<br>`
            
        // }).then((id)=>{
        //   this.$store.state.allSalesPersons.forEach((sales)=>{
        //     if(sales.id == id)
        //     console.log(sales)
         })
        }
   });
  }
   catch (error) {
        return "Not found";
      }
  },
//   getClientAllDetails(id) {       
//           try {
//         this.$store.state.clients.forEach((client) => {
//           // console.log('client',client)
//           if (client.id == id) {
//             this.$swal({
//           title: "Détails du client",
//           html: 
//             `<b>Nom :</b> ${client.name} <br>
//             <b>Adresse :</b> ${client.address1} <br>
//             <b>Code postal :</b> ${client.zip} <br>`
          
//         })
//      } 
//    },
//  );
//       } catch (error) {
//         return "Not found";
//       }
      
//     },

    getClientAllDetails(id) {       
          try {
        this.$store.state.clients.forEach((client) => {
          // console.log('client',client)
          if (client.id == id) {
            this.$swal({
          title: "Détails du client",
          html: 
            `<b>Nom :</b> ${client.name} <br>
            <b>Adresse :</b> ${client.address1} <br>
            <b>Code postal :</b> ${client.zip} <br>`
          
        })
     } 
   },
 );
      } catch (error) {
        return "Not found";
      }
      
    },
    agencyChanged: function () {
      this.agencychange = this.$store.state.selectedAgencyID;
    },
    getProductGroupDetails(id) {
      let name = "";
      let color = "";
      this.product_groups.forEach((product_group) => {
        if (product_group.id == id) {
          name = product_group.name;
          color = product_group.color;
        }
      });
      return { name: name, color: color };
    },
    mouseOver: function (event_id) {
      let pose_details = [];
      let category_details = "";
      let validate_msg = "";
      this.all_poses.forEach((pose) => {
        if (pose.id == event_id) {
          pose_details = pose;

          if (pose.after_sales == "1") {
            validate_msg +=
              '<span class="bg-pink-700 text-white text-xs font-semibold mr-2 px-2.5 py-0.5 rounded">SAV SUITE à LA POSE</span>';
          }
          if (pose.need_material == "1") {
            validate_msg +=
              '<span class="bg-fuchsia-700 text-white text-xs font-semibold mr-2 px-2.5 py-0.5 rounded">Matériel commandé</span>';
          }
          if (pose.need_equipment == "1") {
            validate_msg +=
              '<span class="bg-fuchsia-700 text-white text-xs font-semibold mr-2 px-2.5 py-0.5 rounded">Matériel réceptionné</span>';
          }
          if (pose.file_status == "1") {
            validate_msg +=
              '<span class="bg-fuchsia-700 text-white text-xs font-semibold mr-2 px-2.5 py-0.5 rounded">DDP</span>';
          }
          if (pose.validate == "1") {
            validate_msg +=
              '<span class="bg-fuchsia-700 text-white text-xs font-semibold mr-2 px-2.5 py-0.5 rounded">Validé</span>';
          }
          if (pose.payment_status == "1") {
            validate_msg +=
              '<span class="bg-fuchsia-700 text-white text-xs font-semibold mr-2 px-2.5 py-0.5 rounded">Financement accepté</span>';
          }
        }
      });
      JSON.parse(pose_details.categories).forEach((category) => {
        // console.log("creation",this.getDateFormat(pose_details.date_creation));
        // console.log("start",this.getDateFormat(pose_details.start_date))
        // console.log("teams",this.getDateFormat(pose_details.tms))
        // console.log("teakm",pose_details)
        category_details += this.getProductGroupDetails(category).name + ", ";
      });
      this.$swal.fire({
        title: this.getClientDetails(pose_details.fk_soc),
        html:
          validate_msg +
          ` <br>
              <b> ID:&nbsp; </b>` +
          pose_details.id   +
          `<br> <b>Catégorie(s):&nbsp; </b>` +
          category_details +
          `<br> <b>Coût estimé :&nbsp; </b> ` +
          pose_details.estimated_cost +
          `<br><b> Code postal: &nbsp; </b> ` +
          this.getClientZip(pose_details.fk_soc) +
          `<br> <b>Agence: &nbsp;</b>` +
          this.posse1(pose_details.fk_agency_id)+
          `<br> <b>Metré: &nbsp;</b>` +
           this.getClientMetre(pose_details.metre_ids),
          // +`<br> <p>Nouvelle date de l'équipe:</p>` +
          //   `<b>(`+ this.getDateFormat(pose_details.start_date) + `)</b>
          //    <br> <p>Date d'origine de l'équipe:</p>` + 
          //   `(`+ this.getDateFormat(pose_details.tms) + `)`,
        icon: "info",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });
    },
    async removePose(pose_id, status) {
      await this.$swal.fire({
        title: "Êtes-vous sûr ?",
        text: "Vous êtes sur le point de supprimer cette pose.",
        icon: "warning",
        showDenyButton: false,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Oui, supprimez-le !",
        cancelButtonText: "Annuler",
      }).then((result) => {
        if (result.value) {
          let header = {
            DOLAPIKEY: JSON.parse(window.localStorage.getItem("user-info")).data.success.token,
          };
          axios
          .put(`installationapi/installations/remove/`+pose_id, {status:status}, {
            headers: header,
          }).then((response) => {
              this.successMessage = "Pose removed successfully";
              this.calendarOptions.events.forEach((event, index) => {
                if (event.id == pose_id) {
                  this.calendarOptions.events.splice(index, 1);
                }
              });
              let pose = response.data;
              this.$store.dispatch("loadPoses", {
                limit: "",
                page: "",
              });
              this.fetchUnplannedPoses();
              this.makeInitialTeams();
              this.$swal.fire({
                  title: "Supprimé!",
                  text: "La pose a été supprimée avec succès",
                  icon: "success",
                  showCancelButton: false,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Ok",
                }).then((result) => {
                  if (result.value) {
                    location.reload();
                  }
                });
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    },
    getUserImg(id) {
      let img = "";
      this.installers.forEach((installer) => {
        if (installer.id == id) {
          img = installer.photo;
        }
      });
      return img;
    },
    getDateRangeOfWeek(weeknumber) {
      weeknumber = weeknumber - 1;
      this.activeWeekNumber = weeknumber;
      let days = [];
      var dateformat = "MMM D";
      var dateformat2 = "YYYY-MM-DD";
      var date = moment()
        .isoWeek(weeknumber || 1)
        .startOf("week");
      var weeklength = 5;
      while (weeklength--) {
        days.push({
          label: date.format(dateformat),
          date: date.format(dateformat2),
          weeknumber: weeknumber,
          teams: [],
        });
        date.add(1, "day");
      }
      this.start = days[0].date;
      this.end = days[days.length - 1].date;
      return days;
    },
    async calculateRevenue(type) {
      let revenue = 0;
      let start = "";
      let end = "";
      if (type == "month") {
        var check = moment(this.selected_date, "YYYY/MM/DD");
        var month = check.format("M");
        var day = check.format("D");
        var year = check.format("YYYY");
        var startDate = moment([year, month - 1]);
        var endDate = moment(startDate).endOf("month");
        start = moment(startDate.toDate()).format("YYYY-MM-DD");
        end = moment(endDate.toDate()).format("YYYY-MM-DD");
      } else {
        end = moment(this.end).add(1, "days").format("YYYY-MM-DD");
        start = moment(this.start).subtract(1, "days").format("YYYY-MM-DD");
      }
      // let url =
      //   `installationapi/installations?sqlfilters=status=2 AND fk_agency_id=` +
      //   this.$store.state.selectedAgencyID +
      //   ` AND start_date BETWEEN '` +
      //   start +
      //   `' AND '` +
      //   end +
      //   `'`;

      let url = `installationapi/installations?sqlfilters=(status:=:2) AND (fk_agency_id:=:` + this.$store.state.selectedAgencyID + `) AND (start_date BETWEEN '` + start + `' AND '` + end + `')`;

      if (this.$store.state.selectedAgencyID == 0) {
        // url = `installationapi/installations?sqlfilters=status=2 AND start_date BETWEEN '` + start + `' AND '` + end + `'`;
        url = `installationapi/installations?sqlfilters=(status:=:2) AND (start_date BETWEEN '` + start + `' AND '` + end + `')`;
      }
      let result = null;
      try {
        result = await ApiCallService.get(url);
        // console.log(result)
      } catch (error) {
        result = null;
        revenue = 0;
      }
      if (result) {
        revenue = 0;
        result.data.map((item) => {
          if (item.estimated_cost) {
            revenue += parseFloat(item.estimated_cost);
          }
        });
      }
      return revenue;
    },
    formatPrice(value) {
      // console.log(value)
      var formatter = new Intl.NumberFormat("fr", {
        style: "currency",
        currency: "EUR",
        minimumFractionDigits: 2,
      });
      return formatter.format(value);
    },
    getCategoryColor(category) {
      let color = "red-500";
      this.product_groups.forEach(function (group) {
        if (group.id == category) {
          color = group.color;
        }
      });
      return color;
    },
    getMemberColor(id) {
      let color = "#dbbba6";
      try {
        this.installers.forEach((installer) => {
          if (installer.id == id) {
            if (installer.category[0].color) {
              color = installer.category[0].color;
            }
          }
        });
      } catch (e) {
        color = "#dbbba6";
      }
      return color;
    },
    getUserName(id) {
      let name = {
        first_name: "",
        last_name: "",
        name: "",
      };
      this.installers.forEach((installer) => {
        if (installer.id == id) {
          name["first_name"] = installer.firstname;
          name["last_name"] = installer.lastname;
          name["name"] = installer.name;
        }
      });
      return name;
    },
    getUserName1(date) {
  const installer = this.installers.find(installer => {
    return installer.leaves.some(leave => leave.date_debut === date || leave.date_fin === date);
  });
  // console.log(installer.label, "installer");
  return  installer.label;
},
    async makeInitialEvents() {
      this.startEventDropStatus = true;
      this.$store.dispatch("loadPoses", { limit: "", page: "" });
      this.calendarOptions.events = [];
      this.revenu_of_week = 0;
      this.revenue_of_month = await this.calculateRevenue("month");
      this.revenue_of_week = await this.calculateRevenue("week");
      // let url = `/installationapi/installations?agency_id=` + this.$store.state.selectedAgencyID + `&sqlfilters=t.validate=1`;
      let url = `/installationapi/installations?agency_id=` + this.$store.state.selectedAgencyID + `&sqlfilters=(t.validate:=:1)`;
      let result = null;
      try {
        result = await ApiCallService.get(url);
      } catch (error) {
        result = null;
      }
      if (result) {
        let poses = result.data;
        this.all_poses = poses;
        await poses.forEach((pose) => {
          // console.log(pose)
          if (pose.status == "3") {
            this.fin_poses.push({
              id: pose.id,
              client: pose.fk_soc,
              pose_validation: false,
              client_notes: pose.note_client,
              financement: pose.payment_method,
              categories: pose.categories,
              logement: pose.property_type,
              date_creation: pose.date_creation ? moment.unix(pose.date_creation).format("DD/MM/YYYY") : "",
              start_date: pose.start_date ? moment.unix(pose.start_date).format("YYYY-MM-DD") : "",
              end_date: pose.start_date ? moment.unix(pose.end_date).format("YYYY-MM-DD") : "",
              cost: pose.estimated_cost,
              planning: pose.finition_planning,
              estimated_days: pose.estimated_days,
              status: pose.status,
              need_material: pose.need_material,
              need_equipment: pose.need_equipment,
              file_status: pose.file_status,
              validate: pose.validate,
              payment_status: pose.payment_status,
              after_sales: pose.after_sales,
            });
          }
          if (
            (pose.status == "1" || pose.status == "2" || pose.status == "4" || pose.status == "5") &&
            pose.validate == 1
          ) {
            let planning = pose.status == "4" ? pose.finition_planning : pose.planning;
            if (pose.status == "5") {
              planning = pose.finition_planning;
            }
            let incrementar = parseInt(pose.estimated_days);
            let end = this.findStartandEndofevents(moment.unix(pose.start_date).format("YYYY-MM-DD"), incrementar);
            let weekend_count = this.findWeekendCount(
              moment.unix(pose.start_date).format("YYYY-MM-DD"),
              parseInt(pose.estimated_days)
            );
            this.calendarOptions.events.push({
              id: pose.id,
              title: this.getClientDetails(pose.fk_soc),
              start: pose.start_date ? moment.unix(pose.start_date).format("YYYY-MM-DD") : "",
              end: end,
              days: pose.estimated_days ? parseInt(pose.estimated_days) : 1,
              planning: planning,
              data: this.makeDataFromPosePlanning(planning, pose.id, pose),
              allDay: true,
              status: pose.status,
              day_string: this.enumerateDaysBetweenDates(
                moment.unix(pose.start_date).format("YYYY-MM-DD"),
                parseInt(pose.estimated_days)
              ),
              validated_count: this.getValidatedCount(pose),
              weekend_count: weekend_count,
              all_days_include_weekends: this.enumerateAllDaysBetweenDates(
                moment.unix(pose.start_date).format("YYYY-MM-DD"),
                parseInt(pose.estimated_days)
              ),
              after_sales: pose.after_sales,
            });
          }
        });
      }
      this.startEventDropStatus = false;
      return "resolved";
    },
    getValidatedCount(pose) {
      let count = 0;
      if (pose.need_material == "1") {
        count++;
      }
      if (pose.need_equipment == "1") {
        count++;
      }
      if (pose.file_status == "1") {
        count++;
      }
      if (pose.validate == "1") {
        count++;
      }
      if (pose.payment_status == "1") {
        count++;
      }
      return count;
    },
    async fetchUnplannedPoses(category_id) {
      let url = '';
      this.filtered_poses = [];
      let result = null;
      if(category_id){
        // url = `/installationapi/installations?category_id=${category_id}&agency_id=` + this.$store.state.selectedAgencyID + `&sqlfilters=t.validate=1 AND t.status=0 AND t.after_sales=0`;
        url = `/installationapi/installations?category_id=${category_id}&agency_id=` + this.$store.state.selectedAgencyID + `&sqlfilters=(t.validate:=:1) AND (t.status:=:0) AND (t.after_sales:=:0)`;
      }else{
        // url = `/installationapi/installations?agency_id=` + this.$store.state.selectedAgencyID + `&sqlfilters=t.validate=1 AND t.status=0 AND t.after_sales=0`;
        url = `/installationapi/installations?agency_id=` + this.$store.state.selectedAgencyID + `&sqlfilters=(t.validate:=:1) AND (t.status:=:0) AND (t.after_sales:=:0)`;
      }
      try {
        result = await ApiCallService.get(url);
      } catch (error) {
        result = null;
      }
      if (result) {
        let poses = result.data;
        // console.log("unplanned",poses);
        poses.forEach((pose) => {
          this.filtered_poses.push({
            id: pose.id,
            client: pose.fk_soc,
            pose_validation: false,
            client_notes: pose.note_client,
            financement: pose.payment_method,
            categories: pose.categories,
            logement: pose.property_type,
            date_creation: pose.date_creation ? moment.unix(pose.date_creation).format("DD/MM/YYYY") : "",
            start_date: pose.start_date ? moment.unix(pose.start_date).format("YYYY-MM-DD") : "",
            end_date: pose.start_date ? moment.unix(pose.end_date).format("YYYY-MM-DD") : "",
            cost: pose.estimated_cost,
            planning: pose.planning,
            estimated_days: pose.estimated_days,
            status: pose.status,
            need_material: pose.need_material,
            need_equipment: pose.need_equipment,
            file_status: pose.file_status,
            validate: pose.validate,
            payment_status: pose.payment_status,
            after_sales: pose.after_sales,
            agency:pose.fk_agency_id,
            sales:pose.user_ids
          });
        });
      }
      return "resolved";
    },
  
    async makeInitialTeams() {
        // let url = `/installationapi/installations?agency_id=` + this.$store.state.selectedAgencyID + `&sqlfilters=t.validate=1`;
        let url = `/installationapi/installations?agency_id=` + this.$store.state.selectedAgencyID + `&sqlfilters=(t.validate:=:1)`;
        let result = null;
        try {
          result = await ApiCallService.get(url);
        } catch (error) {
          result = null;
        }
        if (result) {
          let poses = result.data;
          poses.forEach((pose) => {
            if (pose.status == "3" || pose.status == "4") {
              const keys = Object.keys(pose.finition_planning);
              this.initial_teams[pose.id] = [];
              keys.forEach((key, index) => {
                const days_keys = Object.keys(pose.finition_planning[key].days);
                days_keys.forEach((k, i) => {
                  pose.finition_planning[key].days[k].teams.forEach((team) => {
                    this.initial_teams[pose.id].push({
                      day_type: team.day_type,
                      team_size: team.team_size,
                      color: pose.finition_planning[key].color,
                      members: team.members,
                    });
                  });
                });
              });
            } else {
              const keys = Object.keys(pose.planning);
              this.initial_teams[pose.id] = [];
              keys.forEach((key, index) => {
                const days_keys = Object.keys(pose.planning[key].days);
                days_keys.forEach((k, i) => {
                  pose.planning[key].days[k].teams.forEach((team) => {
                    this.initial_teams[pose.id].push({
                      day_type: team.day_type,
                      team_size: team.team_size,
                      color: pose.planning[key].color,
                      members: team.members,
                    });
                  });
                });
              });
            }
          });
        }    
        return "resolved";    
    },
    async getFilteredData(category) {
      this.filtered_poses = [];
      this.unplannedPosesStatus = await this.fetchUnplannedPoses(category);
      this.category_pose_selected = category;
    },
    getFilteredTeamData(category) {
      this.filtered_teams = [];
      if (!category) {
        this.filtered_teams = this.teams;
      } else {
        this.filtered_teams = [];
        this.category = category;
        this.teams.forEach((team) => {
          if (team.category == category) {
            this.filtered_teams.push(team);
          }
        });
      }
    },
    async onDayClickHandler(info) {
      this.selected_date = info.dateStr;
      localStorage.setItem("selected_date", this.selected_date);
      await this.getCreatedTeams();
      var weeknumber = moment(info.dateStr, "YYYY-MM-DD").week();
      this.weekDays = this.getDateRangeOfWeek(weeknumber);
      this.activeWeekNumber = weeknumber - 1;
      this.revenue_of_month = await this.calculateRevenue("month");
      this.revenue_of_week = await this.calculateRevenue("week");
      await this.getLeavesofSelectedWeek();
    },
    async getCreatedTeams() {
      this.teams = [];
      this.filtered_teams = [];
      // let url = `installationapi/teams?sqlfilters=appointed_date='` + this.selected_date + `'`;
      let url = `installationapi/teams?sqlfilters=(appointed_date:=:'` + this.selected_date + `')`;
      let result = null;
      try {
        result = await ApiCallService.get(url);
      } catch (error) {
        result = null;
        this.teams = [];
        this.filtered_teams = [];
      }
      if (result) {
        result.data.map((item) => {
          if (item.status == "1") {
            this.teams.push({
              id: item.id ? parseInt(item.id) : "",
              name: "Team " + item.id,
              members: JSON.parse(item.user_ids),
              size: JSON.parse(item.user_ids).length,
              status: item.status,
              appointed_date: moment.unix(item.appointed_date).format("YYYY-MM-DD"),
              availability: item.availability,
              category: item.category,
              pose_id: item.pose_id,
              ref: item.ref,
            });
            this.filtered_teams = this.teams;
          }
        });
      }
    },
    getProductGroupDetails(id) {
      let name = "";
      let color = "";
      this.product_groups.forEach((product_group) => {
        if (product_group.id == id) {
          name = product_group.name;
          color = product_group.color;
        }
      });
      return { name: name, color: color };
    },
    getClientDetails(id) {
      let name = "";
      try {
        this.$store.state.clients.forEach((client) => {
          // console.log(client)
          if (client.id == id) {
            name = client.name;
          }
        });
        return name ;
      } catch (error) {
        return "Not found";
      }
    },
    getClientSale(id) {
      // console.log(id)
      let sale ="";
      try {
        this.$store.state.allSalesPersons.forEach((sales) => {

          // console.log(`[${sales.id}]`,"dss")
          if (`[${sales.id}]` == id) {
            sale =sales.label;
          }
        });
        return sale ;
      } catch (error) {
        return "Not found";
      }
    },
    getClientZip(id) {
      let zip ="";
      try {
        this.$store.state.clients.forEach((client) => {
          // console.log(client)
          if (client.id == id) {
            zip =client.zip;
          }
        });
        return zip ;
      } catch (error) {
        return "Not found";
      }
    },
    getClientMetre(id) {
      // console.log(id)
      let name ="";
      try {
        this.$store.state.metrepersons.forEach((metre) => {
          // console.log(metre.id)
          if (`[${metre.id}]` == id) {
            name =metre.name;
          }
        }); 
        return name ;
      } catch (error) {
        return "Not found";
      }
    },
    getClientAdd(id) {
      let add ="";
      try {
        this.$store.state.clients.forEach((client) => {
          // console.log(client)
          if (client.id == id) {
            add =client.address1;
          }
        });
        return add ;
      } catch (error) {
        return "Not found";
      }
    },
    async callScheduleAPI(payload) {
      // console.log(payload)
      return new Promise((resolve) => {
        let header = {
          DOLAPIKEY: JSON.parse(window.localStorage.getItem("user-info")).data.success.token,
        };
        axios
          .put(`installationapi/schedule/`, payload, {
            headers: header,
          })
          .then((response) => {
            let statusCode = response.data.status_code;
            if (statusCode == "200") {
              resolve("resolved");
            } else {
              resolve(response.data.schedule);
            }
            this.startEventDropStatus = false;
          })
          .catch((error) => {
            this.errorMessage = "Error occured while changing team date";
            resolve("error");
          });
      });
    },
    getTeamsOfDay(new_days, pose_id) {
      this.teams_of_day = {};
      return new Promise((resolve) => {
        new_days.forEach((day, index) => {
          // Here the point is the resolve that you should resolve('something');.
          axios
            // .get(`installationapi/teams?sqlfilters=fk_agency_id=` +this.$store.state.selectedAgencyID +`AND appointed_date='` +day +`'`,
            .get(`installationapi/teams?sqlfilters=(fk_agency_id:=:` +this.$store.state.selectedAgencyID +`) AND (appointed_date:=:'` +day +`')`,
              {
                headers: { DOLAPIKEY: JSON.parse(window.localStorage.getItem("user-info")).data.success.token },
              }
            )
            .then((resp) => {
              resp.data.map((item) => {
                if (
                  (item.status == "1" && item.pose_id != "0" && item.pose_id != parseInt(pose_id)) ||
                  (item.pose_id != parseInt(pose_id) && item.status == "2")
                ) {
                  if (this.teams_of_day[day] == undefined) {
                    this.teams_of_day[day] = [];
                  }
                  this.teams_of_day[day].push({
                    id: parseInt(item.id),
                    members: JSON.parse(item.user_ids),
                    availability: item.availability,
                    pose_id: item.pose_id,
                    category: item.category,
                  });
                }
              });
              resolve("resolved");
            })
            .catch((error) => {
              resolve("rejected");
            });
        });
      });
    },
     async eventDrop(date) {
      let after_sales = date.event.extendedProps.after_sales;
      if(after_sales == 1){
        this.errorMessage = "L'événement après-vente ne peut pas être déplacé";
        date.revert();
        this.$swal.fire({
          title: "Erreur",
          text: this.errorMessage,
          icon: "error",
          confirmButtonText: "OK",
        });
        return;
      }
      let dragged_dt = date.event.startStr.valueOf();
      var now = moment().format("YYYY-MM-DD").valueOf();
      const dateIsBefore = moment(now).isSameOrBefore(moment(dragged_dt));
      if (!dateIsBefore) {
        this.errorMessage = "Vous ne pouvez pas modifier une pose passée. ";
        this.$swal("Error", this.errorMessage, "error");
        date.revert();
        return false;
      } else {
        this.startEventDropStatus = true;
        let pose_status = parseInt(date.oldEvent.extendedProps.status);
        let new_days = this.enumerateDaysBetweenDates(date.event.startStr, date.event.extendedProps.days);
        let end = this.findStartandEndofevents(date.event.startStr, date.event.extendedProps.days);
        var ndate = new Date(end);
        let id = parseInt(date.event.id);
        let pose_end_date = moment(ndate).subtract(1, "day").format("YYYY-MM-DD");
        let payload = {
          id: id,
          startdate: date.event.startStr,
          enddate: pose_end_date,
        };
        if (pose_status == 2 || pose_status == 5) {
          await this.getTeamsOfDay(new_days, date.event.id);
          // if (this.leave_data[4].length > 1) {
          //   // console.log(this.leave_data)
          //   let leave_names = "";
          //   let msg = this.leave_data[4].length > 1 ? "are leave on this day" : "is leave on";
          //   let dt = "";
          //   await this.leave_data[4].forEach((member) => {
          //     dt = this.leave_dates[member] ? this.leave_dates[member] : "";
          //     leave_names += this.getUserName(member).name + ", ";
          //   });
          //   this.$swal(`${leave_names} ${msg} - ${dt}.`);
          //   date.revert();
          // } 
                        const leaveDates = this.leave_data[4];
              const leavesOnCurrentDate = new_days.filter(date => leaveDates.includes(date));
              if (leavesOnCurrentDate.length > 0) {
                const leave_names = leavesOnCurrentDate.map(member => this.getUserName1(member)).join(", ");
                console.log(leave_names)
                const msg = leavesOnCurrentDate.length > 1 ? "Impossible de déplacer cette pose ce jour-là, un membre de l'équipe  est absent à cette date" : "is leave on";
                this.$swal(`Impossible de déplacer cette pose ce jour-là, un membre de l'équipe  est absent à cette date nommé ${leave_names}  - ${leavesOnCurrentDate.join(", ")}.`);
                date.revert();
              }
          else {
            let result = await this.callScheduleAPI(payload);
            let answer = "";
            if (result == "resolved") {
              this.startEventDropStatus = false;
              this.makeInitialEvents();
            } else {
              let msg_to_show = "Veuillez remplacer les membres et réessayer. <br>";
              Object.keys(result).forEach((key) => {
                Object.keys(result[key]).forEach((key2) => {
                  let dt = moment(key).format("DD/MM/YYYY");
                  msg_to_show +=
                    "<strong>" +
                    this.getUserName(key2).name +
                    "</strong> n'est pas disponible sur <strong>" +
                    dt +
                    "</strong><br>";
                });
              });
              this.$swal({
                title: `Certains membres sont affectés à d'autres poses le même jour.`,
                showDenyButton: false,
                showCancelButton: false,
                html: msg_to_show,
              }).then((optn) => {
                date.revert();
              });
            }
          }
        } else {
          this.$swal({
            title: "Êtes-vous sûr ?",
            text: "Vous êtes sur le point de changer la date de cette pose",
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: "Save",
            denyButtonText: `Don't save`,
          }).then((result) => {
            if (result.isConfirmed) {
              this.callScheduleAPI(payload);
              this.makeInitialEvents();
            } else {
              date.revert();
            }
          });
        }
        this.startEventDropStatus = false;
      }
    },
    // async eventDrop(date) {
    //   // console.log(date.event.startStr)
    //   // console.log(date.oldEvent.startStr)
    //   // console.log("jay",date.event)
    //   let after_sales = date.event.extendedProps.after_sales;
    //   if(after_sales == 1){
    //     this.errorMessage = "L'événement après-vente ne peut pas être déplacé";
    //     date.revert();
    //     this.$swal.fire({
    //       title: "Error",
    //       text: this.errorMessage,
    //       icon: "error",
    //       confirmButtonText: "OK",
    //     });
    //     return;
    //   }
    //   let dragged_dt = date.event.startStr.valueOf();
    //   var now = moment().format("YYYY-MM-DD").valueOf();
    //   const dateIsBefore = moment(now).isSameOrBefore(moment(dragged_dt));
    //   if (!dateIsBefore) {
    //     this.errorMessage = "Vous ne pouvez pas éditer une pose passée.";
    //     this.$swal("Erreur", this.errorMessage, "error");
    //     date.revert();
    //     return false;
    //   } else {
    //     this.startEventDropStatus = true;
    //     let pose_status = parseInt(date.oldEvent.extendedProps.status);
    //     let new_days = this.enumerateDaysBetweenDates(date.event.startStr, date.event.extendedProps.days);
    //     let end = this.findStartandEndofevents(date.event.startStr, date.event.extendedProps.days);
    //     var ndate = new Date(end);
    //     let id = parseInt(date.event.id);
    //     let pose_end_date = moment(ndate).subtract(1, "day").format("YYYY-MM-DD");
    //     let header = {
    //       DOLAPIKEY: JSON.parse(window.localStorage.getItem("user-info")).data.success.token,
    //     };
    //     // let  minus = moment(localTime).subtract(1, "day")
    //     let payload = { 
    //       id: id,
    //       startdate: date.event.startStr,
    //       enddate: pose_end_date,
    //     };
    //     if (pose_status == 2 || pose_status == 5) {
    //       await this.getTeamsOfDay(new_days, date.event.id);
    //       if (this.team_leaves.length > 0) {
    //         let leave_names = "";
    //         let msg = this.team_leaves.length > 1 ? "are leave on this day" : "is leave on";
    //         let dt = "";
    //         await this.team_leaves.forEach((member) => {
    //           dt = this.leave_dates[member] ? this.leave_dates[member] : "";
    //           leave_names += this.getUserName(member).name + ", ";
    //         });
    //         this.$swal(`${leave_names} ${msg} - ${dt}.`);
    //         date.revert();
    //       } else {
    //         let result = await this.callScheduleAPI(payload);

    //         var localTime = moment.utc(date.date).toDate();
    //         localTime = moment(localTime).format("YYYY-MM-DD");
    //         let ref = "T" + "-" + JSON.stringify(moment(localTime).format("X"));

    //         let payload1 = {
    //         module: "installation",
    //         ref: ref,
    //         user_ids: date.event.extendedProps.data[1][0].members,
    //         category: date.event.extendedProps.data[1][0].category_id,
    //         appointed_date:date.oldEvent.startStr,
    //         status: 1,
    //         validateFieldsErrors: [],
    //         import_key: null,
    //         pose_id:date.event.extendedProps.data[1][0].pose_id,
    //         day:date.event.extendedProps.days,
    //         model_pdf: null,
    //         last_main_doc: null,
    //         note_public: date.event.extendedProps.data[1][0].team_size,
    //         date_creation: null,
    //         date_validation: null,
    //         date_modification: null,
    //         fk_agency_id: 2,
    //         availability: date.event.extendedProps.data[1][0].day_type,
    //   };  axios
    //       .post(`/installationapi/teams`, payload1, {
    //         headers: header,
    //       })
    //       .then((response) => {
    //         this.getCreatedTeams();
    //       })
    //       .catch((error) => {
    //         console.log(error);
    //       });
    //         let answer = "";
    //         if (result == "resolved") {
    //           this.startEventDropStatus = false;
    //           this.makeInitialEvents();
    //         } else {
    //           let msg_to_show = "Veuillez remplacer les membres et réessayer. <br>";
    //           Object.keys(result).forEach((key) => {
    //             Object.keys(result[key]).forEach((key2) => {
    //               let dt = moment(key).format("DD/MM/YYYY");
    //               msg_to_show +=
    //                 "<strong>" +
    //                 this.getUserName(key2).name +
    //                 "</strong> n'est pas disponible sur <strong>" +
    //                 dt +
    //                 "</strong><br>";
    //             });
    //           });
    //           this.$swal({
    //             title: `Certains membres sont affectés à d'autres poses le même jour.`,
    //             showDenyButton: false,
    //             showCancelButton: false,
    //             html: msg_to_show,
    //           }).then((optn) => {
    //             date.revert();
    //           });
    //         }
    //       }
    //     } else {
    //       this.$swal({
    //         title: "Êtes-vous sûr?",
    //         text: "Vous êtes sur le point de changer la date de cette pose",
    //         showDenyButton: false,
    //         showCancelButton: true,
    //         confirmButtonText: "OK",
    //         cancelButtonText: `Annulerr`,
    //       }).then((result) => {
    //         if (result.isConfirmed) {
    //           this.callScheduleAPI(payload);
    //           this.makeInitialEvents();
    //         } else {
    //           date.revert();
    //         }
    //       });
    //     }
    //     this.startEventDropStatus = false;
    //   }
    // },
    async startDrag(event, pose, color_code, type) {
      return new Promise((resolve) => {
        let dragged_dt = this.selected_date.valueOf();
        var now = moment().format("YYYY-MM-DD").valueOf();
        const dateIsBefore = moment(now).isSameOrBefore(moment(dragged_dt));
        if (!dateIsBefore) {
          this.errorMessage = "You can't plan pose to past";
          this.$swal("Error", this.errorMessage, "error");
          date.revert();
          return false;
        } else {
          this.startDragStatus = true;
          event.dataTransfer.dropEffect = "move";
          event.dataTransfer.effectAllowed = "move";
          event.dataTransfer.setData("pose", JSON.stringify(pose));
          let incrementar = parseInt(pose.estimated_days);
          let end = this.findStartandEndofevents(this.selected_date, incrementar);
          let planning = pose.planning;
          let data = this.makeDataFromPosePlanning(planning, pose.id, pose);
          let status = 1;
          if (type == "fin_poses") {
            status = 4; // fin_poses with planified date
          }
          let weekend_count = this.findWeekendCount(this.selected_date, parseInt(pose.estimated_days));
          this.calendarOptions.events.push({
            id: pose.id,
            title: this.getClientDetails(pose.client),
            start: this.selected_date,
            end: end,
            days: pose.estimated_days ? parseInt(pose.estimated_days) : 1,
            planning: pose.planning,
            data: data,
            allDay: true,
            status: status,
            day_string: this.enumerateDaysBetweenDates(this.selected_date, parseInt(pose.estimated_days)),
            validated_count: this.getValidatedCount(pose),
            weekend_count: weekend_count,
            after_sales: pose.after_sales,
          });
          var date = new Date(end);
          let pose_end_date = moment(date).subtract(1, "day").format("YYYY-MM-DD");
          this.updatePoseStartEnd(status, this.selected_date, pose_end_date, pose.id);
          resolve("resolved");
          this.startDragStatus = false;
        }
      });
    },
    makeDataFromPosePlanning(planning, pose_id, pose) {
      const keys = Object.keys(planning);
      let data = {};
      keys.forEach((key, index) => {
        const days_keys = Object.keys(planning[key].days);
        days_keys.forEach((k, i) => {
          planning[key].days[k].teams.forEach((team, j) => {
            if (data[k] == undefined) {
              data[k] = [];
            }
            data[k].push({
              id: planning[key].days[k].id,
              category_id: planning[key].id,
              pose_id: pose_id,
              team_index: j,
              day_type: team.day_type,
              team_id: team.team_id ? team.team_id : null,
              team_size: team.team_size,
              color: planning[key].color,
              members: team.members == undefined ? [] : team.members,
              need_material: pose.need_material ? pose.need_material : "0",
              need_equipment: pose.need_equipment ? pose.need_equipment : "0",
              file_status: pose.file_status ? pose.file_status : "0",
              validate: pose.validate ? pose.validate : "0",
              payment_status: pose.payment_status ? pose.payment_status : "0",
            });
          });
        });
      });
      return data;
    },
    enumerateDaysBetweenDates(startDate, days) {
      let endDate = this.findStartandEndofevents(startDate, days);
      var dates = [];
      var weekend_dts = [];
      var currDate = moment(startDate).startOf("day");
      var lastDate = moment(endDate).startOf("day");
      dates.push(moment(currDate.clone().toDate()).format("YYYY-MM-DD"));
      while (currDate.add(1, "days").diff(lastDate) < 0) {
        dates.push(moment(currDate.clone().toDate()).format("YYYY-MM-DD"));
      }
      var fromatted_dt = [];
      dates.forEach((date, index) => {
        if (moment(date).isoWeekday() == 6 || moment(date).isoWeekday() == 7) {
          weekend_dts.push(date);
        } else {
          fromatted_dt.push(date);
        }
      });
      return fromatted_dt;
    },
    enumerateAllDaysBetweenDates(startDate, days) {
      let endDate = this.findStartandEndofevents(startDate, days);
      var dates = [];
      var currDate = moment(startDate).startOf("day");
      var lastDate = moment(endDate).startOf("day");
      dates.push(moment(currDate.clone().toDate()).format("YYYY-MM-DD"));
      while (currDate.add(1, "days").diff(lastDate) < 0) {
        dates.push(moment(currDate.clone().toDate()).format("YYYY-MM-DD"));
      }
      var fromatted_dt = [];
      dates.forEach((date, index) => {
        if (moment(date).isoWeekday() == 6 || moment(date).isoWeekday() == 7) {
          fromatted_dt.push('');
        } else {
          fromatted_dt.push(date);
        }
      });
      return fromatted_dt;
    },
    findWeekendCount(startDate, days) {
      let endDate = this.findStartandEndofevents(startDate, days);
      var dates = [];
      var weekend_dts = [];
      var currDate = moment(startDate).startOf("day");
      var lastDate = moment(endDate).startOf("day");
      dates.push(moment(currDate.clone().toDate()).format("YYYY-MM-DD"));
      while (currDate.add(1, "days").diff(lastDate) < 0) {
        dates.push(moment(currDate.clone().toDate()).format("YYYY-MM-DD"));
      }
      var weekend_count = 0;
      dates.forEach((date, index) => {
        if (moment(date).isoWeekday() == 6) {
          weekend_count += 1;
        }
        if (moment(date).isoWeekday() == 7) {
          weekend_count += 1;
        }
      });
      return weekend_count;
    },
    findStartandEndofevents(date, incrementar) {
      var mydate = new Date(date);
      mydate.setDate(mydate.getDate() + incrementar); // add incrementar days
      var end =
        mydate.getFullYear() +
        "-" +
        (mydate.getMonth() + 1 < 10 ? "0" : "") +
        (mydate.getMonth() + 1) +
        "-" +
        String(mydate.getDate()).padStart(2, "0");
      var nextDate = moment(date).add(1, "day");
      var endDate = moment(end);
      let isWeekend = false;
      let weekend_count = 0;
      if (nextDate.isoWeekday() == 6) {
        weekend_count += 1;
      }
      if (nextDate.isoWeekday() == 7) {
        weekend_count += 1;
      }
      if (nextDate.isoWeekday() == 6 || nextDate.isoWeekday() == 7) {
        isWeekend = true;
        endDate.add(2, "day");
        end = moment(endDate).format("YYYY-MM-DD");
      }
      return end;
    },
    findWeekendCounts(date, incrementar) {
      var mydate = new Date(date);
      mydate.setDate(mydate.getDate() + incrementar); // add incrementar days
      var end =
        mydate.getFullYear() +
        "-" +
        (mydate.getMonth() + 1 < 10 ? "0" : "") +
        (mydate.getMonth() + 1) +
        "-" +
        String(mydate.getDate()).padStart(2, "0");
      var nextDate = moment(date).add(1, "day");
      var endDate = moment(end);
      let isWeekend = false;
      let weekend_count = 0;
      if (nextDate.isoWeekday() == 6) {
        weekend_count += 1;
      }
      if (nextDate.isoWeekday() == 7) {
        weekend_count += 1;
      }
      return weekend_count;
    },
    replaceTeamDataFromPlanning(planning, member, day_index) {
      const keys = Object.keys(planning);
      let changed_member = "";
      keys.forEach((key, index) => {
        planning[key].days[day_index].teams.forEach((team) => {
          if (team.members.includes(member)) {
            changed_member = team.members;
            team.members = [];
          }
        });
      });
      return { planning: planning, changed_member: changed_member };
    },
    getTeamUsingID(team_id) {
      return new Promise((resolve) => {
        let data = "";
        axios
          .get(`installationapi/teams/` + team_id, {
            headers: { DOLAPIKEY: JSON.parse(window.localStorage.getItem("user-info")).data.success.token },
          })
          .then((resp) => {
            data = resp.data.resolve("resolved");
          })
          .catch((error) => {
            resolve("rejected");
          });
        return data;
      });
    },
    async _updateTeamAvailability(header, team, members) {
      return new Promise((resolve) => {
        let user_ids = [];
        let status = 0;
        if (members) {
          if (members.length == 0) {
            let team_data = this.getTeamUsingID(team);
            user_ids = team_data.user_ids;
          } else {
            user_ids = members;
            // checking team status
            const counts = {};
            for (const num of Object.values(user_ids)) {
              counts[num] = counts[num] ? counts[num] + 1 : 1;
            }
            if (counts[0] == 0 || counts[0] == undefined) {
              status = "1";
            }
          }
        }
        let load = {
          user_ids: user_ids,
          availability: "J",
          pose_id: "0",
          status: status,
        };
        if (members) {
          if (members.length < 0) {
            load["status"] = 0;
          }
        }
        axios
          .put(`installationapi/teams/` + team, load, {
            headers: header,
          })
          .then((response) => {
            this.successMessage = "Team changed successfully";
            this.makeInitialEvents();
          })
          .catch((error) => {
            console.log("error", error);
          });
        resolve(true);
      });
    },
    async updatePoseStartEnd(status, start, end, id) {
      return new Promise((resolve) => {
        let header = {
          DOLAPIKEY: JSON.parse(window.localStorage.getItem("user-info")).data.success.token,
        };
        let payload = {
          start_date: start ? start : "",
          end_date: end ? end : "",
        };
        if (status) {
          payload["status"] = status;
        }
        axios
          .put(`installationapi/installations/` + id, payload, {
            headers: header,
          })
          .then((response) => {
            this.successMessage = "Pose updated successfully";
            this.filtered_poses.forEach((f_pose, index) => {
              if (f_pose.id == id) {
                this.filtered_poses.splice(index, 1);
              }
            });
            this.fin_poses.forEach((f_pose, index) => {
              if (f_pose.id == id) {
                this.fin_poses.splice(index, 1);
              }
            });
          })
          .catch((error) => {
            console.log("error", error);
          });
        resolve("resolved");
      });
    },
    startDrag2(evt, d, dd) {
      this.calendarEventDrag = true;
    },
    drop(dropInfo) {
      console.log("dropInfo");
    },
    onDrop(evt, day, mnth) {
      this.imageDragged = true;
      const month = new Date().getMonth();
      const year = new Date().getFullYear();
      var day = day - 1;
      var mnth = mnth - 1;
      for (let i = 0; i < 3; i++) {
        var day = day + 1;
        if (this.calendarEventDrag) {
          for (let i = 0; i < 3; i++) {
            const index = this.attributes.findIndex((key) => key.key === day);
            this.attributes.splice(index, 1);
          }
          this.calendarEventDrag = false;
        }
        this.attributes.push({
          key: day,
          customData: {
            title: "",
            class: "bg-green-50 shadow-sm header-box-outer text-gray",
          },
          dates: new Date(year, mnth, day),
        });
      }
    },
    getAvailabilityName(key) {
      let availability = {
        J: "Full Day",
        M: "Morning",
        A: "Afternoon",
      };
      return availability[key];
    },
    checkAvailabilityStatus(from_type, to_type) {
      let status = false;
      let changed_team_status = from_type;
      if (from_type == "J" && to_type == "J") {
        changed_team_status = "J";
        status = true;
      } else if (from_type == "A" && to_type == "A") {
        changed_team_status = "A";
        status = true;
      } else if (from_type == "M" && to_type == "M") {
        changed_team_status = "M";
        status = true;
      } else if (from_type == "J" && to_type == "A") {
        changed_team_status = "A";
        status = true;
      } else if (from_type == "J" && to_type == "M") {
        changed_team_status = "M";
        status = true;
      } else {
        status = false;
        changed_team_status = "J";
      }
      return { status, changed_team_status };
    },
    availableTeamDrag(event, team) {
      event.dataTransfer.dropEffect = "move";
      event.dataTransfer.effectAllowed = "move";
      event.dataTransfer.setData("team", JSON.stringify(team));
    },
    async onDropTeamtoPose(event, i, to_team, planning, event_date, arg, index, pose_status) {
      if(arg.event.extendedProps.after_sales == 1){
        this.$swal({
          title: "Après vente",
          text: "Les poses après-vente ne peuvent pas être attribuées aux équipes",
          type: "error",
          confirmButtonText: "Ok",
        });
        return false;
      }
      let event_date_str = moment(arg.event.extendedProps.day_string[index - 1]).format("YYYY-MM-DD");
      const dragged_team_data = JSON.parse(event.dataTransfer.getData("team"));
      let team_available_date = dragged_team_data.appointed_date;
      let team_availability = dragged_team_data.availability;
      let availability = await this.checkAvailabilityStatus(team_availability, to_team.day_type);
      if (!availability.status) {
        this.$swal(`L’équipe n'est pas disponible sur l'ensemble de la journée. ${this.getAvailabilityName(to_team.day_type)}`);
        return;
      } else if (to_team.members.length > 0) {
        this.$swal("Team already has members");
        return;
      } else if (team_available_date != event_date_str) {
        this.$swal("Team is not available on this date");
        return;
      } else {
        this.addTeamToPose(i, dragged_team_data, to_team, planning, availability.changed_team_status, pose_status);
      }
    },
    async addTeamToPose(i, team, to_pose_team, planning, changed_team_status, pose_status) {
      let team_v_id = await this.changeTeamStatus(team, changed_team_status, to_pose_team, pose_status);
      const keys = Object.keys(planning);
      let new_planning = planning;
      new_planning[to_pose_team.category_id].days[to_pose_team.id].teams[to_pose_team.team_index]["members"] =
        team.members;
      new_planning[to_pose_team.category_id].days[to_pose_team.id].teams[to_pose_team.team_index]["team_id"] =
        team_v_id;
      new_planning[to_pose_team.category_id].days[to_pose_team.id].teams[to_pose_team.team_index]["team_size"] =
        team.members.length;
      let header = {
        DOLAPIKEY: JSON.parse(window.localStorage.getItem("user-info")).data.success.token,
      };
      let status = 2;
      let payload = {};
      if (pose_status == "4" || pose_status == "5" || pose_status == "3") {
        status = 5;
        payload = {
          finition_planning: new_planning,
          status: status,
        };
      } else {
        payload = {
          planning: new_planning,
          status: status,
        };
      }
      axios
        .put(`installationapi/installations/` + to_pose_team.pose_id, payload, {
          headers: header,
        })
        .then((response) => {
          this.calendarOptions.events.forEach((item, index) => {
            if (item.id == to_pose_team.pose_id) {
              to_pose_team.members = team.members;
              item.status = status;
            }
          });
          this.$swal("Équipe ajoutée avec succès");
          this.makeInitialEvents();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getTimeStamp(date) {
      var localTime = moment(date).toDate();
      localTime = moment(localTime).format("YYYY-MM-DD");
      return moment(localTime).format("X");
    },
    async createTeamPOST(team, header, pose_id, day, pose_status) {
      let payload = {
        module: "teams",
        ref: team.ref,
        user_ids: team.members,
        category: team.category,
        appointed_date: team.appointed_date,
        status: 2,
        validateFieldsErrors: [],
        import_key: null,
        model_pdf: null,
        last_main_doc: null,
        note_public: team.size,
        date_creation: null,
        date_validation: null,
        date_modification: null,
        fk_agency_id: this.$store.state.selectedAgencyID,
        availability: team.availability,
        pose_id: pose_id,
        day: day,
      };
      if (pose_status == "4" || pose_status == "5" || pose_status == "3") {
          payload['ref'] = team.ref.replace("T-", "TF-");
        }
      return axios
        .post(`/installationapi/teams`, payload, {
          headers: header,
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async changeTeamStatus(team, changeTeamStatus, to_pose_team, pose_status) {
      let team__id = team.id;
      let availability_dict = {
        A: "M",
        M: "A",
        J: "J",
      };
      let availability = "";
      let status = "";
      let header = {
        DOLAPIKEY: JSON.parse(window.localStorage.getItem("user-info")).data.success.token,
      };
      availability = availability_dict[changeTeamStatus];
      if (team.id == "") {
        team__id = await this.createTeamPOST(team, header, to_pose_team.pose_id, to_pose_team.id, pose_status);
        this.successMessage = "Team status changed successfully";
        this.teams.splice(this.teams.indexOf(team), 1);
        this.filtered_teams.splice(this.filtered_teams.indexOf(team), 1);
        this.getCreatedTeams();
        this.makeInitialTeams();
      } else {
        let payload = {
          status: 2,
          availability: changeTeamStatus,
          category: to_pose_team.category_id,
          pose_id: to_pose_team.pose_id,
          day: to_pose_team.id,
        };
        if (pose_status == "4" || pose_status == "5" || pose_status == "3") {
          payload['ref'] = team.ref.replace("T-", "TF-");
        }
        axios
          .put(`installationapi/teams/` + team.id, payload, {
            headers: header,
          })
          .then((response) => {
            this.successMessage = "Team status changed successfully";
            this.teams.splice(this.teams.indexOf(team), 1);
            this.filtered_teams.splice(this.filtered_teams.indexOf(team), 1);
            this.getCreatedTeams();
            this.makeInitialTeams();
          })
          .catch((error) => {
            console.log(error);
          });
      }
      return team__id;
    },
    async removeTeam(team) {
      await this.$swal.fire({
        title: "Êtes-vous sûr ?",
        text: "Vous êtes sur le point de supprimer cette équipe.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Oui, supprimez-le !",
      }).then((result) => {
        if (result.value) {
          let header = {
            DOLAPIKEY: JSON.parse(window.localStorage.getItem("user-info")).data.success.token,
          };
          axios
          .put(`installationapi/team/remove/`+team.team_id, {updatePose:1}, {
            headers: header,
          }).then((response) => {
              this.successMessage = "Team removed successfully";
              this.getCreatedTeams();
              this.makeInitialEvents();
              this.$swal.fire({
                  title: "Supprimé!",
                  text: "L'équipe a été supprimée avec succès",
                  icon: "success",
                  showCancelButton: false,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Ok",
                }).then((result) => {
                  if (result.value) {
                    location.reload();
                  }
                });
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    },
    async updateTeam(team_id, header, status, pose_id) {
      let new_status = "1";
      let load = {
        pose_id: pose_id,
        status: new_status,
        day: 0,
        unassigned: 1,
      };
      axios
        .put(`installationapi/teams/` + team_id, load, {
          headers: header,
        })
        .then((response) => {
          console.log("updated TEAM");
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    formatWeekDaysofMonth(weeknumber) {
      let days = [];
      var dateformat = "MMM D";
      var dateformat2 = "YYYY-MM-DD";
      var date = moment()
        .isoWeek(weeknumber || 1)
        .startOf("week");
      var weeklength = 5;
      while (weeklength--) {
        days.push({
          label: date.format(dateformat),
          date: date.format(dateformat2),
          teams: [],
        });
        date.add(1, "day");
      }
      let f_day_of_week = days[0].date;
      let l_day_of_week = days[days.length - 1].date;
      return {
        f_day_of_week: f_day_of_week,
        l_day_of_week: l_day_of_week,
        days: days,
        weeknumber: weeknumber,
      };
    },
    getLeavesofSelectedWeek() {
      let data = this.formatWeekDaysofMonth(this.activeWeekNumber);
      let leave_data = {};

      this.installers.forEach((installer) => {
        if (installer.leaves.length > 0) {
          installer.leaves.forEach((leave) => {
            var day1 = moment(leave.date_debut);
            var day2 = moment(leave.date_fin);
            var result = [moment({ ...day1 })];
            while (day1.date() != day2.date()) {
              day1.add(1, "day");
              result.push(moment({ ...day1 }));
            }
            result = result.map((x) => x.format("YYYY-MM-DD"));

            data.days.forEach((day) => {
              if (result.includes(day.date)) {
                if (leave_data[installer.id]) {
                  leave_data[installer.id].push(day.date);
                } else {
                  leave_data[installer.id] = [day.date];
                }
              }
            });
          });
        }
      });
      this.leave_data = leave_data;
    },
  },
  watch: {
    async agencychange(val) {
    // console.log("AGENCY CHANGE") 
    this.filters = this.product_groups;
    this.unplannedPosesStatus = await this.fetchUnplannedPoses();
    this.makeInitialTeamStatus = await this.makeInitialTeams();
    this.$store.dispatch("loadInstallers");
    this.$store.dispatch("loadProductGroups");
    this.$store.dispatch("loadPoses", { limit: "", page: "" });
    let initialeventsStatus = await this.makeInitialEvents();
      
    },
  },
  computed: {
    ...mapState({
      poses: (state) => state.poses,
      product_groups: (state) => state.product_groups,
      installers: (state) => state.installers,
    }),
  },
};
</script>
<style>
.dashboard__img-toggle-shape.header-box {
  background: #fff;
  border: 2px solid #4e73df;
}
.dashboard__img-toggle-shape.header-box.orange {
  background: #fff;
  border: 2px solid #dbbba6;
}
.header-box-outer {
  border-bottom-color: #9f137e;
}
.flex-col {
  flex-direction: column;
}
.flex {
  display: flex;
}
.flex-grow {
  flex-grow: 1;
}
.h-full {
  height: 100%;
}
.bg-red-600 {
  background-color: #e53e3e;
}
.today {
  background-color: #d2e1ec;
}
.bg-teal-500 {
  background-color: #38b2ac;
}
.bg-pink-500 {
  background-color: #ed64a6;
}
.bg-indigo-500 {
  background-color: #667eea;
}
.overflow-y-scroll {
  overflow-y: scroll;
}
.overflow-x-auto {
  overflow-x: auto;
}
.z-10 {
  z-index: 10;
}
.overflow-hidden {
  overflow: hidden;
}
.text-gray-900 {
  color: #1a202c;
}
.vc-h-full {
  height: 100%;
}
.custom-calendar.vc-container {
  --day-border: 1px solid #b8c2cc;
  --day-border-highlight: 1px solid #b8c2cc;
  --day-width: 90px;
  --day-height: 90px;
  --weekday-bg: #f8fafc;
  --weekday-border: 1px solid #eaeaea;
  border-radius: 0;
  width: auto;
}
.custom-calendar.vc-container .vc-weeks {
  padding: 0;
  height: 80vh;
  grid-template-rows: 33px repeat(6, 1fr);
}
.custom-calendar.vc-container .vc-header {
  background-color: #f1f5f8;
  padding: 10px 0;
}
.custom-calendar.vc-container .vc-weekday {
  background-color: var(--weekday-bg);
  border-bottom: var(--weekday-border);
  border-top: var(--weekday-border);
  padding: 5px 0;
}
.vc-border {
  border-width: 1px;
}
.custom-calendar.vc-container .vc-day:not(.on-right) {
  border-right: var(--day-border);
}
.custom-calendar.vc-container .vc-day:not(.on-bottom) {
  border-bottom: var(--day-border);
}
.custom-calendar.vc-container .vc-day.weekday-1,
.custom-calendar.vc-container .vc-day.weekday-7 {
  background-color: #eff8ff;
}
.custom-calendar.vc-container .vc-day {
  padding: 2px 0;
  text-align: left;
  /*min-height: var(--day-height);*/
  min-width: var(--day-width);
  background-color: #fff;
}
.vc-day {
  position: relative;
  min-height: var(--day-min-height);
  width: 100%;
  height: 100%;
  z-index: 1;
}
.text-center {
  text-align: center;
}
.section {
}
.max-w-full {
  max-width: 100%;
}
.bg-blue-500 {
  background-color: #4299e1;
}
.text-xs {
  font-size: 0.75rem;
}
.text-white {
  color: #fff;
}
.p-1 {
  padding: 0.25rem;
}
.mb-1 {
  margin-bottom: 0.25rem;
}
.mt-0 {
  margin-top: 0;
}
.leading-tight {
  line-height: 1.25;
}
.rounded-sm {
  border-radius: 0.125rem;
}
::-webkit-scrollbar {
  width: 0px;
}
::-webkit-scrollbar-track {
  display: none;
}
.demo-app {
  display: flex;
  min-height: 100%;
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-size: 14px;
}
.demo-app-sidebar {
  width: 300px;
  line-height: 1.5;
  background: #eaf9ff;
  border-right: 1px solid #d3e2e8;
}
.demo-app-sidebar-section {
  padding: 2em;
}
.demo-app-main {
  flex-grow: 1;
  padding: 3em;
}
.fc {
  /* the calendar root */
  max-width: 100%;
  margin: 0 auto;
  width: 100%;
}
.fc-daygrid-body-balanced,
.fc-col-header,
.fc-scrollgrid-sync-table,
.fc-daygrid-body {
  width: 100% !important;
}
.fc-daygrid-event {
  border: none;
}
.fc-h-event .fc-event-main {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
  text-decoration: none;
  background-color: #f0fdf4;
  /* border: 1px solid #9f137e; */
  border-radius: 3%;
  border-right: 0.15px solid #9f137e;
  border-left: 0.15px solid #9f137e;
  
}
.pose-green {
  border: none;
  color: #1a202c !important;
}
.pose-green-bg {
  background-color: #f0fdf4;
}
.fc-daygrid-day-frame {
  margin-top: 20px;
}
.fc .fc-daygrid-day-bg .fc-highlight {
  background-color: #ffa569;
}
.remove-pose-icon {
  z-index: 999999 !important;
}
.fc-daygrid-day {
  cursor: pointer;
}
.fc-daygrid-day-events {
  display: flex;
  flex-direction: column;
}
/* 
.fc-daygrid-event {
  height: 120px;
  overflow-y: auto;
} */
.pose-hover {
  z-index: 999999 !important;
}
</style>
