<template>
  <!-- Begin - Dashboard Side Navbar -->
  <nav
    class="
      fixed
      left-0
      top-0
      flex flex-col
      items-center
      w-16
      h-screen
      overflow-hidden
      text-gray-700
      bg-gray-100
      rounded
      side-nav
    "
  >
    <!-- DealEco Logo Instead of the SVG here -->
    <a class="flex items-center justify-center mt-1 logo"  href="/">
      <img class="mx-auto h-12 w-auto" :src="logo" alt="Workflow" />
    </a>

    <!-- Nav Menu Items Container -->
    <div class="flex flex-col items-center mt-3 border-t border-gray-300">
      <!-- Begin Nav Menu Items -->

      <!-- Home/Dashboard Icon -->
      <router-link
        to="/"
        class="
          flex
          items-center
          justify-center
          w-12
          h-12
          mt-2
          rounded
          hover:bg-gray-300
          menu1
        "
        title="Home"
      >
        <svg
          class="w-6 h-6 stroke-current"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
          />
        </svg>
      </router-link>

      <router-link
        to="/pose"
        class="
          flex
          items-center
          justify-center
          w-12
          h-12
          mt-2
          hover:bg-gray-300
          rounded
          menu4
        "
        title="Pose"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          aria-hidden="true"
          role="img"
          class="w-6 h-6"
          preserveAspectRatio="xMidYMid meet"
          viewBox="0 0 32 32"
        >
          <path
            fill="#7ebfbf"
            d="m26.96 30l-1.921-6.725a1 1 0 0 1 .336-1.056l4.499-3.599l-1.354-5.419l-2.738 3.424A1.003 1.003 0 0 1 25 17h-5v-2h4.52l3.7-4.625a1 1 0 0 1 1.75.383l2 8a.999.999 0 0 1-.344 1.023l-4.48 3.584l1.738 6.086zM23 5.5A3.5 3.5 0 1 1 26.5 9A3.504 3.504 0 0 1 23 5.5zm2 0A1.5 1.5 0 1 0 26.5 4A1.502 1.502 0 0 0 25 5.5z"
          ></path>
          <path
            fill="#7ebfbf"
            d="M20.004 19A2.004 2.004 0 0 1 18 16.996v-1.992A2.004 2.004 0 0 1 20.004 13H22v-3H10v3h1.996A2.004 2.004 0 0 1 14 15.004v1.992A2.004 2.004 0 0 1 11.996 19H10v3h12v-3Z"
          ></path>
          <path
            fill="#7ebfbf"
            d="m5.04 30l1.921-6.725a1.001 1.001 0 0 0-.336-1.056L2.126 18.62l1.355-5.418l2.738 3.423A1.003 1.003 0 0 0 7 17h5v-2H7.48l-3.699-4.625a1 1 0 0 0-1.75.383l-2 8a.999.999 0 0 0 .344 1.023l4.48 3.584l-1.739 6.086zM5.5 9A3.5 3.5 0 1 1 9 5.5A3.504 3.504 0 0 1 5.5 9zm0-5A1.5 1.5 0 1 0 7 5.5A1.502 1.502 0 0 0 5.5 4z"
          ></path>
        </svg>
      </router-link>

      <router-link
        to="/clients"
        class="
          flex
          items-center
          justify-center
          w-12
          h-12
          mt-2
          hover:bg-gray-300
          rounded
          menu2
        "
        title="Clients"
      >
        <svg
          class="w-7 h-7"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
            clip-rule="evenodd"
          ></path>
        </svg>
      </router-link>

      <!-- Active Nav Item Class: bg-gray-300 -->
      <router-link
        to="/teams"
        class="
          flex
          items-center
          justify-center
          w-12
          h-12
          mt-2
          hover:bg-gray-300
          rounded
          menu3
        "
        title="Teams"
      >
      <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          aria-hidden="true"
          role="img"
          class="h-7 w-7"
          preserveAspectRatio="xMidYMid meet"
          viewBox="0 0 20 20"
        >
          <path
            fill="currentColor"
            d="M8.5 4.5a1.5 1.5 0 1 1 3 0a1.5 1.5 0 0 1-3 0ZM10 2a2.5 2.5 0 1 0 0 5a2.5 2.5 0 0 0 0-5Zm4.5 3a1 1 0 1 1 2 0a1 1 0 0 1-2 0Zm1-2a2 2 0 1 0 0 4a2 2 0 0 0 0-4Zm-11 1a1 1 0 1 0 0 2a1 1 0 0 0 0-2Zm-2 1a2 2 0 1 1 4 0a2 2 0 0 1-4 0ZM5 15c.033 0 .067 0 .1-.002c.068.338.171.665.304.975A3 3 0 0 1 2 13V9.25C2 8.56 2.56 8 3.25 8h2.129a2.237 2.237 0 0 0-.365 1H3.25a.25.25 0 0 0-.25.25V13a2 2 0 0 0 2 2Zm12-5.5a2.5 2.5 0 0 1 .958 1.542l.042.008v-1.8C18 8.56 17.44 8 16.75 8h-2.129c.196.292.325.633.365 1h1.764a.25.25 0 0 1 .25.25v.25Zm-1.5.5h-2a1.5 1.5 0 0 0-1.5 1.5v.5h-.5a1.5 1.5 0 0 0-1.5 1.5V15h2v-.5a.5.5 0 0 1 1 0v.5h3v-.5a.5.5 0 0 1 1 0v.5h2v-1.5a1.5 1.5 0 0 0-1.5-1.5H17v-.5a1.5 1.5 0 0 0-1.5-1.5ZM7.25 9a.25.25 0 0 0-.25.25V14c0 1.306.835 2.418 2 2.83v.67c0 .13.01.257.029.381A4.002 4.002 0 0 1 6 14V9.25C6 8.56 6.56 8 7.25 8h5.5c.605 0 1.11.43 1.225 1H7.25ZM13 12v-.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5v.5h-3Zm4 4v.5a.5.5 0 0 1-1 0V16h-3v.5a.5.5 0 0 1-1 0V16h-2v1.5a1.5 1.5 0 0 0 1.5 1.5h6a1.5 1.5 0 0 0 1.5-1.5V16h-2Z"
          ></path>
        </svg>
      </router-link>

      <router-link
        to="/pose/planifier"
        class="
          flex
          items-center
          justify-center
          w-12
          h-12
          mt-2
          hover:bg-gray-300
          rounded
          menu5
        "
        title="Pose Plannifier"
      >
      <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          aria-hidden="true"
          role="img"
          class="h-7 w-7"
          preserveAspectRatio="xMidYMid meet"
          viewBox="0 0 40 40"
        >
          <g fill="currentColor">
            <path
              d="M24.748 26.1c4.015 0 7.281-3.266 7.281-7.279c0-4.017-3.267-7.283-7.281-7.283c-4.015 0-7.282 3.267-7.282 7.283c0 4.013 3.267 7.279 7.282 7.279zm0-13.364a6.09 6.09 0 0 1 6.081 6.084a6.087 6.087 0 0 1-6.081 6.08a6.088 6.088 0 0 1-6.082-6.08c.001-3.354 2.729-6.084 6.082-6.084zm13.297 20.256c-.319-5.684-5.483-6.032-5.568-6.035l-15.492.001c-.052.002-5.217.351-5.535 6.067v4.308a.6.6 0 0 0 .6.6h25.395a.6.6 0 0 0 .6-.6v-4.341zm-1.199 3.741H12.651l-.001-3.675c.256-4.595 4.234-4.893 4.368-4.902l15.424-.001c.169.011 4.146.309 4.403 4.87v3.708zm-24.228-17.79h.004c1.233-.009 2.301-.451 3.174-1.316c.874-.873 1.316-1.941 1.316-3.176c0-1.241-.443-2.311-1.316-3.177c-.874-.873-1.942-1.316-3.176-1.316c-1.241 0-2.311.443-3.175 1.315c-.874.867-1.317 1.937-1.317 3.178c0 1.234.443 2.303 1.316 3.175c.866.866 1.933 1.309 3.174 1.317zm-2.641-7.139c.73-.737 1.596-1.096 2.644-1.096c1.042 0 1.908.359 2.647 1.099c.737.73 1.096 1.596 1.096 2.645c0 1.043-.359 1.908-1.096 2.644c-.737.731-1.604 1.091-2.647 1.099c-1.05-.008-1.916-.366-2.646-1.098c-.738-.736-1.097-1.602-1.097-2.645c0-1.05.359-1.915 1.099-2.648z"
            ></path>
            <path
              d="M12.039 16.15a.37.37 0 0 0 .245.092a.377.377 0 0 0 .283-.129l2.539-2.91a.374.374 0 1 0-.565-.492l-2.293 2.628l-1.128-.975a.373.373 0 1 0-.49.566l1.409 1.22zM20.13 9.8s.174-.25.618-.4c.343-.116.562-.151.562-.151V6.33c0-.768-.568-1.434-1.434-1.434h-2.194v1.18h2.448V9.8zM8.993 7.793h7.241c.37 0 .717-.26.717-.731V5.35c0-.652-.519-1.185-1.17-1.185h-.278C15.181 2.915 13.959 2 12.606 2c-1.353 0-2.575.915-2.896 2.165h-.264c-.651 0-1.184.533-1.184 1.185v1.712c0 .424.309.731.731.731zm3.613-4.345c.802 0 1.448.646 1.448 1.448c0 .802-.646 1.448-1.448 1.448a1.445 1.445 0 0 1-1.448-1.448c0-.802.646-1.448 1.448-1.448z"
            ></path>
            <path
              d="M15.952 22.558H5.096V6.076h2.448v-1.18H5.351c-.768 0-1.419.665-1.419 1.434v15.988c0 .768.651 1.404 1.419 1.404h11.728c0 .001-.888-.367-1.127-1.164z"
            ></path>
          </g>
        </svg>
      
      </router-link>

      <!-- End Nav Menu Items -->
    </div>

    <!-- Account Settings / User -->
    <!-- <a
      class="flex items-center justify-center w-16 h-16 mt-auto menu6"
      href="#"
      title="Settings"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
        />
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
        />
      </svg>
    </a> -->
  </nav>
  <!-- End - Dashboard Side Navbar  -->
</template>

<script>
import logo from "../assets/logo_icon.png";

export default {
  name: "SideBar",
  props: {
    msg: String,
  },
  data() {
    return {
      logo: logo,
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.home svg {
  color: #cbc663;
}
.menu2 svg {
  color: #cec520;
}
.menu3 svg {
  color: #9f137e;
}
.menu4 svg {
  color: #9f137e;
}
.menu5 svg {
  color: #4d725c;
}
.menu6 {
  background-color: #9f137e;
}
.menu6 svg {
  color: #fff;
}
.logo svg {
  color: #9f137e;
}

a.router-link-active {
  --tw-bg-opacity: 1;
  background-color: rgb(209 213 219 / var(--tw-bg-opacity));
}
.logo_icon {
  width: 3rem;
  height: 3rem;
}
</style>
