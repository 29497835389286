<template>
  <div class="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
  <div class="max-w-md w-full space-y-8">
    <div>
      <img class="mx-auto h-auto w-32" :src="logo" alt="Workflow">
      <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">Connectez-vous à votre compte</h2>
      <p class="mt-2 text-center text-sm text-gray-600">
      </p>
    </div>
   
    <form class="mt-8 space-y-10 bg-slate-50 p-5" action="#" method="POST" @submit.prevent="handleSubmit(submit)">
     <!-- messgae alert  -->
    <div class="p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800" role="alert" v-if="errorMessage">
        <span class="font-medium"></span> {{errorMessage}}
    </div>
    <div class="p-4 mb-4 text-sm text-blue-700 bg-blue-100 rounded-lg dark:bg-blue-200 dark:text-blue-800" role="alert" v-if="message">
        <span class="font-medium">{{message}} </span>
    </div>
    <!-- end of alert  -->

      <input type="hidden" name="remember" value="true">
      <div class="rounded-md shadow-sm -space-y-px">
        <div class="my-6">
          <label for="email-address" class="">Nom d'utilisateur</label>
          <input id="email-address" v-model="username" name="username" type="text" autocomplete="email" required class="appearance-none rounded relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900  focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="">
        </div>
        <div  class="my-6">
          <label for="password" class="">Mot de passe</label>
          <input id="password" name="password" v-model="password" type="password" autocomplete="current-password" required class="appearance-none rounded relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="">
        </div>
      </div>

      <div class="flex items-center justify-between">
        <div class="flex items-center">
          <input id="remember-me" name="remember-me" type="checkbox" class="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded">
          <label for="remember-me" class="ml-2 block text-sm text-gray-900"> Se souvenir de moi </label>
        </div>

        <div class="text-sm">
          <a href="#" class="font-medium text-indigo-600 hover:text-indigo-500"> Mot de passe oublié ? </a>
        </div>
      </div>

      <div>
        <button type="submit" class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white signin-btn hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
          <span class="absolute left-0 inset-y-0 flex items-center pl-3">
            <!-- Heroicon name: solid/lock-closed -->
            <svg class="h-5 w-5 text-indigo-500 group-hover:text-indigo-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clip-rule="evenodd" />
            </svg>
          </span>
          Se connecter
        </button>
      </div>
    </form>
  </div>
</div>
</template>
<script>
import axios from "../http-common"; // <-- HTTP common
import logo from "../assets/logo.png";
import  '../services/permissions';

    export default {
        title: 'Login | DealEco',
        name: 'LoginView',
        data() {
            return {
                username: null,
                password: null,
                cardHidden: true,
                type: ["", "info", "success", "warning", "danger"],
                message: null,
                noError: true,
                errorMessage: null,
                // arrusergroups : [],
                logo
                };
        },
        methods : {
            containsKey(obj, key ) {
                return Object.keys(obj).includes(key);
            },
            async handleSubmit() {  
                var app = this;
                const authUser = {};
                await axios.post("/login?", {
                        login: this.username,
                        password: this.password
                    }
                )
                .then(response => {
                    axios.get("/installationapi/user?includePermissions=1&includeGroups=1&includeLeaves=1", {
                        headers: {
                            DOLAPIKEY: response.data.success.token
                        }
                    })
                    .then(res => {

                        let has_techniciengroup     = 0; // VUE_APP_INSTALLER_GROUP_ID
                        let has_responsableaggroup  = 0; // VUE_APP_RESPONSABLE_AGENCE_GROUP_ID
                        let has_animateurgroup      = 0; // VUE_APP_ANIMATEURS_GROUP_ID
                        let has_commerciauxgroup    = 0; // VUE_APP_SALESPERSON_GROUP_ID
                        let has_gestionposesgroup   = 0; // VUE_APP_GESTION_POSES_GROUP_ID

                        // const arrusergroups = [];
                        for(let zz=0; zz<res.data.user_group_list.length; zz++){

                          let groupid = res.data.user_group_list[zz].id;
                          
                          if(groupid == process.env.VUE_APP_RESPONSABLE_AGENCE_GROUP_ID) {
                            has_responsableaggroup = 1;
                          }
                          else if(groupid == process.env.VUE_APP_ANIMATEURS_GROUP_ID) {
                            has_animateurgroup = 1;
                          }
                          else if(groupid == process.env.VUE_APP_INSTALLER_GROUP_ID) {
                            has_techniciengroup = 1;
                          }
                          else if(groupid == process.env.VUE_APP_SALESPERSON_GROUP_ID) {
                            has_commerciauxgroup = 1;
                          }
                          else if(groupid == process.env.VUE_APP_GESTION_POSES_GROUP_ID) {
                            has_gestionposesgroup = 1;
                          }

                          // console.log(res.data.user_group_list[zz].id);
                          // arrusergroups.push(res.data.user_group_list[zz].id);
                        }


                        // console.log('has_techniciengroup : '+has_techniciengroup);
                        // console.log('process.env.VUE_APP_INSTALLER_GROUP_ID : '+process.env.VUE_APP_INSTALLER_GROUP_ID);

                        authUser.data = response.data;
                        authUser.token = response.data.success.token;
                        authUser.firstname = res.data.firstname;
                        authUser.lasttname = res.data.firstname;
                        authUser.user_groups = res.data.user_group_list;
                        authUser.agency_id = res.data.array_options.options_agency;
                        authUser.id = res.data.id;
                        authUser.photo = res.data.photo;


                        if(res.data.admin === '1') {
                            authUser.user_groups = [{name:'admin'}];
                            authUser.user = 'admin';
                            localStorage.setItem("user-info", JSON.stringify(authUser));
                            app.message = response.data.success.message;
                            this.$router.push("/"); 
                            app.$store.commit("setUserInfo", authUser);
                        }else if(has_responsableaggroup || has_animateurgroup || has_gestionposesgroup){                          
                          authUser.user = 'manager';
                          localStorage.setItem("user-info", JSON.stringify(authUser));
                          app.message = response.data.success.message;
                          this.$router.push("/"); 
                          app.$store.commit("setUserInfo", authUser);
                        }else if(has_techniciengroup){                          
                          authUser.user = 'installer';
                          localStorage.setItem("user-info", JSON.stringify(authUser));
                          app.message = response.data.success.message;
                          this.$router.push("/installer"); 
                          app.$store.commit("setUserInfo", authUser);
                        }else if(has_commerciauxgroup){                          
                          authUser.user = 'salesperson';
                          localStorage.setItem("user-info", JSON.stringify(authUser));
                          app.message = response.data.success.message;
                          this.$router.push("/salesperson"); 
                          app.$store.commit("setUserInfo", authUser);
                        }
                        else{
                            app.errorMessage = "Invalid user group";
                          this.$router.push("/login");
                        }

                        // if(res.data.admin === '1') {
                        //     authUser.user_groups = [{name:'admin'}];
                        //     authUser.user = 'admin';
                        //     localStorage.setItem("user-info", JSON.stringify(authUser));
                        //     app.message = response.data.success.message;
                        //     this.$router.push("/"); 
                        //     app.$store.commit("setUserInfo", authUser);
                        // }else if(res.data.user_group_list[0].name == "Responsable d'agence" || res.data.user_group_list[0].name == "Animateurs"){                          
                        //   authUser.user = 'manager';
                        //   localStorage.setItem("user-info", JSON.stringify(authUser));
                        //   app.message = response.data.success.message;
                        //   this.$router.push("/"); 
                        //   app.$store.commit("setUserInfo", authUser);
                        // }else if(res.data.user_group_list[0].name == 'Techniciens'){                          
                        //   authUser.user = 'installer';
                        //   localStorage.setItem("user-info", JSON.stringify(authUser));
                        //   app.message = response.data.success.message;
                        //   this.$router.push("/installer"); 
                        //   app.$store.commit("setUserInfo", authUser);
                        // }else if(res.data.user_group_list[0].name == 'Commerciaux'){                          
                        //   authUser.user = 'salesperson';
                        //   localStorage.setItem("user-info", JSON.stringify(authUser));
                        //   app.message = response.data.success.message;
                        //   this.$router.push("/salesperson"); 
                        //   app.$store.commit("setUserInfo", authUser);
                        // }
                        // else{
                        //     app.errorMessage = "Invalid user group";
                        //   this.$router.push("/login");
                        // }
                                                
                        app.$store.commit('SET_AGENCY_SELECTION', res.data.array_options.options_agency);
                        app.$store.commit("setAuthentication", true);

                    })
                    .catch(error => {
                      try{
                        app.errorMessage = error.res.data.error.message + " - Please check credentials.",
                        app.noError = false;  
                      }                 
                      catch(e){
                        app.errorMessage = "User have not enough permissions",
                        app.noError = false;
                      }
                      app.$store.commit("setAuthentication", false);

                    });
                    //                    
                })
                .catch(error => {
                  // checking if the error is a status code error
                  // else showing default error message
                  try{
                    app.errorMessage = error.response.data.error.message + " - Please check credentials.",
                    app.noError = false;
                  }catch(e){
                    app.errorMessage = "The user ID or password was incorrect",
                    app.noError = false;
                  }                    
                  app.$store.commit("setAuthentication", false);
                });
            }
    }
    };
</script>
<style scoped>
.signin-btn{
    background: #9f137e;
}
.signin-btn svg{
    fill: #ffffff;
}
</style>