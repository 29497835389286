<template>
  <div>
    <!-- Begin - Dashboard Side Navbar -->

      <!-- Component Start -->
      
      <SideBarSales />
      <!-- Component End  -->

      <!-- End - Dashboard Side Navbar  -->

      <!-- Begin -- Nav Top Bar -->

      <TopBarSales :user_info="user_info" />

      <!-- End - Nav Top Bar -->

      <!-- Begin - Dashboard Content -->

     <!-- Change the left padding to match the side nav bar -->
    <main class="pl-48 pt-24 p-4 w-full h-full bg-gray-100">

      <!-- Begin - Section 1 -->
      <div class="flex flex-row lg:flex-row gap-6">
        <div class="relative w-full lg:w-1/1 min-h-[200px] p-4 bg-white rounded-md shadow-md">
          <div class="absolute right-2 top-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
              />
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
              />
            </svg>
          </div>

          <h3 class="text-md font-bold uppercase border-b-2 border-fuchsia-700 text-fuchsia-700">COORDONNÉES CLIENT (Revenu par semaine - $ 0){{arg}}</h3>
          <div class="mt-4">
            <FullCalendar class="" :options="calendarOptions"  >
              <template v-slot:eventContent="arg">
                <!-- Begin - Section 2 -  RESPONSABLES D'EQUIPES -->
                <div class="flex flex-row gap-2">
                  <div
                    class="fc-event relative w-full lg:w-1/10 h-[210px] p-4 rounded-md border border-gray-200 shadow-sm header-box-outer cursor-move"
                    v-for="(dayData, index) in arg.event.extendedProps.data" :key="index"
                    :class="{'bg-[#ffe5e5]': (arg.event.extendedProps.card_color_code == 0), 'bg-[#ffe5e5]': arg.event.extendedProps.card_color_code % 2, 'bg-[#e6f9ee]': !(dayData.card_color_code % 2)}"
                  >
                    <!-- Begin - Section Subtitle -->
                    <h4 class="text-xs font-bold uppercase border-b">{{arg.event.title}} - {{arg.event.extendedProps.days}}</h4>
                    <!-- End - Section Subtitle -->
                    <!-- Begin - Small Toggle Shape Container -->
                    <div class="flex flex-wrap gap-2 h-[160px] overflow-y-scroll">
                      <!-- Replace div tags with img tag - Change border-pink-400 to desired color-->
                      <!-- Team of 1 -->
                        <div
                          class="relative h-9 py-1 mt-4 ml-2 px-2 rounded-md dashboard__img-toggle-shape orange bg-white header-box"
                          v-for="(team, i) in dayData" :key="i"
                          :class="['border-[#'+ team.color +']']"
                        >
                          <!-- Change the bg-fuchsia-700 to the desired color, match with the border of the parent  -->
                          <!--  Full DAY if "J" -->
                          <div
                  class="flex gap-1 items-center justify-center w-9 h-4 rounded-md  absolute top-[-16px] left-[-2px] rounded-bl-none rounded-br-none"
                  title="Full Day" v-if="team.day_type == 'J'"  :class="['bg-[#'+ team.color +']']">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    aria-hidden="true"
                    role="img"
                    class="iconify iconify--ep w-4 h-4"
                    preserveAspectRatio="xMidYMid meet"
                    viewBox="0 0 1024 1024"
                  >
                    <path
                      fill="white"
                      d="M512 704a192 192 0 1 0 0-384a192 192 0 0 0 0 384zm0 64a256 256 0 1 1 0-512a256 256 0 0 1 0 512zm0-704a32 32 0 0 1 32 32v64a32 32 0 0 1-64 0V96a32 32 0 0 1 32-32zm0 768a32 32 0 0 1 32 32v64a32 32 0 1 1-64 0v-64a32 32 0 0 1 32-32zM195.2 195.2a32 32 0 0 1 45.248 0l45.248 45.248a32 32 0 1 1-45.248 45.248L195.2 240.448a32 32 0 0 1 0-45.248zm543.104 543.104a32 32 0 0 1 45.248 0l45.248 45.248a32 32 0 0 1-45.248 45.248l-45.248-45.248a32 32 0 0 1 0-45.248zM64 512a32 32 0 0 1 32-32h64a32 32 0 0 1 0 64H96a32 32 0 0 1-32-32zm768 0a32 32 0 0 1 32-32h64a32 32 0 1 1 0 64h-64a32 32 0 0 1-32-32zM195.2 828.8a32 32 0 0 1 0-45.248l45.248-45.248a32 32 0 0 1 45.248 45.248L240.448 828.8a32 32 0 0 1-45.248 0zm543.104-543.104a32 32 0 0 1 0-45.248l45.248-45.248a32 32 0 0 1 45.248 45.248l-45.248 45.248a32 32 0 0 1-45.248 0z"
                    ></path>
                  </svg>
                </div>
                <!--  Morning DAY if "M" -->
                <div
                  class="flex gap-1 items-center justify-center w-9 h-4 rounded-md  absolute top-[-16px] left-[-2px] rounded-bl-none rounded-br-none"
                  title="Morning Day" v-if="team.day_type == 'M'" :class="['bg-[#'+ team.color +']']">
                  <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      aria-hidden="true"
                      role="img"
                      class="iconify iconify--ep w-4 h-4"
                      preserveAspectRatio="xMidYMid meet"
                      viewBox="0 0 1024 1024"
                    >
                      <path
                        fill="white"
                        d="M32 768h960a32 32 0 1 1 0 64H32a32 32 0 1 1 0-64zm129.408-96a352 352 0 0 1 701.184 0h-64.32a288 288 0 0 0-572.544 0h-64.32zM512 128a32 32 0 0 1 32 32v96a32 32 0 0 1-64 0v-96a32 32 0 0 1 32-32zm407.296 168.704a32 32 0 0 1 0 45.248l-67.84 67.84a32 32 0 1 1-45.248-45.248l67.84-67.84a32 32 0 0 1 45.248 0zm-814.592 0a32 32 0 0 1 45.248 0l67.84 67.84a32 32 0 1 1-45.248 45.248l-67.84-67.84a32 32 0 0 1 0-45.248z"
                      ></path>
                    </svg>
                </div>
                <!-- Afternoon if "A" -->
                <div
                  class="flex gap-1 items-center justify-center w-9 h-4 rounded-md  absolute top-[-16px] left-[-2px] rounded-bl-none rounded-br-none"
                  title="Half Day" v-if="team.day_type == 'A'"  :class="['bg-[#'+ team.color +']']">
                  <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      aria-hidden="true"
                      role="img"
                      class="iconify iconify--ep w-4 h-4 rotate-180"
                      preserveAspectRatio="xMidYMid meet"
                      viewBox="0 0 1024 1024"
                    >
                      <path
                        fill="white"
                        d="M32 768h960a32 32 0 1 1 0 64H32a32 32 0 1 1 0-64zm129.408-96a352 352 0 0 1 701.184 0h-64.32a288 288 0 0 0-572.544 0h-64.32zM512 128a32 32 0 0 1 32 32v96a32 32 0 0 1-64 0v-96a32 32 0 0 1 32-32zm407.296 168.704a32 32 0 0 1 0 45.248l-67.84 67.84a32 32 0 1 1-45.248-45.248l67.84-67.84a32 32 0 0 1 45.248 0zm-814.592 0a32 32 0 0 1 45.248 0l67.84 67.84a32 32 0 1 1-45.248 45.248l-67.84-67.84a32 32 0 0 1 0-45.248z"
                      ></path>
                    </svg>
                </div>


                          <div class="flex items-center justify-center gap-2" v-if="team.members.length > 0 ">
                            <div class="rounded-full w-6 h-6 object-contain " :class="['bg-[#'+ team.color +']']"
                            v-for="(member, j) in team.members" :key="j">
                                <div v-if="getUserImg(team.members[j])">
                                    <span
                                        class="flex items-center justify-center text-xs font-semibold w-6 h-6 uppercase rounded-full text-pink-800 last:mr-0 mr-1"
                                    
                                        :class="[team.color ? 'bg-[#' + team.color  + ']' : 'bg-fuchsia-100']"
                                    >
                                        <img :src="getUserImg(team.members[j])" class="rounded-full" />
                                    </span>
                                    </div>
                                    <div v-else>
                                    <span
                                        class="flex items-center justify-center text-xs font-semibold w-6 h-6 uppercase rounded-full text-pink-800 last:mr-0 mr-1"
                                        
                                        :class="[team.color ? 'bg-[#' + team.color + ']' : 'bg-fuchsia-100']"
                                    >
                                        {{ getUserName(team.members[j]).slice(0, 2) }}
                                    </span>
                                    </div>
                            </div>
                          </div>
                          <div class="flex items-center justify-center gap-2" v-else>
                            <div class="rounded-full w-6 h-6 object-contain " :class="['bg-[#'+ team.color +']']"
                            v-for="(size, j) in parseInt(team.team_size)" :key="j">
                            </div>
                          </div>
                        </div>
                        <!-- End - Team of 1 -->
                    </div>
                    <!-- End - Small Toggle Shape Container -->
                    <div class="absolute right-3 bottom-[20%] translate-y-[20%] flex flex-col">
                      <!-- If active, change the color from bg-gray-200 to desired color -->
                      <div class="w-2 h-2 bg-fuchsia-700 rounded-full my-1"></div>
                      <div class="w-2 h-2 bg-fuchsia-700 rounded-full my-1"></div>
                      <div class="w-2 h-2 bg-gray-200 rounded-full my-1"></div>
                      <div class="w-2 h-2 bg-gray-200 rounded-full my-1"></div>
                      <div class="w-2 h-2 bg-gray-200 rounded-full my-1"></div>
                    </div>
                  </div>
                </div>
              </template>
            </FullCalendar>
          </div>
        </div>
      </div>
      <!-- End - Section 1 -->

      <!-- Begin - Section 2 -->

      <!-- End - Section 2 -->
    </main>

    <!-- End - Dashboard Content -->
    </div>
</template>
<script>
import logo from "../assets/logo.png";
import store from "@/store";
import TopBarSales from "@/components/TopBarSales.vue";
import SideBarSales from "@/components/SideBarSales.vue";
import { mapState } from "vuex";
import axios from "../http-common"; // <-- HTTP common
import moment from 'moment';
import "@fullcalendar/core/vdom"; // FullCalendar Vue Component
import FullCalendar, { CalendarOptions, EventApi, DateSelectArg, EventClickArg } from "@fullcalendar/vue3";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import frLocale from "@fullcalendar/core/locales/fr";

export default {
  title: 'SalesPlanningDetails',
  components: {
    TopBarSales, 
    SideBarSales,
    FullCalendar,
  },
  data() {
    return {
      logo,
      pose: [],
      salespersons_ids: [],
      categories: [],
      my_sales_poses: [],
      user_info: {},
      calendarOptions: {
        locales: [ frLocale ],
        locale: 'fr',
        plugins: [
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin, // needed for dateClick
        ],
        droppable: true,
        headerToolbar: {
          left: "prev,next today",
          center: "title",
          right: "dayGridMonth,dayGridWeek,dayGridDay",
        },
        initialView: "dayGridWeek",
        editable: true,
        selectable: true,
        selectMirror: true,
        dayMaxEvents: true,
        eventDurationEditable: false,
        weekends: true,
        displayEventTime: false,
        hiddenDays: [0, 6],
        dateClick: this.onDayClickHandler,
        events: [],
        eventDrop: this.eventDrop,
        eventStartEditable: false
      },
    };
  },
  computed: {
    ...mapState({
      agencies: (state) => state.agencies,
      product_groups: (state) => state.product_groups,
      installers: (state) => state.salespersons,
      poses: (state) => state.poses,
      installers: (state) => state.installers,

    }),
  },
  async mounted() {
    await this.$store.dispatch("loadPoses", { limit: "", page: "" });
    await this.getUserInfo();
    await this.fetchPoseData();
    await this.$store.dispatch("loadInstallers");
    await this.$store.dispatch("loadPoses", { limit: "", page: "" });
    await this.$store.dispatch("loadProductGroups");
  },
  methods: {
    getCategoryColor(category) {
      let color = "red-500";
      this.product_groups.forEach(function (group) {
        if (group.name == category || group.id == category) {
          color = group.color;
        }
      });
      return color;
    },
    getUserImg(id) {
      let img = "";
      this.installers.forEach((installer) => {
        if (installer.id == id) {
          img = installer.photo;
        }
      });
      return img;
    },
    getUserInfo(){
        let header = {
            DOLAPIKEY: JSON.parse(window.localStorage.getItem("user-info")).data.success.token,
        };
        axios
            .get("installationapi/user?includePermissions=1&includeGroups=1&includeLeaves=1", {
        headers: header,
        })
        .then((response) => {
            this.user_info = response.data;
        })
        .catch((error) => {
            this.user_info  =  {};
        });

    },
    async fetchPoseData() {
      const authUser = JSON.parse(window.localStorage.getItem("user-info"));
      await this.poses.forEach(pose => {
        // console.log(moment(pose.end_date).add(1,'day').format("YYYY-MM-DD"));
        return new Promise(resolve => {
            let salespersons = JSON.parse(pose.user_ids);
            // if(salespersons.includes(authUser.id)){
                this.my_sales_poses.push(pose);
                this.calendarOptions.events.push({
                    id: pose.id,
                    title: this.getClientDetails(pose.client),
                    start: pose.start_date ? pose.start_date : '',
                    end: moment(pose.end_date).add(1,'day').format("YYYY-MM-DD"),
                    days:pose.estimated_days ? pose.estimated_days : 1,
                    planning: pose.planning,
                    data: this.makeDataFromPosePlanning(pose.planning, pose.id),
                    allDay: true,
                    card_color_code: '#ffe5e5',
                    status: pose.status,
                })
            // }
         resolve('resolved');
        });
    });
    },
    getClientDetails(id) {
      let name = "";
      try {        
        this.$store.state.clients.forEach((client) => {
          if (client.id == parseInt(id)) {
            name =  client.name
          }
        });
        return name;
      } catch (error) {
        return "Not found";
      }
    },
    getUserName(id) {
      let name = "";
      this.installers.forEach(installer => {
        if (installer.id == id) {
          name =  installer.firstname;
        }
      });
      return name;
    },
    makeDataFromPosePlanning(planning, pose_id){
      const keys = Object.keys(planning);
      let data = {};
      keys.forEach((key, index) => {
          const days_keys = Object.keys(planning[key].days);
          days_keys.forEach((k, i) => {
            planning[key].days[k].teams.forEach((team, j) => {
              if(data[k] == undefined){
                data[k] = [];
              }
                data[k].push(
                  {
                    "id": planning[key].days[k].id,
                    "category_id": planning[key].id,
                    "pose_id": pose_id,
                    "team_index": j,
                    "day_type":team.day_type,
                    "team_size":team.team_size,
                    "color":planning[key].color,
                    'members': team.members == undefined ? [] : team.members,
                  }
                )
              });           
          });          
      });
      return data;
    }, 

     
  }
};
</script>
<style scoper>
.logo{
    width: 6rem;
    height: 4rem;
}
.box{
    background-color: #9f137e;
    color: #fff;
}
.pink{
    color: #fff;
}
</style>