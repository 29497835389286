<template>
  <!-- Begin - Steps Bar -->
  <div
    class="bg-white rounded-md lg:rounded-md shadow-sm border my-4 w-full pb-4 py-6">
    <div class="flex">
      <input
        type="hidden"
        name="customfield"
        class="form-control"
        v-model="agency_changed" />
      <div class="w-1/4">
        <div class="relative mb-2">
          <div
            class="w-4 h-4 mx-auto rounded-full text-lg text-white flex items-center bg-gray-100"
            :class="{ 'bg-stpes-strong-active': currentstep >= 1 }"></div>
        </div>
        <div
          class="text-xs text-center md:text-base"
          :class="{ 'font-bold': currentstep >= 1 }">
          Fiche Client
        </div>
      </div>
      <div class="w-1/4">
        <div class="relative mb-2">
          <div
            class="absolute flex align-center items-center align-middle content-center"
            style="
              width: calc(100% - 2.5rem - 1rem);
              top: 50%;
              transform: translate(-50%, -50%);
            ">
            <div
              class="w-full bg-gray-100 rounded items-center align-middle align-center flex-1">
              <div
                class="w-0 bg-gray-100 py-1 rounded"
                style="width: 100%"
                :class="{ 'bg-stpes-active': currentstep >= 1 }"></div>
            </div>
          </div>

          <div
            class="w-4 h-4 mx-auto bg-gray-100 rounded-full text-lg text-white flex items-center"
            :class="{ 'bg-stpes-strong-active': currentstep >= 2 }"></div>
        </div>

        <div
          class="text-xs text-center md:text-base"
          :class="{ 'font-bold': currentstep >= 2 }">
          Renseignements sur la vente
        </div>
      </div>

      <div class="w-1/4">
        <div class="relative mb-2">
          <div
            class="absolute flex align-center items-center align-middle content-center"
            style="
              width: calc(100% - 2.5rem - 1rem);
              top: 50%;
              transform: translate(-50%, -50%);
            ">
            <div
              class="w-full bg-gray-100 rounded items-center align-middle align-center flex-1">
              <div
                class="w-0 bg-gray-100 py-1 rounded"
                style="width: 100%"
                :class="{ 'bg-stpes-active': currentstep >= 2 }"></div>
            </div>
          </div>

          <div
            class="w-4 h-4 mx-auto bg-gray-100 border-2 border-gray-200 rounded-full text-lg text-white flex items-center"
            :class="{ 'bg-stpes-strong-active': currentstep >= 3 }"></div>
        </div>

        <div
          class="text-xs text-center md:text-base"
          :class="{ 'font-bold': currentstep >= 3 }">
          Dossier métré et pose
        </div>
      </div>

      <div class="w-1/4">
        <div class="relative mb-2">
          <div
            class="absolute flex align-center items-center align-middle content-center"
            style="
              width: calc(100% - 2.5rem - 1rem);
              top: 50%;
              transform: translate(-50%, -50%);
            ">
            <div
              class="w-full bg-gray-100 rounded items-center align-middle align-center flex-1">
              <div
                class="w-0 bg-gray-100 py-1 rounded"
                style="width: 100%"
                :class="{ 'bg-stpes-active': currentstep >= 3 }"></div>
            </div>
          </div>

          <div
            class="w-4 h-4 mx-auto bg-white border-2 border-gray-200 rounded-full text-lg text-white flex items-center"
            :class="{ 'bg-stpes-strong-active': currentstep >= 4 }"></div>
        </div>

        <div
          class="text-xs text-center md:text-base"
          :class="{ 'font-bold': currentstep >= 4 }">
          Pose planifiée
        </div>
      </div>

      <div class="w-1/4">
        <div class="relative mb-2">
          <div
            class="absolute flex align-center items-center align-middle content-center"
            style="
              width: calc(100% - 2.5rem - 1rem);
              top: 50%;
              transform: translate(-50%, -50%);
            ">
            <div
              class="w-full bg-gray-100 rounded items-center align-middle align-center flex-1">
              <div
                class="w-0 bg-gray-100 py-1 rounded"
                style="width: 100%"
                :class="{ 'bg-stpes-active': currentstep >= 4 }"></div>
            </div>
          </div>

          <div
            class="w-4 h-4 mx-auto bg-white border-2 border-gray-200 rounded-full text-lg text-white flex items-center"
            :class="{ 'bg-stpes-strong-active': currentstep >= 5 }"></div>
        </div>

        <div
          class="text-xs text-center md:text-base"
          :class="{ 'font-bold': currentstep >= 5 }">
          Machines commandées
        </div>
      </div>

      <div class="w-1/4">
        <div class="relative mb-2">
          <div
            class="absolute flex align-center items-center align-middle content-center"
            style="
              width: calc(100% - 2.5rem - 1rem);
              top: 50%;
              transform: translate(-50%, -50%);
            ">
            <div
              class="w-full bg-gray-100 rounded items-center align-middle align-center flex-1">
              <div
                class="w-0 bg-gray-100 py-1 rounded"
                style="width: 100%"
                :class="{ 'bg-stpes-active': currentstep >= 5 }"></div>
            </div>
          </div>

          <div
            class="w-4 h-4 mx-auto bg-white border-2 border-gray-200 rounded-full text-lg text-white flex items-center"
            :class="{ 'bg-stpes-strong-active': currentstep >= 6 }"></div>
        </div>

        <div
          class="text-xs text-center md:text-base"
          :class="{ 'font-bold': currentstep >= 6 }">
          Matériel réceptionné
        </div>
      </div>

      <div class="w-1/4">
        <div class="relative mb-2">
          <div
            class="absolute flex align-center items-center align-middle content-center"
            style="
              width: calc(100% - 2.5rem - 1rem);
              top: 50%;
              transform: translate(-50%, -50%);
            ">
            <div
              class="w-full bg-gray-100 rounded items-center align-middle align-center flex-1">
              <div
                class="w-0 bg-gray-100 py-1 rounded"
                style="width: 100%"
                :class="{ 'bg-stpes-active': currentstep >= 6 }"></div>
            </div>
          </div>

          <div
            class="w-4 h-4 mx-auto bg-white border-2 border-gray-200 rounded-full text-lg text-white flex items-center"
            :class="{ 'bg-stpes-strong-active': currentstep >= 7 }"></div>
        </div>

        <div
          class="text-xs text-center md:text-base"
          :class="{ 'font-bold': currentstep >= 6 }">
          Pose en cours
        </div>
      </div>

      <div class="w-1/4">
        <div class="relative mb-2">
          <div
            class="absolute flex align-center items-center align-middle content-center"
            style="
              width: calc(100% - 2.5rem - 1rem);
              top: 50%;
              transform: translate(-50%, -50%);
            ">
            <div
              class="w-full bg-gray-100 rounded items-center align-middle align-center flex-1">
              <div
                class="w-0 bg-gray-100 py-1 rounded"
                style="width: 100%"
                :class="{ 'bg-stpes-active': currentstep >= 7 }"></div>
            </div>
          </div>

          <div
            class="w-4 h-4 mx-auto bg-white border-2 border-gray-200 rounded-full text-lg text-white flex items-center"></div>
        </div>

        <div
          class="text-xs text-center md:text-base"
          :class="{ 'font-bold': currentstep >= 8 }">
          Pose réceptionnée
        </div>
      </div>
    </div>
  </div>

  <!-- End - Steps Bar -->

  <!-- Begin - Tabs Navigation -->

  <div class="bg-white rounded-md shadow-md border border-gray-100">
    <ul class="flex flex-wrap justify-between -mb-px text-center">
      <li class="mr-2">
        <a
          href="#"
          class="dashboard-bot__tab--link inline-flex p-4 client"
          :class="{ active: activeTabName === 'client' }"
          @click.prevent="setActiveTabName('client')">
          <svg
            class="dashboard-bot__tab--link-svg mr-2 w-5 h-5 text-gray-400"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg">
            <path
              fill-rule="evenodd"
              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
              clip-rule="evenodd"></path>
          </svg>
          Client
        </a>
      </li>
      <li class="mr-2">
        <a
          href="#"
          class="dashboard-bot__tab--link inline-flex p-4 vente"
          :class="{ active: activeTabName === 'vente' }"
          aria-current="page"
          @click.prevent="setActiveTabName('vente')">
          <svg
            class="dashboard-bot__tab--link-svg active mr-2 w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M5 3a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2V5a2 2 0 00-2-2H5zM5 11a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2v-2a2 2 0 00-2-2H5zM11 5a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V5zM11 13a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"></path>
          </svg>
          Vente
        </a>
      </li>
      <li class="mr-2">
        <a
          href="#"
          class="dashboard-bot__tab--link inline-flex p-4 metre"
          :class="{ active: activeTabName === 'metre' }"
          @click.prevent="setActiveTabName('metre')">
          <svg
            class="dashboard-bot__tab--link-svg mr-2 w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M5 4a1 1 0 00-2 0v7.268a2 2 0 000 3.464V16a1 1 0 102 0v-1.268a2 2 0 000-3.464V4zM11 4a1 1 0 10-2 0v1.268a2 2 0 000 3.464V16a1 1 0 102 0V8.732a2 2 0 000-3.464V4zM16 3a1 1 0 011 1v7.268a2 2 0 010 3.464V16a1 1 0 11-2 0v-1.268a2 2 0 010-3.464V4a1 1 0 011-1z"></path>
          </svg>
          Métré
        </a>
      </li>
      <li class="mr-2">
        <a
          href="#"
          class="dashboard-bot__tab--link inline-flex p-4 material"
          :class="{ active: activeTabName === 'material' }"
          @click.prevent="setActiveTabName('material')">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            aria-hidden="true"
            role="img"
            class="dashboard-bot__tab--link-svg mr-2 w-6 h-6"
            preserveAspectRatio="xMidYMid meet"
            viewBox="0 0 24 24">
            <g
              fill="none"
              stroke="#b59a05"
              stroke-linejoin="round"
              stroke-width="1">
              <path
                stroke-linecap="round"
                d="M11.029 2.54a2 2 0 0 1 1.942 0l7.515 4.174a1 1 0 0 1 .514.874v8.235a2 2 0 0 1-1.029 1.748l-7 3.89a2 2 0 0 1-1.942 0l-7-3.89A2 2 0 0 1 3 15.824V7.588a1 1 0 0 1 .514-.874L11.03 2.54Z"></path>
              <path d="m3 7l9 5m0 0l9-5m-9 5v9.5"></path>
              <path
                stroke-linecap="round"
                d="m7.5 9.5l9-5M6 12.328L9 14"></path>
            </g>
          </svg>

          Matériel
        </a>
      </li>
      <li class="mr-2">
        <a
          href="#"
          class="dashboard-bot__tab--link inline-flex p-4 hotel"
          :class="{ active: activeTabName === 'hotel' }"
          @click.prevent="setActiveTabName('hotel')">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            aria-hidden="true"
            role="img"
            class="dashboard-bot__tab--link-svg mr-2 w-5 h-5"
            preserveAspectRatio="xMidYMid meet"
            viewBox="0 0 32 32">
            <path
              d="M9.5 15A1.5 1.5 0 1 1 8 16.5A1.5 1.5 0 0 1 9.5 15m0-2a3.5 3.5 0 1 0 3.5 3.5A3.5 3.5 0 0 0 9.5 13Z"></path>
            <path
              d="M25 14h-8a2 2 0 0 0-2 2v6H4V10.6l12-6.46l12.53 6.74l.94-1.76l-13-7a1 1 0 0 0-.94 0l-13 7A1 1 0 0 0 2 10v20h2v-6h24v6h2V19a5 5 0 0 0-5-5Zm-8 8v-6h8a3 3 0 0 1 3 3v3Z"></path>
          </svg>

          Hotel
        </a>
      </li>
      <li class="mr-2">
        <a
          href="#"
          class="dashboard-bot__tab--link inline-flex p-4 pose"
          :class="{ active: activeTabName === 'pose' }"
          @click.prevent="setActiveTabName('pose')">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            aria-hidden="true"
            role="img"
            class="dashboard-bot__tab--link-svg mr-2 w-6 h-6"
            preserveAspectRatio="xMidYMid meet"
            viewBox="0 0 32 32">
            <path
              d="m26.96 30l-1.921-6.725a1 1 0 0 1 .336-1.056l4.499-3.599l-1.354-5.419l-2.738 3.424A1.003 1.003 0 0 1 25 17h-5v-2h4.52l3.7-4.625a1 1 0 0 1 1.75.383l2 8a.999.999 0 0 1-.344 1.023l-4.48 3.584l1.738 6.086zM23 5.5A3.5 3.5 0 1 1 26.5 9A3.504 3.504 0 0 1 23 5.5zm2 0A1.5 1.5 0 1 0 26.5 4A1.502 1.502 0 0 0 25 5.5z"></path>
            <path
              d="M20.004 19A2.004 2.004 0 0 1 18 16.996v-1.992A2.004 2.004 0 0 1 20.004 13H22v-3H10v3h1.996A2.004 2.004 0 0 1 14 15.004v1.992A2.004 2.004 0 0 1 11.996 19H10v3h12v-3Z"></path>
            <path
              d="m5.04 30l1.921-6.725a1.001 1.001 0 0 0-.336-1.056L2.126 18.62l1.355-5.418l2.738 3.423A1.003 1.003 0 0 0 7 17h5v-2H7.48l-3.699-4.625a1 1 0 0 0-1.75.383l-2 8a.999.999 0 0 0 .344 1.023l4.48 3.584l-1.739 6.086zM5.5 9A3.5 3.5 0 1 1 9 5.5A3.504 3.504 0 0 1 5.5 9zm0-5A1.5 1.5 0 1 0 7 5.5A1.502 1.502 0 0 0 5.5 4z"></path>
          </svg>

          Pose
        </a>
      </li>
      <li class="mr-2">
        <a
          href="#"
          class="dashboard-bot__tab--link inline-flex p-4"
          :class="{ active: activeTabName === 'finition' }"
          @click.prevent="setActiveTabName('finition')"
          v-if="active_fin_tab">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            aria-hidden="true"
            role="img"
            class="dashboard-bot__tab--link-svg mr-2 w-5 h-5"
            preserveAspectRatio="xMidYMid meet"
            viewBox="0 0 32 32">
            <defs></defs>
            <path
              d="M12 21H4V4h18v8h2V4a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2v17a2 2 0 0 0 2 2h8z"></path>
            <path
              d="M30 28.58l-3.11-3.11a6 6 0 1 0-1.42 1.42L28.58 30zM22 26a4 4 0 1 1 4-4a4 4 0 0 1-4 4z"></path>
          </svg>
          Finitions
        </a>
        <a
          href="#"
          class="dashboard-bot__tab--link inline-flex p-4"
          :class="{ active: activeTabName === 'finition' }"
          v-else>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            aria-hidden="true"
            role="img"
            class="dashboard-bot__tab--link-svg mr-2 w-5 h-5"
            preserveAspectRatio="xMidYMid meet"
            viewBox="0 0 32 32">
            <defs></defs>
            <path
              d="M12 21H4V4h18v8h2V4a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2v17a2 2 0 0 0 2 2h8z"></path>
            <path
              d="M30 28.58l-3.11-3.11a6 6 0 1 0-1.42 1.42L28.58 30zM22 26a4 4 0 1 1 4-4a4 4 0 0 1-4 4z"></path>
          </svg>
          Finitions
        </a>
      </li>
      <li class="mr-2">
        <a
          href="#"
          class="dashboard-bot__tab--link inline-flex p-4">
          <svg
            class="dashboard-bot__tab--link-svg mr-2 w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M9 2a1 1 0 000 2h2a1 1 0 100-2H9z"></path>
            <path
              fill-rule="evenodd"
              d="M4 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v11a2 2 0 01-2 2H6a2 2 0 01-2-2V5zm3 4a1 1 0 000 2h.01a1 1 0 100-2H7zm3 0a1 1 0 000 2h3a1 1 0 100-2h-3zm-3 4a1 1 0 100 2h.01a1 1 0 100-2H7zm3 0a1 1 0 100 2h3a1 1 0 100-2h-3z"
              clip-rule="evenodd"></path>
          </svg>
          SAV
        </a>
      </li>
    </ul>
  </div>

  <!-- End - Tabs Navigation -->

  .
  <div class="p-6 w-full bg-white rounded-lg border border-gray-200 shadow-md">
    <!-- Begin - Tab Content -->

    <!-- CLIENT -->
    <div
      class="flex flex-wrap py-6 w-full client"
      v-if="displayContents('client')">
      <!-- Errors -->
      <div
        class="w-full bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
        role="alert"
        v-if="errors.length">
        <span
          class="block sm:inline"
          v-for="error in errors"
          :key="error">
          {{ error }} <br
        /></span>
        <span
          class="absolute top-0 bottom-0 right-0 px-4 py-3"
          @click.prevent="closeAlert(event)">
          <svg
            class="fill-current h-6 w-6 text-red-500"
            role="button"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20">
            <title>Close</title>
            <path
              d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
          </svg>
        </span>
      </div>

      <!-- end of error list -->

      <form
        class="dashboard-tab__form w-full lg:w-6/12 px-2 client_1_form"
        action="">
        <!-- Client Field -->
        <div class="w-full">
          <div class="flex gap-2 w-full">
            <div class="flex flex-col w-full">
              <label
                for=""
                class="dashboard-bot__form--labels"
                >Fiche client</label
              >
              <Multiselect
                v-model="client"
                mode="tags"
                :close-on-select="true"
                :searchable="true"
                :create-option="false"
                :options="clients"
                :max="1" />
            </div>

            <div class="flex flex-col w-full">
              <label
                for=""
                class="dashboard-bot__form--labels"
                >Quel Logement</label
              >
              <select
                class="dashboard-bot__form--inputs select input-select"
                :class="logements"
                v-model="logement"
                required>
                <option
                  default
                  value="">
                  Sélectionner logement
                </option>
                <option
                  v-for="item in logements"
                  :value="item.id"
                  :key="item.id">
                  {{ item.val }}
                </option>
              </select>
            </div>
          </div>
        </div>

        <!-- Client Name Field -->
        <div class="w-full mt-4">
          <div class="flex gap-2 w-full">
            <div class="flex flex-col w-full">
              <label
                for=""
                class="dashboard-bot__form--labels"
                >Nom du client</label
              >
              <input
                type="text"
                class="dashboard-bot__form--inputs"
                v-model="c_firstname"
                placeholder="NOM DU CLIENT"
                readonly />
            </div>

            <div class="flex flex-col w-full">
              <label
                for=""
                class="dashboard-bot__form--labels"
                >Prenom du client</label
              >
              <input
                type="text"
                class="dashboard-bot__form--inputs"
                v-model="c_lastname"
                placeholder="PRENOM DU CLIENT"
                readonly />
            </div>
          </div>
        </div>

        <!-- Address Field -->
        <div class="w-full mt-4">
          <div class="flex gap-10 w-full">
            <div class="flex flex-col space-y-3 w-full">
              <label
                for=""
                class="dashboard-bot__form--labels"
                >Adresse</label
              >
              <input
                type="text"
                placeholder="Adresse 1"
                class="dashboard-bot__form--inputs"
                v-model="address1"
                readonly />
              <input
                type="text"
                placeholder="Adresse 2"
                class="dashboard-bot__form--inputs"
                v-model="address2"
                readonly />

              <div class="flex gap-2 w-full">
                <input
                  type="text"
                  placeholder="Code Postal"
                  class="dashboard-bot__form--inputs w-full"
                  v-model="zip"
                  readonly />
                <input
                  type="text"
                  placeholder="Ville"
                  class="dashboard-bot__form--inputs w-full"
                  v-model="city"
                  readonly />
              </div>
            </div>
          </div>
        </div>

        <!-- Telephone Field -->
        <div class="w-full mt-4">
          <div class="flex gap-2 w-full">
            <div class="flex flex-col w-full">
              <label
                for=""
                class="dashboard-bot__form--labels"
                >Téléphone</label
              >
              <input
                type="tel"
                class="dashboard-bot__form--inputs"
                v-model="phone"
                placeholder="00 00"
                readonly />
            </div>

            <!-- <div class="flex flex-col w-full">
              <label for="" class="dashboard-bot__form--labels">Portable</label>
              <input type="text" class="dashboard-bot__form--inputs" v-model="portable" placeholder="00 00" readonly />
            </div> -->
          </div>
        </div>

        <!-- Email Field -->
        <div class="w-full mt-4">
          <div class="flex gap-2 w-full">
            <div class="flex flex-col w-full">
              <label
                for=""
                class="dashboard-bot__form--labels"
                >Email</label
              >
              <input
                type="tel"
                class="dashboard-bot__form--inputs"
                v-model="email"
                placeholder="xxxxxxx@xx.xx"
                readonly />
            </div>

            <div class="flex flex-col w-full">
              <label
                for=""
                class="dashboard-bot__form--labels"
                >Provenance Du Client</label
              >
              <input
                type="text"
                class="dashboard-bot__form--inputs"
                v-model="origin"
                placeholder="Provenance du client"
                readonly />
            </div>
          </div>
        </div>
      </form>

      <form
        class="dashboard-tab__form w-full lg:w-6/12 px-2 client_2_form"
        action="">
        <!-- Dropdown / Select Field -->
        <div class="w-full">
          <div class="flex gap-2 items-end w-full">
            <label
              for="a"
              class="switch">
              <input
                type="checkbox"
                id="a"
                name="a"
                checked
                v-model="funding_approved" />
              <!-- Add class="slider round" if you want it to be round -->
              <span class="slider round"></span>
            </label>
            <div class="flex flex-col w-full">
              <select
                class="dashboard-bot__form--inputs select input-select"
                v-model="financement">
                <option
                  value=""
                  default
                  selected>
                  sélectionner un financement
                </option>


                <option value="0">Chèque</option>
                <option value="1">Virement bancaire</option>
                <option value="2">Espèce</option>
                <option value="3">Carte bancaire</option>
                <option value="4">Ordre de prélèvement</option>
                <option value="5">TIP</option>
                <option value="6">Cetelem</option>
                <option value="7">Financo</option>
                <option value="8">Domofinance</option>
                <option value="9">Franfinance</option>
                <option value="10">Projexio</option>


                <!-- <option value="0">Bancontact</option>
                <option value="1">Credit card</option>
                <option value="2">Cheque</option>
                <option value="3">Factor</option>
                <option value="4">Giropay</option>
                <option value="5">iDeal</option>
                <option value="6">Klarna</option>
                <option value="7">LCR</option>
                <option value="8">Cash</option>
                <option value="9">Debit order</option>
                <option value="10">Sofort</option>
                <option value="11">TIP</option>
                <option value="12">Traite</option>
                <option value="13">Online Payment</option>
                <option value="14">Transfer</option> -->
              </select>
            </div>
          </div>
        </div>
        <div class="w-full">
          <div class="flex gap-2 mt-2 items-end w-full">
            <div class="flex flex-col w-full">
              <label
                for=""
                class="dashboard-bot__form--labels"
                >CHÈQUE a récupérer</label
              >
              <label
                for="récupérer"
                class="switch">
                <input
                  type="checkbox"
                  id="récupérer"
                  name="récupérer"
                  v-model="collect_cheque" />
                <!-- Add class="slider round" if you want it to be round -->
                <span class="slider round"></span>
              </label>
            </div>
          </div>
        </div>
        <!-- <div class="w-full mt-4">
          <div class="flex gap-2 items-end w-full" v-for="(entry, index) in client_files" :key="index">
            <div class="flex flex-col w-full">
              <label for="" class="dashboard-bot__form--labels">Nom du fichier</label>
              <span class="text-xs text-gray-400 uppercase">  Dossier de financement à faire signer {{index}}</span>
              <span class="text-xs text-gray-400"> REMARQUE : vous pouvez télécharger les fichiers une fois que la pose est créée </span>
              <div class="flex items-center text-xs text-gray-400" v-if="client_pose_files[index]">
                <a :href="client_pose_files[index].url" download="" target="blank">
                <svg v-if="client_pose_files[index].url" class="w-6 h-6 " fill="none" 
                stroke="currentColor" 
                viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"> 
                  <path stroke-linecap="round" stroke-linejoin="round" 
                  stroke-width="2" d="M15 13l-3 3m0 0l-3-3m3 3V8m0 13a9 9 0 110-18 9 9 0 010 18z">                    
                  </path>
                </svg></a>
                <a class="ml-2" :href="client_pose_files[index].url" download="" target="blank"> {{client_pose_files[index].name}}</a><br>
                <a :href="client_pose_files[index].url" @click="downloadFile(client_pose_files[index].url, client_pose_files[index].name)" download target="_blank">{{ client_pose_files[index].name }}</a>
             
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6 cursor-pointer ml-2"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="red"
                  @click="removePoseFile(index, client_pose_files[index].name)"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1"
                    d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </div>
              <div class="relative flex items-center mt-2">
                <label for="attach" class="absolute left-2 cursor-pointer px-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12"
                    />
                  </svg>
                </label>

                <input
                  name="attach"
                  id="attach"
                  type="file"
                  class="w-full dashboard-bot__form--inputs upload cursor-pointer"
                  @change="
                    filesChange($event.target.name, $event.target.files, $event, 'client_fichier_'+index);"
                />

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6 hover:cursor-pointer"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="green"
                  @click="addFile1()"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6 cursor-pointer ml-2"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="red"
                  @click="removeFile1()"
                  v-if="index != 0"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1"
                    d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div> -->
        <div class="w-full mt-4">
          <div class="flex gap-2 items-end w-full">
            <div class="flex flex-col w-full">
              <label
                for=""
                class="dashboard-bot__form--labels"
                >Nom du fichier</label
              >
              <span class="text-xs text-gray-400 uppercase">
                Dossier de financement à faire signer</span
              >

              <div
                class="flex items-center text-xs text-gray-400"
                v-for="(file, i) in client_pose_files"
                :key="i">
                <a
                  :href="client_pose_files[i].url"
                  download=""
                  target="blank">
                  <svg
                    v-if="client_pose_files[i].url"
                    class="w-6 h-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M15 13l-3 3m0 0l-3-3m3 3V8m0 13a9 9 0 110-18 9 9 0 010 18z"></path>
                  </svg>
                </a>
                <!-- <svg class="w-6 h-6 dark:text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 13l-3 3m0 0l-3-3m3 3V8m0 13a9 9 0 110-18 9 9 0 010 18z">                    
                  </path>
                </svg> -->
                <a
                  :href="file.url"
                  download
                  target="blank">
                  {{ file.name }}</a
                >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6 cursor-pointer ml-2"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="red"
                  @click="removePoseFile(i, file.name)">
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1"
                    d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
              </div>

              <div class="relative flex items-center mt-2">
                <label
                  for="attach"
                  class="absolute left-2 cursor-pointer px-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6 file-attachment"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor">
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12" />
                  </svg>
                </label>

                <input
                  name="attach"
                  id="client_fichier_attach"
                  type="file"
                  class="w-full dashboard-bot__form--inputs upload cursor-pointer"
                  @change="
                    filesChange(
                      $event.target.name,
                      $event.target.files,
                      $event,
                      'client_fichier'
                    )
                  " />
              </div>
            </div>
          </div>
        </div>

        <!-- Text Editor -->
        <div class="w-full mt-4">
          <div class="flex flex-col">
            <label
              for=""
              class="dashboard-bot__form--labels"
              >COMMENTAIRES</label
            >

            <ckeditor
              :editor="editor"
              v-model="client_notes"></ckeditor>
          </div>
        </div>
      </form>
    </div>
    <div
      class="flex items-center justify-end space-x-4 client"
      v-if="displayContents('client')">
      <button
        class="dashboard-bot__form--formbtn clear"
        @click.prevent="clearForm('client')">
        ANNULER LA POSE
      </button>
      <!-- <button class="dashboard-bot__form--formbtn clear" @click.prevent="clearForm('client')" v-if="!pose_id">ANNULER LA POSE</button> -->
      <!-- <button class="dashboard-bot__form--formbtn clear" @click.prevent="duplicatePose()" v-if="$route.params.id">Dupliquer</button> -->
      <router-link
        to="/"
        tag="button"
        class="dashboard-bot__form--formbtn back_list"
        >RETOUR à LA LISTE</router-link
      >
      <button
        class="dashboard-bot__form--formbtn submit save_continue"
        @click.prevent="submitClient">
        ENREGISTRER ET RESTER
      </button>
    </div>
    <!-- end of Client -->

    <!-- Vente -->
    <div
      class="flex flex-wrap py-6 w-full client"
      v-if="displayContents('vente')">
      <form class="dashboard-tab__form w-full lg:w-6/12 px-2">
        <!-- Vente Fields -->

        <!-- Errors -->
        <div
          class="w-full bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
          role="alert"
          v-if="venteErrors.length">
          <span
            class="block sm:inline"
            v-for="error in venteErrors"
            :key="error">
            {{ error }} <br
          /></span>
          <span
            class="absolute top-0 bottom-0 right-0 px-4 py-3"
            onclick="this.parentNode.remove(event)">
            <svg
              class="fill-current h-6 w-6 text-red-500"
              role="button"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20">
              <title>Close</title>
              <path
                d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
            </svg>
          </span>
        </div>

        <!-- end of error list -->

        <!-- Salesperson Field -->
        <div class="flex flex-col w-full">
          <label
            for=""
            class="dashboard-bot__form--labels"
            >Commerciaux</label
          >
          <Multiselect
            v-model="salesperson"
            mode="tags"
            :close-on-select="false"
            :searchable="true"
            :create-option="false"
            :options="salespersons"
            :max="2" />
        </div>

        <!-- Product Groups -->

        <div class="rounded-md border bg-white p-4 my-2">
          <div class="flex flex-row md:flex-row gap-2">
            <div
              class="flex items-center gap-2"
              v-for="(product, index) in product_groups"
              :key="index">
              <div class="relative flex justify-center items-center">
                <input
                  type="checkbox"
                  class="form-check-input appearance-none h-5 w-5 border border-gray-300 rounded-sm bg-white checked:bg-fuchsia-700 checked:border-fuchsia-700 focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain cursor-pointer"
                  :id="product.id"
                  v-model="product_group_values[product.id]"
                  :disabled="!edit_product_family"
                  @change="changeProductGroup(product.id, index)" />
              </div>
              <span class="uppercase text-xs text-gray-400">{{
                product.name
              }}</span>
            </div>
          </div>
        </div>

        <!-- Materials Field -->
        <div class="flex flex-col w-full">
          <label
            for=""
            class="dashboard-bot__form--labels"
            >Matériel vendu</label
          >
          <Multiselect
            ref="multiselect"
            v-model="material"
            mode="tags"
            :close-on-select="false"
            :searchable="true"
            :create-option="false"
            :options="materials"
            @select="materialChange($event)"
            @deselect="materialRemove($event)" />
        </div>
        <div
          class="flex mt-2 flex-col w-full"
          v-if="vente_price_summary">
          <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
            <table class="w-full text-sm text-left text-gray-500">
              <thead class="text-xs text-gray-700 uppercase bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    class="px-6 py-3">
                    Nom Produit
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3">
                    Prix original
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3">
                    Prix de vente
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  class="bg-white border-b dark:bg-white"
                  v-for="(price_data, i) in vente_price_summary"
                  :key="i">
                  <th
                    scope="row"
                    class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                    {{ price_data.label }}
                  </th>
                  <td class="px-6 py-4">
                    {{ price_data.price_ttc }}
                  </td>
                  <td class="px-6 py-4">
                    <input
                      type="number"
                      class="dashboard-bot__form--inputs"
                      v-model="price_data.price_ttc_editable"
                      @keyup="handleTTCInput" />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </form>

      <form
        class="dashboard-tab__form w-full lg:w-6/12 px-2"
        action="">
        <!-- Dropdown / Select Field -->
        <div class="w-full mt-4">
          <div class="flex gap-2 w-full">
            <div class="flex flex-col w-full">
              <label
                for=""
                class="dashboard-bot__form--labels"
                >Montant du devis (hors taxe)</label
              >
              <input
                type="text"
                class="dashboard-bot__form--inputs"
                placeholder="00 00 00 €"
                v-model="cost"
                disabled />
            </div>
          </div>
        </div>

        <!-- Text Editor -->
        <div class="w-full mt-4">
          <div class="flex flex-col">
            <label
              for=""
              class="dashboard-bot__form--labels"
              >COMMENTAIRES</label
            >
            <ckeditor
              :editor="editor"
              v-model="vente_notes"></ckeditor>
          </div>
        </div>
      </form>
    </div>
    <div
      class="flex items-center justify-end space-x-4 client"
      v-if="displayContents('vente')">
      <button
        class="dashboard-bot__form--formbtn clear"
        @click.prevent="clearForm('vente')"
        v-if="!pose_id">
        ANNULER LA POSE
      </button>
      <!-- <button class="dashboard-bot__form--formbtn clear" @click.prevent="duplicatePose()" v-if="$route.params.id">Dupliquer</button> -->
      <router-link
        to="/"
        tag="button"
        class="dashboard-bot__form--formbtn back_list"
        >RETOUR à LA LISTE</router-link
      >
      <button
        class="dashboard-bot__form--formbtn submit save_continue"
        @click.prevent="submitVente">
        ENREGISTRER ET RESTER
      </button>
    </div>

    <!-- end of Vente -->

    <!-- Metre -->
    <div
      class="flex flex-wrap py-6 w-full client"
      v-if="displayContents('metre')">
      <form
        class="dashboard-tab__form w-full lg:w-6/12 px-2"
        action="">
        <!-- Client Name Field -->
        <div class="w-full mt-4">
          <div class="flex gap-2 w-full">
            <div class="flex flex-col w-full">
              <label
                for=""
                class="dashboard-bot__form--labels"
                >Date du métré</label
              >
              <input
                type="date"
                name=""
                id=""
                class="border p-1 uppercase px-4"
                v-model="measurement_date" />
            </div>

            <div class="flex flex-col w-full">
              <label
                for=""
                class="dashboard-bot__form--labels"
                >Heures</label
              >
              <input
                type="number"
                class="dashboard-bot__form--inputs"
                min="0"
                max="23"
                v-model="hours" />
            </div>
            <div class="flex flex-col w-full">
              <label
                for=""
                class="dashboard-bot__form--labels"
                >Minutes</label
              >
              <input
                type="number"
                class="dashboard-bot__form--inputs"
                min="0"
                max="59"
                v-model="minutes" />
            </div>
          </div>
        </div>
        <!-- Metreperson Field -->
        <div class="flex flex-col w-full mt-4">
          <label
            for=""
            class="dashboard-bot__form--labels"
            >métré</label
          >
          <Multiselect
            v-model="metreperson"
            mode="tags"
            :close-on-select="false"
            :searchable="true"
            :create-option="false"
            :options="metrepersons"
            :max="2" />
        </div>
        <!-- Address Field -->
        <div class="w-full mt-4">
          <div class="flex gap-2 items-end w-full">
            <div class="flex flex-col w-full">
              <label
                for=""
                class="dashboard-bot__form--labels"
                >Fichiers</label
              >
              <span class="text-xs text-gray-400 uppercase">
                TÉLÉCHARGEZ ici un fichier et/ou photos {{ index }}</span
              >

              <div
                class="flex items-center text-xs text-gray-400"
                v-for="(file, i) in metre_pose_files"
                :key="i">
                <a
                  :href="metre_pose_files[i].url"
                  download=""
                  target="blank">
                  <svg
                    v-if="metre_pose_files[i].url"
                    class="w-6 h-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M15 13l-3 3m0 0l-3-3m3 3V8m0 13a9 9 0 110-18 9 9 0 010 18z"></path>
                  </svg>
                </a>
                <a
                  :href="file.url"
                  download
                  target="blank">
                  {{ file.name }}</a
                >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6 cursor-pointer ml-2"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="red"
                  @click="removePoseFile(i, file.name)">
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1"
                    d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
              </div>
              <div class="relative flex items-center mt-2">
                <label
                  for="attach"
                  class="absolute left-2 cursor-pointer px-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6 file-attachment"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor">
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12" />
                  </svg>
                </label>

                <input
                  name="attach"
                  id="client_fichier_attach"
                  type="file"
                  class="w-full dashboard-bot__form--inputs upload cursor-pointer"
                  @change="
                    filesChange(
                      $event.target.name,
                      $event.target.files,
                      $event,
                      'metre_fichier'
                    )
                  " />
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="w-full mt-4">
          <div class="flex gap-2 items-end w-full" v-for="(entry, index) in metre_files" :key="index">
            <div class="flex flex-col w-full">
              <label for="" class="dashboard-bot__form--labels">Fichiers</label>
              <span class="text-xs text-gray-400 uppercase"> TÉLÉCHARGEZ ici un fichier et/ou photos {{index}}</span>
              <span class="text-xs text-gray-400"> REMARQUE : vous pouvez télécharger les fichiers une fois que la pose est créée </span>
              <div class="flex items-center text-xs text-gray-400" v-if="metre_pose_files[index]">
                <svg class="w-6 h-6 dark:text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 13l-3 3m0 0l-3-3m3 3V8m0 13a9 9 0 110-18 9 9 0 010 18z">                    
                  </path>
                </svg>
                <a :href="metre_pose_files[index].url" download="" target="blank">
                <svg v-if="metre_pose_files[index].url" class="w-6 h-6" fill="none" 
                stroke="currentColor" 
                viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"> 
                  <path stroke-linecap="round" stroke-linejoin="round" 
                  stroke-width="2" d="M15 13l-3 3m0 0l-3-3m3 3V8m0 13a9 9 0 110-18 9 9 0 010 18z">                    
                  </path>
                </svg></a>
                <a class="ml-2" :href="metre_pose_files[index].url" download target="blank"> {{metre_pose_files[index].name}}</a>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6 cursor-pointer ml-2"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="red"
                  @click="removePoseFile(index, metre_pose_files[index].name)"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1"
                    d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </div>
              <div class="relative flex items-center mt-2">
                <label for="attach" class="absolute left-2 cursor-pointer px-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12"
                    />
                  </svg>
                </label>

                <input
                  name="attach"
                  id="attach"
                  type="file"
                  class="w-full dashboard-bot__form--inputs upload cursor-pointer"
                  @change="
                    filesChange($event.target.name, $event.target.files, $event, 'metre_fichier_'+index);"
                />

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6 hover:cursor-pointer"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="green"
                  @click="addFile()"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6 cursor-pointer ml-2"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="red"
                  @click="removeFile()"
                  v-if="index != 0"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1"
                    d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div> -->
      </form>

      <form
        class="dashboard-tab__form w-full lg:w-6/12 px-2"
        action="">
        <!-- Text Editor -->
        <div class="w-full mt-4">
          <div class="flex flex-col">
            <label
              for=""
              class="dashboard-bot__form--labels"
              >Commentaires</label
            >
            <ckeditor
              :editor="editor"
              v-model="metre_notes"></ckeditor>
          </div>
        </div>
      </form>
    </div>
    <div
      class="flex items-center justify-end space-x-4 client"
      v-if="displayContents('metre')">
      <button
        class="dashboard-bot__form--formbtn clear"
        @click.prevent="clearForm('metre')"
        v-if="!pose_id">
        ANNULER LA POSE
      </button>
      <!-- <button class="dashboard-bot__form--formbtn clear" @click.prevent="duplicatePose()" v-if="$route.params.id">Dupliquer</button> -->
      <router-link
        to="/"
        tag="button"
        class="dashboard-bot__form--formbtn back_list"
        >RETOUR à LA LISTE</router-link
      >
      <button
        class="dashboard-bot__form--formbtn submit save_continue"
        @click.prevent="submitMetre">
        ENREGISTRER ET RESTER
      </button>
    </div>
    <!-- end of Metre -->

    <!-- Material -->
    <div
      class="flex flex-wrap py-1 w-full client"
      v-if="displayContents('material')">
      <!-- Begin - Tab Content -->
      <Modal @vendor-submited="vendorSubmitted" />

      <div
        class="py-1 w-full"
        v-for="(material_request, index) in material_requests"
        :key="index">
        <div class="flex flex-wrap items-start my-4">
          <div class="w-full lg:w-6/12 lg:pr-4">
            <div class="flex flex-col w-full">
              <label
                for=""
                class="dashboard-bot__form--labels"
                >Recherche du prestataire</label
              >
              <select
                class="dashboard-bot__form--inputs select input-select"
                required
                v-model="material_request.vendors"
                @change="changeVendorSelect($event, material_request)">
                <option value="">Choisir le prestataire</option>
                <option
                  v-for="item in vendors"
                  :value="item.id"
                  :key="item.id">
                  {{ item.name }}
                </option>
              </select>
            </div>

            <!-- Material Field -->
            <div class="flex flex-col w-full">
              <label
                for=""
                class="dashboard-bot__form--labels"
                >MATÉRIEL COMPLÉMENTAIRE À INSTALLER</label
              >

              <Multiselect
                mode="tags"
                :close-on-select="false"
                :searchable="true"
                :create-option="true"
                :options="materials"
                v-model="material_request.materials" />
            </div>
          </div>

          <div class="w-full lg:w-6/12 lg:pl-4">
            <div class="w-full">
              <div class="flex gap-2 w-full">
                <div class="flex flex-col w-full">
                  <label
                    for=""
                    class="dashboard-bot__form--labels"
                    >NOM DE L’ENTREPRISE - CODE POSTAL</label
                  >
                  <input
                    type="text"
                    class="dashboard-bot__form--inputs"
                    v-model="material_request.provider_postal"
                    disabled />
                </div>
              </div>
            </div>

            <div class="w-full">
              <div class="flex gap-2 w-full mt-7">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6 hover:cursor-pointer"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="green"
                  @click="addMaterials()">
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6 cursor-pointer ml-2"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="red"
                  @click="removeMaterials()"
                  v-if="index != 0">
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1"
                    d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="flex items-center justify-end space-x-4 client"
      v-if="displayContents('material')">
      <button
        class="dashboard-bot__form--formbtn clear"
        @click.prevent="clearForm('material')"
        v-if="!pose_id">
        ANNULER LA POSE
      </button>
      <!-- <button class="dashboard-bot__form--formbtn clear" @click.prevent="duplicatePose()" v-if="$route.params.id">Dupliquer</button> -->
      <router-link
        to="/"
        tag="button"
        class="dashboard-bot__form--formbtn back_list"
        >RETOUR à LA LISTE</router-link
      >
      <button
        class="dashboard-bot__form--formbtn submit save_continue"
        @click.prevent="submitMaterial">
        ENREGISTRER ET RESTER
      </button>
    </div>
    <!-- end of Material -->

    <!-- hotel -->
    <div
      class="flex flex-wrap py-6 w-full client"
      v-if="displayContents('hotel')">
      <!-- Add New Hotel -->
      <AddHotelModal @hotel-submited="vendorSubmitted" />
      <div
        class="py-1 w-full"
        v-for="(hotel_request, index) in hotel_requests"
        :key="index">
        <div class="flex flex-wrap items-start my-4">
          <div class="w-full lg:w-6/12 lg:pr-4">
            <!--  RECHERCE DE L'HOTEL  -->
            <div class="flex flex-col w-full">
              <label
                for=""
                class="dashboard-bot__form--labels"
                >RECHERCHE DE L’HÔTEL</label
              >
              <select
                class="dashboard-bot__form--inputs select input-select"
                required
                v-model="hotel_request.hotels">
                <option value="">Choisir le prestataire</option>
                <option
                  v-for="item in hotels"
                  :value="item.id"
                  :key="item.id">
                  {{ item.name }}
                </option>
              </select>
            </div>

            <!-- Hotel Reservation Field -->
            <div class="flex flex-col w-full">
              <label
                for=""
                class="dashboard-bot__form--labels"
                >Numéro de réservation</label
              >
              <input
                type="text"
                class="dashboard-bot__form--inputs"
                v-model="hotel_request.reservation_no"
                placeholder="000" />
            </div>
            <!-- File -->
            <div class="w-full mt-4">
              <div class="flex gap-2 items-end w-full">
                <div class="flex flex-col w-full">
                  <label
                    for=""
                    class="dashboard-bot__form--labels"
                    >Confirmation de réservation</label
                  >
                  <span class="text-xs text-gray-400 uppercase">
                    TÉLÉCHARGEZ Ici La Confirmation De La RÉSERVATION</span
                  >

                  <div
                    class="flex items-center text-xs text-gray-400"
                    v-for="(file, i) in hotel_files"
                    :key="i">
                    <a
                      :href="hotel_files[i].url"
                      download=""
                      target="blank">
                      <svg
                        v-if="hotel_files[i].url"
                        class="w-6 h-6"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M15 13l-3 3m0 0l-3-3m3 3V8m0 13a9 9 0 110-18 9 9 0 010 18z"></path>
                      </svg>
                    </a>
                    <a
                      :href="file.url"
                      download
                      target="blank">
                      {{ file.name }}</a
                    >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-6 w-6 cursor-pointer ml-2"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="red"
                      @click="removePoseFile(i, file.name)">
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1"
                        d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                  </div>
                  <div class="relative flex items-center mt-2">
                    <label
                      for="attach"
                      class="absolute left-2 cursor-pointer px-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-6 w-6 file-attachment"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor">
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12" />
                      </svg>
                    </label>

                    <input
                      name="attach"
                      id="client_fichier_attach"
                      type="file"
                      class="w-full dashboard-bot__form--inputs upload cursor-pointer"
                      @change="
                        filesChange(
                          $event.target.name,
                          $event.target.files,
                          $event,
                          'hotel_fichier'
                        )
                      " />
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="flex flex-col gap-2 w-full">
              <label for="" class="dashboard-bot__form--labels">Confirmation de réservation</label>
              <span class="text-xs text-gray-400 uppercase"> TÉLÉCHARGEZ Ici La Confirmation De La RÉSERVATION</span>
              <span class="text-xs text-gray-400"> REMARQUE : vous pouvez télécharger les fichiers une fois que la pose est créée </span>
              <div class="flex items-center text-xs text-gray-400" v-if="hotel_files[index]" >
                <svg class="w-6 h-6 dark:text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 13l-3 3m0 0l-3-3m3 3V8m0 13a9 9 0 110-18 9 9 0 010 18z">                    
                  </path>
                </svg>
                <a :href="hotel_files[index].url" download="" target="blank">
                <svg  v-if="hotel_files[index].url"
                class="w-6 h-6 " fill="none" 
                stroke="currentColor" 
                viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"> 
                  <path stroke-linecap="round" stroke-linejoin="round" 
                  stroke-width="2" d="M15 13l-3 3m0 0l-3-3m3 3V8m0 13a9 9 0 110-18 9 9 0 010 18z">                    
                  </path>
                </svg></a>
                
                <a class="ml-2" :href="hotel_files[index].url" download target="blank" > {{hotel_files[index].name}} </a>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6 cursor-pointer ml-2"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="red"
                  @click="removePoseFile(index, hotel_files[index].name)"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1"
                    d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </div>
              <div class="relative flex items-center mt-2">
                <label for="attach" class="absolute left-2 cursor-pointer px-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6 file-attachment"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12"
                    />
                  </svg>
                </label>

                <input
                  name="attach"
                  id="attach"
                  type="file"
                  class="w-full dashboard-bot__form--inputs upload cursor-pointer"
                  @change="
                    filesChange($event.target.name, $event.target.files, $event, 'hotel_fichier__'+index);"
                />
              </div>
            </div> -->
            <!-- Add and Remove Btns -->
          </div>

          <div class="w-full lg:w-6/12 lg:pl-4">
            <div class="w-full">
              <div class="flex flex-col">
                <label
                  for=""
                  class="dashboard-bot__form--labels"
                  >COMMENTAIRES</label
                >
                <ckeditor
                  :editor="editor"
                  v-model="hotel_request.hotel_notes"></ckeditor>
              </div>
            </div>
          </div>
          <!-- 
          <div class="w-full lg:w-6/12 lg:pr-4">
              <div class="flex gap-2 w-full mt-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6 hover:cursor-pointer"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="green"
                  @click="addHotels()"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6 cursor-pointer ml-2"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="red"
                  @click="removeHotels()"
                  v-if="index != 0"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1"
                    d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </div>
            </div> -->
        </div>
      </div>
    </div>
    <div
      class="flex items-center justify-end space-x-4 client"
      v-if="displayContents('hotel')">
      <button
        class="dashboard-bot__form--formbtn clear"
        @click.prevent="clearForm('hotel')"
        v-if="!pose_id">
        ANNULER LA POSE
      </button>
      <!-- <button class="dashboard-bot__form--formbtn clear" @click.prevent="duplicatePose()" v-if="$route.params.id">Dupliquer</button> -->
      <router-link
        to="/"
        tag="button"
        class="dashboard-bot__form--formbtn back_list"
        >RETOUR à LA LISTE</router-link
      >
      <button
        class="dashboard-bot__form--formbtn submit save_continue"
        @click.prevent="submitHotel">
        ENREGISTRER ET RESTER
      </button>
    </div>
    <!-- end of hotel -->

    <!-- pose -->
    <div
      class="flex flex-wrap py-6 w-full client"
      v-if="displayContents('pose')">
      <form
        class="dashboard-tab__form w-full px-2"
        action="">
        <!-- New UI -->
        <div class="flex flex-wrap my-4">
          <div class="w-full lg:w-6/12 pr-4">
            <!-- Start -  Chauffage -->
            <div
              class="border-2 rounded-md mb-8"
              v-for="item in pose_categories_planning"
              :key="item"
              :style="{ 'border-color': '#' + item.color }">
              <!-- Header - Assign the number of jours along with setting the equipes -->
              <div
                class="relative my-2 p-4 pb-2 border-b mx-4"
                :style="{ 'border-color': '#' + item.color }">
                <div class="flex items-center">
                  <span
                    class="text-xs font-bold text-white-800 rounded-full px-2 py-1"
                    :style="{ 'background-color': '#' + item.color }"
                    >{{ item.name }}</span
                  >

                  <!-- Assign the number of equipes -->
                  <div
                    class="absolute top-[-25px] right-5 flex items-center gap-2 ml-auto bg-blue-200 rounded-full px-2 h-10">
                    <span class="text-xs uppercase">JOURS</span>
                    <div
                      class="rounded-full cursor-pointer"
                      @click="dec('pose_days', 0, 1, item)">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-4 w-4 stroke-blue-800"
                        fill="none"
                        viewBox="0 0 24 24">
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                      </svg>
                    </div>

                    <div class="text-xs">
                      <span>{{ item.no_of_days }}</span>
                    </div>
                    <div
                      class="cursor-pointer rounded-full"
                      @click="inc('pose_days', 0, 1, item)">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-4 w-4 stroke-blue-800"
                        fill="none"
                        viewBox="0 0 24 24">
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                      </svg>
                    </div>
                  </div>

                  <!-- Enable/Disable number of jours -->
                </div>
              </div>

              <!-- Start -  Duplicate/Map according to number of jours -->

              <!-- Start - Container of Jour X -->
              <div
                class="flex items-center p-4 border shadow-md mx-4 rounded-sm my-2"
                v-for="(day, index) in item.days"
                :key="index">
                <!-- Show buttons to be enabled/disabled based on the selected equipe and total of equipes -->
                <div class="flex flex-col">
                  <span class="font-bold uppercase text-xs"
                    >Jour {{ day.id }}</span
                  >
                  <div class="flex flex-wrap max-w-[400px] mt-2 gap-2">
                    <div
                      class="flex items-center ml-auto space-x-4 bg-fuchsia-700 shadow-md rounded-md p-1">
                      <!-- <input
                      type="checkbox"
                      class="mx-1"
                      name=""
                      id=""
                      v-for="(day, index) in pose_days"
                      :key="index"
                      checked
                    /> -->
                      <span class="text-xs text-white uppercase font-semibold"
                        >Nombre D'Equipes</span
                      >
                      <div
                        class="rounded-full cursor-pointer"
                        @click="dec('no_of_teams', item, 1, day)">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-4 w-4 stroke-fuchsia-100"
                          fill="none"
                          viewBox="0 0 24 24">
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                      </div>

                      <div
                        class="text-xs text-black bg-fuchsia-200 rounded-md px-2 py-1 select-none">
                        <span>{{ day.team_number }}</span>
                      </div>
                      <div
                        class="cursor-pointer rounded-full"
                        @click="inc('no_of_teams', item, 1, day)">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-4 w-4 stroke-fuchsia-100"
                          fill="none"
                          viewBox="0 0 24 24">
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                      </div>
                    </div>
                    <!-- <button
                      class="dashboard-bot__pose-equipe-btn--chauffage active rounded-full border bg-gray-100 text-black px-2 py-0.5 text-xs uppercase"
                      :style="{ 'background-color': '#' + item.color }"
                      v-for="(team, i) in day.teams"
                      :key="i"
                      @click.prevent="removeTeam(item, index)"
                    >
                      Equipe {{ i + 1 }}
                    </button> -->
                  </div>
                </div>

                <!-- If Equipe X is selected, show it here with the options to assign the shift (Morning/Afternoon/Fullday) -->
                <div
                  class="flex flex-wrap divide-y-2 lg:divide-x-2 lg:divide-y-0 ml-auto">
                  <div
                    class="flex flex-col items-center px-2"
                    v-for="(team, j) in day.teams"
                    :key="j">
                    <span class="text-xs uppercase">Equipe {{ j + 1 }}</span>
                    <div class="flex gap-1 my-1 justify-between w-full">
                      <!-- Inactive: bg-gray-100 / Active/Selected: bg-blue-200 -->
                      <div
                        class="bg-gray-100 rounded-full p-0.5 cursor-pointer hover:bg-blue-200"
                        :class="{ 'bg-blue-400': team.day_type == 'J' }"
                        @click.prevent="
                          changeDayType(item, parseInt(day.id), j, 'J')
                        ">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="iconify iconify--ep w-4 h-4"
                          preserveAspectRatio="xMidYMid meet"
                          viewBox="0 0 1024 1024">
                          <path
                            fill="currentColor"
                            d="M512 704a192 192 0 1 0 0-384a192 192 0 0 0 0 384zm0 64a256 256 0 1 1 0-512a256 256 0 0 1 0 512zm0-704a32 32 0 0 1 32 32v64a32 32 0 0 1-64 0V96a32 32 0 0 1 32-32zm0 768a32 32 0 0 1 32 32v64a32 32 0 1 1-64 0v-64a32 32 0 0 1 32-32zM195.2 195.2a32 32 0 0 1 45.248 0l45.248 45.248a32 32 0 1 1-45.248 45.248L195.2 240.448a32 32 0 0 1 0-45.248zm543.104 543.104a32 32 0 0 1 45.248 0l45.248 45.248a32 32 0 0 1-45.248 45.248l-45.248-45.248a32 32 0 0 1 0-45.248zM64 512a32 32 0 0 1 32-32h64a32 32 0 0 1 0 64H96a32 32 0 0 1-32-32zm768 0a32 32 0 0 1 32-32h64a32 32 0 1 1 0 64h-64a32 32 0 0 1-32-32zM195.2 828.8a32 32 0 0 1 0-45.248l45.248-45.248a32 32 0 0 1 45.248 45.248L240.448 828.8a32 32 0 0 1-45.248 0zm543.104-543.104a32 32 0 0 1 0-45.248l45.248-45.248a32 32 0 0 1 45.248 45.248l-45.248 45.248a32 32 0 0 1-45.248 0z"></path>
                        </svg>
                      </div>
                      <div
                        class="bg-gray-100 rounded-full p-0.5 cursor-pointer hover:bg-blue-200"
                        :class="{ 'bg-blue-400': team.day_type == 'M' }"
                        @click.prevent="
                          changeDayType(item, parseInt(day.id), j, 'M')
                        ">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="iconify iconify--ep w-4 h-4"
                          preserveAspectRatio="xMidYMid meet"
                          viewBox="0 0 1024 1024">
                          <path
                            fill="currentColor"
                            d="M32 768h960a32 32 0 1 1 0 64H32a32 32 0 1 1 0-64zm129.408-96a352 352 0 0 1 701.184 0h-64.32a288 288 0 0 0-572.544 0h-64.32zM512 128a32 32 0 0 1 32 32v96a32 32 0 0 1-64 0v-96a32 32 0 0 1 32-32zm407.296 168.704a32 32 0 0 1 0 45.248l-67.84 67.84a32 32 0 1 1-45.248-45.248l67.84-67.84a32 32 0 0 1 45.248 0zm-814.592 0a32 32 0 0 1 45.248 0l67.84 67.84a32 32 0 1 1-45.248 45.248l-67.84-67.84a32 32 0 0 1 0-45.248z"></path>
                        </svg>
                      </div>
                      <div
                        class="bg-gray-100 rounded-full p-0.5 cursor-pointer hover:bg-blue-200"
                        :class="{ 'bg-blue-400': team.day_type == 'A' }"
                        @click.prevent="
                          changeDayType(item, parseInt(day.id), j, 'A')
                        ">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="iconify iconify--ep w-4 h-4 rotate-180"
                          preserveAspectRatio="xMidYMid meet"
                          viewBox="0 0 1024 1024">
                          <path
                            fill="currentColor"
                            d="M32 768h960a32 32 0 1 1 0 64H32a32 32 0 1 1 0-64zm129.408-96a352 352 0 0 1 701.184 0h-64.32a288 288 0 0 0-572.544 0h-64.32zM512 128a32 32 0 0 1 32 32v96a32 32 0 0 1-64 0v-96a32 32 0 0 1 32-32zm407.296 168.704a32 32 0 0 1 0 45.248l-67.84 67.84a32 32 0 1 1-45.248-45.248l67.84-67.84a32 32 0 0 1 45.248 0zm-814.592 0a32 32 0 0 1 45.248 0l67.84 67.84a32 32 0 1 1-45.248 45.248l-67.84-67.84a32 32 0 0 1 0-45.248z"></path>
                        </svg>
                      </div>
                    </div>
                    <div class="flex gap-1 my-1 justify-between w-full">
                      <div
                        class="rounded-full cursor-pointer"
                        @click.prevent="changeDaySize(item, index, j, 'minus')">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-4 w-4 stroke-blue-800"
                          fill="none"
                          viewBox="0 0 24 24">
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                      </div>

                      <div class="text-xs">
                        <span>{{ team.team_size }}</span>
                      </div>
                      <div
                        class="cursor-pointer rounded-full"
                        @click.prevent="changeDaySize(item, index, j, 'add')">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-4 w-4 stroke-blue-800"
                          fill="none"
                          viewBox="0 0 24 24">
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- End - Container of Jour X -->

              <!-- End - Duplicate/Map according to number of jours -->
            </div>

            <!-- End - Chauffage -->
          </div>

          <div class="w-full lg:w-6/12 pl-4">
            <!-- Text Editor -->
            <div class="w-full mt-1">
              <!-- if Poses are Planned,  Team of n -->
              <p class="uppercase text-xs">
                <span
                  class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-md text-white last:mr-0 mr-1"
                  :class="['bg-red-600']"
                  v-if="pose_status == 1">
                  Poses Planned, Equipe(s) are not yet assigned
                </span>
                <span
                  class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-md text-white last:mr-0 mr-1"
                  :class="['bg-green-800']"
                  v-if="pose_status == 2">
                  Poses Planned, Equipe(s) are assigned
                </span>
              </p>
              <div
                class="flex flex-wrap gap-2 h-[100px] overflow-y-scroll"
                v-if="initial_planned_teams.length > 0">
                <!-- Replace div tags with img tag - Change border-pink-400 to desired color-->
                <!-- Team of 1 -->
                <div
                  class="relative h-12 py-1 mt-4 ml-2 px-2 rounded-md dashboard__img-toggle-shape orange bg-white header-box"
                  v-for="(team, i) in initial_planned_teams"
                  :key="i"
                  :class="['border-[#' + team.color + ']']">
                  <!-- Change the bg-fuchsia-700 to the desired color, match with the border of the parent  -->
                  <!--  Full DAY if "J" -->
                  <div
                    class="flex gap-1 items-center justify-center w-9 h-4 rounded-md absolute top-[-16px] left-[-2px] rounded-bl-none rounded-br-none"
                    title="Full Day"
                    v-if="team.day_type == 'J'"
                    :class="['bg-[#' + team.color + ']']">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      aria-hidden="true"
                      role="img"
                      class="iconify iconify--ep w-4 h-4"
                      preserveAspectRatio="xMidYMid meet"
                      viewBox="0 0 1024 1024">
                      <path
                        fill="white"
                        d="M512 704a192 192 0 1 0 0-384a192 192 0 0 0 0 384zm0 64a256 256 0 1 1 0-512a256 256 0 0 1 0 512zm0-704a32 32 0 0 1 32 32v64a32 32 0 0 1-64 0V96a32 32 0 0 1 32-32zm0 768a32 32 0 0 1 32 32v64a32 32 0 1 1-64 0v-64a32 32 0 0 1 32-32zM195.2 195.2a32 32 0 0 1 45.248 0l45.248 45.248a32 32 0 1 1-45.248 45.248L195.2 240.448a32 32 0 0 1 0-45.248zm543.104 543.104a32 32 0 0 1 45.248 0l45.248 45.248a32 32 0 0 1-45.248 45.248l-45.248-45.248a32 32 0 0 1 0-45.248zM64 512a32 32 0 0 1 32-32h64a32 32 0 0 1 0 64H96a32 32 0 0 1-32-32zm768 0a32 32 0 0 1 32-32h64a32 32 0 1 1 0 64h-64a32 32 0 0 1-32-32zM195.2 828.8a32 32 0 0 1 0-45.248l45.248-45.248a32 32 0 0 1 45.248 45.248L240.448 828.8a32 32 0 0 1-45.248 0zm543.104-543.104a32 32 0 0 1 0-45.248l45.248-45.248a32 32 0 0 1 45.248 45.248l-45.248 45.248a32 32 0 0 1-45.248 0z"></path>
                    </svg>
                  </div>
                  <!--  Morning DAY if "M" -->
                  <div
                    class="flex gap-1 items-center justify-center w-9 h-4 rounded-md absolute top-[-16px] left-[-2px] rounded-bl-none rounded-br-none"
                    title="Morning Day"
                    v-if="team.day_type == 'M'"
                    :class="['bg-[#' + team.color + ']']">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      aria-hidden="true"
                      role="img"
                      class="iconify iconify--ep w-4 h-4"
                      preserveAspectRatio="xMidYMid meet"
                      viewBox="0 0 1024 1024">
                      <path
                        fill="white"
                        d="M32 768h960a32 32 0 1 1 0 64H32a32 32 0 1 1 0-64zm129.408-96a352 352 0 0 1 701.184 0h-64.32a288 288 0 0 0-572.544 0h-64.32zM512 128a32 32 0 0 1 32 32v96a32 32 0 0 1-64 0v-96a32 32 0 0 1 32-32zm407.296 168.704a32 32 0 0 1 0 45.248l-67.84 67.84a32 32 0 1 1-45.248-45.248l67.84-67.84a32 32 0 0 1 45.248 0zm-814.592 0a32 32 0 0 1 45.248 0l67.84 67.84a32 32 0 1 1-45.248 45.248l-67.84-67.84a32 32 0 0 1 0-45.248z"></path>
                    </svg>
                  </div>
                  <!-- Afternoon if "A" -->
                  <div
                    class="flex gap-1 items-center justify-center w-9 h-4 rounded-md absolute top-[-16px] left-[-2px] rounded-bl-none rounded-br-none"
                    title="Half Day"
                    v-if="team.day_type == 'A'"
                    :class="['bg-[#' + team.color + ']']">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      aria-hidden="true"
                      role="img"
                      class="iconify iconify--ep w-4 h-4 rotate-180"
                      preserveAspectRatio="xMidYMid meet"
                      viewBox="0 0 1024 1024">
                      <path
                        fill="white"
                        d="M32 768h960a32 32 0 1 1 0 64H32a32 32 0 1 1 0-64zm129.408-96a352 352 0 0 1 701.184 0h-64.32a288 288 0 0 0-572.544 0h-64.32zM512 128a32 32 0 0 1 32 32v96a32 32 0 0 1-64 0v-96a32 32 0 0 1 32-32zm407.296 168.704a32 32 0 0 1 0 45.248l-67.84 67.84a32 32 0 1 1-45.248-45.248l67.84-67.84a32 32 0 0 1 45.248 0zm-814.592 0a32 32 0 0 1 45.248 0l67.84 67.84a32 32 0 1 1-45.248 45.248l-67.84-67.84a32 32 0 0 1 0-45.248z"></path>
                    </svg>
                  </div>

                  <div
                    class="flex items-center justify-center gap-2"
                    v-if="team.members">
                    <div
                      class="flex items-center justify-center rounded-full w-10 h-10 object-contain bg-[#dbbba6]"
                      v-for="(member, j) in team.members"
                      :key="j">
                      <div v-if="getUserImg(member)">
                        <span
                          class="flex items-center justify-center text-xs font-semibold w-8 h-8 uppercase rounded-full text-pink-800 last:mr-0 mr-1"
                          :class="[
                            team.category
                              ? 'bg-[#' + getCategoryColor(team.category) + ']'
                              : 'bg-fuchsia-100'
                          ]">
                          <img
                            :src="getUserImg(member)"
                            class="rounded-full" />
                        </span>
                      </div>
                      <div v-else>
                        <span
                          class="flex items-center justify-center text-xs font-semibold w-8 h-8 uppercase rounded-full text-pink-800 last:mr-0 mr-1"
                          :class="[
                            team.category
                              ? 'bg-[#' + getCategoryColor(team.category) + ']'
                              : 'bg-fuchsia-100'
                          ]">
                          {{ getUserName(member).first_name.slice(0, 1)
                          }}{{ getUserName(member).last_name.slice(0, 1) }}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    class="flex items-center justify-center gap-2"
                    v-else>
                    <div
                      class="flex items-center justify-center rounded-full w-10 h-10 object-contain bg-[#dbbba6]"
                      v-for="(size, j) in parseInt(team.team_size)"
                      :key="j">
                      <span
                        class="flex items-center justify-center text-xs font-semibold w-8 h-8 uppercase rounded-full text-pink-800 last:mr-0 mr-1"
                        :class="[
                          team.color
                            ? 'bg-[#' + team.color + ']'
                            : 'bg-fuchsia-100'
                        ]">
                      </span>
                    </div>
                  </div>
                </div>
                <!-- End - Team of 1 -->
              </div>
              <!-- End - Team of n -->

              <div class="flex gap-2 items-end w-full">
                <div class="flex flex-col w-full">
                  <label
                    for=""
                    class="dashboard-bot__form--labels"
                    >Fichiers</label
                  >
                  <span class="text-xs text-gray-400 uppercase">
                    TÉLÉCHARGEZ Ici Vos Fichiers</span
                  >

                  <div
                    class="flex items-center text-xs text-gray-400"
                    v-for="(file, i) in pose_download_files"
                    :key="i">
                    <svg
                      class="w-6 h-6 dark:text-white"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M15 13l-3 3m0 0l-3-3m3 3V8m0 13a9 9 0 110-18 9 9 0 010 18z"></path>
                    </svg>
                    <a
                      :href="file.url"
                      download=""
                      target="blank">
                      <svg
                        v-if="file.url"
                        class="w-6 h-6 mr-2"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M15 13l-3 3m0 0l-3-3m3 3V8m0 13a9 9 0 110-18 9 9 0 010 18z"></path>
                      </svg>
                    </a>

                    <a
                      class="ml-2"
                      :href="file.url"
                      download
                      target="blank">
                      {{ file.name }}</a
                    >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-6 w-6 cursor-pointer ml-2"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="red"
                      @click="removePoseFile(i, file.name)">
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1"
                        d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                  </div>
                  <div class="relative flex items-center mt-2">
                    <label
                      for="attach"
                      class="absolute left-2 cursor-pointer px-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor">
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12" />
                      </svg>
                    </label>

                    <input
                      name="attach"
                      id="attach"
                      type="file"
                      class="w-full dashboard-bot__form--inputs border upload cursor-pointer"
                      @change="
                        filesChange(
                          $event.target.name,
                          $event.target.files,
                          $event,
                          'pose_fichier'
                        )
                      " />
                  </div>
                </div>
              </div>
            </div>
            <div class="w-full mt-4">
              <div class="flex flex-col">
                <label
                  for=""
                  class="dashboard-bot__form--labels"
                  >Commentaires</label
                >
                <ckeditor
                  :editor="editor"
                  v-model="note_public"></ckeditor>
              </div>
            </div>
          </div>
        </div>
        <!-- End of New UI -->
      </form>
    </div>
    <div
      class="flex items-center justify-end space-x-4 client"
      v-if="displayContents('pose')">
      <button
        class="dashboard-bot__form--formbtn clear"
        @click.prevent="clearForm('pose')"
        v-if="!pose_id">
        ANNULER LA POSE
      </button>
      <!-- <button class="dashboard-bot__form--formbtn clear" @click.prevent="duplicatePose()" v-if="$route.params.id">Dupliquer</button> -->
      <router-link
        to="/"
        tag="button"
        class="dashboard-bot__form--formbtn back_list"
        >RETOUR à LA LISTE</router-link
      >
      <button
        class="dashboard-bot__form--formbtn submit save_continue"
        @click.prevent="submitPose">
        ENREGISTRER ET RESTER
      </button>
    </div>
    <!-- end of POSE -->

    <!-- finition -->
    <div
      class="flex flex-wrap py-6 w-full client"
      v-if="displayContents('finition')">
      <form
        class="dashboard-tab__form w-full px-2"
        action="">
        <!-- Number of days -->

        <div class="flex flex-wrap my-4">
          <div class="w-full lg:w-6/12 pr-4">
            <!-- Start -  Chauffage -->
            <div
              class="border-2 rounded-md mb-8"
              v-for="item in finition_categories_planning"
              :key="item"
              :style="{ 'border-color': '#' + item.color }">
              <!-- Header - Assign the number of jours along with setting the equipes -->
              <div
                class="relative my-2 p-4 pb-2 border-b mx-4"
                :style="{ 'border-color': '#' + item.color }">
                <div class="flex items-center">
                  <span
                    class="text-xs font-bold text-white-800 rounded-full px-2 py-1"
                    :style="{ 'background-color': '#' + item.color }"
                    >{{ item.name }}</span
                  >

                  <!-- Assign the number of equipes -->
                  <div
                    class="absolute top-[-25px] right-5 flex items-center gap-2 ml-auto bg-blue-200 rounded-full px-2 h-10">
                    <span class="text-xs uppercase">JOURS</span>
                    <div
                      class="rounded-full cursor-pointer"
                      @click="decFin('pose_days', 0, 1, item)">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-4 w-4 stroke-blue-800"
                        fill="none"
                        viewBox="0 0 24 24">
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                      </svg>
                    </div>

                    <div class="text-xs">
                      <span>{{ item.no_of_days }}</span>
                    </div>
                    <div
                      class="cursor-pointer rounded-full"
                      @click="incFin('pose_days', 0, 1, item)">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-4 w-4 stroke-blue-800"
                        fill="none"
                        viewBox="0 0 24 24">
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                      </svg>
                    </div>
                  </div>

                  <!-- Enable/Disable number of jours -->
                </div>
              </div>

              <!-- Start -  Duplicate/Map according to number of jours -->

              <!-- Start - Container of Jour X -->
              <div
                class="flex items-center p-4 border shadow-md mx-4 rounded-sm my-2"
                v-for="(day, index) in item.days"
                :key="index">
                <!-- Show buttons to be enabled/disabled based on the selected equipe and total of equipes -->
                <div class="flex flex-col">
                  <span class="font-bold uppercase text-xs"
                    >Jour {{ day.id }}</span
                  >
                  <div class="flex flex-wrap max-w-[400px] mt-2 gap-2">
                    <div
                      class="flex items-center ml-auto space-x-4 bg-fuchsia-700 shadow-md rounded-md p-1">
                      <!-- <input
                      type="checkbox"
                      class="mx-1"
                      name=""
                      id=""
                      v-for="(day, index) in pose_days"
                      :key="index"
                      checked
                    /> -->
                      <span class="text-xs text-white uppercase font-semibold"
                        >Number of Equipes</span
                      >
                      <div
                        class="rounded-full cursor-pointer"
                        @click="decFin('no_of_teams', item, 1, day)">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-4 w-4 stroke-fuchsia-100"
                          fill="none"
                          viewBox="0 0 24 24">
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                      </div>

                      <div
                        class="text-xs text-black bg-fuchsia-200 rounded-md px-2 py-1 select-none">
                        <span>{{ day.team_number }}</span>
                      </div>
                      <div
                        class="cursor-pointer rounded-full"
                        @click="incFin('no_of_teams', item, 1, day)">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-4 w-4 stroke-fuchsia-100"
                          fill="none"
                          viewBox="0 0 24 24">
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                      </div>
                    </div>
                    <!-- <button
                      class="dashboard-bot__pose-equipe-btn--chauffage active rounded-full border bg-gray-100 text-black px-2 py-0.5 text-xs uppercase"
                      :style="{ 'background-color': '#' + item.color }"
                      v-for="(team, i) in day.teams"
                      :key="i"
                      @click.prevent="removeTeam(item, index)"
                    >
                      Equipe {{ i + 1 }}
                    </button> -->
                  </div>
                </div>

                <!-- If Equipe X is selected, show it here with the options to assign the shift (Morning/Afternoon/Fullday) -->
                <div
                  class="flex flex-wrap divide-y-2 lg:divide-x-2 lg:divide-y-0 ml-auto">
                  <div
                    class="flex flex-col items-center px-2"
                    v-for="(team, j) in day.teams"
                    :key="j">
                    <span class="text-xs uppercase">Equipe {{ j + 1 }}</span>
                    <div class="flex gap-1 my-1 justify-between w-full">
                      <!-- Inactive: bg-gray-100 / Active/Selected: bg-blue-200 -->
                      <div
                        class="bg-gray-100 rounded-full p-0.5 cursor-pointer hover:bg-blue-200"
                        :class="{ 'bg-blue-400': team.day_type == 'J' }"
                        @click.prevent="
                          changeDayType(item, parseInt(day.id), j, 'J')
                        ">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="iconify iconify--ep w-4 h-4"
                          preserveAspectRatio="xMidYMid meet"
                          viewBox="0 0 1024 1024">
                          <path
                            fill="currentColor"
                            d="M512 704a192 192 0 1 0 0-384a192 192 0 0 0 0 384zm0 64a256 256 0 1 1 0-512a256 256 0 0 1 0 512zm0-704a32 32 0 0 1 32 32v64a32 32 0 0 1-64 0V96a32 32 0 0 1 32-32zm0 768a32 32 0 0 1 32 32v64a32 32 0 1 1-64 0v-64a32 32 0 0 1 32-32zM195.2 195.2a32 32 0 0 1 45.248 0l45.248 45.248a32 32 0 1 1-45.248 45.248L195.2 240.448a32 32 0 0 1 0-45.248zm543.104 543.104a32 32 0 0 1 45.248 0l45.248 45.248a32 32 0 0 1-45.248 45.248l-45.248-45.248a32 32 0 0 1 0-45.248zM64 512a32 32 0 0 1 32-32h64a32 32 0 0 1 0 64H96a32 32 0 0 1-32-32zm768 0a32 32 0 0 1 32-32h64a32 32 0 1 1 0 64h-64a32 32 0 0 1-32-32zM195.2 828.8a32 32 0 0 1 0-45.248l45.248-45.248a32 32 0 0 1 45.248 45.248L240.448 828.8a32 32 0 0 1-45.248 0zm543.104-543.104a32 32 0 0 1 0-45.248l45.248-45.248a32 32 0 0 1 45.248 45.248l-45.248 45.248a32 32 0 0 1-45.248 0z"></path>
                        </svg>
                      </div>
                      <div
                        class="bg-gray-100 rounded-full p-0.5 cursor-pointer hover:bg-blue-200"
                        :class="{ 'bg-blue-400': team.day_type == 'M' }"
                        @click.prevent="
                          changeDayType(item, parseInt(day.id), j, 'M')
                        ">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="iconify iconify--ep w-4 h-4"
                          preserveAspectRatio="xMidYMid meet"
                          viewBox="0 0 1024 1024">
                          <path
                            fill="currentColor"
                            d="M32 768h960a32 32 0 1 1 0 64H32a32 32 0 1 1 0-64zm129.408-96a352 352 0 0 1 701.184 0h-64.32a288 288 0 0 0-572.544 0h-64.32zM512 128a32 32 0 0 1 32 32v96a32 32 0 0 1-64 0v-96a32 32 0 0 1 32-32zm407.296 168.704a32 32 0 0 1 0 45.248l-67.84 67.84a32 32 0 1 1-45.248-45.248l67.84-67.84a32 32 0 0 1 45.248 0zm-814.592 0a32 32 0 0 1 45.248 0l67.84 67.84a32 32 0 1 1-45.248 45.248l-67.84-67.84a32 32 0 0 1 0-45.248z"></path>
                        </svg>
                      </div>
                      <div
                        class="bg-gray-100 rounded-full p-0.5 cursor-pointer hover:bg-blue-200"
                        :class="{ 'bg-blue-400': team.day_type == 'A' }"
                        @click.prevent="
                          changeDayType(item, parseInt(day.id), j, 'A')
                        ">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="iconify iconify--ep w-4 h-4 rotate-180"
                          preserveAspectRatio="xMidYMid meet"
                          viewBox="0 0 1024 1024">
                          <path
                            fill="currentColor"
                            d="M32 768h960a32 32 0 1 1 0 64H32a32 32 0 1 1 0-64zm129.408-96a352 352 0 0 1 701.184 0h-64.32a288 288 0 0 0-572.544 0h-64.32zM512 128a32 32 0 0 1 32 32v96a32 32 0 0 1-64 0v-96a32 32 0 0 1 32-32zm407.296 168.704a32 32 0 0 1 0 45.248l-67.84 67.84a32 32 0 1 1-45.248-45.248l67.84-67.84a32 32 0 0 1 45.248 0zm-814.592 0a32 32 0 0 1 45.248 0l67.84 67.84a32 32 0 1 1-45.248 45.248l-67.84-67.84a32 32 0 0 1 0-45.248z"></path>
                        </svg>
                      </div>
                    </div>
                    <div class="flex gap-1 my-1 justify-between w-full">
                      <div
                        class="rounded-full cursor-pointer"
                        @click.prevent="changeDaySize(item, index, j, 'minus')">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-4 w-4 stroke-blue-800"
                          fill="none"
                          viewBox="0 0 24 24">
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                      </div>

                      <div class="text-xs">
                        <span>{{ team.team_size }}</span>
                      </div>
                      <div
                        class="cursor-pointer rounded-full"
                        @click.prevent="changeDaySize(item, index, j, 'add')">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-4 w-4 stroke-blue-800"
                          fill="none"
                          viewBox="0 0 24 24">
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- End - Container of Jour X -->

              <!-- End - Duplicate/Map according to number of jours -->
            </div>

            <!-- End - Chauffage -->
          </div>

          <div class="w-full lg:w-6/12 pl-4">
            <div class="w-full mt-1">
              <!-- if Poses are Planned,  Team of n -->
              <p class="uppercase text-xs">
                <span
                  class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-md text-white last:mr-0 mr-1"
                  :class="['bg-red-600']"
                  v-if="pose_status == 4">
                  Finition Planned, Equipe(s) are not yet assigned
                </span>
                <span
                  class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-md text-white last:mr-0 mr-1"
                  :class="['bg-green-800']"
                  v-if="pose_status == 5">
                  Finition Planned, Equipe(s) are assigned
                </span>
              </p>
              <div
                class="flex flex-wrap gap-2 h-[100px] overflow-y-scroll"
                v-if="initial_planned_finition_teams.length > 0">
                <!-- Replace div tags with img tag - Change border-pink-400 to desired color-->
                <!-- Team of 1 -->
                <div
                  class="relative h-12 py-1 mt-4 ml-2 px-2 rounded-md dashboard__img-toggle-shape orange bg-white header-box"
                  v-for="(team, i) in initial_planned_finition_teams"
                  :key="i"
                  :class="['border-[#' + team.color + ']']">
                  <!-- Change the bg-fuchsia-700 to the desired color, match with the border of the parent  -->
                  <!--  Full DAY if "J" -->
                  <div
                    class="flex gap-1 items-center justify-center w-9 h-4 rounded-md absolute top-[-16px] left-[-2px] rounded-bl-none rounded-br-none"
                    title="Full Day"
                    v-if="team.day_type == 'J'"
                    :class="['bg-[#' + team.color + ']']">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      aria-hidden="true"
                      role="img"
                      class="iconify iconify--ep w-4 h-4"
                      preserveAspectRatio="xMidYMid meet"
                      viewBox="0 0 1024 1024">
                      <path
                        fill="white"
                        d="M512 704a192 192 0 1 0 0-384a192 192 0 0 0 0 384zm0 64a256 256 0 1 1 0-512a256 256 0 0 1 0 512zm0-704a32 32 0 0 1 32 32v64a32 32 0 0 1-64 0V96a32 32 0 0 1 32-32zm0 768a32 32 0 0 1 32 32v64a32 32 0 1 1-64 0v-64a32 32 0 0 1 32-32zM195.2 195.2a32 32 0 0 1 45.248 0l45.248 45.248a32 32 0 1 1-45.248 45.248L195.2 240.448a32 32 0 0 1 0-45.248zm543.104 543.104a32 32 0 0 1 45.248 0l45.248 45.248a32 32 0 0 1-45.248 45.248l-45.248-45.248a32 32 0 0 1 0-45.248zM64 512a32 32 0 0 1 32-32h64a32 32 0 0 1 0 64H96a32 32 0 0 1-32-32zm768 0a32 32 0 0 1 32-32h64a32 32 0 1 1 0 64h-64a32 32 0 0 1-32-32zM195.2 828.8a32 32 0 0 1 0-45.248l45.248-45.248a32 32 0 0 1 45.248 45.248L240.448 828.8a32 32 0 0 1-45.248 0zm543.104-543.104a32 32 0 0 1 0-45.248l45.248-45.248a32 32 0 0 1 45.248 45.248l-45.248 45.248a32 32 0 0 1-45.248 0z"></path>
                    </svg>
                  </div>
                  <!--  Morning DAY if "M" -->
                  <div
                    class="flex gap-1 items-center justify-center w-9 h-4 rounded-md absolute top-[-16px] left-[-2px] rounded-bl-none rounded-br-none"
                    title="Morning Day"
                    v-if="team.day_type == 'M'"
                    :class="['bg-[#' + team.color + ']']">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      aria-hidden="true"
                      role="img"
                      class="iconify iconify--ep w-4 h-4"
                      preserveAspectRatio="xMidYMid meet"
                      viewBox="0 0 1024 1024">
                      <path
                        fill="white"
                        d="M32 768h960a32 32 0 1 1 0 64H32a32 32 0 1 1 0-64zm129.408-96a352 352 0 0 1 701.184 0h-64.32a288 288 0 0 0-572.544 0h-64.32zM512 128a32 32 0 0 1 32 32v96a32 32 0 0 1-64 0v-96a32 32 0 0 1 32-32zm407.296 168.704a32 32 0 0 1 0 45.248l-67.84 67.84a32 32 0 1 1-45.248-45.248l67.84-67.84a32 32 0 0 1 45.248 0zm-814.592 0a32 32 0 0 1 45.248 0l67.84 67.84a32 32 0 1 1-45.248 45.248l-67.84-67.84a32 32 0 0 1 0-45.248z"></path>
                    </svg>
                  </div>
                  <!-- Afternoon if "A" -->
                  <div
                    class="flex gap-1 items-center justify-center w-9 h-4 rounded-md absolute top-[-16px] left-[-2px] rounded-bl-none rounded-br-none"
                    title="Half Day"
                    v-if="team.day_type == 'A'"
                    :class="['bg-[#' + team.color + ']']">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      aria-hidden="true"
                      role="img"
                      class="iconify iconify--ep w-4 h-4 rotate-180"
                      preserveAspectRatio="xMidYMid meet"
                      viewBox="0 0 1024 1024">
                      <path
                        fill="white"
                        d="M32 768h960a32 32 0 1 1 0 64H32a32 32 0 1 1 0-64zm129.408-96a352 352 0 0 1 701.184 0h-64.32a288 288 0 0 0-572.544 0h-64.32zM512 128a32 32 0 0 1 32 32v96a32 32 0 0 1-64 0v-96a32 32 0 0 1 32-32zm407.296 168.704a32 32 0 0 1 0 45.248l-67.84 67.84a32 32 0 1 1-45.248-45.248l67.84-67.84a32 32 0 0 1 45.248 0zm-814.592 0a32 32 0 0 1 45.248 0l67.84 67.84a32 32 0 1 1-45.248 45.248l-67.84-67.84a32 32 0 0 1 0-45.248z"></path>
                    </svg>
                  </div>

                  <div
                    class="flex items-center justify-center gap-2"
                    v-if="team.members">
                    <div
                      class="flex items-center justify-center rounded-full w-10 h-10 object-contain bg-[#dbbba6]"
                      v-for="(member, j) in team.members"
                      :key="j">
                      <div v-if="getUserImg(member)">
                        <span
                          class="flex items-center justify-center text-xs font-semibold w-8 h-8 uppercase rounded-full text-pink-800 last:mr-0 mr-1"
                          :class="[
                            team.category
                              ? 'bg-[#' + getCategoryColor(team.category) + ']'
                              : 'bg-fuchsia-100'
                          ]">
                          <img
                            :src="getUserImg(member)"
                            class="rounded-full" />
                        </span>
                      </div>
                      <div v-else>
                        <span
                          class="flex items-center justify-center text-xs font-semibold w-8 h-8 uppercase rounded-full text-pink-800 last:mr-0 mr-1"
                          :class="[
                            team.category
                              ? 'bg-[#' + getCategoryColor(team.category) + ']'
                              : 'bg-fuchsia-100'
                          ]">
                          {{ getUserName(member).first_name.slice(0, 1)
                          }}{{ getUserName(member).last_name.slice(0, 1) }}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    class="flex items-center justify-center gap-2"
                    v-else>
                    <div
                      class="flex items-center justify-center rounded-full w-10 h-10 object-contain bg-[#dbbba6]"
                      v-for="(size, j) in parseInt(team.team_size)"
                      :key="j">
                      <span
                        class="flex items-center justify-center text-xs font-semibold w-8 h-8 uppercase rounded-full text-pink-800 last:mr-0 mr-1"
                        :class="[
                          team.color
                            ? 'bg-[#' + team.color + ']'
                            : 'bg-fuchsia-100'
                        ]">
                      </span>
                    </div>
                  </div>
                </div>
                <!-- End - Team of 1 -->
              </div>
              <!-- End - Team of n -->
              <!-- End - Team of n -->

              <div class="flex flex-col w-full">
                <label
                  for=""
                  class="dashboard-bot__form--labels"
                  >Matériel de finition</label
                >

                <Multiselect
                  mode="tags"
                  :close-on-select="false"
                  :searchable="true"
                  :create-option="true"
                  :options="materials"
                  v-model="fin_materials" />
              </div>

              <div class="flex gap-2 items-end w-full">
                <div class="flex flex-col w-full">
                  <label
                    for=""
                    class="dashboard-bot__form--labels"
                    >Fichiers</label
                  >
                  <span class="text-xs text-gray-400 uppercase">
                    Telechargez Ici Vos Fichiers</span
                  >
                  <div
                    class="flex items-center text-xs text-gray-400"
                    v-for="(file, i) in fin_download_files"
                    :key="i">
                    <svg
                      class="w-6 h-6 dark:text-white"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M15 13l-3 3m0 0l-3-3m3 3V8m0 13a9 9 0 110-18 9 9 0 010 18z"></path>
                    </svg>
                    <a
                      :href="file.url"
                      download
                      target="blank">
                      {{ file.name }}</a
                    >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-6 w-6 cursor-pointer ml-2"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="red"
                      @click="removePoseFile(i, file.name)">
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1"
                        d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                  </div>
                  <div class="relative flex items-center mt-2">
                    <label
                      for="attach"
                      class="absolute left-2 cursor-pointer px-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor">
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12" />
                      </svg>
                    </label>

                    <input
                      name="attach"
                      id="attach"
                      type="file"
                      class="w-full dashboard-bot__form--inputs border upload cursor-pointer"
                      @change="
                        filesChange(
                          $event.target.name,
                          $event.target.files,
                          $event,
                          'fin_fichier'
                        )
                      " />
                  </div>
                </div>
              </div>
            </div>

            <!-- Text Editor -->
            <div class="w-full mt-4">
              <div class="flex flex-col">
                <label
                  for=""
                  class="dashboard-bot__form--labels"
                  >Additional Information</label
                >
                <ckeditor
                  :editor="editor"
                  v-model="note_finition"></ckeditor>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div
      class="flex items-center justify-end space-x-4 client"
      v-if="displayContents('finition')">
      <button
        class="dashboard-bot__form--formbtn clear"
        @click.prevent="clearForm('finition')"
        v-if="!pose_id">
        ANNULER LA POSE
      </button>
      <!-- <button class="dashboard-bot__form--formbtn clear" @click.prevent="duplicatePose()" v-if="$route.params.id">Dupliquer</button> -->
      <router-link
        to="/"
        tag="button"
        class="dashboard-bot__form--formbtn back_list"
        >RETOUR à LA LISTE</router-link
      >
      <button
        class="dashboard-bot__form--formbtn submit save_continue"
        @click.prevent="submitFin">
        ENREGISTRER ET RESTER
      </button>
    </div>
    <!-- end of POSE -->
  </div>

  <!-- End - Tab Container -->
</template>
<script>
import axios from '../http-common' // <-- HTTP common
import ApiCallService from '@/services/apiCall'
import Multiselect from '@vueform/multiselect'
import allPoses from '@/data/pose.js'
import { mapGetters, mapState } from 'vuex'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import moment from 'moment' // <-- Moment
import Modal from './Modal'
import AddHotelModal from './AddHotelModal'

export default {
  name: 'TabContainer',
  props: [
    'content',
    'pose_validation',
    'pose_material_commande',
    'pose_material_receptionne',
    'pose_ddp',
    'after_sales',
    'pose_ddp_photos',
    'pose_date',
    'agency_changed'
  ],
  components: {
    Multiselect,
    Modal,
    AddHotelModal
  },
  emits: ['clicked-show-pose'], // <-- Emit the updated count
  data() {
    return {
      pose_status: 0,
      pose_categories_planning: [],
      finition_categories_planning: [],
      material_requests: [],
      hotel_requests: [],
      editor: ClassicEditor,
      errors: [],
      venteErrors: [],
      activeTabName: 'client',
      currentstep: 1,
      metre_pose_files: [],
      hotel_files: [],
      client_files: [],
      steps: [
        {
          name: 'client',
          value: 1
        },
        {
          name: 'vente',
          value: 2
        },
        {
          name: 'metre',
          value: 3
        },
        {
          name: 'material',
          value: 4
        },
        {
          name: 'hotel',
          value: 5
        },
        {
          name: 'pose',
          value: 6
        },
        {
          name: 'finition',
          value: 7
        }
      ],
      pose_id: null,
      client: null,
      c_firstname: null,
      c_lastname: null,
      address1: null,
      address2: null,
      zip: null,
      city: null,
      phone: null,
      portable: null,
      email: null,
      origin: null,
      vent: null,
      funding_approved: 1,
      financement: '',
      uploadedFinanceFiles: null,
      uploadError: null,
      client_notes: '',
      // payment: null,

      logement: '',
      logements: [],
      salesperson: null,

      product_group1: 0,
      product_group2: null,
      product_group: [],
      product_family: null,

      material: null,

      vente_notes: '',
      cost: 0,

      installer: null,

      metre_files: [{ name: 'file1', url: '' }],
      client_files: [{ name: 'file2', url: '' }],
      metre_notes: '',
      metreperson: null,
      measurement_date: null,
      hours: 23,
      minutes: 59,

      providers: [{ id: 1, name: 'Provider 1', origin: 'France' }],
      provider: null,
      provider_postal: null,
      material_pose: null,
      hotel: null,
      reservation_no: null,
      hotel_notes: null,
      poses: [],

      number_of_days: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      product_teams: 0,

      pose_days: 0,
      team_size: 2,

      fin_pose_days: 0,
      fin_team_size: 1,

      product_group_values: {},
      planning: [],
      needed_products: [],
      material_prices: [],
      edit_product_family: true,
      initial_planned_teams: [],
      initial_planned_finition_teams: [],
      vente_price_summary: null,
      note_public: '',
      note_finition: '',
      materials: [],
      collect_cheque: 0,
      // client_files: [],
      client_pose_files: [],
      metre_pose_files: [],
      pose_download_files: [],
      fin_materials: [],
      active_fin_tab: false,
      pose: {}, // Current POSE if EDIT only
      duplicate_pose: '', // Dupplicate POSE ID for Put
      duplicate_pose_planning: '', // Dupplicate POSE Planning for Put
      fileSaveStatus: 'saving',
      pending_files: [],
      // hotel_files: [],
      fin_download_files: [],
      pose_ref: ''
    }
  },
  watch: {
    agency_changed: function (val) {
      // this.clearForm('client');
      // this.clearForm('vente');
      // this.clearForm('metre');
      // this.clearForm('hotel');
      // this.$store.dispatch("loadClients", { limit: "", page: "" });
      this.$store.dispatch('loadClients', { limit: '', page: '' })
      this.$store.dispatch('loadMaterials')
      this.$store.dispatch('loadProductGroups')
      this.$store.dispatch('loadUsers')
      this.$store.dispatch('loadInstallers')
      this.$store.dispatch('loadMetres')
    },
    selectedAgency: function (val) {
      if (val != 0) {
        this.errors = []
      }
    },
    logement: function (val) {
      if (val != 0) {
        this.errors = []
      }
    },
    financement: function (val) {
      if (val != 0) {
        this.errors = []
      }
    },
    salesperson: function (val) {
      if (val != 0) {
        this.venteErrors = []
      }
    },
    material: function (val) {
      if (val != 0) {
        this.venteErrors = []
      }
    },
    product_group_values: function (val) {
      if (val != 0) {
        this.venteErrors = []
      }
    },

    metreperson: function (val) {
      if (val != 0) {
        this.errors = []
      }
    },
    installer: function (val) {
      if (val != 0) {
        this.errors = []
      }
    },
    client: function (val) {
      if (val.length < 1) {
        this.logement = null
        this.logements = []
        this.c_firstname = ''
        this.c_lastname = ''
        this.address1 = ''
        this.address2 = ''
        this.address2 = ''
        this.zip = ''
        this.city = ''
        this.phone = ''
        this.portable = ''
        this.email = ''
        this.origin = ''
      } else {
        this.errors = []
        this.fetchLogementdata(val[0])

        this.clients.find(object => {
          if (object.id == val[0]) {
            this.c_firstname = object.name.split(' ')[0]
            this.c_lastname = object.name.split(' ')[1]
            this.logements.push({
              id: 'default-' + object.id,
              val: 'Default'
            })
          }
        })
      }
    },
    logement: function (val) {
      this.errors = []
      let result = JSON.stringify(val).includes('default')
      if (result) {
        this.clients.find(object => {
          if (object.id == val.split('-')[1]) {
            this.c_firstname = object.name.split(' ')[0]
            this.c_lastname = object.name.split(' ')[1]
            this.address1 = object.address1
            this.address2 = object.address2
            // this.address2 = object.address2;
            this.zip = object.zip
            this.city = object.city
            this.phone = object.phone
            this.portable = object.portable
            this.email = object.email
            this.origin = object.name_alias
          }
        })
      } else {
        this.logements.find(object => {
          if (object.id == val) {
            this.address1 = object.address1
            this.address2 = object.address2
            this.address2 = object.address2
            this.zip = object.zip
            this.city = object.city
            this.phone = object.phone
            this.portable = object.portable
            this.email = object.email
            this.origin = object.name_alias
          }
        })
      }
    }
  },
  unmounted() {
    localStorage.removeItem('activeTabName')
  },
  mounted() {
    try {
      this.pose_id = this.$route.params.id
      if (this.pose_id) {
        this.fetchPoseData()
      }
    } catch (e) {
      this.pose_id = null
    }
    this.reset()
    // New Version 0.0.2
    this.$store.dispatch('loadHotels')
    this.$store.dispatch('loadPayments')
    this.$store.dispatch('loadClients', { limit: '', page: '' })
    this.$store.dispatch('loadMaterials')
    this.$store.dispatch('loadProductGroups')
    this.$store.dispatch('loadUsers')
    this.$store.dispatch('loadInstallers')
    this.$store.dispatch('loadAllUsers')
    this.product_groups.map(item => {
      this.product_group_values[item.id] = false
    })
    this.material_requests.push({
      vendors: [],
      materials: [],
      provider_postal: null
    })
    this.hotel_requests.push({
      hotels: [],
      reservation_no: '',
      hotel_notes: ''
    })
    if (localStorage.getItem('activeTabName')) {
      this.setActiveTabName(localStorage.getItem('activeTabName'))
    }
  },

  methods: {
    downloadFile(url, filename) {
      var xhr = new XMLHttpRequest()
      xhr.open('GET', url, true)
      xhr.responseType = 'blob'

      xhr.onload = function () {
        if (xhr.status === 200) {
          var tempLink = document.createElement('a')
          tempLink.href = window.URL.createObjectURL(xhr.response)
          tempLink.download = filename
          tempLink.click()

          setTimeout(function () {
            window.URL.revokeObjectURL(tempLink.href)
            tempLink.remove()
          }, 0)
        }
      }

      xhr.send()
    },
    // async removePoseFile(name){
    //   this.$swal({
    //     title: `Êtes-vous sûr ? Vous êtes sur le point de supprimer le fichier ${name}`,
    //     text: "Vous ne pourrez pas revenir en arrière !",
    //     type: "warning",
    //     showCancelButton: true,
    //     confirmButtonColor: "#3085d6",
    //     cancelButtonColor: "#d33",
    //     confirmButtonText: "Oui, supprimez-le !",
    //     cancelButtonText: "Annuler"
    //   }).then(async (result) => {
    //     if (result.value) {
    //       const fileInput = document.getElementById('attach');
    //         // console.log(fileInput)
    //         fileInput.value = '';
    //           // Remove the file from the pending_files array
    //         this.pending_files = this.pending_files.filter(file => file.filename !== name);
    //       if (!this.pose_ref) {
    //     // Remove the file from the browser directly
    //     this.client_pose_files.splice(this.client_files.findIndex(item => item.name == name), 1);
    //   }
    //   else {
    //       let header = {
    //           "Content-Type": "application/json",
    //           "Accept": "application/json",
    //           DOLAPIKEY: JSON.parse(window.localStorage.getItem("user-info")).data.success.token,
    //         };
    //         let api_url = `documents?modulepart=installation&original_file=installation/${this.pose_ref}/${name}`;
    //         axios.delete(api_url, {headers: header,} ).then(response => {
    //           this.client_pose_files.splice(this.client_files.findIndex(item => item.name == name), 1);
    //           this.metre_pose_files.splice(this.client_files.findIndex(item => item.name == name), 1);
    //           this.pose_download_files.splice(this.client_files.findIndex(item => item.name == name), 1);
    //           this.fin_download_files.splice(this.client_files.findIndex(item => item.name == name), 1);
    //           this.hotel_files.splice(this.client_files.findIndex(item => item.name == name), 1);
    //         }).catch(error => {
    //           console.log(error);
    //         });
    //     }
    //   }
    //   });

    // },
    async removePoseFile(index, name) {
      this.$swal({
        title: 'Êtes-vous sûr ?',
        // text: `Vous êtes sur le point de supprimer le fichier ${this.client_pose_files[index].name}`,
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Oui, supprimez-le !',
        cancelButtonText: 'Annuler'
      }).then(async result => {
        if (result.value) {
          // const fileInput = document.getElementById("attach");
          // fileInput.value = "";

          // Remove the file from the pending_files array
          this.pending_files.splice(index, 1)

          if (!this.pose_ref) {
            // Remove the file from the browser directly
            this.client_pose_files.splice(index, 1)
          } else {
            let header = {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              DOLAPIKEY: JSON.parse(window.localStorage.getItem('user-info'))
                .data.success.token
            }
            let api_url = `documents?modulepart=installation&original_file=installation/${this.pose_ref}/${name}`
            axios
              .delete(api_url, { headers: header })
              .then(response => {
                this.client_pose_files.splice(index, 1)
                this.metre_pose_files.splice(index, 1)
                this.pose_download_files.splice(index, 1)
                this.fin_download_files.splice(index, 1)
                this.hotel_files.splice(index, 1)
              })
              .catch(error => {
                console.log(error)
              })
          }
        }
      })
    },
    async addFilterMaterials(id) {
      let url = '/products?category=' + id
      let result = null
      try {
        result = await ApiCallService.get(url)
      } catch (error) {
        result = null
      }
      if (result) {
        result.data.forEach(material => {
          this.materials.push({
            id: material.id,
            name: material.label,
            label: material.label,
            value: material.id,
            price: material.price,
            price_ttc: material.price_ttc,
            category: id
          })
        })
      }
    },
    async changeProductGroup(id, index) {
      if (this.product_group_values[id]) {
        await this.addFilterMaterials(id)
      } else {
        this.materials.forEach(material => {
          if (material.category == id) {
            this.cost = 0
            this.vente_price_summary = null
            this.materials.splice(this.materials.indexOf(material), 1)
            this.material = null
          }
        })
      }
    },

    vendorSubmitted(vendor) {
      this.$store.dispatch('loadClients', { limit: '', page: '' })
    },
    changeVendorSelect(event, material_request) {
      this.vendors.find(object => {
        if (object.id == event.target.value) {
          material_request.provider_postal = object.postal_code
        }
      })
    },
    changeHotelSelect(event, hotel_request) {
      this.hotels.find(object => {
        if (object.id == event.target.value) {
          hotel_request.reservation_no = object.id
        }
      })
    },
    handleTTCInput(val) {
      this.cost = 0
      const keys = Object.keys(this.vente_price_summary)
      keys.map(key => {
        this.cost += parseFloat(
          this.vente_price_summary[key].price_ttc_editable
        )
      })
    },
    materialChange(event) {
      if (this.vente_price_summary == null) {
        this.vente_price_summary = {}
      }
      this.materials.find(object => {
        if (object.id == event) {
          this.vente_price_summary[event] = {
            label: object.label,
            price_ttc: object.price_ttc,
            price_ttc_editable: object.price_ttc
          }
          this.cost += parseFloat(object.price_ttc)
        }
      })
    },
    materialRemove(event) {
      try {
        this.cost -= parseFloat(this.vente_price_summary[event].price_ttc)
        delete this.vente_price_summary[event]
      } catch {
        this.cost = 0
      }
    },
    getTimeStamp(date) {
      var localTime = moment.utc(date).toDate()
      localTime = moment(localTime).format('MM/DD/YYYY')
      return moment(localTime).format('X')
    },
    getDateTimeStamp(date, hours, minutes) {
      let localTime = moment(date)
      localTime = localTime.add(hours, 'hours').add(minutes, 'minutes')
      return localTime.format('X')
    },
    addMaterials() {
      this.material_requests.push({
        vendors: [],
        materials: []
      })
    },
    addHotels() {
      this.hotel_requests.push({
        hotels: [],
        reservation_no: '',
        hotel_notes: ''
      })
    },
    removeMaterials() {
      if (this.material_requests.length > 1) {
        this.material_requests.pop()
      }
    },
    removeHotels() {
      if (this.hotel_requests.length > 1) {
        this.hotel_requests.pop()
      }
    },
    makePlannigFormat(data) {
      let planning = {}
      for (let i = 0; i < data.length; i++) {
        planning[data[i].id] = data[i]
      }
      return planning
    },
    changeDayType(item, day_id, team_index, type) {
      item['days'][day_id]['teams'][team_index].day_type = type
    },
    changeDaySize(item, day_index, team_index, type) {
      let team_size = item['days'][day_index]['teams'][team_index].team_size
      if (type == 'add') {
        if (team_size < 3) {
          item['days'][day_index]['teams'][team_index].team_size += 1
        }
      } else {
        if (team_size > 1) {
          item['days'][day_index]['teams'][team_index].team_size -= 1
        }
      }
    },
    removeTeam(item, day_index) {
      if (item['days'][day_index].team_number == item.no_of_teams) {
        item.no_of_teams -= 1
      }
      item['days'][day_index].team_number -= 1
      item['days'][day_index]['teams'].pop()
    },
    async fetchLogementdata(client_id) {
      this.logements = []

      let url =
        'contacts?sortfield=t.rowid&sortorder=ASC&limit=100&thirdparty_ids=' +
        client_id
      let result = null
      try {
        result = await ApiCallService.get(url)
      } catch (error) {
        result = null
      }
      if (result) {
        result.data.map(item => {
          this.logements.push({
            id: parseInt(item.id),
            val: item.lastname,
            value: item.id,
            address1: item.address,
            address2: item.town,
            city: item.town,
            zip: item.zip,
            phone: item.phone_mobile,
            portable: item.phone_mobile,
            email: item.email,
            name_alias: item.origin
          })
        })
      }
    },
    inc(property, obj, amt, item) {
      if (property == 'pose_days') {
        item.no_of_days += parseInt(amt)
        var size_days = Object.keys(item['days']).length + 1
        if (size_days == 1) {
          item.team_number = 0
        }
        if (item.team_number == undefined) {
          item.team_number = 0
        }
        item['days'][size_days] = {
          team_number: parseInt(item.team_number),
          teams: [],
          id: size_days
        }
      }
      if (property == 'no_of_teams') {
        item.team_number = parseInt(item.team_number) + parseInt(amt)
        obj.no_of_teams += parseInt(amt)
        item['teams'].push({
          team_size: parseInt(this.team_size),
          day_type: 'J'
        })
      }
    },
    dec(property, obj, amt, item) {
      if (property == 'pose_days') {
        if (item.no_of_days > 0) {
          item.no_of_days -= amt
          var size_days = Object.keys(item['days']).length
          delete item['days'][size_days]
        }
      }
      if (property == 'no_of_teams') {
        if (item.team_number > 0) {
          item.no_of_teams -= amt
          item.team_number -= amt
          item['teams'].pop()
        }
      }
    },
    incFin(property, obj, amt, item) {
      if (property == 'pose_days') {
        item.no_of_days += amt
        var size_days = Object.keys(item['days']).length + 1
        item['days'][size_days] = {
          team_number: item.no_of_teams,
          teams: [],
          id: size_days
        }
      }
      if (property == 'no_of_teams') {
        item.team_number += amt
        item['teams'].push({
          team_size: this.team_size,
          day_type: 'J'
        })
      }
    },
    decFin(property, obj, amt, item) {
      if (property == 'pose_days') {
        item.no_of_days -= amt
        var size_days = Object.keys(item['days']).length
        delete item['days'][size_days]
      }
      if (property == 'no_of_teams') {
        item.no_of_teams -= amt
        item['teams'].pop()
      }
    },
    onValueSelect(value) {
      this.selectedWrestler = value
    },
    setActiveTabName(name) {
      if (name !== 'client' && !this.client) {
        this.setActiveTabName('client')
        return
      }
      this.activeTabName = name
      this.currentstep = this.steps.find(step => step.name === name).value
      localStorage.setItem('activeTabName', this.activeTabName)
    },
    displayContents(name) {
      return this.activeTabName === name
    },
    checkForm: function (e) {
      if (
        this.client &&
        this.logement &&
        this.financement &&
        this.$store.state.selectedAgencyID
      ) {
        return true
      }

      this.errors = []
      let isClientEmpty = true
      try {
        isClientEmpty = Object.keys(this.client).length === 0
      } catch (e) {
        isClientEmpty = true
      }

      if (!this.client || isClientEmpty) {
        this.errors.push('Veuillez sélectionner un Client.')
      }
      if (this.$store.state.selectedAgencyID == null) {
        this.errors.push('Veuillez sélectionner une agence')
      }
      if (!this.logement) {
        this.errors.push('Veuillez sélectionner un logement')
      }
      if (!this.financement) {
        this.errors.push('Veuillez sélectionner un moyen de financement.')
      }
      return false
    },
    checkForm2: function (selected_productgroups) {
      if (
        this.salesperson &&
        selected_productgroups.length &&
        this.material.length
      ) {
        return true
      }
      this.venteErrors = []

      if (!this.salesperson) {
        this.venteErrors.push('Sélectionnez vendeur.')
      }
      if (!selected_productgroups.length) {
        this.venteErrors.push('Sélectionnez les catégories.')
      }
      if (!this.material.length) {
        this.venteErrors.push('Sélectionnez le matériau')
      }
      return false
    },
    getUserImg(id) {
      let img = ''
      this.installers.forEach(installer => {
        if (installer.id == id) {
          img = installer.photo
        }
      })
      return img
    },

    clearForm(name) {
      if (name === 'client') {
        this.client_files = [{ name: 'file2', url: '' }]
        this.logement = ''
        this.financement = ''
        this.collect_cheque = 0
        this.funding_approved = 0
        this.client_notes = ''
      }
      if (name === 'vente') {
        this.salespersons = []
        this.product_group_values = {}
        this.material = []
        this.cost = 0
        this.vente_notes = ''
        this.vente_price_summary = {}
      }
      if (name === 'metre') {
        this.metre_files = [{ name: 'file1', url: '' }]
        this.measurement_date = ''
        this.hours = ''
        this.metre_notes = ''
        this.minutes = ''
        this.metrepersons = []
      }
      if (name === 'material') {
        this.material_requests = []
        this.material_requests.push({
          vendors: [],
          materials: [],
          provider_postal: null
        })
      }
      if (name === 'hotel') {
        this.hotel_requests = []
        this.hotel_requests.push({
          hotels: [],
          reservation_no: '',
          hotel_notes: ''
        })
      }
      if (name == 'pose') {
        this.pose_categories_planning =
          this.$store.state.pose_categories_planning
        this.note_public = ''
        this.$router.go()
      }
    },
    async uploadFile(payload) {
      // console.log(payload,"pay")
      await axios
        .post('installationapi/upload', payload, {
          headers: {
            'Content-Type': 'multipart/form-data',
            DOLAPIKEY: JSON.parse(window.localStorage.getItem('user-info')).data
              .success.token
          }
        })
        .then(response => {
          this.$swal.fire({
            title: 'Téléchargé',
            text: 'Fichier téléchargé avec succès',
            type: 'success',
            confirmButtonText: 'OK'
          })
        })
    },

    async submitClient() {
      let start_date = this.pose_date
        ? moment(this.pose_date[0]).format('YYYY-MM-DD')
        : ''
      let end_date = this.pose_date
        ? moment(this.pose_date[1]).format('YYYY-MM-DD')
        : ''
      let validate_form = this.checkForm()

      if (validate_form) {
        let to = 'vente'
        let payload = {
          module: 'installation',
          fk_soc: this.client[0],
          payment_status: this.funding_approved,
          payment_method: this.financement,
          note_client: this.client_notes,
          fk_agency_id:
            this.$store.state.selectedAgencyID == 0
              ? null
              : this.$store.state.selectedAgencyID,
          property_type: this.logement,
          start_date: start_date,
          end_date: end_date,
          status: this.pose_status,
          validate: 1 ? this.pose_validation : 0,
          photo_status: this.pose_ddp_photos,
          need_material: this.pose_material_commande,
          need_equipment: this.pose_material_receptionne,
          file_status: this.pose_ddp,
          after_sales: this.after_sales,
          collect_cheque: this.collect_cheque
        }
        const resp = await this.updateORcreatePose(payload, to, 'client')

        let p_id = resp.id
        this.pose_ref = resp.ref

        if (this.fileSaveStatus == 'later') {
          this.pending_files.forEach(file => {
            this.uploadFile({
              filename: file.filename,
              id: p_id,
              fileencoding: 'base64',
              filecontent: file.filecontent,
              overwriteifexists: 1
            })
          })
        }
        this.fileSaveStatus = ''
        this.pending_files = []
        // this.pose_ref =
      }
    },

    // submit vente
    submitVente() {
      let selected_productgroups = Object.keys(
        this.product_group_values
      ).filter(n => this.product_group_values[n])
      let active_productgroups = []
      let validate_form = this.checkForm2(selected_productgroups)

      if (validate_form) {
        this.product_groups.map(item => {
          if (selected_productgroups.includes(item.id)) {
            active_productgroups.push(item)
          }
        })
        if (this.planning.length == 0) {
          this.$store.dispatch(
            'createPoseCategoriesPlaning',
            active_productgroups
          )
        } else {
          this.$store.dispatch('loadPoseCategoriesPlaning', this.planning)
        }
        if (this.needed_products.length == 0) {
          this.$store.dispatch(
            'createFinitionCategoriesPlaning',
            active_productgroups
          )
        } else {
          this.$store.dispatch(
            'loadFinitionCategoriesPlaning',
            this.needed_products
          )
        }

        let to = 'metre'
        let user_ids = []
        this.salesperson.forEach(sales => {
          user_ids.push(parseInt(sales))
        })

        let payload = {
          user_ids: user_ids,
          products: this.material,
          note_sale: this.vente_notes,
          estimated_cost: parseFloat(this.cost),
          categories: selected_productgroups
        }
        this.pose_categories_planning =
          this.$store.state.pose_categories_planning
        this.finition_categories_planning =
          this.$store.state.finition_categories_planning
        this.updateORcreatePose(payload, to, 'vente')
      }
    },

    submitMetre() {
      let selected_productgroups = Object.keys(
        this.product_group_values
      ).filter(n => this.product_group_values[n])
      let to = 'material'
      let measurement_date = this.getDateTimeStamp(
        this.measurement_date,
        this.hours,
        this.minutes
      )
      let metre_ids = []
      this.metreperson.forEach(metre => {
        // console.log("jay",metre)
        metre_ids.push(parseInt(metre))
      })
      let payload = {
        metre_ids: metre_ids,
        note_measurement: this.metre_notes,
        measurement_date: measurement_date
      }
      this.updateORcreatePose(payload, to, 'metre')
    },

    submitMaterial() {
      let m_data = []
      let to = 'hotel'
      let payload = {
        additional_products: this.material_requests
      }
      this.updateORcreatePose(payload, to, 'material')
    },

    submitHotel() {
      let to = 'pose'
      let payload = {
        note_hotel: this.hotel_notes,
        reservation_number: this.reservation_no,
        hotel: this.hotel_requests
      }
      this.updateORcreatePose(payload, to, 'hotel')
    },

    submitPose() {
      // if(!this.pose_date) {
      //   this.$swal({
      //     title: "Erreur",
      //     text: "Veuillez sélectionner la dates d'installation souhaitées par le client",
      //     type: "error",
      //     confirmButtonText: "Ok"
      //   });
      //   return;
      // }
      let start_date = this.pose_date
        ? moment(this.pose_date[0]).format('YYYY-MM-DD')
        : ''
      let end_date = this.pose_date
        ? moment(this.pose_date[1]).format('YYYY-MM-DD')
        : ''
      let plannig_data = this.makePlannigFormat(this.pose_categories_planning)
      if (this.pose_status == 0 || this.pose_status == 1) {
        let pose_days = []
        const keys = Object.keys(this.pose_categories_planning)
        keys.forEach(key => {
          pose_days.push(this.pose_categories_planning[key].no_of_days)
        })

        this.pose_days = pose_days.length > 0 ? Math.max(...pose_days) : 0
        this.$swal({
          title: 'Êtes-vous sûr',
          text:
            'Vous allez créer / mettre à jour la pose, celle-ci comprend' +
            this.pose_days +
            " jour(s) d'installation ",
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#038638',
          cancelButtonColor: '#ff9501',
          cancelButtonText: 'Annuler',
          confirmButtonText: 'Oui, créer / modifier la pose!'
        }).then(result => {
          if (result.value) {
            let to = 'client'
            let payload = {
              planning: plannig_data,
              estimated_days: this.pose_days,
              note_public: this.note_public,
              start_date: start_date,
              end_date: end_date
            }
            this.updateORcreatePose(payload, to, 'pose')
            this.$swal({
              title: 'Pose enregistrée !',
              text: 'Votre pose a été enregistrée avec succès',
              icon: 'success',
              button: 'OK'
            })
          }
        })
      } else {
        this.$swal(
          'Cette pose est déjà attribuée aux installateurs. Toute modification de la pose ne sera pas reflétée.'
        )
        let to = 'client'
        let payload = {
          estimated_days: this.pose_days,
          note_public: this.note_public,
          start_date: start_date,
          end_date: end_date
        }
        this.updateORcreatePose(payload, to, 'pose')
      }
    },

    submitFin() {
      let plannig_data = this.makePlannigFormat(
        this.finition_categories_planning
      )

      let to = 'client'
      const isEmpty = Object.keys(plannig_data).length === 0
      let pose_days = []
      const keys = Object.keys(this.finition_categories_planning)
      keys.forEach(key => {
        pose_days.push(this.finition_categories_planning[key].no_of_days)
      })
      this.pose_days = pose_days.length > 0 ? Math.max(...pose_days) : 0
      let payload = {
        needed_products: this.fin_materials,
        note_finition: this.note_finition,
        finition_planning: plannig_data,
        estimated_days: this.pose_days
      }
      if (!isEmpty) {
        payload['status'] = 3
      }
      this.$swal({
        title: 'Êtes-vous sûr',
        text: 'Vous allez créer une finition avec ces produits',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#038638',
        cancelButtonColor: '#ff9501',
        cancelButtonText: 'Annuler',
        confirmButtonText: 'Oui, créer la finition !'
      }).then(result => {
        if (result.value) {
          this.updateORcreatePose(payload, to, 'finition')
          this.$swal({
            title: 'Fintion notée ',
            text: 'Fintion notée avec succès',
            icon: 'success',
            button: 'OK'
          })
        }
      })
    },
    // Update OR Create POSE data here
    async updateORcreatePose(payload, to, from) {
      const userInfo = JSON.parse(window.localStorage.getItem('user-info'))
      const token = userInfo?.data?.success?.token
      const headers = { DOLAPIKEY: token }

      let pose_id = from !== 'duplicate' ? this.pose_id : this.duplicate_pose

      if (from !== 'duplicate') {
        const start_date = this.pose_date
          ? moment(this.pose_date[0]).format('YYYY-MM-DD')
          : ''
        const end_date = this.pose_date
          ? moment(this.pose_date[1]).format('YYYY-MM-DD')
          : ''

        Object.assign(payload, {
          start_date,
          end_date,
          validate: this.pose_validation ? 1 : 0,
          photo_status: this.pose_ddp_photos,
          need_material: this.pose_material_commande,
          need_equipment: this.pose_material_receptionne,
          file_status: this.pose_ddp,
          after_sales: this.after_sales,
          collect_cheque: this.collect_cheque
        })
      }

      if (from !== 'client' && !this.pose_id && !this.client) {
        this.setActiveTabName('client')
        return
      }

      try {
        let response

        if (pose_id) {
          response = await axios.put(
            `/installationapi/installations/${pose_id}`,
            payload,
            { headers }
          )
        } else {
          response = await axios.post(
            '/installationapi/installations',
            payload,
            { headers }
          )
          this.pose_id = response.data.id
        }

        this.setActiveTabName(to)

        return response.data
      } catch (error) {
        console.error('Error updating or creating Pose:', error)
        this.setActiveTabName(to)
      }
    },

    closeAlert(event) {
      this.errors = []
    },

    async fetchPoseData() {
      let url = `/installationapi/installations/` + this.pose_id
      let result = null
      try {
        result = await ApiCallService.get(url)
      } catch (error) {
        result = null
      }

      if (result) {
        let pose = result.data
        this.pose = pose
        this.pose_ref = pose.ref
        this.$emit('clicked-show-pose', pose)
        var now = moment().format('YYYY-MM-DD').valueOf()
        let start_date = moment
          .unix(pose.start_date)
          .format('YYYY-MM-DD')
          .valueOf()
        const dateIsAfter = moment(now).isAfter(moment(start_date))

        if (dateIsAfter && pose.status != 0) {
          this.active_fin_tab = true
        }
        if (pose.status == 0) {
          this.active_fin_tab = false
        }
        if (pose.status == 3 || pose.status == 4 || pose.status == 5) {
          this.active_fin_tab = true
        }
        if (pose.after_sales == 1) {
          this.active_fin_tab = false
        }

        this.client = [pose.fk_soc]
        if (parseInt(pose.payment_status) === 1) {
          this.funding_approved = true
        } else {
          this.funding_approved = false
        }
        this.financement = pose.payment_method
        this.client_notes = pose.note_client
        this.salesperson = JSON.parse(pose.user_ids)
        let material = []
        try {
          JSON.parse(pose.products).forEach(item => {
            material.push(parseInt(item))
          })
        } catch (error) {
          material = []
        }
        if (pose.categories) {
          JSON.parse(pose.categories).forEach(category => {
            this.product_group_values[category] = true
            try {
              this.addFilterMaterials(category)
            } catch (error) {
              console.log('Error')
            }
          })
        }
        this.material = material

        this.vente_notes = pose.note_sale
        this.cost = pose.estimated_cost ? parseFloat(pose.estimated_cost) : 0
        this.logement = pose.property_type

        this.metreperson = JSON.parse(pose.metre_ids)
        this.metre_notes = pose.note_measurement
        try {
          this.material_requests = pose.additional_products
            ? JSON.parse(pose.additional_products)
            : this.material_requests
        } catch (error) {
          this.material_requests = this.material_requests
        }

        this.planning = pose.planning

        this.makePlannedTeamData(pose.planning)
        const isEmpty = Object.keys(pose.planning).length === 0
        if (!isEmpty) {
          this.edit_product_family = false
          this.$store.dispatch('loadPoseCategoriesPlaning', pose.planning)
          this.pose_categories_planning =
            this.$store.state.pose_categories_planning
        } else {
          this.edit_product_family = true
          this.$store.dispatch(
            'createPoseCategoriesPlaning',
            this.product_groups
          )
          this.pose_categories_planning =
            this.$store.state.pose_categories_planning
        }
        this.needed_products = pose.needed_products
          ? JSON.parse(pose.needed_products)
          : []
        this.fin_materials = pose.needed_products
          ? JSON.parse(pose.needed_products)
          : []
        this.makePlannedFinitionTeamData(pose.finition_planning)
        const isEmptyFin = pose.finition_planning
          ? Object.keys(pose.finition_planning).length === 0
          : true

        if (!isEmptyFin) {
          this.$store.dispatch(
            'loadFinitionCategoriesPlaning',
            pose.finition_planning
          )
          this.finition_categories_planning =
            this.$store.state.finition_categories_planning
        } else {
          this.$store.dispatch(
            'createFinitionCategoriesPlaning',
            this.$store.state.pose_categories_planning
          )
          this.finition_categories_planning =
            this.$store.state.finition_categories_planning
        }
        let pose_days = []
        const keys = Object.keys(this.pose_categories_planning)
        keys.forEach(key => {
          pose_days.push(this.pose_categories_planning[key].no_of_days)
        })

        this.pose_days = pose_days.length > 0 ? Math.max(...pose_days) : 0
        this.pose_status = pose.status ? parseInt(pose.status) : 0
        this.note_public = pose.note_public
        // this.hotel_notes = pose.note_hotel; deprecated due to new filed with all data

        // this.hotel = pose.hotel;

        try {
          this.hotel_requests = pose.hotel
            ? JSON.parse(pose.hotel)
            : this.hotel_requests
        } catch (error) {
          this.hotel_requests = this.hotel_requests
        }

        this.reservation_no = pose.reservation_number
        if (parseInt(pose.collect_cheque) === 1) {
          this.collect_cheque = true
        } else {
          this.collect_cheque = false
        }
        if (pose.files.length > 0) {
          pose.files.filter(file => {
            if (file.name.startsWith('client_fichier')) {
              this.client_pose_files.push(file)
            }
            if (file.name.startsWith('metre_fichier')) {
              this.metre_pose_files.push(file)
            }
            if (file.name.startsWith('pose_fichier')) {
              this.pose_download_files.push(file)
            }
            if (file.name.startsWith('hotel_fichier__')) {
              this.hotel_files.push(file)
            }
            if (file.name.startsWith('fin_fichier')) {
              this.fin_download_files.push(file)
            }
          })
        }
        if (pose.measurement_date) {
          this.measurement_date = moment
            .unix(pose.measurement_date)
            .format('YYYY-MM-DD')
            .valueOf()
          this.hours = moment.unix(pose.measurement_date).hour()
          this.minutes = moment.unix(pose.measurement_date).minutes()
        }
      }
    },
    makePlannedTeamData(planning) {
      try {
        const keys = Object.keys(planning)
        this.initial_planned_teams = []
        keys.forEach((key, index) => {
          const days_keys = Object.keys(planning[key].days)
          days_keys.forEach((k, i) => {
            planning[key].days[k].teams.forEach(team => {
              this.initial_planned_teams.push({
                day_type: team.day_type,
                team_size: parseInt(team.team_size),
                color: planning[key].color,
                members: team.members
              })
            })
          })
        })
      } catch (error) {
        this.initial_planned_teams = []
      }
    },
    makePlannedFinitionTeamData(planning) {
      try {
        const keys = Object.keys(planning)
        this.initial_planned_finition_teams = []
        keys.forEach((key, index) => {
          const days_keys = Object.keys(planning[key].days)
          days_keys.forEach((k, i) => {
            planning[key].days[k].teams.forEach(team => {
              this.initial_planned_finition_teams.push({
                day_type: team.day_type,
                team_size: team.team_size,
                color: planning[key].color,
                members: team.members
              })
            })
          })
        })
      } catch (error) {
        this.initial_planned_finition_teams = []
      }
    },

    getUserName(id) {
      let name = {
        first_name: '',
        last_name: ''
      }
      this.installers.forEach(installer => {
        if (installer.id == id) {
          name['first_name'] = installer.firstname
          name['last_name'] = installer.lastname
        }
      })
      return name
    },
    reset() {
      // reset form to initial state
      this.uploadedFinanceFiles = []
      this.uploadError = null
    },

    // File Upload
    filesChange(fieldName, fileList, $event, name) {
      let previewImage = ''
      if (!fileList.length) return
      // append the files to FormData
      const file = $event.target.files[0]
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = event => {
        previewImage = event.target.result.split(';base64,')[1]
        Array.from(Array(fileList.length).keys()).map(x => {
          if (this.pose_id) {
            this.fileSaveStatus = 'saving'
            this.uploadFile({
              filename: name + '__' + fileList[x].name,
              id: this.pose_id,
              fileencoding: 'base64',
              filecontent: previewImage,
              overwriteifexists: 1
            })
          } else {
            this.fileSaveStatus = 'later'
            this.pending_files.push({
              filename: name + '__' + fileList[x].name,
              // id: this.pose_id,
              fileencoding: 'base64',
              filecontent: previewImage,
              overwriteifexists: 1
            })
          }
        })
      }

      if (name.startsWith('client_fichier')) {
        this.client_pose_files.push({
          name: name + '__' + file.name,
          url: file.url || URL.createObjectURL(file)
        })
      }
      if (name.startsWith('metre_fichier')) {
        this.metre_pose_files.push({
          name: name + '__' + file.name,
          url: file.url || URL.createObjectURL(file)
        })
      }
      if (name.startsWith('pose_fichier')) {
        this.pose_download_files.push({
          name: name + '__' + file.name,
          url: file.url || URL.createObjectURL(file)
        })
      }
      if (name.startsWith('hotel_fichier')) {
        this.hotel_files.push({
          name: name + '__' + file.name,
          url: file.url || URL.createObjectURL(file)
        })
      }
      if (name.startsWith('fin_fichier')) {
        this.fin_download_files.push({
          name: name + '__' + file.name,
          url: file.url || URL.createObjectURL(file)
        })
      }
    },

    // addFile() {
    //   this.metre_files.push({
    //     name: "",
    //     url: "",
    //   });
    // },
    // addFile1() {
    //   this.client_files.push({
    //     name: "",
    //     url: "",
    //   });
    // },

    // removeFile(index) {
    //   if (this.metre_files.length > 1) {
    //     this.metre_files.splice(index, 1);
    //   }
    // },
    // removeFile1(index) {
    //   if (this.client_files.length > 1) {
    //     this.client_files.splice(index, 1);
    //   }
    // },
    // Removing members from Planning or Finition Planning
    formatPlanningData(planning) {
      let planning_result = planning
      const keys = Object.keys(planning)
      keys.forEach((key, index) => {
        const days_keys = Object.keys(planning[key].days)
        days_keys.forEach((k, i) => {
          planning[key].days[k].teams.forEach((team, index) => {
            planning[key].days[k].teams[index] = {
              day_type: team.day_type,
              team_size: team.team_size,
              color: planning[key].color,
              team_id: ''
            }
          })
        })
      })
      return planning_result
    },
    // Duplicate POSE
    duplicatePose: function () {
      this.$swal({
        title: 'Dupliquer POSE',
        text: 'Êtes-vous sûr de vouloir dupliquer ce POSE ?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#DD6B55',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        closeOnConfirm: false,
        closeOnCancel: false
      }).then(result => {
        if (result.value) {
          this.fetchResult()
        } else {
          this.$swal.close()
        }
      })
    },
    duplicatePoseConfirm() {
      let pose = this.pose
      pose['status'] = 0
      let planning = pose.planning // Current Planning
      let finition_planning = pose.finition_planning // Current Planning

      pose['planning'] = this.formatPlanningData(planning)
      pose['finition_planning'] = this.formatPlanningData(finition_planning)

      let payload = {
        planning: this.formatPlanningData(planning),
        finition_planning: this.formatPlanningData(finition_planning)
      }
      delete pose['id']
      delete pose['ref']
      try {
        pose['additional_products'] = JSON.parse(pose.additional_products)
      } catch {
        console.log('No additional products')
      }
      try {
        pose['hotel'] = JSON.parse(pose.hotel)
      } catch {
        console.log('No hotel')
      }

      this.duplicate_pose_planning = payload
      let header = {
        DOLAPIKEY: JSON.parse(window.localStorage.getItem('user-info')).data
          .success.token
      }
      return axios
        .post(`installationapi/installations/`, pose, {
          headers: header
        })
        .then(response => {
          this.duplicate_pose = response.data
          return true
        })
        .catch(error => {
          return false
        })
        .then(resultBoolean => {
          return resultBoolean // for await purpose
        })
    },

    // Implementation
    async fetchResult() {
      let success = await this.duplicatePoseConfirm()
      if (success) {
        let to = 'client'
        let payload = this.duplicate_pose_planning
        // await this.updateORcreatePose(payload, to, "duplicate");
        this.$swal(
          'Duplication',
          'Duplication effectuée avec succès',
          'success'
        )
        this.$router.push('/')
      } else {
        this.$swal({
          title: 'Duplication failed',
          text: 'Please try again',
          type: 'error',
          confirmButtonText: 'OK',
          confirmButtonColor: '#DD6B55',
          closeOnConfirm: true
        })
      }
    }
  },
  computed: {
    ...mapState({
      clients: state => state.clients,
      vendors: state => state.vendors,
      all_materials: state => state.materials,
      product_groups: state => state.product_groups,
      salespersons: state => state.allSalesPersons,
      payments: state => state.payments,
      installers: state => state.installers,
      metrepersons: state => state.metrepersons,
      hotels: state => state.hotels,
      agencies: state => state.agencies,
      payments: state => state.payments
    }),
    ...mapGetters({
      selectedAgency: 'selectedAgencyID'
    })
  }
}
</script>
<style scoped>
.input-select {
  background: #fff;
}
.file-attachment {
  color: #9f137e;
}
/* client */
.dashboard-bot__tab--link.client:hover {
  opacity: 1;
  color: #cec520;
  border-bottom: 3px solid #cec520;
}
.dashboard-bot__tab--link.client.active {
  opacity: 1;
  color: #cec520;
  border-bottom: 3px solid #cec520;
}
.dashboard-bot__tab--link.client svg {
  fill: #cec520;
}
/* vente */
.dashboard-bot__tab--link.vente:hover {
  opacity: 1;
  color: #ffc400;
  border-bottom: 3px solid #ffc400;
}
.dashboard-bot__tab--link.vente.active {
  opacity: 1;
  color: #ffc400;
  border-bottom: 3px solid #ffc400;
}
.dashboard-bot__tab--link.vente svg {
  fill: #ffc400;
}
/* metre */
.dashboard-bot__tab--link.metre:hover {
  opacity: 1;
  color: #639a23;
  border-bottom: 3px solid #639a23;
}
.dashboard-bot__tab--link.metre.active {
  opacity: 1;
  color: #639a23;
  border-bottom: 3px solid #639a23;
}
.dashboard-bot__tab--link.metre svg {
  fill: #639a23;
}
/* material */
.dashboard-bot__tab--link.material:hover {
  opacity: 1;
  color: #b59a05;
  border-bottom: 3px solid #b59a05;
}
.dashboard-bot__tab--link.material.active {
  opacity: 1;
  color: #b59a05;
  border-bottom: 3px solid #b59a05;
}
.dashboard-bot__tab--link.material svg {
  fill: #b59a05;
}
/* pose */
.dashboard-bot__tab--link.pose:hover {
  opacity: 1;
  color: #7ebfbf;
  border-bottom: 3px solid #7ebfbf;
}
.dashboard-bot__tab--link.pose.active {
  opacity: 1;
  color: #7ebfbf;
  border-bottom: 3px solid #7ebfbf;
}
.dashboard-bot__tab--link.pose svg {
  fill: #7ebfbf;
}
/* Hotel */
.dashboard-bot__tab--link.hotel:hover {
  opacity: 1;
  color: #0f72a7;
  border-bottom: 3px solid #0f72a7;
}
.dashboard-bot__tab--link.hotel.active {
  opacity: 1;
  color: #0f72a7;
  border-bottom: 3px solid #0f72a7;
}
.dashboard-bot__tab--link.hotel svg {
  fill: #0f72a7;
}

.dashboard-bot__tab--link.active {
  font-size: 1rem;
  font-weight: 800;
}

button.clear {
  background: #ff9501;
  color: #fff;
}
button.clear:hover {
  color: #ff9501;
  background: #fff;
  border: 1px solid #ff9501;
}
a.back_list:hover {
  color: #374151;
  background: #fff;
  border: 1px solid #374151;
}
button.save_continue:hover {
  color: #038638;
  background: #fff;
  border: 1px solid #038638;
}
button.clear {
  border: 1px solid #ff9501;
}
button.save_continue {
  border: 1px solid #038638;
}
a.back_list {
  border: 1px solid #374151;
}
button.save_continue {
  background: #038638;
  color: #ffffff;
}
.dashboard__img-toggle-shape {
  background-color: #0f72a7;
}
.dashboard__img-toggle-shape.orange {
  background-color: orange;
}
.people {
  border-radius: 50%;
  color: white;
  background-color: #fff;
  text-align: center;
}
.product_code:checked {
  background-color: #9f137e;
}
.pose_box {
  border: 1px solid orange;
}
.pose_box label {
  color: orange;
}
.pose_box_blue {
  border: 1px solid #0f72a7;
}
.pose_box_blue label {
  color: #0f72a7;
}
/* Tab custom */
.dashboard-bot__tab--link.hotel.active {
  opacity: 1;
  color: #0f72a7;
  border-bottom: 3px solid #0f72a7;
  font-weight: bolder;
}
.dashboard-bot__tab--link {
  opacity: 0.7;
  font-size: 1rem;
  color: #000;
  font-weight: 300;
  text-transform: uppercase;
}
.bg-stpes-strong-active,
.switch input:checked + .slider {
  background-color: #9f137e !important;
}

.input-select {
  background: #fff;
}
.file-attachment {
  color: #9f137e;
}

button.clear {
  background: #ff9501;
  color: #fff;
}
button.clear:hover {
  color: #ff9501;
  background: #fff;
  border: 1px solid #ff9501;
}
a.back_list:hover {
  color: #374151;
  background: #fff;
  border: 1px solid #374151;
}
button.save_continue:hover {
  color: #038638;
  background: #fff;
  border: 1px solid #038638;
}
button.clear {
  border: 1px solid #ff9501;
}
button.save_continue {
  border: 1px solid #038638;
}
a.back_list {
  border: 1px solid #374151;
}

button.save_continue {
  background: #038638;
  color: #ffffff;
}
.dashboard__img-toggle-shape {
  background-color: #0f72a7;
}
.dashboard__img-toggle-shape.orange {
  background-color: orange;
}
.people {
  border-radius: 50%;
  color: white;
  background-color: #fff;
  text-align: center;
}
.product_code:checked {
  background-color: #9f137e;
}
.pose_box {
  border: 1px solid orange;
}
.pose_box label {
  color: orange;
}
.pose_box_blue {
  border: 1px solid #0f72a7;
}
.pose_box_blue label {
  color: #0f72a7;
}

.bg-stpes-active,
.bg-stpes-strong-active,
.switch input:checked + .slider {
  background-color: #9f137e !important;
}
.switch input:checked {
  background-color: #9f137e !important;
}
</style>
<style src="@vueform/multiselect/themes/default.css"></style>
