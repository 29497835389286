<template>
  <div>
    <!-- Begin - Dashboard Side Navbar -->

    <!-- Component Start -->

    <SideBarInstaller />
    <!-- Component End  -->

    <!-- End - Dashboard Side Navbar  -->

    <!-- Begin -- Nav Top Bar -->

    <TopBarInstaller :user_info="user_info" />

    <!-- End - Nav Top Bar -->

    <!-- Begin - Dashboard Content -->

    <!-- Change the left padding to match the side nav bar -->
    <main class="pl-48 pt-24 p-4 w-full h-full bg-gray-100">
        <div
          class="
            relative
            z-10
            flex
            items-baseline
            justify-between
            pt-6
            pb-6
            border-b border-gray-200
          "
          v-if="searchedPoses.length > 0"
        >
          <h1 class="text-4xl font-extrabold tracking-tight text-gray-900 uppercase">
            poses assorties - "{{query}}"
          </h1>
        </div>

        <section aria-labelledby="products-heading" class="pt-6 pb-24" v-if="searchedPoses.length > 0">
          <h2 id="products-heading" class="sr-only">Products</h2>

          <div class="flex flex-wrap space-y-4 lg:space-y-0">
            <!-- Filters -->
            <!-- Product grid -->
            <div class="w-full lg:w-12/12">
              <!-- Replace with your content -->

              <div
                class="
                  border-4 border-dashed border-gray-200
                  rounded-lg
                  mx-2
                  h-96
                  overflow-scroll
                  lg:h-full
                "
              >
                <table class="w-full text-base text-left text-gray-500" 
                  v-if="searchedPoses.length > 0">
                  <thead class="text-xs text-gray-700 uppercase bg-gray-50">
                    <tr>
                      <th scope="col" class="px-6 py-3">ID</th>
                      <th scope="col" class="px-6 py-3">Client</th>
                      <th scope="col" class="px-6 py-3">Financement</th>
                      <th scope="col" class="px-6 py-3">logement</th>
                      <th scope="col" class="px-6 py-3">Date D'Installation</th>
                      <th scope="col" class="px-6 py-3">Montant</th>
                      <th scope="col" class="px-6 py-3">Statut</th>
                      <th scope="col" class="px-6 py-3">
                        <span class="sr-only">Modifier</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      class="bg-white border-b"
                      v-for="pose in searchedPoses"
                      :key="pose"
                      >
                      <th
                        scope="row"
                        class="
                          px-6
                          py-4
                          font-medium
                          text-gray-900
                          whitespace-nowrap
                        "
                      >
                        {{ pose.id }}
                      </th>
                      <td class="px-6 py-4">
                        <router-link :to="{ path: '/installer' + pose.id }">
                          <span class="title">
                            <strong> {{ getClientDetails(pose.fk_soc) }} </strong>
                          </span>
                        </router-link
                        >                        
                      </td>
                      <td class="px-6 py-4">
                        {{ getPaymentDetails(pose.payment_method) }}
                      </td>
                      <td class="px-6 py-4">
                        {{ pose.property_type }}
                      </td>
                      <td class="px-6 py-4" v-if="pose.start_date">
                        {{ pose.start_date }}
                      </td>
                      <td class="px-6 py-4" v-else>
                        <span
                          class="text-red-500"
                        >
                          <strong>En Attente</strong>
                        </span>
                      </td>
                      <td class="px-6 py-4">
                        <span
                          :class="{
                            'text-green-500': pose.estimated_cost >= 1000,
                            'text-red-500': pose.estimated_cost < 0,
                          }"
                        >
                          <strong>{{ pose.estimated_cost }} </strong>
                        </span>
                      </td>
                      <td class="px-6 py-4">
                        <span
                        class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full bg-white border-2 uppercase last:mr-0 mr-1"
                          :class="{
                            'text-blue-500': pose.status == 3,
                            'text-green-500': pose.status == 2,
                            'text-red-500': pose.status == 1,
                            'text-gray-500': pose.status == 0,
                          }"
                        >
                          <strong v-if="pose.status == 0">Plannification En Attente</strong>
                          <strong v-if="pose.status == 1">Planned, Not assigned</strong>
                          <strong v-if="pose.status == 2">Planned, Assigned</strong>
                          <strong v-if="pose.status == 3">FINITION</strong>
                        </span>
                      </td>

                      <td class="px-6 py-4 text-right">
                        <router-link :to="{ path: '/installer' + pose.id }"
                          append
                          class="
                            dashboard-bot__form--formbtn
                            submit
                            save_continue
                            edit_link
                          "
                          >Details</router-link
                        >
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div v-else 
                    class="
                      text-center
                      overflow-scroll
                      lg:h-auto
                      py-20
                      ">   
                      <label class="text-center text-gray-500 py-2">
                      Aucune pose trouvée                      
                    </label><br/>
                    <router-link
                        :to="{ path: '/pose' }"
                        append
                        class="
                          dashboard-bot__form--formbtn
                          save_continue
                          edit_link mt-2
                        "
                        >Ajouter une pose</router-link>
                  </div>
              </div>
              <!-- /End replace -->
            </div>
          </div>

        </section>
         <div
          class="
            relative
            z-10
            flex
            items-baseline
            justify-between
            pt-6
            pb-6
            border-b border-gray-200
          " v-if="searchedPoses.length == 0"
        >
          <h1 class="text-4xl font-extrabold tracking-tight text-gray-900 uppercase">
            No results found  - "{{query}}""
          <router-link
                        :to="{ path: '/installer' }"
                        append
                        class="
                          dashboard-bot__form--formbtn
                          save_continue
                          edit_link mt-2
                        "
                        >Home</router-link>
          </h1>
        </div>
    </main>

    <!-- End - Dashboard Content -->
  </div>
</template>
<script>
import logo from "../assets/logo.png";
import installer_img from "../assets/installer.png";
import store from "@/store";
import TopBarInstaller from "@/components/TopBarInstaller.vue";
import SideBarInstaller from "@/components/SideBarInstaller.vue";
import { mapState } from "vuex";
import axios from "../http-common"; // <-- HTTP common
import moment from "moment";

export default {
  title: "SearchInstallerView",
  components: {
    TopBarInstaller,
    SideBarInstaller,
  },
  data() {
    return {
      logo,
      installer_img,
      name: "",
      selected_agency: "",
      user_info: {},
      searchedPoses: [],
      payment_methods: {
        '0': "Chèque",
        '1': "Virement bancaire",
        '2': "Espèce",
        '3': "Carte bancaire",
        '4': "Ordre de prélèvement",
        '5': "TIP",
        '6': "Cetelem",
        '7': "Financo",
        '8': "Domofinance",
        '9': "Franfinance",
        '10': "Projexio",
        }
      // payment_methods: {
      //   '0': "Bancontact",
      //       '1': "Credit card",
      //       '2': "Cheque",
      //       '3': "Factor",
      //       '4': "Giropay",
      //       '5': "iDeal",
      //       '6': "Klarna",
      //       '7': "LCR",
      //       '8': "Cash",
      //       '9': "Debit order",
      //       '10': "Sofort", 
      //       '11': "TIP", 
      //       '12': "Traite",
      //       '13': "Online Payment",
      //       '14': "Transfer",
      //   }
    };
  },
  methods: {
    getPaymentDetails(id) {
      return this.payment_methods[id];
    },
    getUserInfo() {
      let header = {
        DOLAPIKEY: JSON.parse(window.localStorage.getItem("user-info")).data.success.token,
      };
      axios
        .get("installationapi/user?includePermissions=1&includeGroups=1&includeLeaves=1", {
          headers: header,
        })
        .then((response) => {
          this.user_info = response.data;
        })
        .catch((error) => {
          this.user_info = {};
        });
    },
    async loadPoses (id) {
      console.log(id)
      return new Promise(resolve => {
        this.searchedPoses = [];
        let api_url = `/installationapi/installations?agency_id=`+ this.$store.state.selectedAgencyID;
        let data = [];
        this.installer_poses = [];
        axios.get(api_url, {
                headers: {
                    'DOLAPIKEY': JSON.parse(window.localStorage.getItem('user-info')).data.success.token,
                }
            })
            .then(response => response.data)
            .then(poses => {
              poses.forEach(pose => {
                const keys = Object.keys(pose.planning);
                  keys.forEach((key, index) => {
                    const day_keys = Object.keys(pose.planning[key].days);
                    day_keys.forEach((k, i) => {
                      const team_keys = Object.keys(pose.planning[key].days[k].teams);
                      team_keys.forEach((l, j) => {
                        if(pose.planning[key].days[k].teams[l].members){
                          const memb_keys = Object.keys(pose.planning[key].days[k].teams[l].members);
                          memb_keys.forEach((m, o) => {
                            // if(id == pose.planning[key].days[k].teams[l].members[m]){
                              if(pose.planning[key].days[k].teams[l].members[m]){
                              if(!data.includes(pose.id)){
                                let client_name = this.getClientDetails(pose.fk_soc);
                                if(this.searchedPoses.indexOf(pose) == -1){
                                    if (client_name.toLowerCase().includes(this.query.toLowerCase())) {
                                      this.searchedPoses.push(pose);
                                    }
                                }
                              }
                            }
                          });
                        }              
                      });
                    });
                  });
              });
            })
      resolve('resolved');
      });
    },
    getClientDetails(id) {
      let name = "";
      try {        
        this.clients.forEach((client) => {
          if (client.id == id) {
            name =  client.name
          }
        });
        return name;
      } catch (error) {
        return "Not found";
      }
    },

  },
  async beforeRouteUpdate (to, from) {
    this.searchedPoses = [];
    const authUser = JSON.parse(window.localStorage.getItem("user-info"));
    this.query = to.params.query;
    await this.loadPoses(authUser.id);
  },
  created() {
    this.query = this.$route.params.query;
    this.$store.dispatch("loadPoses", { limit: "", page: "" });
  },
  async mounted() {    
    
    const authUser = JSON.parse(window.localStorage.getItem("user-info"));
    await this.loadPoses(authUser.id);  // <-- Load poses for the user
    this.name = authUser.firstname + " " + authUser.lasttname;
    await this.getUserInfo();
  },
  computed: {
    ...mapState({
      agencies: (state) => state.agencies,
      installer_poses: (state) => state.installer_poses,
      product_groups: (state) => state.product_groups,
      poses: (state) => state.poses,
      clients: (state) => state.clients,
    }),
  },
};
</script>
<style scoper>
.logo {
  width: 6rem;
  height: 4rem;
}
.box {
  background-color: #9f137e;
  color: #fff;
}
.pink {
  color: #fff;
}
span.title {
  color: #9f137e;
}
a.edit_link {
  background-color: #9f137e !important;
  color: #fff !important;
  border: #9f137e solid 1px !important;
}
a.edit_link:hover {
  color: #9f137e !important;
  background-color: #fff !important;
  border: #9f137e solid 1px !important;
}
</style>
