<template>
   <div class="container mx-auto">
      <div class="flex justify-center">
         <button
            @click="isOpen = true"
            class="px-6 py-2 text-white bg-[#9f137e] rounded-md shadow"
            type="button"
            >
         Ajouter un nouveau fournisseur
         </button>
         <div
            v-show="isOpen"
            class="
            absolute
            inset-0
            flex
            items-center
            justify-center
            bg-gray-700 bg-opacity-50
            "
            style="z-index: 9999"
            >
            <div class="relative p-4 w-full max-w-md h-full md:h-auto">
               <!-- Modal content -->
               <div class="relative bg-white rounded-lg shadow ">
                  <button type="button" class="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center " @click="isOpen = false">
                     <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path>
                     </svg>
                  </button>
                  <div class="py-6 px-6 lg:px-8">
                     <h3 class="mb-4 text-xl font-medium text-gray-900 ">Veuillez entrer les détails</h3>
                     <form class="space-y-6" action="#">
                        <div>
                           <label for="vendor_name" class="block mb-2 text-sm font-medium text-gray-900 ">NOM DE FOURNISSEUR</label>
                           <input type="text" name="vendor_name" id="id_vendor_name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " 
                           placeholder="NOM DE FOURNISSEUR" required v-model="name" :class="{ 'bg-red-50 border border-red-500': nameError == true }">
                        </div>
                        <div>
                           <label for="address" class="block mb-2 text-sm font-medium text-gray-900 ">Adresse</label>
                           <input type="text" name="address" id="address" required placeholder="Adresse" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " 
                           v-model="address" :class="{ 'bg-red-50 border border-red-500': addressError == true }">
                        </div>
                        <div>
                           <label for="zip" class="block mb-2 text-sm font-medium text-gray-900 ">Code postal </label>
                           <input type="number" name="zip" id="zip" required placeholder="Code postal" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " 
                           v-model="zip" :class="{ 'bg-red-50 border border-red-500': zipError == true }">
                        </div>
                        <div>
                           <label for="town" class="block mb-2 text-sm font-medium text-gray-900 ">Ville </label>
                           <input type="text" name="town" id="town" required placeholder="Ville" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " 
                           v-model="town" >
                        </div>
                        <div>
                           <label for="phone" class="block mb-2 text-sm font-medium text-gray-900 ">Numéro de téléphone</label>
                           <input type="text" name="phone" id="phone" required placeholder="Numéro de téléphone" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " 
                           v-model="phone" :class="{ 'bg-red-50 border border-red-500': phoneError == true }">
                           <span v-if="phoneErrorValue" class="text-red-500 text-sm">{{ phoneErrorValue }}</span>
                        </div>
                        <button type="button" class="w-full text-white bg-[#9f137e] rounded-md hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium text-sm px-5 py-2.5 text-center  "
                        @click.prevent="addNewVendor">Ajouter un nouveau fournisseur</button>
                       
                     </form>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>
<script>
import axios from "../http-common"; // <-- HTTP common
   export default {
     name: "Modal",
      emits: ["vendor-submited"],
      data() {
         return {
         isOpen: false,
         name: "",
         address: "",
         zip: "",
         phone: "",
         nameError: false,
         addressError: false,
         zipError: false,
         phoneError: false,
         town: "",
         nameErrorValue: "",
         phoneErrorValue: "",
         
         };
      },
     methods: {
       async addNewVendor() {
         let header = {
            DOLAPIKEY: JSON.parse(window.localStorage.getItem("user-info")).data.success.token,
         };
         var regExp = /^[0][0-9]/;
         if(this.name){
            await this.checkNameExists();
         }
         if(this.name && this.address && this.zip && this.phone.length > 9 && 
                  regExp.test(this.phone) == true && this.nameError == false && 
                  this.addressError == false && this.zipError == false && this.phoneError == false){
            axios.post("/thirdparties", {
               name: this.name,
               address: this.address,
               zip: this.zip,
               prospect: 0,
               phone: this.phone,
               fournisseur: 1,
               town: this.town,               
            }, {
              headers: header,
            }).then(response => {
               this.name = "";
               this.address = "";
               this.zip = "";
               this.phone = "";
               this.town = "";
               this.isOpen = false;
               this.nameError = false;
               this.addressError = false;
               this.zipError = false;
               this.phoneError = false;
               this.$emit("vendor-submited", response);
               this.updateAgency(response.data);               
            }).catch(error => {
               this.$swal("Something went wrong, please try again", "", "error");
            });
         }else{
            if(!this.name) {
               this.nameError = true;
            }
            if(!this.address) {
               this.addressError = true;
            }
            if(!this.zip) {
               this.zipError = true;
            }
            if(JSON.stringify(this.phone).length < 10) {
               this.phoneError = true;
            }
            var regExp = /^[0][0-9].{10,}$/;
            if(!regExp.test(this.phone)) {
               this.phoneError = true;
            }

         }
       },
       async checkNameExists(){
          let header = {
            DOLAPIKEY: JSON.parse(window.localStorage.getItem("user-info")).data.success.token,
         };
         // axios.get(`/thirdparties?sqlfilters=t.nom like '${this.name}' AND t.fournisseur=1`, {
         axios.get(`/thirdparties?sqlfilters=(t.nom:like:'${this.name}') AND (t.fournisseur:=:1)`, {
            headers: header,
         }).then(response => {
            if(response.data.length > 0) {
               this.nameError = true;
               this.nameErrorValue = "Ce nom existe déjà";
            }else{
               this.nameError = false;
               this.nameErrorValue = "";
            }
         }).catch(error => {
            this.nameError = false;
            this.nameErrorValue = "";
         });
       },
       async updateAgency(agency) {
         let header = {
            DOLAPIKEY: JSON.parse(window.localStorage.getItem("user-info")).data.success.token,
         };
         axios.put("/thirdparties/"+agency, {
               "array_options": {
                "options_agency": this.agency ? parseInt(this.agency) : this.$store.state.selectedAgencyID,
                },
            }, {
              headers: header,
            }).then(response => {
               // console.log(response.data);
            }).catch(error => {
               console.log(error);
            });
       },
     },
     watch: {
         name () {
            this.nameError = false;
         },
         address () {
            this.addressError = false;
         },
         zip () {
            this.zipError = false;
         },
         phone:{
            handler:function (newValue,oldValue) {
               let firstChar = newValue.charAt(0);
               if(newValue.length != 10 || firstChar != 0 || isNaN(newValue)) {
                  this.phoneError = true;
                  this.phoneErrorValue = "Le numéro de téléphone doit être composé de 10 chiffres et commencer par 0";
               }
               else{
                  this.phoneError = false;
                  this.phoneErrorValue = "";
               }
            },
            deep:false,
            immediate:false
         },
     }
   };
</script>