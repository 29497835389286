<template>
  <div>
    <!-- Begin - Dashboard Side Navbar -->

    <!-- Component Start -->

    <SideBarInstaller />
    <!-- Component End  -->

    <!-- End - Dashboard Side Navbar  -->

    <!-- Begin -- Nav Top Bar -->

    <TopBarInstaller :user_info="user_info" />

    <!-- End - Nav Top Bar -->

    <!-- Begin - Dashboard Content -->

    <!-- Change the left padding to match the side nav bar -->
    <main class="pl-48 pt-24 p-4 w-full h-full bg-gray-100">
      <div class="flex flex-col mb-4">
        <h1 class="text-2xl font-bold text-gray-800">
          {{ getClientDetails(pose.fk_soc).name }}
        </h1>
        <div class="flex gap-12">
          <small class="text-gray-400 text-xs font-bold uppercase mt-1">
            1 equipe
          </small>
          <small class="text-gray-400 text-xs font-bold uppercase mt-1">
            {{ getDateFormat(pose.start_date) }} au
            {{ getDateFormat(pose.end_date) }}
          </small>
        </div>
      </div>

      <!-- Begin - Section 1 -->
<div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">

        <div
          class="relative w-full min-h-[200px] p-4 bg-white rounded-md shadow-md">
          <div class="absolute right-2 top-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor">
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
            </svg>
          </div>

          <h3
            class="text-md font-bold uppercase border-b-2 border-fuchsia-700 text-fuchsia-700">
            COORDONNÉES CLIENT
          </h3>
          <p class="text-sm text-gray-500 capitalize">
            <b>Nom:</b> &nbsp;{{ getClientDetails(pose.fk_soc).name }}
          </p>
          <p class="text-sm text-gray-500 capitalize">
            <b>Addresse:</b> &nbsp;{{ getClientDetails(pose.fk_soc).address1 }}
          </p>
          <p class="text-sm text-gray-500 capitalize">
            <b>Addresse 2:</b> &nbsp;{{
              getClientDetails(pose.fk_soc).address2
            }}
          </p>
          <div class="mt-4">
            <p class="text-sm text-gray-500 capitalize">
              <b>Téléphone:</b>&nbsp; {{ getClientDetails(pose.fk_soc).phone }}
            </p>
            <p class="text-sm text-gray-500 capitalize">
              <b>Email:</b>&nbsp; {{ getClientDetails(pose.fk_soc).email }}
            </p>
            <p class="text-sm text-gray-500 capitalize">
              <b>Client Code:</b>&nbsp;
              {{ getClientDetails(pose.fk_soc).code_client }}
            </p>
          </div>
        </div>
        <div
          class="w-full min-h-[200px] p-4 bg-white rounded-md shadow-md">
          <h3
            class="text-md font-bold uppercase border-b-2 border-fuchsia-700 text-fuchsia-700">
            DATES D'INFORMATION
          </h3>
          <div class="grid grid-cols-1 lg:grid-cols-3 py-2 h-full">
            <div class="relative h-32 bg-purple-200 px-2 rounded-md">
              <span
                class="absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] text-4xl"
                >{{ pose.estimated_days }}</span
              >
            </div>
            <div class="lg:col-span-2 px-4">
              <ul class="pl-4 list-disc">
                <li>Début:&nbsp; {{ getDateFormat(pose.start_date) }}</li>
                <li>Fin:&nbsp; {{ getDateFormat(pose.end_date) }}</li>
              </ul>
            </div>
          </div>
        </div>
        <div
          class="relative w-full min-h-[200px] p-4 bg-white rounded-md shadow-md">
          <div class="absolute right-2 top-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor">
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M12 3v1m0 16v1m9-9h-1M4 12H3m15.364 6.364l-.707-.707M6.343 6.343l-.707-.707m12.728 0l-.707.707M6.343 17.657l-.707.707M16 12a4 4 0 11-8 0 4 4 0 018 0z" />
            </svg>
          </div>

          <div
            v-for="(hotel, i) in hotels"
            :key="i">
            <h3
              class="text-md font-bold uppercase border-b-2 border-fuchsia-700 text-fuchsia-700">
              Hotel-{{ i + 1 }}
            </h3>
            <p class="text-sm text-gray-500 capitalize">
              <b>Nom:</b> {{ getHotelsInfo(hotel.hotels).name }}
            </p>
            <p class="text-sm text-gray-500 capitalize">
              <b>Adresse:</b> {{ getHotelsInfo(hotel.hotels).address1 }}
            </p>
            <!-- <p class="text-sm text-gray-500 uppercase">NUMÉRO DE RÉSERVATION:  {{getHotelsInfo(hotel.hotels).address2}}</p> -->

            <p class="text-sm text-gray-500 capitalize">
              <b> Téléphone: </b> {{ getHotelsInfo(hotel.hotels).phone }}
            </p>
            <div class="mt-4">
              <p class="text-sm text-gray-500">
                <b>Code Postal:</b> {{ getHotelsInfo(hotel.hotels).zip }}
              </p>
            </div>
            <div class="mt-2">
              <p class="text-sm text-gray-500 font-bold">
                Numéro De Réservation: {{ hotel.reservation_no }}
              </p>
              <p class="text-sm text-gray-500" v-html="hotel.hotel_notes"></p>
            </div>
          </div>
        </div>

      <!-- End - Section 1 -->

      <!-- Begin - Section 2 -->

        <!-- Begin - 1st Column (Coordnnes Client / Informatins Sur La Pose / Dssier Metre) -->
        <div class="w-full min-h-[200px]">
          <div class="w-full min-h-[200px] p-4 bg-white rounded-md shadow-md">
            <div class="h-[400px] mt-2 overflow-y-scroll">
              <p class="text-sm overflow-y-scroll">
                <strong>Remarques clients :&nbsp; </strong
                >{{ pose.note_client }}
              </p>
              <p class="text-sm overflow-y-scroll">
                <strong>Remarques métrés: &nbsp;</strong>
                {{ pose.note_measurement }}
              </p>
              <p class="text-sm overflow-y-scroll">
                <strong>Remarques hôtels:&nbsp; </strong> 
                <p v-html="pose_hotel_name"></p>
              </p>
              <p class="text-sm overflow-y-scroll">
                <strong>Notes:&nbsp; </strong> <div v-html='pose.note_public'></div>
              </p>
            </div>
          </div>

          <div
            class="w-full min-h-[200px] mt-4 p-4 bg-white rounded-md shadow-md">
            <h3
              class="text-md font-bold uppercase border-b-2 border-fuchsia-700 text-fuchsia-700">
              Dossier Metré
            </h3>
            <div class="flex mt-2 divide-x-2">
              <div class="w-full">
                <p class="text-sm text-gray-500 capitalize">
                  <b>Date:&nbsp;</b>
                  {{
                    getDateFormat(pose.measurement_date)
                      ? getDateFormat(pose.measurement_date)
                      : 'N/A'
                  }}
                </p>
                <p class="text-sm text-gray-500 capitalize">
                  <b>Commentaires:&nbsp;</b> {{ pose.note_measurement }}
                </p>
              </div>
            </div>
          </div>
        </div>

        <!-- Begin - 2nd Column (Material A Poser / Location / Photos Et Doc) -->
        <div class="w-full">
          <div class="w-full min-h-[200px] p-4 bg-white rounded-md shadow-md">
            <h3
              class="text-md font-bold uppercase border-b-2 border-fuchsia-700 text-fuchsia-700">
              DÉTAILS DE LA VENTE
            </h3>
            <div class="flex mt-2 divide-x-2">
              <div class="w-full">
                <p
                  class="text-sm text-gray-500 capitalize"
                  v-for="salesperson in salespersons_ids"
                  :key="salesperson">
                  <b>Commerciaux:&nbsp;</b> {{ getUsersInfo(salesperson) }}
                </p>

                <div class="mt-2">
                  <p
                    class="text-sm text-gray-500"
                    v-for="product in pose.products"
                    :key="product">
                    {{ getMaterialname(product) }}
                  </p>
                  <p class="text-sm text-gray-500 capitalize">
                    <br />
                    <b>Commentaires:</b>&nbsp; {{ pose.note_sale }}
                  </p>
                </div>
              </div>

              <div class="w-full px-2">
                <div class="mt-2">
                  <span
                    class="text-sm text-gray-400 px-2 uppercase"
                    v-for="category in categories"
                    :key="category"
                    :class="[
                      'text-[#' + getProductGroupDetails(category).color + ']'
                    ]"
                    >{{ getProductGroupDetails(category).name }}</span
                  >
                </div>
              </div>
            </div>
          </div>

          <div
            class="w-full min-h-[200px] mt-4 p-4 bg-white rounded-md shadow-md">
            <h3
              class="text-md font-bold uppercase border-b-2 border-fuchsia-700 text-fuchsia-700">
              Photos Et Dc Suite A La Pose
            </h3>
            <div class="w-full flex justify-between mt-2 space-x-10">
              <div class="relative w-full bg-gray-100 p-4">
                <div
                  class="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]">
                  <img
                    :src="previewUrl || pose_download_files[0].url"
                    v-if="previewUrl || pose_download_files.length > 0" />
                 
                  <div v-if="previewMessage">{{ previewMessage }}</div>
                  <!-- <svg v-else
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
                    />
                  </svg> -->
                </div>
              </div>
              <div class="w-full">
                <!-- Begin - Toggle Component -->
                <h5 class="text-sm font-bold">
                  <span class="text-gray-700">DDP</span>
                </h5>
                <label
                  for="pose_ddp"
                  class="switch">
                  <input
                    :disabled="true"
                    type="checkbox"
                    id="pose_ddp"
                    name="pose_ddp"
                    :checked="pose.file_status == 1" />
                  <span class="slider round"></span>
                </label>
                <!-- End - Toggle Component -->
                <!-- Begin - Toggle Component -->
                <h5 class="text-sm font-bold">
                  <span class="text-gray-700">DDP + Photos</span>
                </h5>
                <label
                  for="pose_ddp_photos"
                  class="switch">
                  <input
                    :disabled="true"
                    type="checkbox"
                    id="pose_ddp_photos"
                    name="pose_ddp_photos"
                    :checked="pose.photo_status == 1" />
                  <span class="slider round"></span>
                </label>
                <!-- End - Toggle Component -->
                <h5 class="text-sm font-bold">
                  <span class="text-gray-700">Pose validée</span>
                </h5>
                <label
                  for="validate"
                  class="switch">
                  <input
                    :disabled="true"
                    type="checkbox"
                    id="validate"
                    name="validate"
                    :checked="pose.validate == 1" />
                  <span class="slider round"></span>
                </label>
                <!-- End - Toggle Component -->
                <h5 class="text-sm font-bold">
                  <span class="text-gray-700">Matériaux nécessaires </span>
                </h5>
                <label
                  for="validate"
                  class="switch">
                  <input
                    type="checkbox"
                    id="need_material"
                    name="need_material"
                    :checked="pose.need_material == 1" />
                  <span class="slider round"></span>
                </label>
                <!-- End - Toggle Component -->
                <!-- <h5 class="text-sm font-bold">
                  <span class="text-gray-700">Need Equipment</span>
                </h5>
                <label for="validate" class="switch">
                  <input type="checkbox" id="need_equipment" name="need_equipment" :checked="pose.need_equipment == 1"/>
                  <span class="slider round"></span>
                </label> -->
              </div>
            </div>

            <!-- <div class="w-full mt-2">
              <label class="uppercase text-xs font-bold" for="">Client Fichier</label>
              <div class="flex items-center gap-2">
                <input type="file" class="text-red-700 uppercase text-xs font-bold ml-2" 
                @change="
                      filesChange($event.target.name, $event.target.files, $event,'client_fichier', pose.id);"/>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6 cursor-pointer"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12"
                  />
                </svg>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6 cursor-pointer"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </div>
            </div> -->
            <div class="w-full mt-2">
              <label
                class="uppercase text-xs font-bold"
                for=""
                >Pose Fichier</label
              >
              <div class="flex items-center gap-2">
                <input
                  type="file"
                  class="text-red-700 uppercase text-xs font-bold ml-2"
                  @change="
                    filesChange(
                      $event.target.name,
                      $event.target.files,
                      $event,
                      'pose_fichier',
                      pose.id
                    )
                  " />
                <!-- <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6 cursor-pointer"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12"
                  />
                </svg> -->
                <!-- <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6 cursor-pointer"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg> -->
              </div>
            </div>
            <!-- <div class="w-full mt-2">
              <label class="uppercase text-xs font-bold" for="">Metre Fichier</label>
              <div class="flex items-center gap-2">
                <input type="file" class="text-red-700 uppercase text-xs font-bold ml-2" 
                @change="
                      filesChange($event.target.name, $event.target.files, $event, 'metre_fichier', pose.id);"/>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6 cursor-pointer"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12"
                  />
                </svg>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6 cursor-pointer"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </div>
            </div> -->
            <!-- <div class="w-full mt-2">
              <label class="uppercase text-xs font-bold" for="">Nom du fichier</label>
              <div class="flex items-center gap-2">
                <input type="text" class="w-full border-2 rounded-md p-2" placeholder="Nom du fichier" />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6 cursor-pointer"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12"
                  />
                </svg>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6 cursor-pointer"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"s
                    stroke-width="2"
                    d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </div>
            </div> -->

            <!-- modal view of the files -->
            <div
              id="filePreviewModal"
              class="modal">
              <div
                class="modal-content bg-white mx-auto mt-16 p-6 border border-gray-300 max-w-3xl relative">
                <span
                  class="close absolute top-2 right-2 text-gray-600 text-xl cursor-pointer"
                  @click="closePreviewModal"
                  >&times;</span
                >
                <template v-if="getFileExtension(previewUrl) === 'pdf'">
                  <iframe
                    :src="previewUrl"
                    width="100%"
                    height="600px"></iframe>
                </template>
                <template v-else>
                  <div class="flex justify-center items-center h-600">
                    <!-- Add Tailwind classes -->
                    <img
                      :src="previewUrl"
                      class="max-w-full max-h-full" />
                  </div>
                </template>
              </div>
            </div>

            <!-- Files Uploaded Container -->
            <div class="w-full min-h-[150px] mt-2 border-2 rounded-md p-4">
              <span
                class="text-xs text-gray-800"
                v-for="(file, i) in pose_download_files"
                :key="i"
                ><strong>POSE FICHIER :&nbsp;</strong>
                <a
                  href="#"
                  @click="openPreviewModal(file.url, file.type)">
                  {{ file.name }}</a
                ><br
              /></span>
              <span
                class="text-xs text-gray-800"
                v-for="(file, i) in metre_pose_files"
                :key="i"
                ><strong>Metré FICHIER:&nbsp; </strong
                ><a
                  href="#"
                  @click="openPreviewModal(file.url, file.type)">
                  {{ file.name }}</a
                ><br
              /></span>
              <span
                class="text-xs text-gray-800"
                v-for="(file, i) in hotel_pose_files"
                :key="i"
                ><strong>HOTEL FICHIER:&nbsp; </strong
                ><a
                  href="#"
                  @click="openPreviewModal(file.url, file.type)">
                  {{ file.name }}</a
                ><br
              /></span>

              <!-- <span class="text-xs text-gray-800 " v-for="(file, i) in client_files" :key="i"><strong>CLIENT FICHIER :&nbsp;</strong> <a :href="file.url" download target="blank">{{file.name}}<br></a></span><br /> -->
              <!-- <span class="text-xs text-gray-800 " v-for="(file, i) in pose_download_files" :key="i"><strong>POSE FICHIER :&nbsp; </strong><a :href="file.url" download target="blank">{{file.name}}<br></a></span><br /> -->
              <!-- <span class="text-xs text-gray-800 " v-for="(file, i) in metre_pose_files" :key="i"><strong>Metré FICHIER:&nbsp; </strong><a :href="file.url" download target="blank">{{file.name}}<br></a></span><br /> -->
              <!-- <span class="text-xs text-gray-800 " v-for="(file, i) in hotel_pose_files" :key="i"><strong>HOTEL FICHIER:&nbsp; </strong><a :href="file.url" download target="blank">{{file.name}}<br></a></span><br /> -->
            </div>
          </div>
        </div>
        <!-- End - 2nd Column (Material A Poser / Location / Photos Et Doc)  -->

        <!-- Begin - 3rd Column (Hotel / Nte Sur La Pose) -->

        <div class="w-full mt-4 p-4 bg-white rounded-md shadow-md">
          <h3
            class="text-md font-bold uppercase border-b-2 border-fuchsia-700 text-fuchsia-700">
            DÉTAILS DU MATÉRIEL
          </h3>

          <div class="">
            <div
              class="mt-2"
              v-for="(material, key) in additional_products"
              :key="key">
              <span class="text-gray-700">{{
                getVendorName(material.vendors)
              }}</span>
              <span
                class="text-sm text-gray-400 px-2 uppercase"
                v-for="mat in material.materials"
                :key="mat"
                :class="['text-[##7ebee1']"
                >{{ getMaterialname(mat) }}</span
              >
            </div>
          </div>
        </div>
      </div>    

        </main>
        <!-- End - 3rd Column (Hotel / Note Sur La Pose) -->
      </div>

      <!-- End - Section 2 -->


    <!-- End - Dashboard Content -->

</template>
<script>
import logo from '../assets/logo.png'
import store from '@/store'
import TopBarInstaller from '@/components/TopBarInstaller.vue'
import SideBarInstaller from '@/components/SideBarInstaller.vue'
import { mapState } from 'vuex'
import axios from '../http-common' // <-- HTTP common
import moment from 'moment'

export default {
  title: 'InstallerHomeView',
  components: {
    TopBarInstaller,
    SideBarInstaller
  },
  data() {
    return {
      logo,
      url: '/document.php?modulepart=installation&amp;file=installation%2FPO-1680691996%2Fmetre_fichier__av-2.jpeg&amp;entity=1',
      pose: [],
      salespersons_ids: [],
      categories: [],
      hotels: [],
      pose_hotel_name: '',
      user_info: [],
      client_files: [],
      metre_pose_files: [],
      hotel_pose_files: [],
      pose_download_files: [],
      additional_products: [],
      previewUrl: '',
      previewMessage: null,
      fileType: ''
    }
  },

  computed: {
    ...mapState({
      agencies: state => state.agencies,
      product_groups: state => state.product_groups,
      all_hotels: state => state.hotels,
      salespersons: state => state.allSalesPersons,
      all_materials: state => state.materials,
      vendors: state => state.vendors,
      clients: state => state.clients
    })
  },
  async mounted() {
    // console.log(this.pose)
    this.getHotelsInfo()
    this.pose = []
    this.pose_id = this.$route.params.id
    await this.$store.dispatch('loadPoses', { limit: '', page: '' })
    await this.$store.dispatch('loadClients', { limit: '', page: '' })
    await this.$store.dispatch('loadProductGroups')
    await this.$store.dispatch('loadAllUsers')
    await this.$store.dispatch('loadMaterials')
    await this.$store.dispatch('loadHotels')
    await this.fetchPoseData(this.pose_id)
    await this.getUserInfo()
  },
  methods: {
    getFileExtension(url) {
      const extension = url.split('.').pop().toLowerCase()
      return extension
    },
    // Other methods...
    openPreviewModal(url, fileType) {
      this.previewUrl = url
      this.fileType = fileType
      const modal = document.getElementById('filePreviewModal')
      modal.style.display = 'block'
    },
    closePreviewModal() {
      const modal = document.getElementById('filePreviewModal')
      modal.style.display = 'none'
    },

    //  async uploadFile(payload){
    //     // console.log(payload)
    //       this.previewUrl = await axios.post("installationapi/upload", payload, {
    //       headers: {
    //         "Content-Type": "multipart/form-data",
    //         DOLAPIKEY: JSON.parse(window.localStorage.getItem("user-info")).data.success.token,
    //       }
    //     });
    //   },
    async uploadFile(payload) {
      try {
        const response = await axios.post('installationapi/upload', payload, {
          headers: {
            'Content-Type': 'multipart/form-data',
            DOLAPIKEY: JSON.parse(window.localStorage.getItem('user-info')).data
              .success.token
          }
        })
        return response.data // Return the response data
      } catch (error) {
        console.error(error)
        throw error // Throw the error for error handling
      }
    },
    filesChange(fieldName, fileList, $event, name, pose_id) {
      if (!fileList.length) return
      const file = $event.target.files[0]
      const reader = new FileReader()
      reader.onload = async event => {
        try {
          const fileData = event.target.result.split(';base64,')[1]
          this.previewUrl = event.target.result
          if (file.type === 'application/pdf') {
            this.previewMessage =
              'Aucun aperçu disponible pour les fichiers PDF.'
          } else {
            this.previewMessage = null // Clear the preview message for non-PDF files
          }
          for (let i = 0; i < fileList.length; i++) {
            const payload = {
              filename: name + '__' + fileList[i].name,
              id: pose_id,
              fileencoding: 'base64',
              filecontent: fileData,
              overwriteifexists: 1
            }
            await this.uploadFile(payload)
          }
        } catch (error) {
          console.error(error)
        }
      }
      reader.readAsDataURL(file)
    },
    // File Upload
    //  filesChange(fieldName, fileList, $event, name, pose_id) {
    //   // let previewImage ="";
    //   if (!fileList.length) return;
    //   const file = $event.target.files[0];
    //   const reader = new FileReader();
    //   reader.readAsDataURL(file);
    //   reader.onload = (event) => {
    //    this.previewUrl = event.target.result.split(';base64,')[1];
    //     Array.from(Array(fileList.length).keys()).map((x) => {
    //       this.uploadFile({
    //         filename: name+'__'+fileList[x].name,
    //         id: pose_id,
    //         fileencoding:"base64",
    //         filecontent: this.previewUrl,
    //         overwriteifexists: 1
    //       })
    //     });

    //    };
    //    reader.readAsDataURL(file);
    // },
    getVendorName(id) {
      try {
        return this.vendors.find(vendor => vendor.id == id).name
      } catch (e) {
        return ''
      }
    },
    getMaterialname(id) {
      try {
        return this.all_materials.find(material => material.id == id).name
      } catch (e) {
        return ''
      }
    },
    getUserInfo() {
      let header = {
        DOLAPIKEY: JSON.parse(window.localStorage.getItem('user-info')).data
          .success.token
      }
      axios
        .get(
          'installationapi/user?includePermissions=1&includeGroups=1&includeLeaves=1',
          {
            headers: header
          }
        )
        .then(response => {
          this.user_info = response.data
        })
        .catch(error => {
          this.user_info = {}
        })
    },
    fetchPoseData: function (id) {
      let api_url = `/installationapi/installations/` + id
      axios
        .get(api_url, {
          headers: {
            DOLAPIKEY: JSON.parse(window.localStorage.getItem('user-info')).data
              .success.token
          }
        })
        .then(response => response.data)
        .then(pose => {
          this.additional_products = JSON.parse(pose.additional_products)

          if (pose.files.length > 0) {
            pose.files.filter(file => {
              // console.log(file.url);
              if (file.name.startsWith('client_fichier')) {
                this.client_files.push(file)
              }
              if (file.name.startsWith('metre_fichier')) {
                this.metre_pose_files.push(file)
              }
              if (file.name.startsWith('hotel_fichier')) {
                this.hotel_pose_files.push(file)
              }
              if (file.name.startsWith('pose_fichier')) {
                this.pose_download_files.push(file)
              }
            })
          }
          this.pose = pose
          this.pose_hotel_name = JSON.parse(pose.hotel)[0].hotel_notes;
          this.hotels = JSON.parse(pose.hotel)
          this.salespersons_ids = JSON.parse(pose.user_ids)
          this.categories = JSON.parse(pose.categories)
        })
    },
    getClientDetails(id) {
      let client_info = {}
      try {
        this.$store.state.clients.forEach(client => {
          if (client.id == id) {
            client_info = {
              name: client.name,
              zip: client.zip,
              address1: client.address1,
              address2: client.town,
              city: client.city,
              phone: client.phone,
              email: client.email,
              code_client: client.code_client
            }
          }
        })
        return client_info
      } catch (error) {
        return 'Not found'
      }
    },
    // getHotelsInfo(id){
    //   let name = ""; let zip = "";  let address1= ""; let address2 = ""; let city= ""; let phone = "";
    //   this.all_hotels.forEach((hotel) => {
    //     console.log("jk",hotel)
    //     if (hotel.id == parseInt(id)) {

    //         name =  hotel.name,
    //         zip = hotel.zip,
    //         address1 = hotel.address1,
    //         address2 = hotel.town,
    //         city = hotel.city,
    //         phone = hotel.phone

    //     }
    //   });
    //   return { name:name, zip:zip, address1:address1, address2:address2, city:city, phone:phone };
    // },
    getHotelsInfo(id) {
      let data = {}
      this.all_hotels.forEach(hotel => {
        // console.log("jk",hotel)
        if (hotel.id == parseInt(id)) {
          data = {
            name: hotel.name,
            zip: hotel.zip,
            address1: hotel.address1,
            address2: hotel.town,
            city: hotel.city,
            phone: hotel.phone,
            hotel_notes: hotel.hotel_notes
          }
        }
      })
      return data
    },
    // getUsersInfo(id){
    //   console.log('jumbo',id)
    //   let data = {};
    //   this.salespersons.forEach((salesperson) => {
    //     console.log("new",salesperson.id)
    //     if (salesperson.id == parseInt(id)) {
    //       data = {
    //         'name': salesperson.name,
    //         'id': salesperson.id,
    //       }
    //     }
    //   });
    //   return data;
    // },
    getUsersInfo(id) {
      // console.log('jumbo',id)
      let name = ''
      this.salespersons.forEach(salesperson => {
        // console.log("new",salesperson.id)
        if (salesperson.id == id) {
          name = salesperson.name
        }
      })
      return name
    },

    getDateFormat(date) {
      if (date == '') {
        return ''
      }
      return moment.unix(date).format('DD/MM/YYYY')
    },
    getProductGroupDetails(id) {
      let name = ''
      let color = ''
      this.product_groups.forEach(product_group => {
        if (product_group.id == id) {
          name = product_group.name
          color = product_group.color
        }
      })
      return { name: name, color: color }
    }
  }
}
</script>
<style scoper>
.logo {
  width: 6rem;
  height: 4rem;
}
.box {
  background-color: #9f137e;
  color: #fff;
}
.pink {
  color: #fff;
}
/* Modal styles */
.modal {
  display: none;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.8);
}

.modal-content {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  max-width: 800px;
  position: relative;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}
</style>
