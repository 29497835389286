<template>
  <div>
    <SideBar />
    <TopBar @agency-changed="agencyChanged" />
    <!-- Begin - Dashboard Content -->
    <main class="pl-24 pt-24 p-4 w-full h-full bg-gray-200">
      <div
        class="flex flex-wrap lg:flex-nowrap items-center content-center gap-3 p-6 w-full bg-white rounded-lg border border-gray-200 shadow-md"
      >
        <!-- Begin - Date Range Component -->
        <div class="dashboard-top__content--pose-date-range w-full md:w-4/12 lg:w-4/12">
          <h5 class="text-xs font-bold">
            <span class="uppercase text-[#9f137e]">Date de pose (début) & (fin) </span>
          </h5>
          <Datepicker
            v-model="pose_date"
            :disabledWeekDays="[6, 0]"
            range
            :partialRange="false"
            placeholder="Sélectionnez le début et la fin"
            :enableTimePicker="false"
            :format="format"
            locale="fr"
            :minDate="new Date()"
            :readonly="status == '2'"
          ></Datepicker>
        </div>
        <!-- End - Date Range Component -->

        <div class="dashboard-top__content--pose-toggle w-full md:w-2/12 lg:w-2/12">
          <!-- Begin - Toggle Component -->
          <h5 class="text-sm font-bold">
            <span class="text-gray-700">Pose validée</span>
          </h5>
          <label for="pose_valide" class="switch">
            <input type="checkbox" id="pose_valide" name="pose_valide" checked v-model="pose_validation" />
            <span class="slider round"></span>
          </label>
          <!-- End - Toggle Component -->
        </div>

        <!-- Begin - Material Component -->
        <div class="dashboard-top__content--pose-material w-full md:w-6/12 lg:w-4/12">
          <div>
            <!-- Begin - Toggle Component -->
            <h5 class="text-sm font-bold">
              <span class="text-gray-700">Matériel commandé</span>
            </h5>
            <label for="id_pose_material_commande" class="switch">
              <input
                type="checkbox"
                id="id_pose_material_commande"
                name="pose_material_commande"
                v-model="pose_material_commande"
              />
              <!-- Add class="slider round" if you want it to be round -->
              <span class="slider round"></span>
            </label>
            <!-- End - Toggle Component -->
          </div>
          <div>
            <!-- Begin - Toggle Component -->
            <h5 class="text-sm font-bold">
              <span class="text-gray-700">Matériel réceptionné</span>
            </h5>
            <label for="id_pose_material_receptionne" class="switch">
              <input
                type="checkbox"
                id="id_pose_material_receptionne"
                name="pose_material_receptionne"
                v-model="pose_material_receptionne"
              />
              <span class="slider round"></span>
            </label>
            <!-- End - Toggle Component -->
          </div>
        </div>
        <!-- End - Material Component -->

        <!-- Begin - DDP Component -->
        <div class="dashboard-top__content--pose-ddp w-full items-end md:w-6/12 lg:w-2/12">
          <div>
            <!-- Begin - Toggle Component -->
            <h5 class="text-sm font-bold">
              <span class="text-gray-700">DDP</span>
            </h5>
            <label for="pose_ddp" class="switch">
              <input type="checkbox" id="pose_ddp" name="pose_ddp" v-model="pose_ddp" />
              <span class="slider round"></span>
            </label>
            <!-- End - Toggle Component -->
          </div>
        </div>
        <!-- Begin - DDP Component -->
        <div class="dashboard-top__content--pose-ddp w-full items-end md:w-6/12 lg:w-3/12">
          <div>
            <!-- Begin - Toggle Component -->
            <h5 class="text-sm font-bold">
              <span class="text-gray-700">DDP + Photos</span>
            </h5>
            <label for="pose_ddp_photos" class="switch">
              <input type="checkbox" id="pose_ddp_photos" name="pose_ddp_photos" v-model="pose_ddp_photos" />
              <span class="slider round"></span>
            </label>
            <!-- End - Toggle Component -->
          </div>
        </div>
        <!-- End - DDP Component -->

        <!-- Begin - SAV Suite Component -->
        <div class="dashboard-top__content--pose-toggle w-full items-end md:w-6/12 lg:w-3/12">
          <!-- Begin - Toggle Component -->
          <h5 class="text-sm font-bold">
            <span class="text-gray-700">SAV suite à la pose</span>
          </h5>
          <label for="pose_sav" class="switch">
            <input type="checkbox" id="pose_sav" name="pose_sav" v-model="after_sales" />
            <span class="slider round"></span>
          </label>

          <!-- End - Toggle Component -->
        </div>
        <!-- End - SAV Suite Component -->
      </div>

      <TabContainer
        @clicked-show-pose="clickedShowDetailPose"
        :pose_validation="pose_validation"
        :pose_material_commande="pose_material_commande"
        :pose_material_receptionne="pose_material_receptionne"
        :pose_ddp="pose_ddp"
        :after_sales="after_sales"
        :pose_ddp_photos="pose_ddp_photos"
        :pose_date="pose_date"
        :agency_changed="agencychange"
      />
    </main>

    <!-- End - Dashboard Content -->
  </div>
</template>

<script>
// @ is an alias to /src
import SideBar from "@/components/SideBar.vue";
import TopBar from "@/components/TopBar.vue";
import TabContainer from "@/components/TabContainer.vue";
import moment from "moment"; // <-- Moment
import Datepicker from "@vuepic/vue-datepicker";

export default {
  title: "Create Pose | DealEco",
  name: "PoseCreate",
  components: {
    SideBar,
    TopBar,
    TabContainer,
    Datepicker,
  },
  data() {
    const date = new Date();
    const format = (date) => {
      const day1 = date[0].getDate();
      const month1 = date[0].getMonth() + 1;
      const year1 = date[0].getFullYear();
      const day2 = date[1].getDate();
      const month2 = date[1].getMonth() + 1;
      const year2 = date[1].getFullYear();
      return `${day1}/${month1}/${year1} to ${day2}/${month2}/${year2}`;
    };

    return {
      pose_validation: false,
      pose_material_commande: false,
      pose_material_receptionne: false,
      pose_ddp: false,
      pose_ddp_photos: false,
      after_sales: false,
      pose_date: "",
      format,
      status: "",
      agencychange: "",
    };
  },
  methods: {
    getDateFormat(date) {
      return moment.unix(date).format("YYYY-MM-DD");
    },
    clickedShowDetailPose: function (value) {
      this.pose_ddp_photos = true ? value.photo_status == 1 : false;
      this.pose_material_commande = true ? value.need_material == 1 : false;
      this.pose_material_receptionne = true ? value.need_equipment == 1 : false;
      this.pose_ddp = true ? value.file_status == 1 : false;
      this.after_sales = true ? value.after_sales == 1 : false;
      this.pose_validation = true ? value.validate == 1 : false;
      this.pose_date = value.end_date
        ? [this.getDateFormat(value.start_date), this.getDateFormat(value.end_date)]
        : null;
      this.status = value.status;
    },
    agencyChanged: function () {
      this.agencychange = this.$store.state.selectedAgencyID;
    },
  },
};
</script>
<style scoped>
.bg-stpes-active,
.bg-stpes-strong-active,
.switch input:checked,
.slider input:checked {
  background-color: #9f137e !important;
}
.bg-stpes-active,
.bg-stpes-strong-active,
.switch input:checked + .slider {
  background-color: #9f137e !important;
}
.switch input:checked {
  background-color: #9f137e !important;
}
</style>
