<template>
  <div>
    <SideBar />
    <TopBar />

    <!-- Begin - Dashboard Content -->

    <!-- Begin - Dashboard Content -->
      <main class="pl-24 pt-24 p-4 w-full h-full bg-gray-200">
          <Details :router_id="$route.params.id"></Details>
      </main>
      <!-- End - Dashboard Content -->

    <!-- End - Dashboard Content -->
  </div>
    
</template>

<script>
// @ is an alias to /src
import SideBar from '@/components/SideBar.vue'
import TopBar from '@/components/TopBar.vue'
import TabContainer from '@/components/TabContainer.vue'
import Details from '@/components/Details.vue'

export default {
  title: 'Dashboard',
  name: 'HomeView',
  components: {
    SideBar,
    TopBar,
    TabContainer,
    Details
  }, 
  data() {
    return {
      pose_validation: true,
    }
  },
  mounted() {
    const token = localStorage.getItem("token");
  }
}
</script>
