import axio from "../http-common";



class ApiCallService {
  async get(url) {
    let response = null;
    try{
        let header = {
          DOLAPIKEY: JSON.parse(window.localStorage.getItem("user-info")).data.success.token,
        };
        response =  await axio.get(url, {headers: header});
    }catch(error){
        if(error.response && error.response.status === 404) {
            // console.clear();
        }
    }
    return response;
  }
  post(url, data) {
    return axio.post(url, {headers: header},  data);
  }
  put(url, data) {
    return axio.put(url, {headers: header}, data);
  }
  delete(url) {
    return axio.delete(url, {headers: header});
  }
}

export default new ApiCallService();