<template>
  <!-- Begin - Dashboard Content -->
  <div>
    <SideBar />
    <TopBar @agency-changed="agencyChanged" />
    <main class="pl-24 pt-24 p-4 w-full h-full bg-gray-200">
      <!-- Begin - Team Creation Top Section -->
      <input
        type="hidden"
        name="customfield"
        class="form-control"
        v-model="agencychange" />
      <div class="flex flex-col">
        <h1 class="text-2xl font-bold text-gray-800">CRÉATION DES ÉQUIPES</h1>
        <p class="text-gray-600">
          Créer des équipes pour les dates sélectionnées
        </p>
        <small class="text-gray-400 text-xs font-bold uppercase mt-1"
          >Disponibilités pour la semaine du
          <span class="text-red-800"> {{ getDateFormat(start) }} </span> au
          <span class="text-red-800">{{ getDateFormat(end) }} </span>
        </small>
      </div>

      <div
        class="flex flex-wrap lg:flex-nowrap items-end content-center gap-10 mt-2 p-6 w-full bg-white rounded-lg border border-gray-200 shadow-md">
        <!-- Begin - Section 1 - Equipes -->
        <div
          class="w-full lg:w-2/5 h-[200px] p-6 bg-white rounded-md border border-gray-200 shadow-sm">
          <!-- Begin - Section Subtitle -->
          <!-- Begin - Section Subtitle -->
          <div class="flex flex-wrap items-center border-b">
            <h4 class="text-xs font-bold uppercase">Équipes</h4>
            <div class="flex gap-2 items-center ml-auto">
              <p class="text-xs uppercase font-bold">
                SELECTIONNER UNE CATÉGORIE
              </p>
              <select
                class="border px-2 rounded-md"
                name="team_category"
                id="team_category"
                v-model="team_category"
                :class="{
                  'bg-red-50 border border-red-500': errorTeamCategory == true
                }">
                <option
                  default
                  value="">
                  CATÉGORIE
                </option>
                <option
                  :value="category.id"
                  v-for="(category, i) in product_groups"
                  :key="i"
                  :class="['uppercase text-[#' + category.color + ']']">
                  {{ category.name }}
                </option>
              </select>
            </div>
            <div class="flex gap-2 items-center ml-auto my-2">
              <p class="text-xs uppercase font-bold">NOMBRE DE MEMBRES:</p>
              <select
                class="border px-2 rounded-md"
                name="team_size"
                id="team_size"
                v-model="team_size">
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
              </select>
              <button @click.prevent="createTeam">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor">
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
              </button>
            </div>
          </div>

          <!-- End - Section Subtitle -->

          <!-- Begin - Small Toggle Shape Container -->
          <div class="flex flex-wrap gap-2 h-[140px] overflow-y-auto py-1">
            <!-- TEAM n -->
            <div
              class="relative h-11 py-0 ml-2 px-1 mt-1 rounded-md dashboard__img-toggle-shape orange bg-white header-box"
              v-for="(team, index) in teams"
              :key="index">
              <div
                class="flex items-center justify-center gap-2 cursor-move"
                draggable="true"
                @dragstart="startDrag($event, team)">
                <div
                  class="flex items-center justify-center rounded-full w-10 h-10 object-contain bg-[#dbbba6]"
                  v-for="i in parseInt(team.size)"
                  :key="i">
                  <span
                    class="flex items-center justify-center text-xs font-semibold w-8 h-8 uppercase rounded-full text-white last:mr-0 mr-1"
                    :class="['bg-[#' + getCategoryColor(team.category) + ']']">
                  </span>
                </div>
              </div>
            </div>

            <!-- END TEAM n -->
          </div>

          <!-- End - Small Toggle Shape Container -->
        </div>
        <!-- End - Section 1 - Equipes -->

        <!-- Begin - Section 2 -  RESPONSABLES D'EQUIPES -->
        <div
          class="w-full lg:w-1/5 h-[200px] p-6 bg-white rounded-md border border-gray-200 shadow-sm">
          <!-- Begin - Section Subtitle -->
          <h4 class="text-xs font-bold uppercase border-b">
            RESPONSABLES D'ÉQUIPES
          </h4>
          <!-- End - Section Subtitle -->

          <!-- Begin - Small Toggle Shape Container -->
          <div
            class="flex flex-wrap gap-2 h-[140px] overflow-y-auto"
            v-if="team_leads.length > 0">
            <!-- Replace div tags with img tag - Change border-pink-400 to desired color-->
            <div
              class="relative flex items-center justify-center mt-4 border-2 border-pink-400 rounded-full w-10 h-10 object-contain bg-white-100 cursor-move"
              :class="[
                user.category[0]
                  ? 'border-[#' + user.category[0].color + ']'
                  : 'border-gray-400'
              ]"
              v-for="(user, index) in team_leads"
              :key="index"
              draggable="true"
              @dragstart="startDragUsers($event, user)">
              <!-- Import v-tooltip and link it with this for it to work as a popover:
            https://www.npmjs.com/package/v-tooltip
            
             -->
              <div
                class="absolute top-[-5px] right-[-5px] bg-white rounded-full cursor-pointer"
                @mouseenter="mouseOver(user.id)"
                @mouseleave="mouseLeave(user.id)">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-4 w-4"
                  viewBox="0 0 20 20"
                  fill="currentColor">
                  <path
                    fill-rule="evenodd"
                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                    clip-rule="evenodd" />
                </svg>
                <div
                  class="absolute top-1 right-0 flex flex-col items-center mb-6 group-hover:flex z-9999"
                  v-if="tooltipStatus[user.id]">
                  <span
                    class="relative z-10 p-2 text-xs leading-none text-white whitespace-no-wrap bg-black shadow-lg"
                    >{{ user.firstname }} {{ user.lastname }}</span
                  >
                </div>
              </div>

              <span
                class="flex justify-center items-center text-xs font-semibold w-8 h-8 text-center uppercase rounded-full text-pink-800"
                :class="[
                  user.category[0]
                    ? 'bg-[#' + user.category[0].color + ']'
                    : 'bg-pink-200'
                ]"
                v-if="user.photo">
                <img
                  :src="user.photo"
                  class="rounded-full"
                  draggable="false" />
              </span>
              <span
                class="flex justify-center items-center text-xs font-semibold w-8 h-8 text-center uppercase rounded-full text-pink-800"
                :class="[
                  user.category[0]
                    ? 'bg-[#' + user.category[0].color + ']'
                    : 'bg-pink-200'
                ]"
                v-else>
                {{ user.firstname.slice(0, 1) }}{{ user.lastname.slice(0, 1) }}
              </span>
            </div>
          </div>

          <!-- End - Small Toggle Shape Container -->
        </div>
        <!-- End - Section 2 - RESPONSABLES D'EQUIPES -->

        <!-- Begin - Section 3 - Équipers  -->
        <div
          class="w-full lg:w-1/5 h-[200px] p-6 bg-white rounded-md border border-gray-200 shadow-sm">
          <!-- Begin - Section Subtitle -->
          <h4 class="text-xs font-bold uppercase border-b">Équipes</h4>
          <!-- End - Section Subtitle -->
          <!-- Begin - Small Toggle Shape Container -->
          <div
            class="flex flex-wrap gap-2 h-[140px] overflow-y-auto"
            v-if="normal_installers.length > 0">
            <!-- Replace div tags with img tag - Change border-pink-400 to desired color-->
            <div
              class="relative flex items-center justify-center mt-4 border-2 border-pink-400 rounded-full w-10 h-10 object-contain bg-white-100 cursor-move"
              :class="[
                user.category[0]
                  ? 'border-[#' + user.category[0].color + ']'
                  : 'border-gray-400'
              ]"
              v-for="(user, index) in normal_installers"
              :key="index"
              draggable="true"
              @dragstart="startDragUsers($event, user)">
              <!-- Import v-tooltip and link it with this for it to work as a popover:
            https://www.npmjs.com/package/v-tooltip
            
             -->
              <div
                class="absolute top-[-5px] right-[-5px] bg-white rounded-full cursor-pointer"
                @mouseenter="mouseOver(user.id)"
                @mouseleave="mouseLeave(user.id)">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-4 w-4"
                  viewBox="0 0 20 20"
                  fill="currentColor">
                  <path
                    fill-rule="evenodd"
                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                    clip-rule="evenodd" />
                </svg>
                <div
                  class="absolute top-1 right-0 flex flex-col items-center mb-6 group-hover:flex z-9999"
                  v-if="tooltipStatus[user.id]">
                  <span
                    class="relative z-10 p-2 text-xs leading-none text-white whitespace-no-wrap bg-black shadow-lg"
                    >{{ user.firstname }} {{ user.lastname }}</span
                  >
                </div>
              </div>

              <span
                class="flex justify-center items-center text-xs font-semibold w-8 h-8 text-center uppercase rounded-full text-pink-800"
                :class="[
                  user.category[0]
                    ? 'bg-[#' + user.category[0].color + ']'
                    : 'bg-pink-200'
                ]"
                v-if="user.photo">
                <img
                  :src="user.photo"
                  class="rounded-full"
                  draggable="false" />
              </span>
              <span
                class="flex justify-center items-center text-xs font-semibold w-8 h-8 text-center uppercase rounded-full text-pink-800"
                :class="[
                  user.category[0]
                    ? 'bg-[#' + user.category[0].color + ']'
                    : 'bg-pink-200'
                ]"
                v-else>
                {{ user.firstname.slice(0, 1) }}{{ user.lastname.slice(0, 1) }}
              </span>
            </div>
          </div>

          <!-- Begin - Small Toggle Shape Container -->

          <!-- End - Small Toggle Shape Container -->
        </div>
        <!-- End - Section 3 - Equipers -->

        <!-- Begin - Section 4 - Vacances  -->
        <div
          class="w-full lg:w-1/5 h-[200px] p-6 bg-white rounded-md border border-gray-200 shadow-sm">
          <!-- Begin - Section Subtitle -->
          <h4 class="text-xs font-bold uppercase border-b">Vacances</h4>
          <!-- End - Section Subtitle -->
          <!-- Begin - Small Toggle Shape Container -->
          <div
            class="flex flex-wrap gap-2 h-[140px] overflow-y-auto py-2"
            v-if="leave_data">
            <!-- Replace div tags with img tag - Change border-pink-400 to desired color-->

            <!-- Leaves section starts -->
            <div
              class="relative h-11 py-0 ml-2 px-1 mt-2 rounded-md dashboard__img-toggle-shape orange bg-white header-box"
              v-for="(leaves, index) in leave_data"
              :key="index">
              <div class="flex items-center justify-center gap-2">
                <!--  IF Team is full / Members Occupied -->
                <div
                  class="flex flex-col items-center justify-center rounded-md p-0.5"
                  title="Full Day"
                  :class="['bg-[#' + getMemberColor(index) + ']']">
                  <p
                    class="text-[0.55rem] whitespace-nowrap text-pink-800 font-semibold"
                    v-for="(leave, index) in leaves"
                    :key="leave">
                    <!-- Show only one date if start date and end date are the same -->
                    <span
                      v-if="index === 0 && leaves.length === 1"
                      style="display: inline"
                      >{{ leave }}</span
                    >

                    <!-- First Value -->
                    <span
                      v-else-if="index === 0"
                      style="display: inline"
                      >{{ leave }}</span
                    >

                    <!-- Last Value -->
                    <span
                      v-else-if="index === leaves.length - 1"
                      style="display: inline"
                      >{{ leave }}</span
                    >
                  </p>
                </div>
                <!--  end -->
                <div
                  class="flex items-center justify-center rounded-full w-10 h-10 object-contain bg-[#dbbba6]"
                  v-if="getUserImg(index)">
                  <span
                    class="flex items-center justify-center text-xs font-semibold w-8 h-8 uppercase rounded-full text-pink-800 last:mr-0 mr-1"
                    :class="['bg-[#' + getMemberColor(index) + ']']">
                    <img
                      :src="getUserImg(index)"
                      class="rounded-full" />
                  </span>
                </div>
                <div
                  class="flex items-center justify-center rounded-full w-10 h-10 object-contain bg-[#dbbba6]"
                  v-else>
                  <span
                    class="flex items-center justify-center text-xs font-semibold w-8 h-8 uppercase rounded-full text-pink-800 last:mr-0 mr-1"
                    :class="['bg-[#' + getMemberColor(index) + ']']">
                    {{ getUserName(index).first_name.slice(0, 1)
                    }}{{ getUserName(index).last_name.slice(0, 1) }}
                  </span>
                </div>
              </div>
            </div>

            <!-- END of Leaves -->
          </div>

          <!-- End - Small Toggle Shape Container -->

          <!-- End - Small Toggle Shape Container -->
        </div>
        <!-- End - Section 4 - Vacances -->
      </div>
      <!-- End - Team Creation Top Section -->

      <!-- Start - Team Creation Bottom Section -->

      <div
        class="flex flex-wrap mt-2 p-6 w-full bg-white rounded-lg border border-gray-200 shadow-md sec-team-creation">
        <!-- Begin - Calendar Component -->
        <div
          class="w-full h-full lg:w-3/12 p-6 bg-white rounded-md border border-gray-200 shadow-sm">
          <FullCalendar
            class="text-center text-xs h-full"
            :options="calendarOptions">
          </FullCalendar>
        </div>
        <!-- End - Calendar Component -->

        <!-- Begin - Team Management Planner -->
        <div
          class="w-full lg:w-9/12 p-6 bg-white rounded-md border border-gray-200 shadow-sm"
          v-if="!weekDays.length">
          Please choose any date
        </div>

        <div
          class="w-full lg:w-9/12 p-6 bg-white rounded-md border border-gray-200 shadow-sm"
          v-else>
          <h2
            class="text-xs text-gray-400 uppercase text-center border-4 p-1 border-dashed rounded-md"
            @droppable="true"
            @drop="removeTeamDrop($event)"
            @dragover.prevent
            @dragenter.prevent>
            Faites glisser ici pour supprimer
          </h2>

          <!-- Begin - Team Management Days Content Holder -->
          <div class="flex flex-wrap items-start divide-x-2 mt-4 lg:h-[80%]">
            <div
              class="w-full lg:w-1/5 min-h-[300px] h-full overflow-y-auto bg-gray-100"
              v-for="(day, index) in weekDays"
              :key="index"
              @droppable="true"
              @drop="onDrop($event, index, day, 'day')"
              @dragover.prevent
              @dragenter.prevent>
              <h1
                class="teamMGM__nav--days w-5/5 py-3 px-2 text-xs uppercase active text-center">
                {{ day.label1 }}&nbsp;{{ day.label }}
              </h1>

              <div class="flex flex-wrap p-2 gap-2">
                <!-- TEAM n -->
                <div
                  class="relative h-11 py-0 ml-2 px-1 mt-2 rounded-md dashboard__img-toggle-shape orange bg-white header-box cursor-move"
                  v-for="team in day.teams"
                  :key="team"
                  draggable="true"
                  @dragstart="removeTeamDrag($event, team)">
                  <div
                    class="flex items-center justify-center gap-2"
                    v-if="team.status != 2">
                    <!-- Delete team Icon -->
                    <div
                      class="absolute top-[-5px] right-[-5px] bg-white rounded-full cursor-pointer"
                      @click="removeTeam(team)">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-4 w-4"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="red">
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="1"
                          d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                      </svg>
                    </div>
                    <!--  Full DAY if "J" -->
                    <div
                      class="flex gap-1 items-center justify-center w-22 h-4 rounded-md absolute top-[-16px] left-[-2px] rounded-bl-none rounded-br-none"
                      title="Full Day"
                      v-if="team.availability == 'J'"
                      :class="[
                        'bg-[#' + getCategoryColor(team.category) + ']'
                      ]">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="iconify iconify--ep w-4 h-4"
                        preserveAspectRatio="xMidYMid meet"
                        viewBox="0 0 1024 1024">
                        <path
                          fill="white"
                          d="M512 704a192 192 0 1 0 0-384a192 192 0 0 0 0 384zm0 64a256 256 0 1 1 0-512a256 256 0 0 1 0 512zm0-704a32 32 0 0 1 32 32v64a32 32 0 0 1-64 0V96a32 32 0 0 1 32-32zm0 768a32 32 0 0 1 32 32v64a32 32 0 1 1-64 0v-64a32 32 0 0 1 32-32zM195.2 195.2a32 32 0 0 1 45.248 0l45.248 45.248a32 32 0 1 1-45.248 45.248L195.2 240.448a32 32 0 0 1 0-45.248zm543.104 543.104a32 32 0 0 1 45.248 0l45.248 45.248a32 32 0 0 1-45.248 45.248l-45.248-45.248a32 32 0 0 1 0-45.248zM64 512a32 32 0 0 1 32-32h64a32 32 0 0 1 0 64H96a32 32 0 0 1-32-32zm768 0a32 32 0 0 1 32-32h64a32 32 0 1 1 0 64h-64a32 32 0 0 1-32-32zM195.2 828.8a32 32 0 0 1 0-45.248l45.248-45.248a32 32 0 0 1 45.248 45.248L240.448 828.8a32 32 0 0 1-45.248 0zm543.104-543.104a32 32 0 0 1 0-45.248l45.248-45.248a32 32 0 0 1 45.248 45.248l-45.248 45.248a32 32 0 0 1-45.248 0z"></path>
                      </svg>
                      <span
                        class="text-[0.55rem] text-green-800 font-bold px-3"
                        v-if="team.status == 1"
                        >COMPLET</span
                      >
                    </div>
                    <!--  Morning DAY if "M" -->
                    <div
                      class="flex gap-1 items-center justify-center w-22 h-4 rounded-md absolute top-[-16px] left-[-2px] rounded-bl-none rounded-br-none"
                      title="Morning Day"
                      v-if="team.availability == 'M'"
                      :class="[
                        'bg-[#' + getCategoryColor(team.category) + ']'
                      ]">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="iconify iconify--ep w-4 h-4"
                        preserveAspectRatio="xMidYMid meet"
                        viewBox="0 0 1024 1024">
                        <path
                          fill="white"
                          d="M32 768h960a32 32 0 1 1 0 64H32a32 32 0 1 1 0-64zm129.408-96a352 352 0 0 1 701.184 0h-64.32a288 288 0 0 0-572.544 0h-64.32zM512 128a32 32 0 0 1 32 32v96a32 32 0 0 1-64 0v-96a32 32 0 0 1 32-32zm407.296 168.704a32 32 0 0 1 0 45.248l-67.84 67.84a32 32 0 1 1-45.248-45.248l67.84-67.84a32 32 0 0 1 45.248 0zm-814.592 0a32 32 0 0 1 45.248 0l67.84 67.84a32 32 0 1 1-45.248 45.248l-67.84-67.84a32 32 0 0 1 0-45.248z"></path>
                      </svg>
                      <span
                        class="text-[0.55rem] text-green-800 font-bold px-3"
                        v-if="team.status == 1"
                        >COMPLET</span
                      >
                    </div>
                    <!-- Afternoon if "A" -->
                    <div
                      class="flex gap-1 items-center justify-center w-22 h-4 rounded-md absolute top-[-16px] left-[-2px] rounded-bl-none rounded-br-none"
                      title="Half Day"
                      v-if="team.availability == 'A'"
                      :class="[
                        'bg-[#' + getCategoryColor(team.category) + ']'
                      ]">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="iconify iconify--ep w-4 h-4 rotate-180"
                        preserveAspectRatio="xMidYMid meet"
                        viewBox="0 0 1024 1024">
                        <path
                          fill="white"
                          d="M32 768h960a32 32 0 1 1 0 64H32a32 32 0 1 1 0-64zm129.408-96a352 352 0 0 1 701.184 0h-64.32a288 288 0 0 0-572.544 0h-64.32zM512 128a32 32 0 0 1 32 32v96a32 32 0 0 1-64 0v-96a32 32 0 0 1 32-32zm407.296 168.704a32 32 0 0 1 0 45.248l-67.84 67.84a32 32 0 1 1-45.248-45.248l67.84-67.84a32 32 0 0 1 45.248 0zm-814.592 0a32 32 0 0 1 45.248 0l67.84 67.84a32 32 0 1 1-45.248 45.248l-67.84-67.84a32 32 0 0 1 0-45.248z"></path>
                      </svg>
                      <span
                        class="text-[0.55rem] text-green-800 font-bold px-3"
                        v-if="team.status == 1"
                        >COMPLET</span
                      >
                    </div>

                    <div
                      class="flex items-center justify-center rounded-full w-10 h-10 object-contain bg-[#dbbba6]"
                      v-for="(slot, i) in team.size"
                      :key="i"
                      @droppable="true"
                      @drop="onDropUser($event, i, team, day.teams)"
                      @dragover.prevent
                      @dragenter.prevent>
                      <div v-if="getUserImg(team.members[i])">
                        <span
                          class="flex items-center justify-center text-xs font-semibold w-8 h-8 uppercase rounded-full text-pink-800 last:mr-0 mr-1"
                          :class="[
                            team.category
                              ? 'bg-[#' + getCategoryColor(team.category) + ']'
                              : 'bg-fuchsia-100'
                          ]">
                          <img
                            :src="getUserImg(team.members[i])"
                            class="rounded-full" />
                        </span>
                      </div>
                      <div v-else>
                        <span
                          class="flex items-center justify-center text-xs font-semibold w-8 h-8 uppercase rounded-full text-pink-800 last:mr-0 mr-1"
                          :class="[
                            team.category
                              ? 'bg-[#' + getCategoryColor(team.category) + ']'
                              : 'bg-fuchsia-100'
                          ]">
                          {{
                            getUserName(team.members[i]).first_name.slice(0, 1)
                          }}{{
                            getUserName(team.members[i]).last_name.slice(0, 1)
                          }}
                        </span>
                      </div>
                    </div>
                  </div>
                  <!-- else -->
                  <div
                    class="flex items-center justify-center gap-2"
                    v-else>
                    <!--  Full DAY if "J" -->
                    <div
                      class="flex gap-1 items-center justify-center w-22 h-4 rounded-md absolute top-[-16px] left-[-2px] rounded-bl-none rounded-br-none"
                      title="Full Day"
                      v-if="team.availability == 'J'"
                      :class="[
                        'bg-[#' + getCategoryColor(team.category) + ']'
                      ]">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="iconify iconify--ep w-4 h-4"
                        preserveAspectRatio="xMidYMid meet"
                        viewBox="0 0 1024 1024">
                        <path
                          fill="white"
                          d="M512 704a192 192 0 1 0 0-384a192 192 0 0 0 0 384zm0 64a256 256 0 1 1 0-512a256 256 0 0 1 0 512zm0-704a32 32 0 0 1 32 32v64a32 32 0 0 1-64 0V96a32 32 0 0 1 32-32zm0 768a32 32 0 0 1 32 32v64a32 32 0 1 1-64 0v-64a32 32 0 0 1 32-32zM195.2 195.2a32 32 0 0 1 45.248 0l45.248 45.248a32 32 0 1 1-45.248 45.248L195.2 240.448a32 32 0 0 1 0-45.248zm543.104 543.104a32 32 0 0 1 45.248 0l45.248 45.248a32 32 0 0 1-45.248 45.248l-45.248-45.248a32 32 0 0 1 0-45.248zM64 512a32 32 0 0 1 32-32h64a32 32 0 0 1 0 64H96a32 32 0 0 1-32-32zm768 0a32 32 0 0 1 32-32h64a32 32 0 1 1 0 64h-64a32 32 0 0 1-32-32zM195.2 828.8a32 32 0 0 1 0-45.248l45.248-45.248a32 32 0 0 1 45.248 45.248L240.448 828.8a32 32 0 0 1-45.248 0zm543.104-543.104a32 32 0 0 1 0-45.248l45.248-45.248a32 32 0 0 1 45.248 45.248l-45.248 45.248a32 32 0 0 1-45.248 0z"></path>
                      </svg>
                      <span class="text-[0.55rem] text-red-800 font-bold px-3"
                        >ASSIGNÉ</span
                      >
                    </div>
                    <div
                      class="flex gap-1 items-center justify-center w-22 h-4 rounded-md absolute top-[-16px] left-[-2px] rounded-bl-none rounded-br-none"
                      title="Full Day"
                      v-if="team.availability == ''"
                      :class="[
                        'bg-[#' + getCategoryColor(team.category) + ']'
                      ]">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="iconify iconify--ep w-4 h-4"
                        preserveAspectRatio="xMidYMid meet"
                        viewBox="0 0 1024 1024">
                        <path
                          fill="white"
                          d="M512 704a192 192 0 1 0 0-384a192 192 0 0 0 0 384zm0 64a256 256 0 1 1 0-512a256 256 0 0 1 0 512zm0-704a32 32 0 0 1 32 32v64a32 32 0 0 1-64 0V96a32 32 0 0 1 32-32zm0 768a32 32 0 0 1 32 32v64a32 32 0 1 1-64 0v-64a32 32 0 0 1 32-32zM195.2 195.2a32 32 0 0 1 45.248 0l45.248 45.248a32 32 0 1 1-45.248 45.248L195.2 240.448a32 32 0 0 1 0-45.248zm543.104 543.104a32 32 0 0 1 45.248 0l45.248 45.248a32 32 0 0 1-45.248 45.248l-45.248-45.248a32 32 0 0 1 0-45.248zM64 512a32 32 0 0 1 32-32h64a32 32 0 0 1 0 64H96a32 32 0 0 1-32-32zm768 0a32 32 0 0 1 32-32h64a32 32 0 1 1 0 64h-64a32 32 0 0 1-32-32zM195.2 828.8a32 32 0 0 1 0-45.248l45.248-45.248a32 32 0 0 1 45.248 45.248L240.448 828.8a32 32 0 0 1-45.248 0zm543.104-543.104a32 32 0 0 1 0-45.248l45.248-45.248a32 32 0 0 1 45.248 45.248l-45.248 45.248a32 32 0 0 1-45.248 0z"></path>
                      </svg>
                      <span class="text-[0.55rem] text-red-800 font-bold px-3"
                        >ASSIGNÉ</span
                      >
                    </div>
                    <!--  Morning DAY if "M" -->
                    <!--  Morning DAY if "M" -->
                    <div
                      class="flex gap-1 items-center justify-center w-22 h-4 rounded-md absolute top-[-16px] left-[-2px] rounded-bl-none rounded-br-none"
                      title="Morning Day"
                      v-if="team.availability == 'M'"
                      :class="[
                        'bg-[#' + getCategoryColor(team.category) + ']'
                      ]">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="iconify iconify--ep w-4 h-4"
                        preserveAspectRatio="xMidYMid meet"
                        viewBox="0 0 1024 1024">
                        <path
                          fill="white"
                          d="M32 768h960a32 32 0 1 1 0 64H32a32 32 0 1 1 0-64zm129.408-96a352 352 0 0 1 701.184 0h-64.32a288 288 0 0 0-572.544 0h-64.32zM512 128a32 32 0 0 1 32 32v96a32 32 0 0 1-64 0v-96a32 32 0 0 1 32-32zm407.296 168.704a32 32 0 0 1 0 45.248l-67.84 67.84a32 32 0 1 1-45.248-45.248l67.84-67.84a32 32 0 0 1 45.248 0zm-814.592 0a32 32 0 0 1 45.248 0l67.84 67.84a32 32 0 1 1-45.248 45.248l-67.84-67.84a32 32 0 0 1 0-45.248z"></path>
                      </svg>
                      <span class="text-[0.55rem] text-red-800 font-bold px-4"
                        >ASSIGNÉ</span
                      >
                    </div>
                    <!-- Afternoon if "A" -->
                    <div
                      class="flex gap-1 items-center justify-center w-22 h-4 rounded-md absolute top-[-16px] left-[-2px] rounded-bl-none rounded-br-none"
                      title="Half Day"
                      v-if="team.availability == 'A'"
                      :class="[
                        'bg-[#' + getCategoryColor(team.category) + ']'
                      ]">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="iconify iconify--ep w-4 h-4 rotate-180"
                        preserveAspectRatio="xMidYMid meet"
                        viewBox="0 0 1024 1024">
                        <path
                          fill="white"
                          d="M32 768h960a32 32 0 1 1 0 64H32a32 32 0 1 1 0-64zm129.408-96a352 352 0 0 1 701.184 0h-64.32a288 288 0 0 0-572.544 0h-64.32zM512 128a32 32 0 0 1 32 32v96a32 32 0 0 1-64 0v-96a32 32 0 0 1 32-32zm407.296 168.704a32 32 0 0 1 0 45.248l-67.84 67.84a32 32 0 1 1-45.248-45.248l67.84-67.84a32 32 0 0 1 45.248 0zm-814.592 0a32 32 0 0 1 45.248 0l67.84 67.84a32 32 0 1 1-45.248 45.248l-67.84-67.84a32 32 0 0 1 0-45.248z"></path>
                      </svg>
                      <span class="text-[0.55rem] text-red-800 font-bold px-4"
                        >ASSIGNÉ</span
                      >
                    </div>

                    <div
                      class="flex items-center justify-center rounded-full w-10 h-10 object-contain bg-[#dbbba6]"
                      v-for="(slot, i) in team.size"
                      :key="i"
                      @droppable="true"
                      @drop="onDropUser($event, i, team, day.teams)"
                      @dragover.prevent
                      @dragenter.prevent>
                      <div v-if="getUserImg(team.members[i])">
                        <span
                          class="flex items-center justify-center text-xs font-semibold w-8 h-8 uppercase rounded-full text-pink-800 last:mr-0 mr-1"
                          v-if="team.members[i] !== 0"
                          :class="[
                            team.category
                              ? 'bg-[#' + getCategoryColor(team.category) + ']'
                              : 'bg-fuchsia-100'
                          ]">
                          <img
                            :src="getUserImg(team.members[i])"
                            class="rounded-full" />
                        </span>
                      </div>
                      <div v-else>
                        <span
                          class="flex items-center justify-center text-xs font-semibold w-8 h-8 uppercase rounded-full text-pink-800 last:mr-0 mr-1"
                          v-if="team.members[i] !== 0"
                          :class="[
                            team.category
                              ? 'bg-[#' + getCategoryColor(team.category) + ']'
                              : 'bg-fuchsia-100'
                          ]">
                          {{
                            getUserName(team.members[i]).first_name.slice(0, 1)
                          }}{{
                            getUserName(team.members[i]).last_name.slice(0, 1)
                          }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- END TEAM n -->
              </div>
            </div>
          </div>
          <!-- End - Team Management Days Content Holder -->
          <div
            class="flex flex-wrap lg:flex-nowrap items-center mt-4 gap-2 justify-end">
            <!-- Change "Week 2 of June 2022 to be dynamic and represent the week" -->
            <span class="text-xs uppercase text-gray-500 mr-auto">
              <b>Semaine:</b>
              {{ activeWeekNumber }} - du {{ getDateFormat(start) }} au
              {{ getDateFormat(end) }}</span
            >

            <select
              class="border rounded-md py-2.5 px-2"
              name=""
              id=""
              v-model="to_clone_week">
              <option value="">Sélectionner une semaine</option>
              <option
                v-for="(data, i) in selectedmonthData"
                :key="i"
                :value="data">
                Semaine {{ i }} - du {{ getDateFormat(data.f_day_of_week) }} au
                {{ getDateFormat(data.l_day_of_week) }}
              </option>
            </select>
            <button
              class="bg-fuchsia-700 hover:bg-fuchsia-200 hover:text-black text-white font-bold py-2 px-4 rounded-md"
              @click.prevent="cloneWeek()">
              Dupliquer
            </button>
          </div>

          <!-- Clone Alert - Replace with this package:
          https://www.npmjs.com/package/vue-toast-notification to create toasts instead of status messages
           -->
          <div class="flex justify-end mt-4">
            <!-- Status Message on Clone -->
            <!-- If not successful, relace bg-green-200 with bg-red-200 and ring-green-400 to ring-red-400 -->
            <span
              class="bg-green-200 ring-4 ring-green-400 text-xs uppercase rounded-md p-2"
              v-if="clone_notifns">
              <b>{{ clone_notifns }}</b></span
            >
            <span
              class="bg-green-200 ring-4 ring-green-400 text-xs uppercase rounded-md p-2"
              v-if="cloneSuccess.length > 0">
              <b>Semaine {{ cloneSuccess[0] }}</b> a été cloné sur
              <b>Semaine {{ cloneSuccess[1] }}</b> avec succès!
            </span>
          </div>
        </div>

        <!-- End - Team Management Planner -->
      </div>

      <!-- End - Team Creation Bottom Section -->
    </main>
  </div>

  <!-- End - Dashboard Content -->
</template>
<script>
import SideBar from '@/components/SideBar.vue'
import TopBar from '@/components/TopBar.vue'
import moment from 'moment'
import { SetupCalendar, Calendar, DatePicker } from 'v-calendar'
import '@fullcalendar/core/vdom' // FullCalendar Vue Component
import FullCalendar, {
  CalendarOptions,
  EventApi,
  DateSelectArg,
  EventClickArg
} from '@fullcalendar/vue3'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin, { Draggable } from '@fullcalendar/interaction'
import frLocale from '@fullcalendar/core/locales/fr'
import { mapGetters, mapState } from 'vuex'
import axios from '../http-common' // <-- HTTP common
import ApiCallService from '@/services/apiCall'
import 'moment/locale/fr'

export default {
  title: 'Team Planifier | DealEco',
  components: {
    SideBar,
    TopBar,
    Calendar,
    FullCalendar
  },
  data() {
    let selected_date = new Date().toISOString().replace(/T.*$/, '')
    if (localStorage.getItem('selected_date_team')) {
      selected_date = moment(localStorage.getItem('selected_date_team')).format(
        'YYYY-MM-DD'
      )
    }
    return {
      tooltipShow: false,
      errors: [],
      team_size: 2,
      team_category: '',
      calendarEventDrag: false,
      start: '',
      end: '',
      teams: [],
      created_teams: [],
      calendarOptions: {
        initialDate: selected_date,
        locales: [frLocale],
        locale: 'fr',
        plugins: [
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin // needed for dateClick
        ],
        droppable: true,
        headerToolbar: {
          left: 'prev,next',
          center: 'title',
          right: 'today'
        },
        initialView: 'dayGridMonth',
        selectable: true,
        dayMaxEvents: true,
        weekends: true,
        hiddenDays: [0, 6],
        events: [],
        dateClick: this.onDayClickHandler
      },
      attributes: [
        // This is a single attribute
        { key: 'today', highlight: true, dates: new Date() }
      ],
      numOfdaysPastSinceLastMonday: 0,
      weekDays: [],
      selected_date: selected_date,
      imageDragged: false,
      imageDragged2: false,
      selectedmonthData: {},
      activeWeekNumber: 0,
      to_clone_week: '',
      clone_notifns: '',
      cloneSuccess: [],
      leave_data: {},
      errorTeamCategory: false,
      tooltipStatus: [],
      agencychange: '',
      team_leads: [],
      normal_installers: []
      // installers: [],
    }
  },
  computed: {
    ...mapState({
      installers: state => state.installers,
      product_groups: state => state.product_groups
    }),
    ...mapGetters({
      selectedAgency: 'selectedAgencyID'
    })
  },
  unmounted() {
    this.created_teams = []
    this.teams = []
  },
  mounted() {
    if (localStorage.getItem('selected_date_team')) {
      this.selected_date = moment(
        localStorage.getItem('selected_date_team')
      ).format('YYYY-MM-DD')
    }
    this.calendarOptions.initialDate = this.selected_date
    moment.updateLocale('en', {
      week: {
        dow: 1
      }
    })
    var weeknumber = moment(this.selected_date, 'YYYY-MM-DD').week()
    this.weekDays = this.getDateRangeOfWeek(weeknumber)
    // console.log('m',this.weekDays)
    this.$store.dispatch('loadUsers')
    this.$store.dispatch('loadInstallers')
    this.$store.dispatch('loadProductGroups')
    this.getWeekOfSelectedMonth()
    this.getCreatedTeams()
    this.getLeavesofSelectedWeek()
    this.installers.filter(installer => {
      if (installer.team_lead == '1') {
        this.team_leads.push(installer)
      } else {
        this.normal_installers.push(installer)
      }
    })
  },
  methods: {
    agencyChanged: function () {
      this.agencychange = this.$store.state.selectedAgencyID
    },
    mouseOver: function (user) {
      this.tooltipStatus[user] = true
    },
    mouseLeave: function (user) {
      this.tooltipStatus[user] = false
    },
    toggleTooltip: function () {
      if (this.tooltipShow) {
        this.tooltipShow = false
      } else {
        this.tooltipShow = true
        createPopper(this.$refs.btnRef, this.$refs.tooltipRef, {
          placement: 'right'
        })
      }
    },
    async removeTeam(team) {
      this.$swal
        .fire({
          title: 'Êtes-vous sûr ?',
          text: 'Vous êtes sur le point de supprimer une équipe.Vous ne pourrez pas revenir en arrière ! ',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#038638',
          cancelButtonColor: '#ff9501',
          confirmButtonText: 'Oui, supprimez-la !',
          cancelButtonText: 'Annuler'
        })
        .then(result => {
          if (result.value) {
            this.created_teams = this.created_teams.filter(
              t => t.id !== team.id
            )
            this.setTeamstoWeekdays()
            let header = {
              DOLAPIKEY: JSON.parse(window.localStorage.getItem('user-info'))
                .data.success.token
            }
            axios
              .delete(`/installationapi/teams/${team.id}`, { headers: header })
              .then(response => {
                console.log('Removed Team')
              })
              .catch(error => {
                console.log('Remove Team erros', error)
              })
            this.$swal.fire(
              'Supprimé!',
              `ID d'équipe: ${team.id} removed.`,
              'success'
            )
          }
        })
    },
    removeTeamDrag(event, team) {
      event.dataTransfer.dropEffect = 'move'
      event.dataTransfer.effectAllowed = 'move'
      event.dataTransfer.setData('team_to_remove', JSON.stringify(team))
    },
    removeTeamDrop(event) {
      let team_to_remove = JSON.parse(
        event.dataTransfer.getData('team_to_remove')
      )
      if (team_to_remove.status != 2) {
        this.removeTeam(team_to_remove)
      } else {
        this.$swal.fire(
          'Erreur',
          "Vous ne pouvez pas supprimer l'équipe, qui est déjà dans un POSE.",
          'error'
        )
      }
    },
    getLeavesofSelectedWeek() {
      let data = this.formatWeekDaysofMonth(this.activeWeekNumber)
      let leave_data = {}
      this.installers.forEach(installer => {
        // console.log('jaku', installer)
        if (installer.leaves.length > 0) {
          installer.leaves.forEach(leave => {
            var day1 = moment(leave.date_debut)
            var day2 = moment(leave.date_fin)
            var result = [moment({ ...day1 })]
            while (day1.date() != day2.date()) {
              day1.add(1, 'day')
              result.push(moment({ ...day1 }))
            }
            result = result.map(x => x.format('YYYY-MM-DD'))
            data.days.forEach(day => {
              if (result.includes(day.date)) {
                if (leave_data[installer.id]) {
                  leave_data[installer.id].push(
                    moment(day.date).format('DD/MM')
                  )
                } else {
                  leave_data[installer.id] = [moment(day.date).format('DD/MM')]
                }
              }
            })
          })
        }
      })
      this.leave_data = leave_data
    },
    addCloneSuccessCallback() {
      this.cloneSuccess.push(this.activeWeekNumber)
      this.cloneSuccess.push(this.to_clone_week['weeknumber'])
      this.to_clone_week = ''
    },
    cloneWeek() {
      this.cloneSuccess = []
      let fromClonedata = this.formatWeekDaysofMonth(this.activeWeekNumber)
      if (!this.to_clone_week) {
        this.clone_notifns = 'Please select a week to clone'
        return
      }
      this.clone_notifns = ''
      var itemsProcessed = 0
      fromClonedata.days.forEach((day, index) => {
        let d = this.to_clone_week.days[index].date
        var localTime = moment.utc(d).toDate()
        localTime = moment(localTime).format('YYYY-MM-DD HH:mm:ss')
        this.getTeamFromDate(day.date, localTime)
        itemsProcessed++
        if (itemsProcessed === fromClonedata.days.length) {
          this.addCloneSuccessCallback()
        }
      })
    },
    createTeamAPI(team, localTime, header) {
      // console.log(team.user_ids)
      const counts = {}
      let user_ids = JSON.parse(team.user_ids)
      for (const num of Object.values(user_ids)) {
        counts[num] = counts[num] ? counts[num] + 1 : 1
      }
      let status = '0'
      if (counts[0] == 0) {
        status = '1'
      }
      if (counts[0] == undefined) {
        status = '1'
      }
      if (counts[0] > 0) {
        status = '0'
      }
      let ref = 'T' + '-' + JSON.stringify(moment(localTime).format('X'))
      let payload = {
        module: 'installation',
        ref: ref,
        user_ids: team.user_ids,
        category: team.category,
        appointed_date: moment(localTime).format('X'),
        status: status,
        validateFieldsErrors: [],
        import_key: null,
        model_pdf: null,
        last_main_doc: null,
        note_public: team.size,
        date_creation: null,
        date_validation: null,
        date_modification: null,
        fk_agency_id: 2,
        availability: team.availability
      }
      axios
        .post(`/installationapi/teams`, payload, {
          headers: header
        })
        .then(response => {
          console.log('cloned')
        })
        .catch(error => {
          console.log('Clone erros', error)
        })
    },
    async getTeamFromDate(date, localTime) {
      const userInfo = JSON.parse(window.localStorage.getItem('user-info'))
      const token = userInfo?.data?.success?.token
      const header = { DOLAPIKEY: token }

      try {
        // const url = `installationapi/teams?sqlfilters=appointed_date BETWEEN '${date}' AND '${date}'`
        const url = `installationapi/teams?sqlfilters=(appointed_date BETWEEN '${date}' AND '${date}')`
        const result = await ApiCallService.get(url, { headers: header })

        if (result) {
          result.data.forEach(item => {
            if (item && item.status.toString() === '0') {
              console.log('errorr')
            } else {
              this.createTeamAPI(item, localTime, header)
            }
          })
        }
      } catch (error) {
        console.log('error', error)
      }
    },
    getWeekOfSelectedMonth() {
      var check = moment(this.selected_date, 'YYYY/MM/DD')
      var month = check.format('M')
      var day = check.format('D')
      var year = check.format('YYYY')
      var startDate = moment([year, month - 1])
      var endDate = moment(startDate).endOf('month')
      let firstDayofSelectedMonth = moment(startDate.toDate()).format(
        'YYYY-MM-DD'
      )
      let endDayofSelectedMonth = moment(endDate.toDate()).format('YYYY-MM-DD')
      this.selectedmonthData = this.getWeeknumbersofMonth(
        firstDayofSelectedMonth,
        endDayofSelectedMonth
      )
    },
    getWeeknumbersofMonth(firstDayofSelectedMonth, endDayofSelectedMonth) {
      var weeknumbers = []
      var startDate = moment(firstDayofSelectedMonth, 'YYYY-MM-DD')
      var endDate = moment(endDayofSelectedMonth, 'YYYY-MM-DD')
      var data = {}
      while (startDate.isBefore(endDate)) {
        let weeknumber = startDate.isoWeek()
        startDate.add(1, 'week')
        data[weeknumber] = this.formatWeekDaysofMonth(weeknumber)
      }
      return data
    },
    formatWeekDaysofMonth(weeknumber) {
      let days = []
      var dateformat = 'MMM D'
      var dateformat2 = 'YYYY-MM-DD'
      var date = moment()
        .isoWeek(weeknumber || 1)
        .startOf('week')
      var weeklength = 5
      while (weeklength--) {
        days.push({
          label: date.format(dateformat),
          date: date.format(dateformat2),
          teams: []
        })
        date.add(1, 'day')
      }
      let f_day_of_week = days[0].date
      let l_day_of_week = days[days.length - 1].date
      return {
        f_day_of_week: f_day_of_week,
        l_day_of_week: l_day_of_week,
        days: days,
        weeknumber: weeknumber
      }
    },
    getCategoryColor(category) {
      let color = 'red-500'
      this.product_groups.forEach(function (group) {
        if (group.name == category || group.id == category) {
          color = group.color
        }
      })
      return color
    },
    getMemberColor(id) {
      let color = '#dbbba6'
      try {
        this.installers.forEach(installer => {
          if (installer.id == id) {
            if (installer.category[0].color) {
              color = installer.category[0].color
            }
          }
        })
      } catch (error) {
        color = '#dbbba6'
      }
      return color
    },
    getUserName(id) {
      let name = {
        first_name: '',
        last_name: ''
      }
      this.installers.forEach(installer => {
        if (installer.id == id) {
          name['first_name'] = installer.firstname
          name['last_name'] = installer.lastname
        }
      })
      return name
    },
    getUserImg(id) {
      let img = ''
      this.installers.forEach(installer => {
        if (installer.id == id) {
          img = installer.photo
        }
      })
      return img
    },
    setTeamstoWeekdays() {
      this.weekDays.forEach(day => {
        day.teams = []
        this.created_teams.forEach(team => {
          if (team.appointed_date == day.date) {
            day.teams.push(team)
          }
        })
      })
    },
    async getCreatedTeams() {
      this.created_teams = []
      let url =
        `installationapi/teams?sqlfilters=appointed_date BETWEEN '` +
        this.start +
        `' AND '` +
        this.end +
        `'`
      let result = null
      try {
        result = await ApiCallService.get(url)
      } catch (error) {
        result = null
      }
      if (result) {
        // console.log('result', result)
        result.data.map(item => {
          this.created_teams.push({
            id: parseInt(item.id),
            name: 'Team ' + item.id,
            members: JSON.parse(item.user_ids),
            size: JSON.parse(item.user_ids).length,
            status: item.status,
            appointed_date: moment
              .unix(item.appointed_date)
              .format('YYYY-MM-DD'),
            availability: item.availability,
            category: item.category,
            ref: item.ref,
            appointed_date_timestamp: item.appointed_date,
            pose_id: item.pose_id
          })
        })
      }
      if (this.created_teams.length > 0) {
        this.setTeamstoWeekdays()
      }
    },
    createTeam() {
      if (!this.team_category) {
        this.errorTeamCategory = true
      } else {
        this.errorTeamCategory = false
        this.teams.push({
          name: 'Team ' + this.teams.length,
          members: [],
          days: [],
          size: this.team_size,
          category: this.team_category
        })
      }
    },
    async onDayClickHandler(info) {
      this.selected_date = info.dateStr
      localStorage.setItem('selected_date_team', this.selected_date)
      // console.log(this.selected_date);
      this.getWeekOfSelectedMonth()
      var weeknumber = moment(info.dateStr, 'YYYY-MM-DD').week()
      this.weekDays = this.getDateRangeOfWeek(weeknumber)
      this.getCreatedTeams()
      this.getLeavesofSelectedWeek()
    },
    getDateFormat(date) {
      var date = moment(date).format('DD-MM-YYYY')
      return date
    },
    getDateRangeOfWeek(weeknumber) {
      weeknumber = weeknumber
      this.activeWeekNumber = weeknumber
      let days = []
      var dateformat = 'D'
      moment.locale('fr')
      var dateformat1 = 'dddd'
      var dateformat2 = 'YYYY-MM-DD'
      var date = moment()
        .isoWeek(weeknumber || 1)
        .startOf('week')
      var weeklength = 5
      while (weeklength--) {
        days.push({
          label: date.format(dateformat),
          label1: moment(date).format(dateformat1),
          date: date.format(dateformat2),
          weeknumber: weeknumber,
          teams: []
        })
        date.add(1, 'day')
      }
      this.start = days[0].date
      this.end = days[days.length - 1].date
      return days
    },
    getWeekDays() {
      let currentDate = new Date()
      let startDate = new Date(currentDate.getFullYear(), 0, 1)
      var days = Math.floor((currentDate - startDate) / (24 * 60 * 60 * 1000))
      var weekNumber = Math.ceil((currentDate.getDay() + 1 + days) / 7)
      this.weekDays = this.getDateRangeOfWeek(weekNumber)
    },
    startDrag(event, team) {
      event.dataTransfer.dropEffect = 'move'
      event.dataTransfer.effectAllowed = 'move'
      event.dataTransfer.setData('team', JSON.stringify(team))
    },
    startDragUsers(event, user) {
      event.dataTransfer.dropEffect = 'move'
      event.dataTransfer.effectAllowed = 'move'
      event.dataTransfer.setData('user', JSON.stringify(user))
    },
    async onDropUser(event, position, team, this_day_teams) {
      let user = JSON.parse(event.dataTransfer.getData('user'))
      let user_ids = team.members
      let is_ok_to_add = true
      if (Object.keys(this.leave_data).length !== 0) {
        try {
          this.leave_data[user.id].forEach(leave => {
            if (leave == moment(team.appointed_date).format('DD/MM')) {
              this.$swal(`${user.firstname} is not available on this day`)
              is_ok_to_add = false
              return false
            }
          })
        } catch (e) {
          is_ok_to_add = true
        }
      }
      this_day_teams.forEach(day_team => {
        if (Object.values(day_team.members).includes(parseInt(user.id))) {
          is_ok_to_add = false
          return false
        }
      })
      if (Object.values(user_ids).includes(parseInt(user.id))) {
        this.errors.push(`${user.firstname} est déjà dans cette équipe`)
        this.$swal(`${user.firstname} est déjà dans cette équipe`)
        return false
      } else if (!is_ok_to_add) {
        this.$swal({
          type: 'error',
          title: 'Oops...',
          text: "L'utilisateur est en congé ou déjà affecté ce jour!"
        })
      } else {
        let header = {
          DOLAPIKEY: JSON.parse(window.localStorage.getItem('user-info')).data
            .success.token
        }
        const counts = {}
        for (const num of Object.values(user_ids)) {
          counts[num] = counts[num] ? counts[num] + 1 : 1
        }
        let status = '0'
        if (team.status == '2') {
          status = team.status
        } else if (counts[0] == 1 || counts[0] == 0 || counts[0] == undefined) {
          status = '1'
        }
        user_ids[position] = user.id
        let ref =
          'T' +
          '-' +
          JSON.stringify(team.appointed_date_timestamp) +
          '-' +
          user_ids.join('-')
        let payload = {
          user_ids: user_ids,
          status: status,
          ref: ref
        }
        axios
          .put(`installationapi/teams/` + team.id, payload, {
            headers: header
          })
          .then(response => {
            this.getCreatedTeams()
          })
          .catch(error => {
            console.log(error)
          })
      }
    },
    onDrop(event, list, date, type) {
      try {
        let user_ids = []
        let team = JSON.parse(event.dataTransfer.getData('team'))
        for (let i = 0; i < team.size; i++) {
          user_ids.push(0)
        }
        let header = {
          DOLAPIKEY: JSON.parse(window.localStorage.getItem('user-info')).data
            .success.token
        }
        var localTime = moment.utc(date.date).toDate()
        localTime = moment(localTime).format('YYYY-MM-DD HH:mm:ss')
        let ref = 'T' + '-' + JSON.stringify(moment(localTime).format('X'))
        let payload = {
          module: 'installation',
          ref: ref,
          user_ids: user_ids,
          category: team.category,
          appointed_date: moment(localTime).format('X'),
          status: '0',
          validateFieldsErrors: [],
          import_key: null,
          model_pdf: null,
          last_main_doc: null,
          note_public: team.size,
          date_creation: null,
          date_validation: null,
          date_modification: null,
          fk_agency_id: 2,
          availability: 'J'
        }
        axios
          .post(`/installationapi/teams`, payload, {
            headers: header
          })
          .then(response => {
            this.getCreatedTeams()
          })
          .catch(error => {
            console.log(error)
          })
      } catch (e) {
        console.log('e', e)
      }
    }
    // loadInstallers() {
    //   let url = "installationapi/users?group=23";
    //   axios
    //     .get(url, {
    //       headers: {
    //         DOLAPIKEY: JSON.parse(window.localStorage.getItem("user-info")).data.success.token,
    //       },
    //     })
    //     .then((response) => response.data)
    //     .then((users) => {
    //       let user_type = JSON.parse(window.localStorage.getItem("user-info")).user;
    //       users.forEach((user) => {
    //          this.installers.push({
    //                 id: user.id,
    //                 name: user.firstname + " " + user.lastname,
    //                 value: user.id,
    //                 label: user.firstname + " " + user.lastname,
    //                 firstname: user.firstname,
    //                 lastname: user.lastname,
    //                 photo: user.photo ? user.photo.mini : "",
    //                 category: user.category.length > 0 ? user.category : [],
    //                 leaves: user.leaves,
    //                 team_lead: user.array_options.options_team_lead,
    //        });
    //         if (user_type != "admin") {
    //           if (user.admin === "0") {
    //             if (user.array_options.options_team_lead == "1") {
    //               this.team_leads.push({
    //                 id: user.id,
    //                 name: user.firstname + " " + user.lastname,
    //                 value: user.id,
    //                 label: user.firstname + " " + user.lastname,
    //                 firstname: user.firstname,
    //                 lastname: user.lastname,
    //                 photo: user.photo ? user.photo.mini : "",
    //                 category: user.category.length > 0 ? user.category : [],
    //                 leaves: user.leaves,
    //                 team_lead: user.array_options.options_team_lead,
    //               });
    //             } else {
    //               this.normal_installers.push({
    //                 id: user.id,
    //                 name: user.firstname + " " + user.lastname,
    //                 value: user.id,
    //                 label: user.firstname + " " + user.lastname,
    //                 firstname: user.firstname,
    //                 lastname: user.lastname,
    //                 photo: user.photo ? user.photo.mini : "",
    //                 category: user.category.length > 0 ? user.category : [],
    //                 leaves: user.leaves,
    //                 team_lead: user.array_options.options_team_lead,
    //               });
    //             }
    //           }
    //         } else {
    //           if (user.array_options.options_team_lead == "1") {
    //             this.team_leads.push({
    //               id: user.id,
    //               name: user.firstname + " " + user.lastname,
    //               value: user.id,
    //               label: user.firstname + " " + user.lastname,
    //               firstname: user.firstname,
    //               lastname: user.lastname,
    //               photo: user.photo ? user.photo.mini : "",
    //               category: user.category.length > 0 ? user.category : [],
    //               leaves: user.leaves,
    //               team_lead: user.array_options.options_team_lead,
    //             });
    //           } else {
    //             this.normal_installers.push({
    //               id: user.id,
    //               name: user.firstname + " " + user.lastname,
    //               value: user.id,
    //               label: user.firstname + " " + user.lastname,
    //               firstname: user.firstname,
    //               lastname: user.lastname,
    //               photo: user.photo ? user.photo.mini : "",
    //               category: user.category.length > 0 ? user.category : [],
    //               leaves: user.leaves,
    //               team_lead: user.array_options.options_team_lead,
    //             });
    //           }
    //         }
    //       });
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    // },
  },
  watch: {
    team_category(val) {
      this.errorTeamCategory = false
    },
    agencychange(val) {
      // console.log('agency change')
      this.team_leads = []
      this.normal_installers = []
      this.$store.dispatch('loadUsers')
      this.$store.dispatch('loadInstallers')
      this.$store.dispatch('loadProductGroups')
      // this.getCreatedTeams();
      this.installers.filter(installer => {
        if (installer.team_lead == '1') {
          this.team_leads.push(installer)
        } else {
          this.normal_installers.push(installer)
        }
      })

      // this.loadInstallers();
    }
  }
}
</script>
<style>
.dashboard__img-toggle-shape {
  background-color: #0f72a7;
}
.dashboard__img-toggle-shape.orange {
  background-color: orange;
}
.people {
  border-radius: 50%;
  color: white;
  background-color: #fff;
  text-align: center;
}
.teamMGM__nav--days:hover {
  background-color: #9f137e;
  color: #fff;
  transition: 0.3s all;
}
.sec-team-creation {
  min-height: calc(70vh + 50px);
}
</style>
