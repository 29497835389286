<template>
  <div>
    <SideBar />
    <TopBar @agency-changed="agencyChanged" />

    <!-- Begin - Dashboard Content -->

    <!-- Begin - Dashboard Content -->
    <main class="pl-24 pt-24 p-4 w-full h-full bg-gray-200">
      <PoseLists :agency_changed="agencychange" />
    </main>
    <!-- End - Dashboard Content -->

    <!-- End - Dashboard Content -->
  </div>
</template>

<script>
// @ is an alias to /src
import SideBar from "@/components/SideBar.vue";
import TopBar from "@/components/TopBar.vue";
import TabContainer from "@/components/TabContainer.vue";
import PoseLists from "@/components/PoseLists.vue";

export default {
  title: "Dashboard",
  name: "HomeView",
  components: {
    SideBar,
    TopBar,
    TabContainer,
    PoseLists,
  },
  data() {
    return {
      pose_validation: true,
      agencychange: "",
    };
  },
  mounted() {
    const token = localStorage.getItem("token");
  },
  methods: {
    agencyChanged: function () {
      this.agencychange = this.$store.state.selectedAgencyID;
    },
  },
};
</script>
<style scoped>
h6.title {
  color: #9f137e;
}
</style>
