<template>
  <div>
    <!-- Begin - Dashboard Side Navbar -->

    <!-- Component Start -->

    <SideBarSales />
    <!-- Component End  -->

    <!-- End - Dashboard Side Navbar  -->

    <!-- Begin -- Nav Top Bar -->

    <TopBarSales :user_info="user_info" />

    <!-- End - Nav Top Bar -->

    <!-- Begin - Dashboard Content -->

    <!-- Change the left padding to match the side nav bar -->
    <main class="pl-48 pt-24 p-4 w-full h-full bg-gray-100">
      <div class="flex flex-col mb-4">
        <h1 class="text-2xl font-bold text-gray-800">Accueil</h1>
      </div>

      <!-- Begin - Section 1 -->
      <div class="flex flex-row lg:flex-row gap-6 items-start">
        <div
          class="w-full lg:w-6/12 p-4 bg-white rounded-md shadow-md"
          v-for="(category, k) in my_category_details"
          :key="k">
          <div class="flex flex-row items-center py-2 h-full">
            <div
              class="relative w-full min-w-[120px] lg:max-w-[120px] min-h-[120px] px-2 rounded-md"
              :class="['bg-[#' + getProductGroupDetails(k).color + ']']">
              <span
                class="absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] text-5xl text-white"
                >{{ category.length }}</span
              >
            </div>
            <div>
              <h3
                class="text-md uppercase text-black p-4 font-bold"
                :class="['text-[#' + getProductGroupDetails(k).color + ']']">
                {{ getProductGroupDetails(k).name }}
              </h3>

              <div class="px-4 self-center">
                <ul>
                  <h3 class="text-md uppercase text-black">TOTAL</h3>
                  <li
                    class="font-bold uppercase text-2xl"
                    :class="[
                      'text-[#' + getProductGroupDetails(k).color + ']'
                    ]">
                    {{ getSum(category) }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- End - Section 1 -->

      <!-- Begin - Section 2 -->

      <div class="flex flex-row lg:flex-row gap-6 mt-4 mb-20">
        <!-- Begin - 1st Column (Coordnnes Client / Informatins Sur La Pose / Dssier Metre) -->

        <!-- Begin - 3rd Column (Hotel / Nte Sur La Pose) -->
        <div
          class="w-full lg:w-12/12 min-h-[200px] p-4 bg-white rounded-md shadow-md">
          <h3
            class="text-md font-bold uppercase border-b-2 border-fuchsia-700 text-fuchsia-700">
            Liste des poses planifiees
          </h3>

          <div
            class="flex items-center justify-between my-4 bg-fuchsia-700 p-3 rounded-md">
            <div class="rounded-full cursor-pointer hover:bg-white/25">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5"
                viewBox="0 0 20 20"
                fill="white">
                <path
                  fill-rule="evenodd"
                  d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                  clip-rule="evenodd" />
              </svg>
            </div>
            <div class="text-xs text-white font-bold uppercase">
              TOUTES LES POSE ASSIGNÉES
            </div>
            <div class="rounded-full cursor-pointer hover:bg-white/25">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5"
                viewBox="0 0 20 20"
                fill="white">
                <path
                  fill-rule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clip-rule="evenodd" />
              </svg>
            </div>
          </div>

          <div
            class="px-4 max-h-[450px] overflow-y-scroll"
            v-if="my_sales_poses.length > 0">
            <!-- SALES POSES -->
            <div
              class="w-full p-4 rounded-md my-4"
              v-for="(pose, index) in my_sales_poses"
              :key="index"
              :class="{
                'bg-[#ffe5e5]': index == 0,
                'bg-[#ffe5e5]': index % 2,
                'bg-[#e6f9ee]': !(index % 2)
              }">
              <div
                class="flex items-center py-2 gap-4 border-b-2 border-dotted">
                <p class="font-bold uppercase text-sm">
                  {{ getClientDetails(pose.fk_soc).name }}
                </p>
                <span class="uppercase text-gray-400 text-xs self-end">
                  CODE POSTAL: {{ getClientDetails(pose.fk_soc).zip }}
                </span>
              </div>

              <div class="grid grid-cols-1 md:grid-cols-2 items-center text-xs">
                <div class="w-full">
                  <span
                    class="text-xs text-gray-400 px-2 uppercase"
                    v-for="category in JSON.parse(pose.categories)"
                    :key="category"
                    :class="[
                      'text-[#' + getProductGroupDetails(category).color + ']'
                    ]"
                    >{{ getProductGroupDetails(category).name }}</span
                  >
                  <span
                    class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full bg-white border-2 last:mr-0 mr-1"
                    :class="{
                      'text-green-500': pose.status == 2,
                      'text-red-500': pose.status == 1,
                      'text-gray-500': pose.status == 0
                    }">
                    <strong v-if="pose.status == 0">Planning Pending</strong>
                    <strong v-if="pose.status == 1"
                      >Planned, Not assigned</strong
                    >
                    <strong v-if="pose.status == 2">Planned, Assigned</strong>
                  </span>
                  <span
                    class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full bg-white border-2 last:mr-0 mr-1"
                    :class="{
                      'text-green-500': pose.validate == '1',
                      'text-red-500': pose.validate == '0'
                    }">
                    <strong v-if="pose.validate == '1'">Validated</strong>
                    <strong v-if="pose.validate == '0'"
                      >Validation Pending</strong
                    >
                  </span>
                </div>
                <div class="w-full flex justify-end gap-4 items-center p-1">
                  <span class="font-bold"
                    >{{ getDateFormat(pose.start_date) }} au
                    {{ getDateFormat(pose.end_date) }}</span
                  >
                  <router-link :to="{ path: '/salesperson' + pose.id }">
                    <button
                      class="p-2 rounded-md bg-fuchsia-700 text-white uppercase text-xs hover:bg-fuchsia-400 transition-all">
                      Voir la fiche
                    </button>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
          <div
            class="px-4 max-h-[450px] overflow-y-scroll"
            v-else>
            <p class="font-bold uppercase text-sm">Aucune pose planifiée</p>
          </div>
        </div>
        <!-- End - 3rd Column (Hotel / Note Sur La Pose) -->
      </div>

      <!-- End - Section 2 -->
    </main>

    <!-- End - Dashboard Content -->
  </div>
</template>
<script>
import logo from '../assets/logo.png'
import installer_img from '../assets/installer.png'
import store from '@/store'
import TopBarSales from '@/components/TopBarSales.vue'
import SideBarSales from '@/components/SideBarSales.vue'
import { mapState } from 'vuex'
import axios from '../http-common' // <-- HTTP common
import moment from 'moment'

export default {
  title: 'SalesPersonHomeView',
  components: {
    TopBarSales,
    SideBarSales
  },
  data() {
    return {
      logo,
      installer_img,
      name: '',
      selected_agency: '',
      user_info: {},
      my_sales_poses: [],
      my_category_details: {}
    }
  },
  methods: {
    handleLogout() {
      this.$store.commit('setAuthentication', false)
      localStorage.removeItem('user-info')
      this.$router.push('/login')
    },
    getUserInfo() {
      let header = {
        DOLAPIKEY: JSON.parse(window.localStorage.getItem('user-info')).data
          .success.token
      }
      axios
        .get(
          'installationapi/user?includePermissions=1&includeGroups=1&includeLeaves=1',
          {
            headers: header
          }
        )
        .then(response => {
          this.user_info = response.data
        })
        .catch(error => {
          this.user_info = {}
        })
    },

    getClientDetails(id) {
      let name = ''
      let zip = ''
      try {
        this.$store.state.clients.forEach(client => {
          if (client.id == id) {
            name = client.name
            zip = client.zip
          }
        })
        return { name: name, zip: zip }
      } catch (error) {
        return 'Not found'
      }
    },
    getDateFormat(date) {
      return moment.unix(date).format('DD/MM/YYYY')
    },
    getProductGroupDetails(id) {
      let name = ''
      let color = ''
      this.product_groups.forEach(product_group => {
        if (product_group.id == id) {
          name = product_group.name
          color = product_group.color
        }
      })
      return { name: name, color: color }
    },
    getSum(array) {
      const filteredArray = array.filter(Number.isFinite) // Remove NaN and non-finite values
      const sum = filteredArray.reduce((a, b) => a + b, 0)
      return sum
    },
    async loadSalesPose() {
      return new Promise(resolve => {
        const authUser = JSON.parse(window.localStorage.getItem('user-info'))
        let api_url =
          `/installationapi/installations?agency_id=` +
          this.$store.state.selectedAgencyID
        axios
          .get(api_url, {
            headers: {
              DOLAPIKEY: JSON.parse(window.localStorage.getItem('user-info'))
                .data.success.token
            }
          })
          .then(response => response.data)
          .then(poses => {
            poses.reverse().forEach(pose => {
              let salespersons = JSON.parse(pose.user_ids)
              if (salespersons) {
                salespersons.forEach(salesperson => {
                  if (salesperson == authUser.id) {
                    this.my_sales_poses.push(pose)
                    JSON.parse(pose.categories).forEach(category => {
                      if (this.my_category_details[category] == undefined) {
                        this.my_category_details[category] = []
                      }
                      this.my_category_details[category].push(
                        parseInt(pose.estimated_cost)
                      )
                    })
                  }
                })
              }
            })
          })
          .catch(error => {
            this.my_sales_poses = []
            this.my_category_details = {}
          })
        resolve('resolved')
      })
    }
  },
  async mounted() {
    const authUser = JSON.parse(window.localStorage.getItem('user-info'))
    this.name = authUser.firstname + ' ' + authUser.lasttname
    await this.getUserInfo()
    await this.loadSalesPose()
    await this.$store.dispatch('loadProductGroups')
    this.$store.dispatch('loadClients', { limit: '', page: '' })
    this.$store.dispatch('loadUsers')
    this.$store.dispatch('loadMaterials')
  },
  computed: {
    ...mapState({
      agencies: state => state.agencies,
      product_groups: state => state.product_groups
    })
  }
}
</script>
<style scoper>
.logo {
  width: 6rem;
  height: 4rem;
}
.box {
  background-color: #9f137e;
  color: #fff;
}
.pink {
  color: #fff;
}
</style>
