<template>
  <div class="bg-white rounded-md">
    <div>
      <main class="max-w107xl mx-2 px14 sm:px-6">
        <div
          class="
            relative
            z-10
            flex
            items-baseline
            justify-between
            pt-6
            pb-6
            border-b border-gray-200
          "
        >
          <h1 class="text-4xl font-extrabold tracking-tight text-gray-900">
            LISTE DES POSES 
          </h1>
          <input type="hidden" name="customfield" class="form-control" v-model="agency_changed">
          <!-- Begin - Client Filter -->
            <div
              class="
                dashboard-top__content--pose-date-range
                w-full
                md:w-1/4
                lg:w-1/4
              "
            >
              <h5 class="text-xs font-bold">
                <span class="uppercase text-[#9f137e]">Fiche client</span>
              </h5>
              <Multiselect
                mode="tags"
                :close-on-select="true"
                :searchable="true"
                :create-option="false"
                :options="clients"
                :max="1"
                @select="clientSelect($event)"
                @deselect="clientRemove($event)"
              />
            </div>
            <!-- End - Client Filter -->
            <!-- Begin - Client Filter -->
            <div
              class="
                dashboard-top__content--pose-date-range
                w-full
                md:w-1/4
                lg:w-1/4
              "
            >
              <h5 class="text-xs font-bold">
                <span class="uppercase text-[#9f137e]">Filtrer par date</span>
              </h5>
              <Datepicker v-model="pose_date" :disabledWeekDays="[6, 0]" range :partialRange="false" placeholder="Sélectionnez le début et la fin"
                :enableTimePicker="false" :format="format" locale="fr"></Datepicker>
            </div>
            <div
              class="
                dashboard-top__content--pose-date-range
                w-full
                md:w-1/4
                lg:w-1/4
              "
            >
              <h5 class="text-xs font-bold">
                <span class="uppercase text-[#9f137e]">Filtrer par statut</span>
              </h5>
              <Multiselect
                v-model="status_selected"
                mode="tags"
                :close-on-select="false"
                :searchable="true"
                :create-option="false"
                :options="filterStatus"
                :max="7"
              />
            </div>
            <!-- End - Client Filter -->
        </div>

        <section aria-labelledby="products-heading" class="pt-6 pb-24">
          <h2 id="products-heading" class="sr-only">Products</h2>

          <div class="flex flex-wrap space-y-4 lg:space-y-0">
            <!-- Filters -->

            <div class="w-full lg:w-3/12">
              <form class="shadow-md border border-gray-100 rounded-md mx-2">
                <h3 class="pt-4 px-4 uppercase font-bold">FILTRES</h3>
                <FilterBox>
                  <template v-slot:categories>
                    <ul class="flex flex-col pose-list space-y-2 items-end p-4">
                      <li class="w-full rounded-lg">
                        <a
                          href="#"
                          class="
                            filters-item
                            flex
                            items-center
                            rounded-lg
                            px-4
                            py-0
                          "
                          :class="{ active: !category }"
                          @click.prevent="getFilteredData()">
                          Toutes les agences
                        </a>
                      </li>
                      <li
                        class="w-full rounded-lg"
                        v-for="filter, index in filters"
                        :key="index"
                      >
                        <a
                          href="#"
                          class="
                            filters-item
                            flex
                            items-center
                            rounded-lg
                            px-4
                            py-0
                          "
                          :class="{ active: category == filter.id }"
                          @click.prevent="getFilteredData(filter.id)"
                        >
                          <div v-html="icons[index].icon" v-if="icons[index]"></div>

                          {{ filter.name.toUpperCase() }}
                        </a>
                      </li>
                    </ul>
                  </template>
                </FilterBox>
              </form>
            </div>

            <!-- Product grid -->
            <div class="w-full lg:w-9/12">
              <!-- Replace with your content -->

              <div
                class="
                  border-4 border-dashed border-gray-200
                  rounded-lg
                  mx-2
                  h-96
                  overflow-scroll
                  lg:h-full
                "
              >
                <table class="w-full text-base text-left text-gray-500" 
                  v-if="paginated_poses.length > 0">
                  <thead class="text-xs text-gray-700 uppercase bg-gray-50">
                    <tr>
                      <th scope="col" class="px-6 py-3">ID</th>
                      <th scope="col" class="px-6 py-3">Client</th>
                      <th scope="col" class="px-6 py-3">Financement</th>
                      <th scope="col" class="px-6 py-3">logement</th>
                      <th scope="col" class="px-6 py-3">Date D'Installation</th>
                      <th scope="col" class="px-6 py-3">Montant</th>
                      <th scope="col" class="px-6 py-3">Statut</th>
                      <th scope="col" class="px-6 py-3">
                        <span class="sr-only">Modifier</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      class="bg-white border-b"
                      v-for="pose in paginated_poses"
                      :key="pose"
                      >
                      <th
                        scope="row"
                        class="
                          px-6
                          py-4
                          font-medium
                          text-gray-900
                          whitespace-nowrap
                        "
                      >
                        {{ pose.id }}
                      </th>
                      <td class="px-6 py-4">
                        <router-link
                          :to="{ path: '/clients/' + pose.fk_soc  }"
                          >
                          <span class="title">
                            <strong> {{ getClientDetails(pose.fk_soc) }} </strong>
                          </span>
                        </router-link
                        >                        
                      </td>
                      <td class="px-6 py-4">
                        {{ getPaymentDetails(pose.payment_method) }}
                      </td>
                      <td class="px-6 py-4">
                        {{ pose.property_type }}
                      </td>
                      <td class="px-6 py-4" v-if="pose.start_date">
                        {{ getDateFormat(pose.start_date) }}
                      </td>
                      <td class="px-6 py-4" v-else>
                        <span
                          class="text-red-500"
                        >
                          <strong>En attente</strong>
                        </span>
                      </td>
                      <td class="px-6 py-4">
                        <span
                          :class="{
                            'text-green-500': pose.estimated_cost >= 1000,
                            'text-red-500': pose.estimated_cost < 0,
                          }"
                        >
                          <strong>{{ pose.estimated_cost }} </strong>
                        </span>
                      </td>
                      <td class="px-6 py-4">
                        <span
                        class="text-xs font-semibold inline-block py-1 px-2 rounded-full bg-white border-2 uppercase last:mr-0 mr-1"
                          :class="{
                            'text-red-500': pose.status == 5,
                            'text-blue-500': pose.status == 4,
                            'text-blue-500': pose.status == 3,
                            'text-green-500': pose.status == 2,
                            'text-red-500': pose.status == 1,
                            'text-gray-500': pose.status == 0,
                          }"
                        >
                          <strong v-if="pose.status == 0">Plannification En attente</strong>
                          <strong v-if="pose.status == 1">Plannifié, Non assigné</strong>
                          <strong v-if="pose.status == 2">Plannifié, assigné</strong>
                          <strong v-if="pose.status == 3">Finition Non Plannifié</strong>
                          <strong v-if="pose.status == 4">Finition Plannifié</strong>
                          <strong v-if="pose.status == 5">Finition assigné</strong>
                        </span>
                        <span
                        class="text-xs font-semibold inline-block py-1 px-2 rounded-full bg-white border-2 uppercase last:mr-0 mr-1"
                          :class="{
                            'text-pink-500': pose.after_sales == 1,
                          }"
                        v-if="pose.after_sales == 1">
                          <strong >SAV suite à la pose</strong>
                        </span>
                      </td>

                      <td class="px-6 py-4 text-right">
                        <router-link
                          :to="{ path: '/pose/edit/' + pose.id }"
                          append
                          class="
                            dashboard-bot__form--formbtn
                            submit
                            save_continue
                            edit_link
                          "
                          >Modifier</router-link
                        >
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div v-else 
                    class="
                      text-center
                      overflow-scroll
                      lg:h-auto
                      py-20
                      ">   
                      <label class="text-center text-gray-500 py-2">
                      Aucune pose trouvée                      
                    </label><br/>
                    <router-link
                        :to="{ path: '/pose' }"
                        append
                        class="
                          dashboard-bot__form--formbtn
                          save_continue
                          edit_link mt-2
                        "
                        >Ajouter une pose</router-link>
                  </div>
              </div>
              <!-- /End replace -->
            </div>
          </div>

          <div class="flex flex-col items-center mt-2">
            <!-- Help text -->
            <span class="text-sm text-gray-700 dark:text-gray-400">
              Page
              <span class="font-semibold text-gray-900 dark:text-white">{{
                pagination.current_page + 1
              }}</span>
              sur
              <span class="font-semibold text-gray-900 dark:text-white">{{
                pagination.last_page + 1
              }}</span>
            </span>
            <!-- Buttons -->
            <div class="inline-flex mt-2 xs:mt-0">
              <!-- Previous Button -->
              <a
                @click.prevent="fetchPoses('prev')"
                class="
                  inline-flex
                  items-center
                  py-2
                  px-4
                  mr-3
                  text-sm
                  font-medium
                  text-gray-500
                  bg-white
                  rounded-lg
                  border border-gray-300
                  hover:bg-gray-100 hover:text-gray-700
                  cursor-pointer
                "
                v-if="pagination.current_page > 0"
              >
                <svg
                  class="mr-2 w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                Previous
              </a>
              <a
                @click.prevent="fetchPoses('next')"
                class="
                  inline-flex
                  items-center
                  py-2
                  px-4
                  text-sm
                  font-medium
                  text-gray-500
                  bg-white
                  rounded-lg
                  border border-gray-300
                  hover:bg-gray-100 hover:text-gray-700
                  cursor-pointer
                "
                v-if="pagination.current_page !== pagination.last_page"
              >
                Next
                <svg
                  class="ml-2 w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </a>
            </div>
          </div>
        </section>
      </main>
    </div>
  </div>
</template>
<script>
import axios from "../http-common";
import FilterBox from "./FilterBox.vue";
import { mapState } from "vuex";
import moment from 'moment';
import Multiselect from "@vueform/multiselect";
import Datepicker from '@vuepic/vue-datepicker';



export default {
  name: "PoseLists",
  props: ['agency_changed'],
  data() {
    const date = new Date();
    const format = (date) => {
      const day1 = date[0].getDate();
      const month1 = date[0].getMonth() + 1;
      const year1 = date[0].getFullYear();
      const day2 = date[1].getDate();
      const month2 = date[1].getMonth() + 1;
      const year2 = date[1].getFullYear();
      return `${day1}/${month1}/${year1} to ${day2}/${month2}/${year2}`;
    }
    return {
      category: "",
      selected_clients: "",
      pose_date: "",
      format,
      icons: [
      {
          icon: '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" class="iconify iconify--ph h-5 w-5 mr-2"  preserveAspectRatio="xMidYMid meet" viewBox="0 0 256 256"><path fill="currentColor" d="M128 161.2V48a8 8 0 0 0-16 0v113.2a28 28 0 1 0 16 0Zm-8 38.8a12 12 0 1 1 12-12a12 12 0 0 1-12 12Zm58.1-114.3a8 8 0 0 1 0-11.4a28 28 0 0 1 19.8-8.2a28.4 28.4 0 0 1 19.8 8.2a11.9 11.9 0 0 0 16.9 0a8 8 0 0 1 11.3 11.4a28 28 0 0 1-19.8 8.2a28.4 28.4 0 0 1-19.8-8.2a11.9 11.9 0 0 0-16.9 0a8 8 0 0 1-11.3 0Zm67.8 28.6a8 8 0 0 1 0 11.4a28 28 0 0 1-19.8 8.2a28.4 28.4 0 0 1-19.8-8.2a11.9 11.9 0 0 0-16.9 0a8 8 0 0 1-11.3-11.4a28 28 0 0 1 19.8-8.2a28.4 28.4 0 0 1 19.8 8.2a11.9 11.9 0 0 0 16.9 0a8 8 0 0 1 11.3 0Zm-85.9 29V48a40 40 0 0 0-80 0v95.3A59.4 59.4 0 0 0 60 188a60 60 0 0 0 120 0a59.4 59.4 0 0 0-20-44.7ZM120 232a44 44 0 0 1-44-44a43.5 43.5 0 0 1 16.6-34.4A8.1 8.1 0 0 0 96 147V48a24 24 0 0 1 48 0v99a8.2 8.2 0 0 0 3.3 6.5A44 44 0 0 1 120 232Z"></path></svg>',
        },
        {
          icon: '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" class="iconify iconify--carbon h-5 w-5 mr-2"  preserveAspectRatio="xMidYMid meet" viewBox="0 0 32 32"><path fill="currentColor" d="M30 26V14H13v12h5v2H2v2h28v-2h-5v-2zm-5-2v-3h3v3zm-2-3v3h-3v-3zm-3-2v-3h3v3zm8 0h-3v-3h3zm-10-3v3h-3v-3zm-3 8v-3h3v3zm8 4h-3v-2h3zM9.333 14.981A4 4 0 1 1 16 12h-2a2 2 0 1 0-3.333 1.49zM11 2h2v4h-2zm-9 9h4v2H2zm2.222-5.364l1.414-1.414L8.464 7.05L7.05 8.464zM16.95 8.464L15.536 7.05l2.828-2.828l1.414 1.414z"></path></svg>',
        },
        {
          icon: '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" class="iconify iconify--material-symbols h-5 w-5 mr-2" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="currentColor" d="M11 17h2v-6h-2Zm1-8q.425 0 .713-.288Q13 8.425 13 8t-.287-.713Q12.425 7 12 7t-.712.287Q11 7.575 11 8t.288.712Q11.575 9 12 9Zm0 13q-2.075 0-3.9-.788q-1.825-.787-3.175-2.137q-1.35-1.35-2.137-3.175Q2 14.075 2 12t.788-3.9q.787-1.825 2.137-3.175q1.35-1.35 3.175-2.138Q9.925 2 12 2t3.9.787q1.825.788 3.175 2.138q1.35 1.35 2.137 3.175Q22 9.925 22 12t-.788 3.9q-.787 1.825-2.137 3.175q-1.35 1.35-3.175 2.137Q14.075 22 12 22Zm0-10Zm0 8q3.325 0 5.663-2.337Q20 15.325 20 12t-2.337-5.663Q15.325 4 12 4T6.338 6.337Q4 8.675 4 12t2.338 5.663Q8.675 20 12 20Z"></path></svg>',
        },
     
     
        // {
        //   icon: '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" class="iconify iconify--material-symbols h-5 w-5 mr-2"  preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="currentColor" d="M5 21q-.425 0-.713-.288Q4 20.425 4 20v-8.375L3 12.4q-.35.275-.75.212q-.4-.062-.65-.412t-.187-.75q.062-.4.387-.65l9.6-7.325q.125-.1.275-.163q.15-.062.325-.062q.175 0 .325.062q.15.063.275.163l9.625 7.325q.325.25.375.65q.05.4-.2.75q-.25.325-.65.375q-.4.05-.725-.2L20 11.625V20q0 .425-.288.712Q19.425 21 19 21Zm1-2h12v-8.9l-6-4.575L6 10.1Zm0 0h12Zm2-4q.425 0 .713-.288Q9 14.425 9 14t-.287-.713Q8.425 13 8 13t-.713.287Q7 13.575 7 14t.287.712Q7.575 15 8 15Zm4 0q.425 0 .713-.288Q13 14.425 13 14t-.287-.713Q12.425 13 12 13t-.712.287Q11 13.575 11 14t.288.712Q11.575 15 12 15Zm4 0q.425 0 .712-.288Q17 14.425 17 14t-.288-.713Q16.425 13 16 13t-.712.287Q15 13.575 15 14t.288.712Q15.575 15 16 15Z"></path></svg>',
        // },
      ],
      pagination: {
        current_page: 0,
        last_page: 0,
        per_page: 20,
        total_poses_count: 0,
      },
      paginated_poses: [],
      payment_methods: {
        '0': "Chèque",
        '1': "Virement bancaire",
        '2': "Espèce",
        '3': "Carte bancaire",
        '4': "Ordre de prélèvement",
        '5': "TIP",
        '6': "Cetelem",
        '7': "Financo",
        '8': "Domofinance",
        '9': "Franfinance",
        '10': "Projexio",
      },
      // payment_methods: {
      //   '0': "Bancontact",
      //       '1': "Credit card",
      //       '2': "Cheque",
      //       '3': "Factor",
      //       '4': "Giropay",
      //       '5': "iDeal",
      //       '6': "Klarna",
      //       '7': "LCR",
      //       '8': "Cash",
      //       '9': "Debit order",
      //       '10': "Sofort", 
      //       '11': "TIP", 
      //       '12': "Traite",
      //       '13': "Online Payment",
      //       '14': "Transfer",
      // },
      filterStatus: [
        {
          id: 1,
          name: "PLANNIFICATION EN ATTENTE",
          label: "PLANNIFICATION EN ATTENTE",
          value: 0,
        },
        {
          id: 2,
          name: "Plannifié, NON ASSIGNED",
          label: "Plannifié, NON ASSIGNED",
          value: 1,
        },
        {
          id: 3,
          name: "Plannifié, assigné",
          label: "Plannifié, assigné",
          value: 2,
        },        
        {
          id: 4,
          name: "FINITION, Non Plannifié",
          label: "FINITION, Non Plannifié",
          value: 3,
        },
        {
          id: 5,
          name: "FINITION Plannifié",
          label: "FINITION, Plannifié",
          value: 4,
        },
        {
          id: 6,
          name: "FINITION assigné",
          label: "FINITION, assigné",
          value: 5,
        },
        
      ],
      status_selected: [],
    };
  },
  components: {
    FilterBox,
    Multiselect,
    Datepicker
  },
  created() {
    this.$store.dispatch("loadPoses", { limit: "", page: "" });
    this.$store.dispatch("loadClients", { limit: "", page: "" });
    this.$store.dispatch("loadProductGroups", { limit: "", page: "" });

  },
  async mounted() {
    this.$store.dispatch("loadClients", { limit: "", page: "" });
    await this.$store.dispatch("loadProductGroups", { limit: "", page: "" });
    this.pagination.total_poses_count = this.$store.state.poses.length;
    this.pagination.last_page =
      Math.ceil(this.pagination.total_poses_count / this.pagination.per_page) -
      1;
    this.fetchPoses();
    
    this.selected_clients = ""; 
    this.$store.dispatch("loadPoses", { limit: "", page: "" });
   
  },
  computed: {
    ...mapState({
      poses: (state) => state.poses,
      filters: (state) => state.product_groups,
      clients: (state) => state.clients,
    }),
  },
  watch: {
    // agency_changed: function (val) {
    //   console.log(val)
    //   this.clearForm('client');
    // this.$store.dispatch("loadClients");
    // },
    pose_date: function (val) {
      if(val) {
        this.getFilteredData();
      }
    },
    
    status_selected: function (val) {
      this.getFilteredData();
    },
    agency_changed: function (val) {
      this.getFilteredData();
      this.$store.dispatch("loadClients", { limit: "", page: "" });
    },
    
  },
  methods: {
    clientSelect(client) {
      this.selected_clients = client;
      axios
        .get(`installationapi/installations`, {
          headers: {
            DOLAPIKEY: JSON.parse(window.localStorage.getItem("user-info")).data
              .success.token,
          },
        })
        .then((response) => response.data)
        .then((poses) => {
          this.paginated_poses = [];
          if(this.selected_clients){
            poses.forEach((pose) => {
              if(pose.fk_soc == this.selected_clients){
                this.paginated_poses.push(pose);
                // console.log(this.paginated_poses)
              }
            });
          }else{
            this.paginated_poses = poses;
          }
          this.pagination.total_poses_count = poses.length;
          this.pagination.last_page =
            Math.ceil(this.pagination.total_poses_count / this.pagination.per_page) -
            1;
        })
        .catch((error) => {
          this.pagination = {
              current_page: 0,
              last_page: 0,
              per_page: 10,
              total_poses_count: 0,
            }
        });
    },
    clientRemove(client) {
      this.selected_clients = "";
      this.getFilteredData();
    },
    getDateFormat(date){
      return moment.unix(date).format('DD/MM/YYYY');
    },
    getPaymentDetails(id) {
      return this.payment_methods[id];
    },
    getClientDetails(id) {
      let name = "";
      try {        
        this.$store.state.clients.forEach((client) => {
          if (client.id == id) {
            name =  client.name
          }
        });
        return name;
      } catch (error) {
        return "Not found";
      }
    },
    getFilteredData(category) {
      this.category = category;
      this.fetchPoses();
    },
    fetchPoses(flag) {
      if (flag === "next") {
        this.pagination.current_page = this.pagination.current_page + 1;
      } else if (flag === "prev") {
        this.pagination.current_page = this.pagination.current_page - 1;
      } else {
        this.pagination.current_page = 0;
      }
      this.paginated_poses = [];      
      let api_url = '';
      if(this.category) {
        api_url =
                `/installationapi/installations?&category_id=` +
                this.category +
                `&limit=` +
                this.pagination.per_page +

                this.pagination.current_page +
              
                `&agency_id=` +
                this.$store.state.selectedAgencyID;
      } else {
        api_url =
              `/installationapi/installations?limit=` +
              this.pagination.per_page +
              `&page=` +
              this.pagination.current_page +
              `&agency_id=` +
                this.$store.state.selectedAgencyID;
      }
      if(this.pose_date) {
        const start_date = moment(this.pose_date[0]).subtract(1, 'day').format('YYYY-MM-DD');
        const end_date = moment(this.pose_date[1]).add(1, 'days').format('YYYY-MM-DD');
        api_url += `&sqlfilters=start_date BETWEEN '`+ start_date +`' AND '`+ end_date +`'`;
      }  
      if(this.status_selected.length > 0) {
        // api_url += `&sqlfilters=status IN (`+ this.status_selected.join(',') +`)`;
        api_url += '&sqlfilters=(status:IN:'+ this.status_selected.join(',') +')';
      } 
      axios
        .get(api_url, {
          headers: {
            DOLAPIKEY: JSON.parse(window.localStorage.getItem("user-info")).data
              .success.token,
          },
        })
        .then((response) => response.data)
        .then((poses) => {
          this.paginated_poses = [];
            poses.forEach((pose) => {  
                this.paginated_poses.push(pose);   
            });
          this.pagination.total_poses_count = poses.length;
          this.pagination.last_page =
            Math.ceil(this.pagination.total_poses_count / this.pagination.per_page) -
            1;
        })
        .catch((error) => {
          this.pagination = {
              current_page: 0,
              last_page: 0,
              per_page: 10,
              total_poses_count: 0,
            }
            
        });
    },
    
  },
};
</script>
<style scoped>
.edit_link,
span.title {
  color: #9f137e;
}
a.edit_link {
  background-color: #9f137e;
  color: #fff;
  border: #9f137e solid 1px;
}
a.edit_link:hover {
  color: #9f137e;
  background-color: #fff;
  border: #9f137e solid 1px;
}
.pose-list .active {
  background-color: #9f137e;
  color: #fff;
  border: #9f137e solid 1px;
  font-weight: bold;
  border-radius: 10px;
}
.filters-item {
  border: #9f137e solid 1px;
}
</style>