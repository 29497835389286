import { createStore } from 'vuex'
import axios from "../http-common"; // <-- HTTP common
import createPersistedState from 'vuex-persistedstate'; // <-- Persisted state
import moment from 'moment'; // <-- Moment

export default createStore({
    state: {
        isAuthenticated: !!localStorage.getItem('user-info'),
        agencies: [],
        selectedAgencyID: null,
        clients: [],
        vendors: [],
        product_groups: [],
        user_categories: [],
        poses: [],
        salespersons: [],
        allSalesPersons:[],
        installers: [],
        metrepersons:[],
        user_info: {},
        pose_categories_planning: [],
        finition_categories_planning: [],
        hotels: [],
        payments:[],
        installer_poses: []
    },
    plugins: [createPersistedState()],
    mutations: {
        SET_AGENCY_SELECTION(state, payload) {
            state.selectedAgencyID = payload;
        },
        setAuthentication(state, status) {
            state.isAuthenticated = status;
        },
        setUserInfo(state, user_info) {
            state.user_info = user_info;
        },
        clearData(state) {
            state.agencies = [];
            state.selectedAgencyID = null;
            state.clients = [];
            state.vendors = [];
            state.product_groups = [];
            state.user_categories = [];
            state.poses = [];
            state.salespersons = [];
            state.installers = [];
            state.metrepersons = [];
            state.user_info = {};
            state.pose_categories_planning = [];
            state.finition_categories_planning = [];
            state.hotels = [];
            state.payments = [];
            state.installer_poses = [];
            state.logements = [];
            state.materials = [];
        },
        LogOut(state) {
            isAuthenticated = false;
            localStorage.removeItem("user-info");
            localStorage.removeItem("token");

        },
        setDolAPIkey(state, key) {
            state.dolapikey = key;
        },
        setAgencies(state, agencies) {
            // console.log(state.agencies);
            if(state.agencies.length == 0){
                console.log("set agencies");
                state.agencies = []
                let user_type = JSON.parse(window.localStorage.getItem('user-info')).user;
                agencies.forEach(agency => {
                    if (state.selectedAgencyID == "0") {
                        state.agencies.push({
                            id: agency.id,
                            name: agency.name,
                            label: agency.label,
                        });
                    } else {
                        if (user_type == "admin") {
                            state.agencies.push({
                                id: agency.id,
                                name: agency.name,
                                label: agency.label,
                            });
                        } else if (agency.id == state.selectedAgencyID) {
                            state.agencies.push({
                                id: agency.id,
                                name: agency.name,
                                label: agency.label,
                            });
                        }
                    }
                });
            }
        },
        setHotels(state, clients) {
            state.hotels = [];
            clients.forEach(client => {
                if (client.typent_id == process.env.VUE_APP_HOTEL_TYPE_INT_ID) {
                    // console.log('yes',client.typent_id)
                    state.hotels.push({
                        id: client.id,
                        name: client.name,
                        value: client.id,
                        label: client.name,
                        postal_code: client.zip,
                        address1: client.address,
                        address2: client.town,
                        city: client.town,
                        zip: client.zip,
                        phone: client.phone,

                    });
                }
            });
        },
         setPayments(state, payments) { 
            // console.log("payments",payments)
            state.payments = [];
            payments.forEach(payment => {
                state.payments.push({
                        id: payment.id,
                        code: payment.code,
                        type: payment.type,
                        label: payment.label,
   
                    });
                
            });
        },
        setClients(state, clients) {
            state.clients = []
            state.vendors = [];
            // if (this.state.selectedAgencyID != "0") {
            //     clients = clients.filter(client => client.array_options.options_agency == this.state.selectedAgencyID);
            // }
            clients.forEach(client => {
                // console.log(client)
                if (client.client == 1 && client.fournisseur == 0) {
                    state.clients.push({
                        id: client.id,
                        name: client.name,
                        label: client.phone ? client.name + " (" + client.phone + ")" : client.name,
                        value: client.id,
                        address1: client.address,
                        address2: client.town,
                        city: client.town,
                        zip: client.zip,
                        phone: client.phone,
                        portable: client.phone,
                        email: client.email,
                        name_alias: client.name_alias,   // using name_alias for substitutng  value instead of origin  
                        code_client: client.code_client,
                        date_creation: moment.unix(client.date_creation).format("DD/MM/YYYY"),
                        agency:client.array_options.options_agency
                
                    });
                }
                if (client.fournisseur == 1) {
                    state.vendors.push({
                        id: client.id,
                        name: client.name,
                        value: client.id,
                        label: client.name,
                        postal_code: client.zip,
                    });
                }
            });
        },
        setMaterials(state, materials) {
            // console.log("material",materials)
            state.materials = [];
            materials.forEach(material => {
                state.materials.push({
                    id: material.id,
                    name: material.label,
                    label: material.label,
                    value: material.id,
                    price: material.price,
                    price_ttc: material.price_ttc,
                });
            });
        },
        setProductGroups(state, product_groups) {
            state.product_groups = [];
            product_groups.forEach(product_group => {
                state.product_groups.push({
                    id: product_group.id,
                    name: product_group.label,
                    label: product_group.label,
                    color: product_group.color,
                });
            });
        },
        setUserCategories(state, user_categories) {
            state.user_categories = [];
            user_categories.forEach(product_group => {
                state.user_categories.push({
                    id: product_group.id,
                    name: product_group.label,
                    label: product_group.label,
                    color: product_group.color,
                });
            });
        },
        setPoses(state, poses) {
            state.poses = [];
            poses.forEach(pose => {
                state.poses.push({
                    id: pose.id,
                    client: pose.fk_soc,
                    pose_validation: false,
                    client_notes: pose.note_client,
                    financement: pose.payment_method,
                    categories: pose.categories,
                    logement: pose.property_type,
                    date_creation: pose.date_creation ? moment.unix(pose.date_creation).format("DD/MM/YYYY") : '',
                    start_date: pose.start_date ? moment.unix(pose.start_date).format("YYYY-MM-DD") : '',
                    end_date: pose.end_date ? moment.unix(pose.end_date).format("YYYY-MM-DD") : '',
                    cost: pose.estimated_cost,
                    planning: pose.planning,
                    finition_planning: pose.finition_planning,
                    estimated_days: pose.estimated_days,
                    status: pose.status,
                    estimated_cost: pose.estimated_cost,
                    validate: pose.validate,
                    user_ids: pose.user_ids,
                });
            });
        },

        setSalespersons(state, users) {
            state.salespersons = [];
            users.forEach(user => {
                let selectedAgencyID = state.selectedAgencyID;
                if (user.admin === "0" && user.array_options['options_agency'] == selectedAgencyID && user.id !== state.user_info.id) {
                    state.salespersons.push({
                        id: user.id,
                        name: user.firstname + ' ' + user.lastname,
                        value: user.id,
                        label: user.firstname + ' ' + user.lastname,
                        firstname: user.firstname,
                        lastname: user.lastname,
                        photo: user.photo ? user.photo.small : user.photo,
                        category: user.category,
                        leaves: user.leaves,
                    })
                }
            })
        },
        setAllSalespersons(state, users) {
            // console.log('salesguy', users);
            state.allSalesPersons = [];
            users.forEach(user => {
                if (user.admin === "0"&& user.id !== state.user_info.id) {
                    state.allSalesPersons.push({
                        id: user.id,
                        name: user.firstname + ' ' + user.lastname,
                        value: user.id,
                        label: user.firstname + ' ' + user.lastname,
                        firstname: user.firstname,
                        lastname: user.lastname,
                        photo: user.photo ? user.photo.small : user.photo,
                        category: user.category,
                        leaves: user.leaves,
                        agency_id: user.array_options.options_agency
                    })
                }
            })
            // console.log("useer",  state.allSalesPersons);
        },

        setInstallers(state, users) {
            // console.log('installers',users)
            state.installers = [];
            users.forEach(user => {
                state.installers.push({
                    id: user.id,
                    name: user.firstname + ' ' + user.lastname,
                    value: user.id,
                    label: user.firstname + ' ' + user.lastname,
                    firstname: user.firstname,
                    lastname: user.lastname,
                    photo: user.photo ? user.photo.mini : "",
                    category: user.category.length > 0 ? user.category : [],
                    leaves: user.leaves,
                    team_lead: user.array_options.options_team_lead,
                })
            })
        },

        setMetres(state, users) {
            // console.log('metres',users)
            state.metrepersons = [];
            users.forEach(user => {
                state.metrepersons.push({
                    id: user.id,
                    name: user.firstname + ' ' + user.lastname,
                    value: user.id,
                    label: user.firstname + ' ' + user.lastname,
                    firstname: user.firstname,
                    lastname: user.lastname,
                    photo: user.photo ? user.photo.mini : "",
                    category: user.category.length > 0 ? user.category : [],
                    leaves: user.leaves,
                    team_lead: user.array_options.options_team_lead,
                })
            })
        },

        setPoseCategoriesPlaning(state, pose_categories) {
            state.pose_categories_planning = [];
            const keys = Object.keys(pose_categories);
            let no_of_teams = {};

            keys.forEach(key => {
                no_of_teams[key] = [];
                const cat_keys = Object.keys(pose_categories[key].days);
                cat_keys.forEach(cat_key => {
                    no_of_teams[key].push(pose_categories[key].days[cat_key].teams.length);
                })
            });

            keys.forEach((key, index) => {
                state.pose_categories_planning.push({
                    id: key,
                    name: pose_categories[key].name,
                    label: pose_categories[key].name,
                    no_of_days: pose_categories[key].no_of_days,
                    no_of_teams: Math.max(...no_of_teams[key]),
                    days: pose_categories[key].days,
                    color: pose_categories[key].color
                });
            });

        },
        setFinitionCategoriesPlaning(state, pose_categories) {
            state.finition_categories_planning = [];
            const keys = Object.keys(pose_categories);
            let no_of_teams = {};

            keys.forEach(key => {
                no_of_teams[key] = [];
                const cat_keys = Object.keys(pose_categories[key].days);
                cat_keys.forEach(cat_key => {
                    no_of_teams[key].push(pose_categories[key].days[cat_key].teams.length);
                })
            });

            keys.forEach((key, index) => {
                state.finition_categories_planning.push({
                    id: key,
                    name: pose_categories[key].name,
                    no_of_days: pose_categories[key].no_of_days,
                    no_of_teams: Math.max(...no_of_teams[key]),
                    days: pose_categories[key].days,
                    color: pose_categories[key].color
                });
            });

        },
        createPoseCategoriesPlanings(state, pose_categories) {
            state.pose_categories_planning = [];
            pose_categories.forEach(pose_category => {
                state.pose_categories_planning.push({
                    id: pose_category.id,
                    no_of_days: 0,
                    no_of_teams: 0,
                    name: pose_category.label,
                    color: pose_category.color,
                    days: {},
                });
            });
        },
        createFinitionCategoriesPlanings(state, pose_categories) {
            state.finition_categories_planning = [];
            pose_categories.forEach(pose_category => {
                state.finition_categories_planning.push({
                    id: pose_category.id,
                    no_of_days: 0,
                    no_of_teams: 0,
                    name: pose_category.label,
                    color: pose_category.color,
                    days: {},
                });
            });
        },
        setInstallerPoses(state, args) {
            let data = [];
            state.installer_poses = [];
            state.poses.forEach(pose => {
                const keys = Object.keys(pose.planning);
                keys.forEach((key, index) => {
                    const day_keys = Object.keys(pose.planning[key].days);
                    day_keys.forEach((k, i) => {
                        const team_keys = Object.keys(pose.planning[key].days[k].teams);
                        team_keys.forEach((l, j) => {
                            if (pose.planning[key].days[k].teams[l].members) {
                                const memb_keys = Object.keys(pose.planning[key].days[k].teams[l].members);
                                memb_keys.forEach((m, o) => {
                                    // if (args.id == pose.planning[key].days[k].teams[l].members[m]) {
                                        if (pose.planning[key].days[k].teams[l].members[m]) {
                                        if (!data.includes(pose.id)) {
                                            data.push(pose.id)
                                        }
                                    }
                                });
                            }
                        });
                    });
                });
            });
            state.installer_poses = data;
            // console.log(data)
        }

    },
    getters: {
        agencies: state => {
            return state.agencies;
        },
        hotels: state => {
            return state.hotels;
        },
        payments: state => {
            return state.payments;
        },
        clients: state => {
            return state.clients;
        },
        vendors: state => {
            return state.vendors;
        },
        materials: state => {
            return state.materials;
        },
        product_groups: state => {
            return state.product_groups;
        },
        poses: state => {
            return state.poses;
        },
        selectedAgencyID: state => {
            return state.selectedAgencyID;
        },
        salespersons: state => {
            return state.allSalesPersons;
        },
        metrepersons: state => {
            return state.metrepersons;
        },
        pose_categories_planning: state => {
            return state.pose_categories_planning;
        },
        user_categories: state => {
            return state.user_categories;
        },
        installer_poses: state => {
            return state.installer_poses;
        }
    },
    actions: {
        // Load Agencies from API
        loadAgencies({ commit }) {
            axios.get('/agenciesapi/agenciess', {
                    headers: {
                        'DOLAPIKEY': JSON.parse(window.localStorage.getItem('user-info')).data.success.token,
                    }
                })
                .then(response => response.data)
                .then(agencies => {
                    commit('setAgencies', agencies)
                })
                .catch(error => {
                    commit('setAgencies', [])
                });

        },

        // Load Clients from API
        loadClients({ commit }, args) {
            // let api_url = `/installationapi/thirdparties`;
            // if (args.limit) {
            //     api_url = `/installationapi/thirdparties?limit=` + args.limit + `&page=` + args.page;
            // }

            var filteragencyid = '';
            if(this.state.selectedAgencyID != "0"){
                filteragencyid = ` AND (efu.agency:=:${this.state.selectedAgencyID}) `;
            }
            
            let api_url = `/installationapi/thirdparties?sqlfilters=(client:=:1) AND (fournisseur:=:0)`+filteragencyid;

            axios.get(api_url, {
                    headers: {
                        'DOLAPIKEY': JSON.parse(window.localStorage.getItem('user-info')).data.success.token,
                    }
                })
                .then(response => response.data)
                .then(clients => {
                    commit('setClients', clients);
                })
                .catch(error => {
                    commit('setClients', [])
                });
        },
        loadHotels({ commit }, args) {
            let api_url = `/thirdparties`;
            // if (args.limit) {
            //     api_url = `/thirdparties?limit=` + args.limit + `&page=` + args.page;
            // }
            axios.get(api_url, {
                    headers: {
                        'DOLAPIKEY': JSON.parse(window.localStorage.getItem('user-info')).data.success.token,
                    }
                })
                .then(response => response.data)
                .then(clients => {
                    commit('setHotels', clients);
                })
                .catch(error => {
                    commit('setHotels', [])
                });
        },
        loadPayments({ commit }, args) {
            let api_url = `/setup/dictionary/payment_types`;
            // if (args.limit) {
            //     api_url = `/thirdparties?limit=` + args.limit + `&page=` + args.page;
            // }
            axios.get(api_url, {
                    headers: {
                        'DOLAPIKEY': JSON.parse(window.localStorage.getItem('user-info')).data.success.token,
                    }
                })
                .then(response => response.data)
                .then(payments => {
                    commit('setPayments', payments); 
                    // console.log(payments)
                })
               
                .catch(error => {
                    commit('setPayments', [])
                });
        },
        // Load Installers POSES
        loadInstallerPoses({ commit }, args) {
            commit('setInstallerPoses', args);
        },

        // Load materials from API
        loadMaterials({ commit }) {
            axios.get('/products', {
                    headers: {
                        'DOLAPIKEY': JSON.parse(window.localStorage.getItem('user-info')).data.success.token,
                    }
                })
                .then(response => response.data)
                .then(materials => {
                    commit('setMaterials', materials)
                })
                .catch(error => {
                    commit('setMaterials', [])
                });
        },

        // Load Product Groups
        loadProductGroups({ commit }) {
            axios.get('/categories?type=product&sqlfilters=(t.fk_parent:=:0)', {
                    headers: {
                        'DOLAPIKEY': JSON.parse(window.localStorage.getItem('user-info')).data.success.token,
                    }
                })
                .then(response => response.data)
                .then(product_groups => {
                    commit('setProductGroups', product_groups)
                })
                .catch(error => {
                    commit('setProductGroups', [])
                });
        },

        // Load User Categories
        loadUserCategories({ commit }) {
            // axios.get('/categories?sqlfilters=t.type='+process.env.VUE_APP_SALESPERSON_GROUP_ID, {
            axios.get('/categories?sqlfilters=(t.type:=:'+process.env.VUE_APP_SALESPERSON_GROUP_ID+')', {
                    headers: {
                        'DOLAPIKEY': JSON.parse(window.localStorage.getItem('user-info')).data.success.token,
                    }
                })
                .then(response => response.data)
                .then(user_categories => {
                    commit('setUserCategories', user_categories)
                })
                .catch(error => {
                    commit('setUserCategories', [])
                });
        },

        // Load POSES from API
        loadPoses({ commit }, args) {
            let api_url = `/installationapi/installations?agency_id=` + this.state.selectedAgencyID;
            if (args.limit.length > 0) {
                api_url = `/installationapi/installations?limit=` + args.limit + `&page=` + args.page;
            }
            axios.get(api_url, {
                    headers: {
                        'DOLAPIKEY': JSON.parse(window.localStorage.getItem('user-info')).data.success.token,
                    }
                })
                .then(response => response.data)
                .then(poses => {
                    commit('setPoses', poses)
                })
                .catch(error => {
                    commit('setPoses', [])
                });
        },

        // Set selected Agency
        updateAgencySelection(context, payload) {
            context.commit('SET_AGENCY_SELECTION', payload)

        },

        // Load USERs as SALESPERSON from API
        // Now we are fetching all users
        // because there is not goup added in API for salesperson
        loadUsers({ commit }) {
            axios.get('installationapi/users?agency_id=' + this.state.selectedAgencyID + '&group=' + process.env.VUE_APP_SALESPERSON_GROUP_ID, {
                    headers: {
                        'DOLAPIKEY': JSON.parse(window.localStorage.getItem('user-info')).data.success.token,
                    }
                })
                .then(response => response.data)
                .then(users => {
                    commit('setSalespersons', users)
                  
                })
                .catch(error => {
                    commit('setSalespersons', [])
                });
        },
        // because there is not goup added in API for salesperson
        loadAllUsers({ commit }) {
             // const VUE_SALESPERSON_GROUP_ID = 7
             let url = 'installationapi/users?group=' + process.env.VUE_APP_SALESPERSON_GROUP_ID;
            axios.get(url, {
                    headers: {
                        'DOLAPIKEY': JSON.parse(window.localStorage.getItem('user-info')).data.success.token,
                    }
                })
                .then(response => response.data)
                .then(users => {
             
                        commit('setAllSalespersons', users)
                    
                    
                

           
                })
                .catch(error => {
                    commit('setAllSalespersons', [])
                });
        },
        loadInstallers({ commit }) {
            // const VUE_INSTALLER_GROUP_ID = 23
            let url = 'installationapi/users?group=' + process.env.VUE_APP_INSTALLER_GROUP_ID;
            axios.get(url, {
                    headers: {
                        'DOLAPIKEY': JSON.parse(window.localStorage.getItem('user-info')).data.success.token,
                    }
                })
                .then(response => response.data)
                .then(users => {
                    commit('setInstallers', users)
                })
                .catch(error => {
                    console.log(error, process.env.VUE_APP_INSTALLER_GROUP_ID)
                    commit('setInstallers', [])
                });
        },

        loadMetres({ commit }) {
            // const VUE_METRE_GROUP_ID = 16
            let url = 'installationapi/users?group=' + process.env.VUE_APP_METRE_GROUP_ID;
            axios.get(url, {
                    headers: {
                        'DOLAPIKEY': JSON.parse(window.localStorage.getItem('user-info')).data.success.token,
                    }
                })
                .then(response => response.data)
                .then(users => {
                    commit('setMetres', users)
                })
                .catch(error => {
                    console.log(error, process.env.VUE_APP_INSTALLER_GROUP_ID)
                    commit('setMetres', [])
                });
        },

        loadPoseCategoriesPlaning(context, payload, event) {
            context.commit('setPoseCategoriesPlaning', payload)
        },
        loadFinitionCategoriesPlaning(context, payload, event) {
            context.commit('setFinitionCategoriesPlaning', payload)
        },

        createPoseCategoriesPlaning(context, payload, event) {
            context.commit('createPoseCategoriesPlanings', payload)
        },
        createFinitionCategoriesPlaning(context, payload, event) {
            context.commit('createFinitionCategoriesPlanings', payload)
        }


    },
    modules: {}
})
