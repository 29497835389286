<template>
  <!-- Begin - Installer Dashboard Side Navbar -->
    <nav
        class="fixed left-0 top-0 z-9 flex flex-col items-center w-40 h-full overflow-hidden text-gray-700 bg-gray-100 rounded"
        style="z-index: 999999;"
      >
        <a class="flex items-center w-full px-3 mt-3" href="/installer">
            <!-- Replace SVG and remove span with img logo -->
            <img class="mx-auto h-12 w-auto" :src="logo" alt="Workflow" />
        </a>
        <div class="w-full px-2">
          <div class="flex flex-col items-center w-full mt-3 border-t border-gray-300">
            <router-link
                    to="/salesperson"
                    class="
                    flex items-center w-full h-12 px-3 mt-2 rounded hover:bg-purple-200 active:bg-purple-200
                    "
                    title="Home"
                >
                <svg
                class="w-6 h-6 stroke-current"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
                />
              </svg>
                <span class="ml-2 text-sm font-medium">Accueil</span>
            </router-link>
            <router-link class="flex items-center w-full h-12 px-3 mt-2 rounded hover:bg-purple-200 active:bg-purple-200" to="/salesperson/planning">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                />
              </svg>
              <span class="ml-2 text-sm font-medium">Planning</span>
            </router-link>
          </div>
        </div>
      </nav>
  <!-- End - Dashboard Side Navbar  -->
</template>

<script>
import logo from "../assets/logo_icon.png";

export default {
  name: "SideBarSales",
  props: {
    msg: String,
  },
  data() {
    return {
      logo: logo,
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.home svg {
  color: #cbc663;
}
.menu2 svg {
  color: #cec520;
}
.menu3 svg {
  color: #9f137e;
}
.menu4 svg {
  color: #9f137e;
}
.menu5 svg {
  color: #4d725c;
}
.menu6 {
  background-color: #9f137e;
}
.menu6 svg {
  color: #fff;
}
.logo svg {
  color: #9f137e;
}

a.router-link-active {
  --tw-bg-opacity: 1;
  background-color: #e9d5ff;
}
.logo_icon {
  width: 3rem;
  height: 3rem;
}
</style>
