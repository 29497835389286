<template>
  <div class="bg-white rounded-md">
      <div
        class="
          flex flex-wrap
          lg:flex-nowrap
          items-center
          content-center
          gap-10
          p-6
          w-full
          bg-white
          rounded-lg
          border border-gray-200
          shadow-md
        "
      >
        <!-- Begin - Date Range Component -->
        <div
          class="
            dashboard-top__content--pose-date-range
            w-full
            md:w-6/12
            lg:w-2/12
          "
        >
          <span class="text-xs">Nom du client</span>
          <h5 class="text-sm font-bold">
            <span class="uppercase text-[#9f137e]">{{ detail.name }}</span>
          </h5>
        </div>
        <!-- End - Date Range Component -->

        <!-- Begin - Date Range Component -->
        <div
          class="
            dashboard-top__content--pose-date-range
            w-full
            md:w-6/12
            lg:w-2/12
          "
        >
          <span class="text-xs">Date de création</span>
          <h5 class="text-sm font-bold">
            <span class="text-[#9f137e] uppercase">{{ detail.date_creation }}</span>
          </h5>
        </div>
        <!-- End - Date Range Component -->
      </div>


      <!-- Begin - Tab Container  -->
  <div class="p-6 w-full bg-white rounded-lg border border-gray-200 shadow-md">
    <!-- Begin - Tab Content -->

    <!-- CLIENT -->
    <div
      class="flex flex-wrap py-6 w-full client"
    >

      <form
        class="dashboard-tab__form w-full lg:w-6/12 px-2 client_1_form"
        action=""
      >
        <!-- Client Field -->
        <div class="w-full">
          <div class="flex gap-2 w-full">
            <div class="flex flex-col w-full">
              <span class="text-xs">Adresse</span>
              <h5 class="text-sm font-bold">
                <span class="text-[#9f137e] uppercase">{{ detail.address1 }}</span>
              </h5>              
            </div>

            <div class="flex flex-col w-full">
              <span class="text-xs">Rue</span>
              <h5 class="text-sm font-bold">
                <span class="text-[#9f137e] uppercase">{{ detail.address2 }}</span>
              </h5> 
            </div>
          </div>
        </div>

        <!-- Client Name Field -->
        <div class="w-full mt-4">
          <div class="flex gap-2 w-full">
            <div class="flex flex-col w-full">
              <span class="text-xs">VIlle</span>
              <h5 class="text-sm font-bold">
                <span class="text-[#9f137e] uppercase">{{ detail.city }}</span>
              </h5>
            </div>

            <div class="flex flex-col w-full">
              <span class="text-xs">Code postal</span>
              <h5 class="text-sm font-bold">
                <span class="text-[#9f137e] uppercase">{{ detail.zip }}</span>
              </h5>
            </div>
          </div>
        </div>


        <!-- Telephone Field -->
        <div class="w-full mt-4">
          <div class="flex gap-2 w-full">
            <div class="flex flex-col w-full">
              <span class="text-xs">Téléphone</span>
              <h5 class="text-sm font-bold">
                <span class="text-[#9f137e] uppercase">{{ detail.phone }}</span>
              </h5>
            </div>

            <div class="flex flex-col w-full">
              <span class="text-xs">Email</span>
              <h5 class="text-sm font-bold">
                <span class="text-[#9f137e] lowercase">{{ detail.email }}</span>
              </h5>
            </div>
          </div>
        </div>

        <!-- Email Field -->
        <div class="w-full mt-4">
          <div class="flex gap-2 w-full">
            <div class="flex flex-col w-full">
              <span class="text-xs">Provenance du client</span>
              <h5 class="text-sm font-bold">
                <span class="text-[#9f137e] uppercase">{{ detail.name_alias }}</span>
              </h5>
            </div>

            <div class="flex flex-col w-full">
              <span class="text-xs">Code Client </span>
              <h5 class="text-sm font-bold">
                <span class="text-[#9f137e] uppercase">{{ detail.code_client }}</span>
              </h5>
            </div>
          </div>
        </div>
      </form>

      <form
        class="dashboard-tab__form w-full lg:w-6/12 px-2 client_2_form"
        action=""
      >

        <div class="w-full mt-4">
          <div class="flex gap-2 items-end w-full">
            <div class="flex flex-col w-full">
              <label for="" class="dashboard-bot__form--labels"
                >Documents</label
              >
              <div class="relative flex items-center mt-2">
                <label for="attach" class="absolute left-2 cursor-pointer px-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6 file-attachment"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12"
                    />
                  </svg>
                </label>

                <input
                  name="attach"
                  id="attach"
                  type="file"
                  class="
                    w-full
                    dashboard-bot__form--inputs
                    upload
                    cursor-pointer
                  "
                  @change="
                    filesChange($event.target.name, $event.target.files,$event, 'Newdocs');
                    fileCount = $event.target.files.length; 
                  "
                />

              </div>
              
            </div>
          </div>
        </div>
      </form>
    </div>
    <div
      class="flex items-center justify-end space-x-4 client"
    >
      <router-link
        to="/clients/"
        tag="button"
        class="dashboard-bot__form--formbtn back_list"
        >RETOUR À LA LISTE</router-link
      >
    </div>
    <!-- end of Client -->
  </div>

      
  </div>
</template>
<script>
import FilterBox from "./FilterBox.vue";
import moment from 'moment';


export default {
  name: "PoseLists",
  props: {
    router_id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      docus: [],
      detail: {},
      pending_files : [],
    };
  },
  components: {
    FilterBox,
  },
  mounted() {
    this.getClientDetails();
  },
  methods: {

 // File Upload
 filesChange(fieldName, fileList, $event, name) {
      let previewImage ="";
      if (!fileList.length) return;     

      // append the files to FormData
      const file = $event.target.files[0]
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (event) => {
      // console.log(fileList[0].name)
        previewImage = event.target.result.split(';base64,')[1];
        Array.from(Array(fileList.length).keys()).map((x) => {
          if (this.pose_id){
            this.fileSaveStatus = 'saving';
            this.uploadFile({
              filename: name+'__'+fileList[x].name,
              id: this.pose_id,
              fileencoding:"base64",
              filecontent: previewImage,
              overwriteifexists: 1
            })
          }else{
            this.fileSaveStatus = 'later';
            this.pending_files.push({
              filename: name+'__'+fileList[x].name,
              id: this.pose_id,
              fileencoding:"base64",
              filecontent: previewImage,
              overwriteifexists: 1
            })
          }
         
          
        });

       }; 
       
       if(name.startsWith("Newdocs")){
          this.docus.push({'name':name+'__'+file.name});
        }
        // if(name.startsWith("metre_fichier")){
        //   this.metre_pose_files.push({'name':name+'__'+file.name});
        // }
        // if(name.startsWith("pose_fichier")){
        //   this.pose_download_files.push({'name':name+'__'+file.name});
        // }  
        // if(name.startsWith("hotel_fichier__")){
        //   this.hotel_files.push({'name':name+'__'+file.name});
        // } 
        // if(name.startsWith("fin_fichier")){
        //   this.fin_download_files.push({'name':name+'__'+file.name});
        // } 
        
    },

    getClientDetails() {
      try {        
        this.$store.state.clients.forEach((client) => {
          if (client.id == this.router_id) {
            this.detail = client;
            // console.log(this.detail)
          }
        });
      } catch (error) {
        return "Not found";
      }
    }
  },
};
</script>