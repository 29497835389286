<template>
  <div>
    <SideBar />
    <TopBar @agency-changed="agencyChanged"/>

    <!-- Begin - Dashboard Content -->

    <!-- Begin - Dashboard Content -->
      <main class="pl-24 pt-24 p-4 w-full h-full bg-gray-200">
        <ClientList :agency_changed="agencychange"/>
      </main>
      <!-- End - Dashboard Content -->

    <!-- End - Dashboard Content -->
  </div>
    
</template>

<script>
// @ is an alias to /src
import SideBar from '@/components/SideBar.vue'
import TopBar from '@/components/TopBar.vue'
import TabContainer from '@/components/TabContainer.vue'
import ClientList from '@/components/ClientList.vue'

export default {
  title: 'Clients List',
  name: 'ListClients',
  components: {
    SideBar,
    TopBar,
    TabContainer,
    ClientList
  }, 
  data() {
    return {
      pose_validation: true,
      agencychange: '',
    }
  },
  methods: {
    agencyChanged: function () {
      this.agencychange = this.$store.state.selectedAgencyID
    }
  },
}
</script>
<style scoped>
h6.title{
  color: #9f137e;
}

</style>
